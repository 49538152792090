import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 810 1080">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FCD51D" opacity="1.000000" stroke="none" 
	d="
M665.000000,1081.000000 
	C443.692841,1081.000000 222.385712,1081.000000 1.039281,1081.000000 
	C1.039281,721.068787 1.039281,361.137573 1.039281,1.103171 
	C270.899231,1.103171 540.798523,1.103171 811.000000,1.103171 
	C811.000000,310.029327 811.000000,619.058716 810.662170,928.607178 
	C806.885559,931.746704 803.446777,934.367249 799.645142,937.005615 
	C797.648254,938.054871 795.847778,938.896851 794.413818,940.156250 
	C791.179504,942.996948 788.126465,946.044128 784.922363,948.596313 
	C784.563416,945.218445 784.035583,942.251709 784.035217,939.284790 
	C783.997742,642.481873 784.000061,345.678894 784.000977,48.875957 
	C784.000977,46.542938 783.627075,44.127167 784.103210,41.894077 
	C785.103760,37.202068 782.770264,34.756035 778.659607,32.970207 
	C777.920410,32.913555 777.497925,32.882156 777.008911,32.467911 
	C774.619202,31.388403 772.296387,30.082689 769.972961,30.081663 
	C559.178284,29.988670 348.383575,29.995710 137.588837,29.995676 
	C135.922485,29.995676 134.226624,30.181505 132.598892,29.929482 
	C131.228775,29.717342 129.937897,28.993345 128.611954,28.495934 
	C129.241867,27.245571 129.617035,25.741444 130.555969,24.798901 
	C132.379059,22.968796 134.518829,21.454138 136.591629,19.756166 
	C132.561386,17.523451 128.135559,20.509787 123.568069,28.129396 
	C120.350670,30.501404 117.176826,32.523968 114.639511,35.159363 
	C111.959625,37.942841 109.886482,41.310474 107.499329,44.443756 
	C107.450371,44.464592 107.350876,44.426834 106.979691,44.361221 
	C105.444885,45.902462 104.281258,47.509323 103.102898,49.083839 
	C103.088165,49.051498 103.102325,49.108875 102.777695,49.144352 
	C101.990669,49.841198 101.528282,50.502571 100.956009,51.347778 
	C100.677269,51.766598 100.471603,51.963051 99.869171,52.130184 
	C94.582825,56.727665 89.601974,61.260067 84.782928,65.958435 
	C83.966637,66.754288 83.846313,68.263969 83.260582,69.699097 
	C82.426231,70.652069 81.735741,71.349785 80.679596,72.043503 
	C77.935181,74.743332 75.556412,77.447151 73.160858,80.154007 
	C73.144058,80.157036 73.149170,80.123566 72.906570,80.215668 
	C72.462708,80.581604 72.261444,80.855431 71.946121,81.233810 
	C71.832054,81.338364 71.528732,81.399666 71.151352,81.376175 
	C70.248940,82.322281 69.723915,83.291885 69.053635,84.538498 
	C67.683067,85.930176 66.457764,87.044830 64.987732,88.255692 
	C64.578239,88.651161 64.413483,88.950432 64.273331,89.250511 
	C64.297935,89.251320 64.295662,89.300507 63.933205,89.265427 
	C60.375820,92.806534 57.180897,96.382721 53.868896,100.180443 
	C53.483349,100.594543 53.214874,100.787109 52.640579,101.048004 
	C51.896526,101.768951 51.458294,102.421593 50.914093,103.280716 
	C50.615211,103.743729 50.365547,103.919769 49.828140,104.129845 
	C49.400852,104.521172 49.204556,104.797989 48.914528,105.282974 
	C48.655048,105.761734 48.423492,105.952377 47.817505,106.087296 
	C47.114700,106.535492 46.720528,106.959480 46.299927,107.332634 
	C46.273495,107.281799 46.380924,107.321671 46.084167,107.423386 
	C45.547775,108.075203 45.308140,108.625305 44.943108,109.420258 
	C44.267532,110.135841 43.717350,110.606567 42.843910,111.114456 
	C42.056797,111.794556 41.592937,112.437477 41.123936,113.098587 
	C41.118793,113.116776 41.086323,113.137634 40.741234,113.156036 
	C35.930672,118.422050 31.465191,123.669678 26.662777,128.998108 
	C20.397842,133.895981 14.485723,138.726288 11.944552,146.429489 
	C15.386941,144.074600 18.185461,141.097931 21.199606,138.358505 
	C23.149429,136.586380 25.414715,135.161362 27.538643,133.580811 
	C28.024515,136.254089 28.935390,138.927261 28.935961,141.600647 
	C28.999836,441.000397 28.995739,740.400146 28.998402,1039.799927 
	C28.998417,1041.465576 28.547293,1043.361450 29.189783,1044.737549 
	C29.967194,1046.402832 31.686697,1047.628174 33.130676,1049.384277 
	C36.545715,1050.474731 39.828266,1051.880005 43.111526,1051.881714 
	C246.881409,1051.984375 450.651337,1051.960693 654.421265,1052.012573 
	C662.722412,1052.014771 671.021423,1052.708984 679.325562,1052.918701 
	C682.174377,1052.990601 683.249023,1054.232056 682.986084,1056.941895 
	C682.960999,1056.966187 682.917664,1057.020752 682.638672,1057.100830 
	C681.911621,1057.762573 681.463501,1058.344116 680.991455,1058.945801 
	C680.967590,1058.966187 680.925781,1059.012939 680.611450,1059.081055 
	C678.871460,1060.412964 677.445801,1061.677002 675.818909,1063.102295 
	C675.414612,1063.539673 675.211487,1063.815552 675.021729,1064.287354 
	C675.035095,1064.483398 675.041443,1064.876465 674.937256,1065.236206 
	C673.503418,1068.031616 672.695923,1071.897583 670.737854,1072.612549 
	C666.333618,1074.220947 664.671326,1076.657837 665.000000,1081.000000 
z"/>
<path fill="#F2D50D" opacity="1.000000" stroke="none" 
	d="
M665.468628,1081.000000 
	C664.671326,1076.657837 666.333618,1074.220947 670.737854,1072.612549 
	C672.695923,1071.897583 673.503418,1068.031616 675.317078,1065.250977 
	C679.822998,1064.953735 681.123169,1062.634521 680.925781,1059.012939 
	C680.925781,1059.012939 680.967590,1058.966187 681.278809,1058.879395 
	C682.032593,1058.201904 682.475098,1057.611328 682.917603,1057.020752 
	C682.917664,1057.020752 682.960999,1056.966187 683.353882,1056.946167 
	C688.131714,1053.620361 692.516663,1050.314575 697.197266,1046.834961 
	C703.578247,1040.322021 709.638367,1033.958130 715.753601,1027.647705 
	C734.005554,1008.812866 752.345764,990.062683 770.482361,971.117493 
	C773.715881,967.739868 776.140564,963.587830 778.912537,959.637817 
	C778.886292,959.482300 778.994629,959.185974 779.259216,959.137817 
	C779.696777,959.042358 779.869751,958.995117 780.308228,958.772583 
	C781.381836,957.408936 782.189880,956.220520 783.322144,954.943787 
	C789.100159,948.899597 794.554077,942.943726 800.007996,936.987793 
	C803.446777,934.367249 806.885559,931.746704 810.662170,929.063110 
	C811.000000,979.592224 811.000000,1030.184448 811.000000,1081.000000 
	C762.645996,1081.000000 714.291687,1081.000000 665.468628,1081.000000 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M696.901611,1047.008667 
	C692.516663,1050.314575 688.131714,1053.620361 683.378906,1056.921875 
	C683.249023,1054.232056 682.174377,1052.990601 679.325562,1052.918701 
	C671.021423,1052.708984 662.722412,1052.014771 654.421265,1052.012573 
	C450.651337,1051.960693 246.881409,1051.984375 43.111526,1051.881714 
	C39.828266,1051.880005 36.545715,1050.474731 32.976227,1049.001099 
	C32.134163,1042.840576 31.094114,1037.403687 31.092667,1031.966431 
	C31.014292,737.889587 31.018728,443.812744 31.058224,149.735901 
	C31.058954,144.307236 31.482658,138.870926 31.897877,133.452652 
	C32.186501,129.686325 30.093430,128.848190 26.999712,128.917297 
	C31.465191,123.669678 35.930672,118.422050 40.743874,113.475403 
	C41.041710,114.414322 40.991817,115.052254 40.841774,115.862015 
	C40.741619,116.033836 40.832531,116.414330 40.459068,116.448563 
	C36.475895,119.080811 32.417831,121.388718 33.266453,127.384872 
	C33.194237,402.613739 33.184788,677.373535 33.195553,952.133362 
	C33.196766,983.121582 33.312241,1014.109802 33.341690,1045.460083 
	C34.839348,1046.166138 36.370041,1046.510132 38.368713,1046.941895 
	C40.501968,1047.046997 42.167244,1047.079956 43.832520,1047.079834 
	C260.575958,1047.059814 477.319427,1047.037476 694.418823,1047.023193 
	C695.483704,1047.023560 696.192627,1047.016113 696.901611,1047.008667 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M777.075439,32.850754 
	C777.497925,32.882156 777.920410,32.913555 778.713257,33.289547 
	C779.408508,34.431362 779.733337,35.228588 780.044861,36.491280 
	C780.025635,114.061577 780.012756,191.166412 780.053833,268.271210 
	C780.054871,270.201294 780.650940,272.131042 780.964966,274.521271 
	C780.963623,279.301239 780.966858,283.620850 780.741211,288.290894 
	C780.344849,291.117615 780.031067,293.593933 780.030701,296.070312 
	C780.003113,515.045227 779.999573,734.020203 779.752930,952.997559 
	C779.511475,953.000000 779.028442,952.997986 778.904846,952.613525 
	C778.718384,950.065186 778.600891,947.901306 778.600403,945.737366 
	C778.549072,722.973083 778.489197,500.208771 778.472961,277.444458 
	C778.467285,198.969406 778.570984,120.494370 778.620422,42.019321 
	C778.624878,34.943066 778.610107,34.953228 771.488159,34.953339 
	C558.220215,34.956692 344.952240,34.957138 131.684265,34.965950 
	C128.187973,34.966095 124.639282,34.703918 121.207985,35.203072 
	C116.397507,35.902851 113.257370,38.838749 112.104355,43.507683 
	C110.484001,43.639786 109.016151,44.031357 107.548294,44.422928 
	C109.886482,41.310474 111.959625,37.942841 114.639511,35.159363 
	C117.176826,32.523968 120.350670,30.501404 123.734329,28.456059 
	C125.660721,33.843803 129.871124,33.002048 133.627274,33.002220 
	C343.112183,33.011795 552.597046,33.009991 762.081970,33.000729 
	C767.079773,33.000507 772.077637,32.902927 777.075439,32.850754 
z"/>
<path fill="#EFC719" opacity="1.000000" stroke="none" 
	d="
M780.058228,36.025818 
	C779.733337,35.228588 779.408508,34.431362 779.029968,33.314800 
	C782.770264,34.756035 785.103760,37.202068 784.103210,41.894077 
	C783.627075,44.127167 784.000977,46.542938 784.000977,48.875957 
	C784.000061,345.678894 783.997742,642.481873 784.035217,939.284790 
	C784.035583,942.251709 784.563416,945.218445 784.834900,948.920044 
	C784.168335,950.817139 783.511841,951.979614 782.671509,953.127930 
	C782.487671,953.113892 782.119507,953.093567 781.859314,952.751953 
	C781.401855,950.110474 781.032410,947.810608 781.031982,945.510681 
	C781.004272,814.596191 781.011902,683.681641 781.005798,552.767151 
	C781.001648,464.491608 780.982544,376.216034 780.970093,287.940491 
	C780.966858,283.620850 780.963623,279.301239 780.976929,274.046143 
	C780.998901,196.010071 781.011230,118.909431 780.969971,41.808811 
	C780.968994,39.880981 780.375427,37.953472 780.058228,36.025818 
z"/>
<path fill="#EFC719" opacity="1.000000" stroke="none" 
	d="
M777.008911,32.467915 
	C772.077637,32.902927 767.079773,33.000507 762.081970,33.000729 
	C552.597046,33.009991 343.112183,33.011795 133.627274,33.002220 
	C129.871124,33.002048 125.660721,33.843803 124.057823,28.376137 
	C128.135559,20.509787 132.561386,17.523451 136.591629,19.756166 
	C134.518829,21.454138 132.379059,22.968796 130.555969,24.798901 
	C129.617035,25.741444 129.241867,27.245571 128.611954,28.495934 
	C129.937897,28.993345 131.228775,29.717342 132.598892,29.929482 
	C134.226624,30.181505 135.922485,29.995676 137.588837,29.995676 
	C348.383575,29.995710 559.178284,29.988670 769.972961,30.081663 
	C772.296387,30.082689 774.619202,31.388403 777.008911,32.467915 
z"/>
<path fill="#E7C21A" opacity="1.000000" stroke="none" 
	d="
M26.662777,128.998108 
	C30.093430,128.848190 32.186501,129.686325 31.897877,133.452652 
	C31.482658,138.870926 31.058954,144.307236 31.058224,149.735901 
	C31.018728,443.812744 31.014292,737.889587 31.092667,1031.966431 
	C31.094114,1037.403687 32.134163,1042.840576 32.844070,1048.660889 
	C31.686697,1047.628174 29.967194,1046.402832 29.189783,1044.737549 
	C28.547293,1043.361450 28.998417,1041.465576 28.998402,1039.799927 
	C28.995739,740.400146 28.999836,441.000397 28.935961,141.600647 
	C28.935390,138.927261 28.024515,136.254089 27.538643,133.580811 
	C25.414715,135.161362 23.149429,136.586380 21.199606,138.358505 
	C18.185461,141.097931 15.386941,144.074600 11.944552,146.429489 
	C14.485723,138.726288 20.397842,133.895981 26.662777,128.998108 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M40.941925,115.690193 
	C40.991817,115.052254 41.041710,114.414322 41.088963,113.457008 
	C41.086323,113.137634 41.118793,113.116776 41.433037,113.053070 
	C42.220573,112.352005 42.693867,111.714645 43.167160,111.077286 
	C43.717350,110.606567 44.267532,110.135841 45.214977,109.282028 
	C45.868465,108.373184 46.124695,107.847427 46.380920,107.321671 
	C46.380924,107.321671 46.273495,107.281799 46.593063,107.291313 
	C47.317135,106.888245 47.721634,106.475670 48.126129,106.063095 
	C48.423492,105.952377 48.655048,105.761734 49.161705,105.187912 
	C49.688122,104.594879 49.873627,104.305107 50.059135,104.015327 
	C50.365547,103.919769 50.615211,103.743729 51.206230,103.190689 
	C52.051693,102.256004 52.499046,101.617844 52.946396,100.979683 
	C53.214874,100.787109 53.483349,100.594543 54.194115,100.093689 
	C57.856159,96.290436 61.075909,92.795471 64.295662,89.300507 
	C64.295662,89.300507 64.297935,89.251320 64.512245,89.148529 
	C64.895195,88.750320 65.063828,88.454903 65.232460,88.159492 
	C66.457764,87.044830 67.683067,85.930176 69.347145,84.406509 
	C70.366859,83.131561 70.947792,82.265610 71.528732,81.399666 
	C71.528732,81.399666 71.832054,81.338364 72.200623,81.157974 
	C72.762520,80.692917 72.955849,80.408241 73.149170,80.123566 
	C73.149170,80.123566 73.144058,80.157036 73.446548,80.079147 
	C74.659790,79.652634 75.570541,79.304008 76.597900,79.347717 
	C76.872780,80.821114 77.031044,81.902184 76.854172,83.116089 
	C74.477158,84.544792 72.212822,85.594841 70.445549,87.194153 
	C67.794945,89.592827 65.493141,92.376915 62.793774,95.215927 
	C60.609024,97.656982 58.673508,99.879219 56.774197,102.059883 
	C59.715385,109.149643 58.286785,116.123619 56.539410,123.007011 
	C56.311108,123.906364 54.244057,124.338951 52.853340,124.702179 
	C51.356792,122.759499 49.817951,121.229126 48.762756,119.417114 
	C46.947517,116.299927 44.786427,114.346962 40.941925,115.690193 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M107.499329,44.443760 
	C109.016151,44.031357 110.484001,43.639786 112.637390,43.546780 
	C122.533867,43.662292 131.744827,43.479240 140.798645,43.612663 
	C138.093948,44.950695 135.546371,45.972248 132.603394,47.012566 
	C130.347733,47.653915 128.530823,48.531693 126.619957,48.856316 
	C116.661354,50.548119 106.254494,50.559021 97.696716,56.570320 
	C98.614204,54.867085 99.421661,53.494026 100.229126,52.120972 
	C100.471603,51.963051 100.677269,51.766598 101.250977,51.260277 
	C102.137993,50.362255 102.620163,49.735569 103.102325,49.108879 
	C103.102325,49.108875 103.088165,49.051498 103.432617,49.032906 
	C104.968346,47.485157 106.159607,45.955994 107.350876,44.426834 
	C107.350876,44.426834 107.450371,44.464592 107.499329,44.443760 
z"/>
<path fill="#E7C21A" opacity="1.000000" stroke="none" 
	d="
M782.855286,953.142029 
	C783.511841,951.979614 784.168335,950.817139 784.912231,949.331055 
	C788.126465,946.044128 791.179504,942.996948 794.413818,940.156250 
	C795.847778,938.896851 797.648254,938.054871 799.645142,937.005615 
	C794.554077,942.943726 789.100159,948.899597 783.292297,954.628052 
	C782.910645,953.981079 782.882996,953.561584 782.855286,953.142029 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M99.869164,52.130180 
	C99.421661,53.494026 98.614204,54.867085 97.530258,56.623558 
	C97.253784,57.006977 96.967598,57.217831 96.793236,57.297375 
	C96.401886,57.547276 96.291199,57.770439 96.084625,58.221329 
	C95.575157,58.828152 95.267868,59.260056 94.637321,59.812332 
	C92.661377,61.674160 91.008690,63.415615 89.260368,65.189377 
	C89.164742,65.221680 89.077026,65.403496 88.774109,65.486603 
	C86.782272,66.861099 85.093353,68.152481 83.404442,69.443855 
	C83.846313,68.263969 83.966637,66.754288 84.782928,65.958435 
	C89.601974,61.260067 94.582825,56.727665 99.869164,52.130180 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M63.933205,89.265427 
	C61.075909,92.795471 57.856159,96.290436 54.311188,99.872162 
	C57.180897,96.382721 60.375820,92.806534 63.933205,89.265427 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M76.481277,78.955383 
	C75.570541,79.304008 74.659790,79.652634 73.463348,80.076118 
	C75.556412,77.447151 77.935181,74.743332 80.795685,72.306252 
	C81.335617,72.755669 81.393799,72.938339 81.212914,73.373360 
	C79.476318,75.402267 77.978798,77.178825 76.481277,78.955383 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M81.451988,73.121002 
	C81.393799,72.938339 81.335617,72.755669 81.161331,72.310242 
	C81.735741,71.349785 82.426231,70.652069 83.260582,69.699097 
	C85.093353,68.152481 86.782272,66.861099 88.733719,65.809952 
	C86.636154,68.447594 84.276062,70.845001 81.794373,73.233490 
	C81.672775,73.224556 81.451988,73.121002 81.451988,73.121002 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M680.611450,1059.081055 
	C681.123169,1062.634521 679.822998,1064.953735 675.421265,1064.891357 
	C675.041443,1064.876465 675.035095,1064.483398 675.225220,1064.127563 
	C675.617004,1063.494751 675.818542,1063.217896 676.020081,1062.940918 
	C677.445801,1061.677002 678.871460,1060.412964 680.611450,1059.081055 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M106.979691,44.361221 
	C106.159607,45.955994 104.968346,47.485157 103.447357,49.065247 
	C104.281258,47.509323 105.444885,45.902462 106.979691,44.361221 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M71.151352,81.376175 
	C70.947792,82.265610 70.366859,83.131561 69.492401,84.129501 
	C69.723915,83.291885 70.248940,82.322281 71.151352,81.376175 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M42.843910,111.114456 
	C42.693867,111.714645 42.220573,112.352005 41.438179,113.034882 
	C41.592937,112.437477 42.056797,111.794556 42.843910,111.114456 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M682.638672,1057.100830 
	C682.475098,1057.611328 682.032593,1058.201904 681.302734,1058.859131 
	C681.463501,1058.344116 681.911621,1057.762573 682.638672,1057.100830 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M52.640579,101.048004 
	C52.499046,101.617844 52.051693,102.256004 51.312202,102.984192 
	C51.458294,102.421593 51.896526,101.768951 52.640579,101.048004 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M102.777695,49.144356 
	C102.620163,49.735569 102.137993,50.362255 101.360863,51.076447 
	C101.528282,50.502571 101.990669,49.841198 102.777695,49.144356 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M46.084167,107.423386 
	C46.124695,107.847427 45.868465,108.373184 45.340370,109.037170 
	C45.308140,108.625305 45.547775,108.075203 46.084167,107.423386 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M47.817501,106.087296 
	C47.721634,106.475670 47.317135,106.888245 46.619495,107.342148 
	C46.720528,106.959480 47.114700,106.535492 47.817501,106.087296 
z"/>
<path fill="#F2D50D" opacity="1.000000" stroke="none" 
	d="
M675.818909,1063.102295 
	C675.818542,1063.217896 675.617004,1063.494751 675.211914,1063.931641 
	C675.211487,1063.815552 675.414612,1063.539673 675.818909,1063.102295 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M49.828140,104.129845 
	C49.873627,104.305107 49.688122,104.594879 49.255436,104.979729 
	C49.204556,104.797989 49.400852,104.521172 49.828140,104.129845 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M64.987732,88.255692 
	C65.063828,88.454903 64.895195,88.750320 64.487640,89.147720 
	C64.413483,88.950432 64.578239,88.651161 64.987732,88.255692 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M72.906570,80.215668 
	C72.955849,80.408241 72.762520,80.692917 72.314690,81.053421 
	C72.261444,80.855431 72.462708,80.581604 72.906570,80.215668 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M697.197266,1046.834961 
	C696.192627,1047.016113 695.483704,1047.023560 694.565186,1046.728516 
	C695.917908,1044.616089 697.480103,1042.806274 699.358154,1040.882324 
	C713.900146,1025.858276 728.088074,1010.911743 742.364929,996.050537 
	C752.951904,985.030151 763.654358,974.120728 774.545898,963.022217 
	C775.200806,962.272217 775.615234,961.661926 776.346680,961.008545 
	C777.422058,960.574768 778.180420,960.184082 778.938782,959.793396 
	C776.140564,963.587830 773.715881,967.739868 770.482361,971.117493 
	C752.345764,990.062683 734.005554,1008.812866 715.753601,1027.647705 
	C709.638367,1033.958130 703.578247,1040.322021 697.197266,1046.834961 
z"/>
<path fill="#DCC13A" opacity="1.000000" stroke="none" 
	d="
M782.671509,953.127930 
	C782.882996,953.561584 782.910645,953.981079 782.968140,954.716370 
	C782.189880,956.220520 781.381836,957.408936 780.305786,958.383667 
	C780.053101,957.128296 780.068420,956.086487 780.378113,954.971313 
	C781.154907,954.296387 781.637207,953.695007 782.119507,953.093567 
	C782.119507,953.093567 782.487671,953.113892 782.671509,953.127930 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M778.912537,959.637817 
	C778.180420,960.184082 777.422058,960.574768 776.339966,960.689209 
	C776.749084,959.597107 777.481934,958.781128 778.414185,958.267578 
	C778.740540,958.775269 778.867615,958.980591 778.994629,959.185974 
	C778.994629,959.185974 778.886292,959.482300 778.912537,959.637817 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M779.259216,959.137817 
	C778.867615,958.980591 778.740540,958.775269 778.562256,957.872314 
	C778.683472,955.782410 778.855957,954.390198 779.028442,952.997986 
	C779.028442,952.997986 779.511475,953.000000 779.764648,953.339050 
	C780.039795,954.133667 780.061768,954.589172 780.083679,955.044739 
	C780.068420,956.086487 780.053101,957.128296 780.040283,958.558960 
	C779.869751,958.995117 779.696777,959.042358 779.259216,959.137817 
z"/>
<path fill="#FDFEF8" opacity="1.000000" stroke="none" 
	d="
M699.042236,1040.996460 
	C697.480103,1042.806274 695.917908,1044.616089 694.209290,1046.720459 
	C477.319427,1047.037476 260.575958,1047.059814 43.832520,1047.079834 
	C42.167244,1047.079956 40.501968,1047.046997 38.132011,1046.752686 
	C36.076462,1046.016602 34.725597,1045.557251 33.374733,1045.098022 
	C33.312241,1014.109802 33.196766,983.121582 33.195553,952.133362 
	C33.184788,677.373535 33.194237,402.613739 33.465851,127.144363 
	C36.077267,124.313736 38.420551,122.192650 40.800133,120.532509 
	C40.911396,126.004593 40.986362,131.015732 40.987122,136.488724 
	C40.956730,146.964264 41.000546,156.977966 40.976967,167.456833 
	C40.968132,176.619202 41.026695,185.316391 41.025246,194.446320 
	C41.005169,196.914642 41.045105,198.950226 41.015915,201.412109 
	C40.992855,203.561783 41.038925,205.285141 41.022171,207.472946 
	C40.987988,214.951279 41.016628,221.965164 40.990288,229.446350 
	C40.989437,259.893555 41.043564,289.873474 41.066086,320.334442 
	C41.018871,351.870789 41.003258,382.926086 40.973961,414.451935 
	C40.985901,427.611084 41.011524,440.299652 41.018166,453.457306 
	C41.008595,463.622894 41.018005,473.319397 41.009789,483.478729 
	C40.996479,488.293121 41.000797,492.644653 40.996593,497.465057 
	C40.997265,511.624481 41.006458,525.315063 40.993332,539.473633 
	C40.990986,564.622375 41.010960,589.303162 41.011990,614.453857 
	C41.001118,628.947754 41.009193,642.971741 41.008766,657.464600 
	C41.009388,678.619873 41.018513,699.306274 41.019386,720.461731 
	C40.999802,824.740112 41.011871,928.549438 40.899994,1032.358643 
	C40.895008,1036.985718 41.572586,1039.148560 47.059856,1039.138184 
	C143.037262,1038.956543 239.015091,1038.987671 335.368378,1038.981201 
	C336.494720,1038.987549 337.245514,1038.984985 338.465149,1038.988159 
	C346.619934,1038.989502 354.305847,1038.984985 362.460938,1038.985962 
	C382.956207,1038.987915 402.982300,1038.984253 423.476135,1038.986206 
	C441.967041,1038.984375 459.990204,1038.976807 478.481140,1038.975342 
	C485.275940,1038.987671 491.602966,1038.998901 497.929993,1038.999268 
	C562.532410,1039.002319 627.134888,1038.982056 691.737122,1039.089722 
	C694.173218,1039.093750 696.607239,1040.332886 699.042236,1040.996460 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M41.061325,136.026886 
	C40.986362,131.015732 40.911396,126.004593 40.860172,120.080811 
	C40.866787,118.250229 40.849659,117.332283 40.832531,116.414330 
	C40.832531,116.414330 40.741619,116.033836 40.841774,115.862015 
	C44.786427,114.346962 46.947517,116.299927 48.762756,119.417114 
	C49.817951,121.229126 51.356792,122.759499 53.007763,124.990227 
	C54.343304,126.965500 55.349083,128.367157 56.339611,129.747559 
	C55.085644,130.889832 53.984459,131.892929 52.506294,132.931854 
	C48.439983,133.987396 44.750652,135.007141 41.061325,136.026886 
z"/>
<path fill="#EBECE9" opacity="1.000000" stroke="none" 
	d="
M40.459068,116.448563 
	C40.849659,117.332283 40.866787,118.250229 40.823875,119.619873 
	C38.420551,122.192650 36.077267,124.313736 33.534584,126.675323 
	C32.417831,121.388718 36.475895,119.080811 40.459068,116.448563 
z"/>
<path fill="#EBECE9" opacity="1.000000" stroke="none" 
	d="
M33.341694,1045.460083 
	C34.725597,1045.557251 36.076462,1046.016602 37.664028,1046.665039 
	C36.370041,1046.510132 34.839348,1046.166138 33.341694,1045.460083 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M778.904846,952.613525 
	C778.855957,954.390198 778.683472,955.782410 778.362915,957.569946 
	C777.481934,958.781128 776.749084,959.597107 776.022949,960.732422 
	C775.615234,961.661926 775.200806,962.272217 774.126587,962.937866 
	C769.548889,961.900635 771.084900,958.637024 771.081970,956.304565 
	C770.989014,883.201477 770.993835,810.098267 770.992371,736.527100 
	C770.994202,725.369873 770.987427,714.680725 770.991211,703.524841 
	C770.680115,697.040344 770.358459,691.022644 770.276733,684.666870 
	C770.680176,682.523621 770.979309,680.718811 770.985535,678.913025 
	C771.029541,666.276733 771.013000,653.640320 771.032715,640.705444 
	C771.070557,640.010498 771.091553,639.614014 771.124756,638.735474 
	C771.103394,600.826111 771.069824,563.398804 771.058838,525.505920 
	C771.045105,511.690125 771.008911,498.339966 771.002319,484.523376 
	C771.014832,478.034302 770.997681,472.011627 771.010742,465.522858 
	C771.013367,459.359833 770.985779,453.662903 771.016479,447.514221 
	C770.995056,443.741943 770.915283,440.421417 770.924194,436.632996 
	C770.985107,407.783600 770.957458,379.402100 771.166992,350.743744 
	C771.262634,348.642822 771.121094,346.818787 771.014282,344.541962 
	C771.033142,341.059662 771.017273,338.030090 771.053101,334.534058 
	C771.069214,327.044891 771.033569,320.022186 771.054932,312.533813 
	C771.050293,305.371429 770.988647,298.674713 770.995117,291.525269 
	C771.024353,287.713898 770.985413,284.355255 771.040405,280.560669 
	C771.065674,277.754150 770.996887,275.383545 771.025146,272.552734 
	C771.003235,266.439178 770.884338,260.785767 770.874756,254.663971 
	C771.036987,252.202469 771.133667,250.209381 771.136169,248.216171 
	C771.172424,219.116547 771.196106,190.016891 771.373779,160.708344 
	C771.356445,159.664505 771.188049,158.829559 771.016846,157.526321 
	C771.006409,142.037338 770.998779,127.016663 770.943481,111.655716 
	C770.917664,109.522255 770.939575,107.729065 770.994324,105.472092 
	C771.008179,88.670120 770.989197,72.331940 771.019531,55.552704 
	C771.038574,52.744484 771.008301,50.377316 771.160645,47.845474 
	C771.231506,47.119755 771.119690,46.558716 771.010803,45.588211 
	C769.739319,44.471912 768.466125,43.151245 767.190369,43.148697 
	C740.475220,43.095272 713.759705,43.190922 686.663208,43.195599 
	C685.514160,43.175934 684.746094,43.217930 683.514526,43.197792 
	C679.723022,43.110405 676.395020,43.063488 673.067017,43.063087 
	C601.851135,43.054539 530.635254,43.032291 459.419403,43.057156 
	C434.629791,43.065811 409.840210,43.193428 384.666931,43.193649 
	C383.507660,43.165680 382.732056,43.210773 381.495178,43.195400 
	C374.371613,43.178642 367.709351,43.222351 360.663757,43.197765 
	C359.506042,43.171707 358.731689,43.213943 357.569916,43.197426 
	C342.797607,43.186626 328.412720,43.234577 313.585938,43.202374 
	C310.754059,43.166588 308.364014,43.210957 305.614197,43.214710 
	C266.626434,43.140003 227.998505,43.087772 189.370560,43.083786 
	C178.886322,43.082706 168.402084,43.227303 157.459473,43.243183 
	C151.652664,43.219612 146.304214,43.257904 140.955780,43.296192 
	C131.744827,43.479240 122.533867,43.662292 112.789879,43.806252 
	C113.257370,38.838749 116.397507,35.902851 121.207985,35.203072 
	C124.639282,34.703918 128.187973,34.966095 131.684265,34.965950 
	C344.952240,34.957138 558.220215,34.956692 771.488159,34.953339 
	C778.610107,34.953228 778.624878,34.943066 778.620422,42.019321 
	C778.570984,120.494370 778.467285,198.969406 778.472961,277.444458 
	C778.489197,500.208771 778.549072,722.973083 778.600403,945.737366 
	C778.600891,947.901306 778.718384,950.065186 778.904846,952.613525 
z"/>
<path fill="#E7C21A" opacity="1.000000" stroke="none" 
	d="
M780.378113,954.971313 
	C780.061768,954.589172 780.039795,954.133667 780.006165,953.336609 
	C779.999573,734.020203 780.003113,515.045227 780.030701,296.070312 
	C780.031067,293.593933 780.344849,291.117615 780.741211,288.290894 
	C780.982544,376.216034 781.001648,464.491608 781.005798,552.767151 
	C781.011902,683.681641 781.004272,814.596191 781.031982,945.510681 
	C781.032410,947.810608 781.401855,950.110474 781.859314,952.751953 
	C781.637207,953.695007 781.154907,954.296387 780.378113,954.971313 
z"/>
<path fill="#E7C21A" opacity="1.000000" stroke="none" 
	d="
M780.044922,36.491280 
	C780.375427,37.953472 780.968994,39.880981 780.969971,41.808811 
	C781.011230,118.909431 780.998901,196.010071 780.981506,273.585815 
	C780.650940,272.131042 780.054871,270.201294 780.053833,268.271210 
	C780.012756,191.166412 780.025635,114.061577 780.044922,36.491280 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M63.043011,94.997108 
	C65.493141,92.376915 67.794945,89.592827 70.445549,87.194153 
	C72.212822,85.594841 74.477158,84.544792 77.126701,83.311737 
	C80.191025,88.241104 82.780663,93.046112 85.068916,97.990601 
	C87.624809,103.513382 89.907166,109.162766 92.028702,114.961197 
	C90.526451,114.821915 89.274590,114.112419 88.087547,114.207993 
	C85.236458,114.437538 80.847115,114.097023 79.888123,115.684181 
	C77.391136,119.816765 76.652130,124.550560 77.675690,129.951080 
	C78.975861,136.811127 78.614021,144.079117 78.253822,151.134705 
	C77.895187,158.159760 71.208183,164.713226 76.734131,172.254929 
	C77.103340,172.758820 76.682617,173.858398 76.595032,174.677246 
	C76.152733,178.812241 78.628777,182.477829 82.675537,183.660873 
	C86.561218,184.796814 90.873695,182.906494 92.751228,179.244370 
	C95.287819,174.296738 95.950912,174.268921 98.672134,179.114777 
	C96.405739,181.712173 95.988190,184.026825 98.998611,186.438477 
	C99.005775,188.917908 99.012283,190.959854 98.569229,193.001282 
	C94.033386,193.038940 90.381111,193.153793 86.528442,195.908142 
	C80.346786,200.327499 73.107002,196.331024 66.320328,196.269394 
	C65.858849,196.265198 65.388710,195.395279 64.950607,194.903061 
	C57.453987,186.480545 56.197693,176.377884 57.366146,165.747391 
	C57.807510,161.731918 58.419056,157.602798 59.834316,153.858597 
	C61.817684,148.611435 64.911263,143.881104 65.099060,137.918701 
	C65.307899,131.287994 63.223297,124.318069 67.592987,118.091324 
	C68.175308,117.261528 67.166977,115.312874 66.890091,113.883362 
	C66.501747,111.878349 66.124542,109.871002 65.714134,107.870491 
	C64.833412,103.577385 63.934483,99.288010 63.043011,94.997108 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M62.793774,95.215927 
	C63.934483,99.288010 64.833412,103.577385 65.714134,107.870491 
	C66.124542,109.871002 66.501747,111.878349 66.890091,113.883362 
	C67.166977,115.312874 68.175308,117.261528 67.592987,118.091324 
	C63.223297,124.318069 65.307899,131.287994 65.099060,137.918701 
	C64.911263,143.881104 61.817684,148.611435 59.834316,153.858597 
	C58.419056,157.602798 57.807510,161.731918 57.366146,165.747391 
	C56.197693,176.377884 57.453987,186.480545 64.950607,194.903061 
	C65.388710,195.395279 65.858849,196.265198 66.320328,196.269394 
	C73.107002,196.331024 80.346786,200.327499 86.528442,195.908142 
	C90.381111,193.153793 94.033386,193.038940 98.914703,193.134491 
	C100.729965,196.459045 101.750191,199.649826 103.104164,203.884384 
	C100.382622,203.557602 98.405403,203.114395 96.431770,203.129852 
	C93.200241,203.155121 89.187347,202.334030 86.895874,203.902878 
	C81.260246,207.761276 75.317650,208.202499 68.961769,208.169113 
	C66.862762,208.158066 64.773544,209.421814 62.653973,209.515121 
	C58.550446,209.695770 53.565849,210.868195 50.545364,209.018539 
	C47.207462,206.974442 44.485077,206.553085 41.084991,207.008499 
	C41.038925,205.285141 40.992855,203.561783 41.350464,201.278091 
	C43.574337,198.279190 43.966812,195.983063 41.085258,194.013596 
	C41.026695,185.316391 40.968132,176.619202 41.318626,167.324005 
	C45.222046,159.852219 48.902027,153.064087 52.130543,146.067642 
	C53.687691,142.693161 54.534016,138.957443 55.346554,135.306152 
	C55.478153,134.714783 53.751160,133.709824 52.883270,132.896042 
	C53.984459,131.892929 55.085644,130.889832 56.339611,129.747559 
	C55.349083,128.367157 54.343304,126.965500 53.183098,125.275787 
	C54.244057,124.338951 56.311108,123.906364 56.539410,123.007011 
	C58.286785,116.123619 59.715385,109.149643 56.774197,102.059883 
	C58.673508,99.879219 60.609024,97.656982 62.793774,95.215927 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M92.308563,114.757057 
	C89.907166,109.162766 87.624809,103.513382 85.068916,97.990601 
	C82.780663,93.046112 80.191025,88.241104 77.461838,83.178909 
	C77.031044,81.902184 76.872780,80.821114 76.597900,79.347717 
	C77.978798,77.178825 79.476318,75.402267 81.212914,73.373360 
	C81.451988,73.121002 81.672775,73.224556 81.996628,73.483902 
	C89.817757,80.268867 97.269966,86.847252 104.826302,93.303764 
	C111.095596,98.660561 117.487518,103.873848 123.971474,109.398354 
	C124.452446,110.112106 124.787056,110.578224 124.806152,111.198990 
	C118.946358,114.176567 113.402100,116.999489 107.497955,119.898125 
	C103.702827,121.269630 100.267593,122.565422 97.072166,123.770760 
	C95.225670,120.276817 93.767120,117.516937 92.308563,114.757057 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M123.825119,109.150711 
	C117.487518,103.873848 111.095596,98.660561 104.826302,93.303764 
	C97.269966,86.847252 89.817757,80.268867 82.118225,73.492828 
	C84.276062,70.845001 86.636154,68.447594 89.036636,65.726837 
	C89.077026,65.403496 89.164742,65.221680 89.589981,65.128746 
	C91.663673,63.254532 93.312126,61.473248 94.960579,59.691963 
	C95.267868,59.260056 95.575157,58.828152 96.219299,58.101742 
	C96.728058,57.635036 96.865211,57.438568 96.967598,57.217831 
	C96.967598,57.217831 97.253784,57.006977 97.420242,56.953739 
	C106.254494,50.559021 116.661354,50.548119 126.619957,48.856316 
	C128.530823,48.531693 130.347733,47.653915 133.044220,47.017086 
	C136.353378,47.002846 138.826309,47.002846 141.790634,47.002846 
	C141.037964,50.013756 140.538086,52.013424 140.020981,54.408939 
	C139.372696,57.244263 138.741623,59.683739 138.069290,62.512341 
	C137.681610,64.341888 137.331497,65.781433 136.989395,67.222870 
	C134.881073,76.106407 132.774567,84.990372 130.347809,94.033066 
	C129.331741,97.790016 128.635300,101.388092 127.685959,105.056076 
	C126.230408,106.467560 125.027763,107.809143 123.825119,109.150711 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M123.971481,109.398361 
	C125.027763,107.809143 126.230408,106.467560 128.057465,105.044342 
	C131.123627,103.631371 133.767685,102.569466 135.968414,100.917114 
	C142.051147,96.350052 148.155502,90.868927 149.456787,83.508568 
	C150.711609,76.410980 149.211258,68.688538 148.098557,61.365623 
	C147.501389,57.435417 144.685196,54.316986 140.038208,54.013092 
	C140.538086,52.013424 141.037964,50.013756 141.790634,47.002846 
	C138.826309,47.002846 136.353378,47.002846 133.439636,46.998325 
	C135.546371,45.972248 138.093948,44.950695 140.798645,43.612663 
	C146.304214,43.257904 151.652664,43.219612 157.325729,43.543369 
	C156.231934,44.584057 154.813507,45.262695 152.995499,46.132515 
	C154.762054,49.623707 157.358810,52.811768 157.963028,56.340965 
	C159.363785,64.522697 161.196045,73.052635 160.140182,81.084740 
	C159.434219,86.455116 159.965927,90.129028 163.004150,94.330765 
	C163.339706,95.442528 163.662262,96.225739 163.968750,97.373314 
	C161.930618,101.584869 159.908554,105.432076 157.173752,110.635323 
	C155.779449,106.699181 154.721024,103.711243 153.399963,99.981903 
	C151.342804,101.886757 149.668365,103.437233 147.956421,105.307121 
	C147.577179,106.408066 147.235458,107.189590 146.485291,108.004745 
	C140.213455,109.544075 134.372238,111.150200 128.464447,112.454773 
	C127.500984,112.667526 126.243340,111.548050 125.121674,111.044334 
	C124.787056,110.578224 124.452446,110.112106 123.971481,109.398361 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M94.637321,59.812336 
	C93.312126,61.473248 91.663673,63.254532 89.685608,65.096436 
	C91.008690,63.415615 92.661377,61.674160 94.637321,59.812336 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M96.793236,57.297375 
	C96.865211,57.438568 96.728058,57.635036 96.421471,57.926823 
	C96.291199,57.770439 96.401886,57.547276 96.793236,57.297375 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M770.983765,736.995117 
	C770.993835,810.098267 770.989014,883.201477 771.081970,956.304565 
	C771.084900,958.637024 769.548889,961.900635 773.886108,963.077637 
	C763.654358,974.120728 752.951904,985.030151 742.364929,996.050537 
	C728.088074,1010.911743 713.900146,1025.858276 699.358154,1040.882324 
	C696.607239,1040.332886 694.173218,1039.093750 691.737122,1039.089722 
	C627.134888,1038.982056 562.532410,1039.002319 497.929993,1038.999268 
	C491.602966,1038.998901 485.275940,1038.987671 478.544220,1038.636475 
	C480.448914,1033.766724 484.402588,1032.074707 489.207489,1032.019165 
	C495.206177,1031.949707 501.220154,1032.219849 507.196472,1031.846191 
	C508.820953,1031.744751 510.333496,1029.853149 511.897400,1028.783203 
	C510.700928,1027.511963 509.459473,1026.279419 508.320587,1024.958496 
	C507.167572,1023.621155 505.625122,1022.344727 505.148712,1020.770569 
	C504.593842,1018.937317 505.057343,1016.808472 504.996033,1014.810425 
	C504.875031,1010.868774 506.696777,1009.045471 510.688171,1008.962585 
	C522.427246,1008.718872 534.165894,1008.396667 545.898376,1007.942078 
	C548.146240,1007.854980 551.228943,1007.776428 552.433594,1006.393372 
	C555.874451,1002.443359 560.557190,1002.353882 564.867126,1000.883728 
	C566.498474,1000.327332 567.693359,998.441406 569.041992,997.115417 
	C573.127075,993.098694 577.148743,989.015991 581.299011,985.068176 
	C582.066528,984.338074 584.199097,984.143494 584.213440,983.657898 
	C584.390625,977.637329 589.457275,977.290527 593.295044,975.424866 
	C597.356018,973.450623 601.116882,970.168091 605.360535,969.399963 
	C615.091736,967.638367 625.030640,966.994019 634.899231,966.041382 
	C637.706238,965.770386 640.611511,966.322021 643.378174,965.893372 
	C645.467224,965.569580 647.757019,964.692078 649.334351,963.336182 
	C651.884094,961.144470 654.045105,960.842407 656.979065,962.504150 
	C658.583984,963.413208 661.310181,964.385620 662.496521,963.653625 
	C669.346008,959.426880 676.783142,961.434937 683.966919,961.044983 
	C686.784729,960.892029 688.452942,960.301086 689.270264,957.099609 
	C689.691284,955.450562 692.127808,953.880249 693.994995,953.163025 
	C695.742371,952.491699 697.940430,952.992859 699.940125,952.979187 
	C703.382080,952.955688 705.152039,951.451416 705.031738,947.781372 
	C704.884338,943.286865 704.601807,938.727783 705.205872,934.309875 
	C705.466187,932.406250 707.741577,930.729858 709.222107,929.056946 
	C710.392273,927.734680 712.467529,926.773560 712.871643,925.303284 
	C713.755432,922.087708 715.598206,920.956421 718.639526,921.002930 
	C720.472107,921.030945 722.309204,920.924622 724.138123,921.010071 
	C727.457947,921.165161 729.090393,919.787720 729.016663,916.327209 
	C728.910156,911.329895 729.223877,906.310364 728.838989,901.340515 
	C728.723083,899.842957 727.176331,897.746948 725.778625,897.185364 
	C720.753845,895.166687 717.077454,886.228271 719.383057,881.272034 
	C720.239075,879.431763 720.976257,875.572449 720.633240,875.433533 
	C715.651917,873.415344 713.824280,868.305420 710.090942,865.327332 
	C708.160156,863.787231 704.337769,863.683899 705.507935,860.401489 
	C706.099182,858.742981 709.500305,857.266724 711.709167,857.146423 
	C721.085571,856.635864 721.097595,856.832764 720.997803,847.267029 
	C720.987427,846.267639 721.261963,845.111084 720.853943,844.306396 
	C720.249023,843.113342 719.239990,841.364990 718.249390,841.256653 
	C713.437561,840.730286 712.829102,837.670410 712.977844,833.789307 
	C713.092651,830.793945 713.082703,827.787842 712.978149,824.791748 
	C712.863403,821.502136 713.296326,818.791870 716.287170,816.459412 
	C717.502869,815.511230 717.930908,810.973206 717.172974,810.489807 
	C711.963684,807.167175 713.400085,802.155457 712.833191,797.505066 
	C712.636597,795.892456 710.906372,793.582642 709.443359,793.184998 
	C705.947693,792.234802 704.951538,790.214722 704.989563,787.009521 
	C705.042969,782.510681 705.196167,777.995911 704.873535,773.519226 
	C704.765686,772.022583 703.461609,769.480408 702.478088,769.366394 
	C697.023621,768.733704 696.644348,765.126160 697.026733,761.036621 
	C697.895935,751.740356 690.956604,745.575928 687.852905,737.879944 
	C687.679443,737.449951 686.623413,737.449646 686.114990,737.067444 
	C684.429626,735.800537 682.806946,734.450317 681.160156,733.132141 
	C682.719666,731.737427 684.065002,729.677429 685.885010,729.091431 
	C689.298096,727.992493 689.831482,723.999634 688.611938,723.511780 
	C683.294312,721.384705 681.463135,716.032593 677.367004,712.870728 
	C672.961609,709.470337 668.137085,705.851807 665.488281,701.194946 
	C663.613098,697.898254 662.148865,696.958984 658.914124,696.976196 
	C647.415466,697.037354 635.916321,697.011658 624.417358,696.989136 
	C620.735840,696.981873 618.516785,696.022705 616.524475,691.944275 
	C614.555420,687.913513 608.536377,686.405884 606.669922,681.328491 
	C606.482727,680.819336 604.171692,681.077881 602.845032,681.003296 
	C600.525574,680.872864 596.927307,681.649963 596.113525,680.481201 
	C593.124573,676.188110 588.091858,673.661621 585.669006,669.670349 
	C583.516724,666.124634 581.781433,664.941467 578.213928,664.983032 
	C576.220398,665.006226 574.075562,665.268372 572.280457,664.630432 
	C570.999939,664.175415 569.450012,662.445190 569.376221,661.203430 
	C569.303467,659.980896 571.007507,657.510254 571.814270,657.558411 
	C578.332031,657.947205 583.342651,654.199219 589.041443,652.319946 
	C596.573975,649.835938 604.975464,649.986694 612.889038,649.331421 
	C611.601318,650.874634 610.427979,652.091431 609.254700,653.308228 
	C610.857666,654.227295 612.458435,655.150269 614.064514,656.064026 
	C615.198181,656.709106 616.283325,657.486023 617.487915,657.950806 
	C620.329956,659.047363 623.191223,660.698303 626.114990,660.860901 
	C633.588440,661.276672 641.103821,660.883057 648.593811,661.104858 
	C650.080322,661.148865 652.500854,662.202209 652.826904,663.289246 
	C654.289673,668.165039 657.676575,669.069702 662.074585,669.063843 
	C664.649231,669.060425 667.350952,669.324768 669.775085,670.131287 
	C676.058228,672.221436 682.056824,675.500488 688.472656,676.768738 
	C695.521484,678.161987 699.086243,680.799683 700.081055,688.234558 
	C700.266602,689.621460 700.859192,691.245300 701.823181,692.187500 
	C706.671204,696.925903 709.714355,702.201965 708.016907,709.268677 
	C707.941772,709.581360 707.902832,710.005432 708.052429,710.254028 
	C710.306335,713.997437 708.497681,720.353516 715.326233,721.570068 
	C718.717773,714.883423 716.302307,708.191833 715.589905,701.397766 
	C720.294250,700.860535 724.728821,699.490540 726.085815,705.838684 
	C726.281982,706.756226 727.578796,707.950134 728.520020,708.109680 
	C733.021057,708.872681 733.268005,711.930237 733.017578,715.498535 
	C732.701111,720.008545 733.570862,723.735840 738.788269,725.174622 
	C739.792969,725.451721 740.919861,727.705444 740.815796,728.948975 
	C740.695557,730.385986 739.389771,731.804382 738.393372,733.068970 
	C736.795898,735.096619 733.547180,737.071899 733.648132,738.924805 
	C733.848022,742.596313 737.684326,741.992126 740.455261,741.953064 
	C741.757935,741.934753 743.118958,741.690125 744.344177,741.246216 
	C752.959045,738.124756 761.708862,735.942200 770.983765,736.995117 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M423.008423,1038.980469 
	C402.982300,1038.984253 382.956207,1038.987915 362.396423,1038.618652 
	C361.360107,1032.974731 357.917572,1030.744629 353.161041,1030.350098 
	C350.049835,1030.092041 346.872437,1030.673340 343.751770,1030.467651 
	C342.493652,1030.384766 340.487762,1029.430664 340.227081,1028.485596 
	C338.894562,1023.655029 338.339081,1018.597046 336.829865,1013.834656 
	C336.226898,1011.931946 334.152802,1009.923584 332.258362,1009.123230 
	C328.532684,1007.549377 325.105286,1006.425659 323.497772,1001.929199 
	C322.855347,1000.132324 319.655853,998.476440 317.431519,998.181763 
	C313.008911,997.596008 308.445221,998.164429 303.962219,997.890137 
	C302.633453,997.808899 300.392822,996.654541 300.253296,995.736206 
	C299.420044,990.252502 295.702057,989.947449 291.393768,989.972046 
	C277.895813,990.049072 264.397125,990.012512 250.898819,989.984375 
	C249.219070,989.980835 247.539764,989.763733 246.756241,989.708679 
	C248.635147,987.033325 250.361267,984.564392 252.101395,982.105408 
	C252.557327,981.461121 252.969513,980.401184 253.549377,980.287964 
	C260.379089,978.954651 260.206207,973.816223 259.990051,968.660217 
	C259.881653,966.074402 260.380402,964.457336 263.458466,963.660767 
	C265.225769,963.203430 266.524475,960.748169 267.912079,959.090149 
	C268.600891,958.267151 268.935089,956.446899 269.564514,956.381836 
	C276.658905,955.648315 276.328491,950.518799 275.939148,945.613464 
	C275.588135,941.191406 277.249451,939.598083 281.638428,939.941650 
	C286.953613,940.357666 292.504028,940.662781 292.334595,934.095032 
	C294.915314,936.086243 297.705475,938.022705 300.206238,940.279785 
	C301.147003,941.128845 301.846436,942.698975 301.913422,943.979187 
	C302.130859,948.134277 301.669525,952.345947 302.144104,956.458008 
	C302.402588,958.697571 303.775604,962.444702 305.075867,962.639343 
	C310.189209,963.404602 315.677643,963.929749 320.632019,962.804932 
	C324.928772,961.829529 324.105774,956.873047 323.940887,953.287170 
	C323.769196,949.551941 324.844025,947.907349 328.955627,947.912231 
	C339.007141,947.924194 339.764679,947.139587 339.933289,937.173218 
	C340.000000,933.232544 341.574097,931.875183 345.357697,931.957764 
	C352.520050,932.114136 359.688293,931.997009 366.854095,932.001038 
	C373.964417,932.005005 373.964386,932.009155 374.000458,939.098450 
	C374.040314,946.938599 375.075623,947.963989 382.988983,947.999451 
	C383.988861,948.003967 385.172302,947.663086 385.949341,948.080383 
	C387.443298,948.882996 389.390900,949.839111 389.944824,951.216370 
	C392.058624,956.472351 395.525421,957.370056 399.858765,953.730103 
	C402.668945,951.369690 406.016388,949.655579 408.885406,947.355408 
	C410.190948,946.308838 411.555542,944.628784 411.760254,943.085754 
	C412.050690,940.896362 411.955231,938.117798 410.801117,936.425476 
	C409.638824,934.721130 406.980194,934.037170 404.977173,932.906128 
	C405.008881,932.604126 405.040588,932.302063 405.072296,932.000000 
	C406.818146,932.000000 408.563965,932.000122 410.309784,932.000061 
	C420.641876,931.999573 430.976471,932.118347 441.303986,931.900635 
	C443.433716,931.855774 445.718750,930.868835 447.612305,929.766602 
	C450.932556,927.833923 454.056335,925.545227 457.152740,923.257202 
	C458.162750,922.510803 459.457001,921.355042 459.546204,920.296021 
	C459.903564,916.053833 462.569763,915.942505 465.840027,915.959412 
	C482.670868,916.046448 499.502533,915.970154 516.333679,916.020020 
	C520.175720,916.031433 523.164001,915.347656 524.149414,910.824219 
	C524.401306,909.668274 526.543152,908.235413 527.905334,908.135254 
	C532.546997,907.794006 537.273682,908.367249 541.873474,907.810852 
	C543.929138,907.562195 547.166870,905.707764 547.443665,904.151062 
	C548.500427,898.207275 554.723511,899.049500 555.562927,900.379028 
	C558.330627,904.762634 563.199280,906.859863 565.757751,910.778748 
	C568.527710,915.021729 572.643555,918.510864 576.187317,915.303162 
	C580.186035,911.683777 584.388855,907.932495 587.822510,903.610779 
	C590.917419,899.715332 593.758484,895.615540 596.925537,891.782715 
	C597.498901,891.088806 599.098267,891.119080 600.238892,891.045593 
	C602.064758,890.927917 603.904053,891.038879 605.735962,890.989502 
	C610.572998,890.859253 612.893433,887.811890 611.782959,883.096008 
	C610.634460,878.218872 607.019836,877.871216 602.880859,877.951843 
	C596.241211,878.081177 596.088684,877.804932 595.999634,871.353088 
	C595.894531,863.733643 594.175232,862.142944 586.482849,861.973999 
	C578.345215,861.795349 570.212952,861.386841 562.076965,861.120239 
	C558.476929,861.002258 556.568665,862.957947 557.215698,866.384460 
	C557.822021,869.595215 556.515564,870.822693 553.764160,871.004700 
	C551.021484,871.185974 548.223022,871.281311 545.506104,870.948547 
	C538.616943,870.104919 531.769653,868.922485 524.884399,868.041443 
	C522.420715,867.726196 519.561401,868.639465 517.455994,867.703003 
	C507.197479,863.139954 496.453430,865.185547 485.946625,865.191101 
	C479.764771,865.194336 472.967163,865.838867 467.564575,868.481140 
	C461.334808,871.527893 455.323120,870.900757 449.162598,870.895630 
	C440.194550,870.888184 431.221466,869.991394 422.261383,870.137634 
	C417.611328,870.213440 412.999878,871.815918 408.340393,872.528687 
	C406.237396,872.850403 404.056580,872.784119 401.916443,872.718567 
	C396.941956,872.566223 391.972290,872.253662 386.997681,872.107117 
	C382.232483,871.966736 377.449768,872.156738 372.699646,871.834473 
	C366.455292,871.410889 360.231323,870.093384 354.005890,870.139954 
	C346.771332,870.194153 339.556824,871.584473 332.311981,871.902405 
	C319.521149,872.463806 306.714966,872.717712 293.912354,872.916931 
	C292.238556,872.942932 290.557983,871.853271 288.862610,871.339600 
	C286.275909,870.555969 283.687805,869.769531 281.073700,869.085938 
	C278.535004,868.422058 275.952362,867.927063 273.410187,867.275391 
	C272.295074,866.989563 271.197937,866.079956 270.151642,866.166565 
	C261.581909,866.875977 253.895279,863.482666 245.930923,861.279785 
	C240.625854,859.812439 238.066269,861.954346 238.017426,867.539368 
	C237.954773,874.704590 237.867264,881.874329 238.054230,889.035156 
	C238.148148,892.632568 237.002472,894.151978 233.214539,894.059326 
	C225.053757,893.859558 216.884140,893.945557 208.719315,894.029053 
	C206.133316,894.055481 204.414627,893.602722 203.517593,890.670532 
	C203.016174,889.031311 201.263000,887.225403 199.640396,886.573242 
	C192.338257,883.638428 184.635834,886.449707 180.344055,894.091003 
	C180.192108,891.373291 180.014572,889.650879 180.012192,887.928223 
	C179.990494,872.263428 180.173355,856.595154 179.862579,840.936523 
	C179.794830,837.522888 177.511078,834.198242 177.119827,830.734131 
	C176.679642,826.837097 176.928299,822.789185 177.443542,818.881592 
	C177.818253,816.039978 179.760147,813.346130 179.875229,810.540161 
	C180.216400,802.221802 180.089752,793.879028 179.953308,785.548950 
	C179.901917,782.410828 180.341217,780.247070 183.952103,779.252502 
	C185.493774,778.827820 186.557999,776.551758 187.759872,775.053406 
	C188.543701,774.076172 189.013565,772.277588 189.917542,772.058716 
	C195.753067,770.645447 195.907486,766.256348 196.076019,761.571960 
	C196.140900,759.768677 196.732880,756.657349 197.657257,756.425659 
	C204.053650,754.821838 204.053589,750.160583 204.108292,745.091064 
	C204.126221,743.427917 204.954376,740.897095 206.181274,740.271362 
	C212.285202,737.158386 215.216415,731.046265 219.870697,726.607727 
	C220.656052,725.858887 221.052643,724.249084 221.868454,724.057495 
	C228.015427,722.614502 228.224854,717.910522 227.944809,713.119751 
	C227.714828,709.185547 229.216705,707.992126 233.115921,707.876282 
	C237.907379,707.733826 238.048569,710.313354 238.047791,713.855835 
	C238.045975,722.104675 239.915024,723.909302 247.942154,723.998718 
	C258.578552,724.117249 260.112000,722.587646 259.998566,711.971863 
	C259.912628,703.926575 258.080261,702.135620 249.886032,701.987976 
	C248.207840,701.957764 246.532593,701.763428 244.855988,701.644714 
	C251.694000,699.925659 251.938614,690.711487 258.833466,689.869446 
	C259.120239,689.834412 259.513855,689.049194 259.522095,688.607178 
	C259.614502,683.630310 263.066284,683.885010 266.517548,683.984192 
	C269.890350,684.081177 273.903595,684.320190 275.037018,680.451111 
	C276.303436,676.127869 278.925354,675.787903 282.502350,675.962952 
	C285.993591,676.133606 289.505676,676.128052 292.997681,675.965454 
	C296.794006,675.788635 301.272827,676.499146 304.202820,674.739441 
	C308.515442,672.149231 312.563782,668.213257 315.118835,663.899353 
	C317.037842,660.659302 318.761414,659.936035 321.978485,659.958984 
	C335.476166,660.055298 348.975006,660.020203 362.473297,659.984375 
	C366.611359,659.973389 370.526642,659.595154 371.817810,654.466003 
	C372.071381,653.458679 373.438965,652.304810 374.512177,652.005798 
	C378.888214,650.786133 380.078033,647.747681 380.029877,643.659790 
	C380.003845,641.448669 380.312012,639.233704 380.469391,637.020447 
	C380.987061,637.061890 381.504730,637.103333 382.022400,637.144714 
	C382.022400,639.431702 381.909912,641.725281 382.044434,644.004333 
	C382.386658,649.802124 383.623871,650.972107 389.309601,650.992493 
	C396.975250,651.019958 404.643768,650.879944 412.305664,651.051575 
	C415.978058,651.133728 418.712708,650.671204 419.941223,646.455261 
	C420.282867,645.282898 422.961456,644.250610 424.625519,644.154907 
	C426.329437,644.056824 429.381317,644.850830 429.639099,645.832947 
	C431.240601,651.934631 435.675720,652.025757 440.592407,652.110474 
	C442.454315,652.142517 445.570770,653.030884 445.927490,654.197754 
	C447.608093,659.695496 451.692841,660.002258 456.190125,660.000671 
	C467.188812,659.996765 478.188019,659.947876 489.185516,660.062927 
	C490.688904,660.078613 493.327637,660.664001 493.503815,661.430725 
	C495.114197,668.439026 500.361694,667.975281 505.665344,668.139221 
	C507.184998,668.186218 509.485687,669.540955 509.991119,670.850708 
	C511.524902,674.825073 514.271301,676.085632 518.151550,676.008484 
	C521.149963,675.948792 524.161438,676.160217 527.147461,675.959290 
	C530.911682,675.705994 533.973877,675.973328 535.021484,680.596985 
	C535.301819,681.834351 537.345703,682.726379 538.665527,683.655334 
	C539.576782,684.296753 541.439575,684.643860 541.505615,685.267639 
	C542.314941,692.914001 547.883972,692.160034 553.071594,691.971924 
	C555.871704,691.870422 557.618835,692.483643 558.607849,695.591553 
	C559.149963,697.295105 561.511353,698.418518 563.049316,699.806946 
	C564.067017,700.725769 565.158508,701.578125 566.079773,702.585632 
	C569.888245,706.750427 573.612976,710.992310 577.458313,715.122192 
	C577.961182,715.662292 579.094543,715.566162 579.740417,716.034302 
	C580.602844,716.659546 581.267456,717.555115 582.028748,718.322937 
	C583.896667,720.206726 585.765320,722.089722 587.645935,723.960815 
	C588.412415,724.723450 589.273010,725.399536 589.983521,726.209106 
	C593.702209,730.446472 597.385437,734.714966 601.088318,738.966248 
	C601.190735,739.083923 601.369446,739.182495 601.523376,739.201294 
	C606.096558,739.759583 606.056946,742.981873 606.053711,746.519653 
	C606.046875,753.973022 608.068787,756.170898 615.601318,755.882324 
	C620.654541,755.688721 622.727722,757.239075 622.046997,762.353333 
	C621.763672,764.481995 621.638367,766.756714 622.078979,768.829468 
	C622.877625,772.587036 624.057800,776.274536 625.253967,779.933960 
	C627.018677,785.332947 630.876526,789.928650 630.264465,796.305481 
	C630.047913,798.561462 632.257019,802.290894 634.323975,803.235474 
	C637.451416,804.664795 638.068542,806.319275 638.038452,809.264771 
	C637.936157,819.262451 638.005554,829.261963 637.997986,839.260742 
	C637.993042,845.857300 637.947876,845.501953 631.514282,846.161316 
	C628.571411,846.463013 623.752563,848.017822 623.352661,849.803345 
	C622.275940,854.611511 621.891174,860.315491 623.583130,864.770935 
	C624.964355,868.408325 625.356140,871.890076 626.167175,875.496765 
	C627.642395,882.057495 628.517456,889.275879 636.290710,892.342529 
	C637.258484,892.724304 637.891174,895.071289 637.922485,896.523987 
	C638.083984,904.019653 638.003723,911.520630 637.998596,919.019714 
	C637.993958,925.821594 637.988708,925.821594 631.239075,926.003662 
	C624.047607,926.197632 621.769592,928.126221 622.155640,935.339172 
	C622.374207,939.422119 624.381775,943.378052 625.112732,947.475159 
	C625.650146,950.487122 625.854858,953.723145 625.255493,956.665466 
	C625.091736,957.469788 621.495483,957.923279 619.468750,957.942749 
	C607.304626,958.059875 595.138306,957.932434 582.973938,958.033203 
	C576.307617,958.088379 574.016296,960.154541 574.077515,966.612671 
	C574.159363,975.244751 573.171936,973.839111 566.146240,974.022888 
	C562.009155,974.131104 557.182495,973.207092 553.915283,975.010559 
	C549.547607,977.421631 545.393066,981.486877 543.025330,985.858948 
	C541.067444,989.474182 539.169250,990.199463 535.790344,990.025391 
	C533.297302,989.897034 530.712036,989.603638 528.309448,990.087036 
	C523.511780,991.052429 518.306030,991.632446 514.221436,994.008850 
	C510.573395,996.131104 508.320190,1000.566406 505.223236,1003.759644 
	C503.893646,1005.130493 502.093292,1006.594666 500.322021,1006.883789 
	C495.056122,1007.743103 490.435547,1008.554321 486.668793,1013.474609 
	C482.091644,1019.453430 475.819458,1022.824280 467.327484,1022.291992 
	C461.489319,1021.925964 455.470795,1024.274658 449.555054,1025.515869 
	C448.824219,1025.669189 447.971710,1026.336304 447.613800,1027.001221 
	C444.532471,1032.725708 439.916168,1031.588379 435.211700,1029.822754 
	C429.320099,1027.611450 426.613861,1028.902344 424.750488,1034.871094 
	C424.309296,1036.284546 423.596344,1037.612915 423.008423,1038.980469 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M423.476135,1038.986206 
	C423.596344,1037.612915 424.309296,1036.284546 424.750488,1034.871094 
	C426.613861,1028.902344 429.320099,1027.611450 435.211700,1029.822754 
	C439.916168,1031.588379 444.532471,1032.725708 447.613800,1027.001221 
	C447.971710,1026.336304 448.824219,1025.669189 449.555054,1025.515869 
	C455.470795,1024.274658 461.489319,1021.925964 467.327484,1022.291992 
	C475.819458,1022.824280 482.091644,1019.453430 486.668793,1013.474609 
	C490.435547,1008.554321 495.056122,1007.743103 500.322021,1006.883789 
	C502.093292,1006.594666 503.893646,1005.130493 505.223236,1003.759644 
	C508.320190,1000.566406 510.573395,996.131104 514.221436,994.008850 
	C518.306030,991.632446 523.511780,991.052429 528.309448,990.087036 
	C530.712036,989.603638 533.297302,989.897034 535.790344,990.025391 
	C539.169250,990.199463 541.067444,989.474182 543.025330,985.858948 
	C545.393066,981.486877 549.547607,977.421631 553.915283,975.010559 
	C557.182495,973.207092 562.009155,974.131104 566.146240,974.022888 
	C573.171936,973.839111 574.159363,975.244751 574.077515,966.612671 
	C574.016296,960.154541 576.307617,958.088379 582.973938,958.033203 
	C595.138306,957.932434 607.304626,958.059875 619.468750,957.942749 
	C621.495483,957.923279 625.091736,957.469788 625.255493,956.665466 
	C625.854858,953.723145 625.650146,950.487122 625.112732,947.475159 
	C624.381775,943.378052 622.374207,939.422119 622.155640,935.339172 
	C621.769592,928.126221 624.047607,926.197632 631.239075,926.003662 
	C637.988708,925.821594 637.993958,925.821594 637.998596,919.019714 
	C638.003723,911.520630 638.083984,904.019653 637.922485,896.523987 
	C637.891174,895.071289 637.258484,892.724304 636.290710,892.342529 
	C628.517456,889.275879 627.642395,882.057495 626.167175,875.496765 
	C625.356140,871.890076 624.964355,868.408325 623.583130,864.770935 
	C621.891174,860.315491 622.275940,854.611511 623.352661,849.803345 
	C623.752563,848.017822 628.571411,846.463013 631.514282,846.161316 
	C637.947876,845.501953 637.993042,845.857300 637.997986,839.260742 
	C638.005554,829.261963 637.936157,819.262451 638.038452,809.264771 
	C638.068542,806.319275 637.451416,804.664795 634.323975,803.235474 
	C632.257019,802.290894 630.047913,798.561462 630.264465,796.305481 
	C630.876526,789.928650 627.018677,785.332947 625.253967,779.933960 
	C624.057800,776.274536 622.877625,772.587036 622.078979,768.829468 
	C621.638367,766.756714 621.763672,764.481995 622.046997,762.353333 
	C622.727722,757.239075 620.654541,755.688721 615.601318,755.882324 
	C608.068787,756.170898 606.046875,753.973022 606.053711,746.519653 
	C606.056946,742.981873 606.096558,739.759583 601.523376,739.201294 
	C601.369446,739.182495 601.190735,739.083923 601.088318,738.966248 
	C597.385437,734.714966 593.702209,730.446472 589.983521,726.209106 
	C589.273010,725.399536 588.412415,724.723450 587.645935,723.960815 
	C585.765320,722.089722 583.896667,720.206726 582.028748,718.322937 
	C581.267456,717.555115 580.602844,716.659546 579.740417,716.034302 
	C579.094543,715.566162 577.961182,715.662292 577.458313,715.122192 
	C573.612976,710.992310 569.888245,706.750427 566.079773,702.585632 
	C565.158508,701.578125 564.067017,700.725769 563.049316,699.806946 
	C561.511353,698.418518 559.149963,697.295105 558.607849,695.591553 
	C557.618835,692.483643 555.871704,691.870422 553.071594,691.971924 
	C547.883972,692.160034 542.314941,692.914001 541.505615,685.267639 
	C541.439575,684.643860 539.576782,684.296753 538.665527,683.655334 
	C537.345703,682.726379 535.301819,681.834351 535.021484,680.596985 
	C533.973877,675.973328 530.911682,675.705994 527.147461,675.959290 
	C524.161438,676.160217 521.149963,675.948792 518.151550,676.008484 
	C514.271301,676.085632 511.524902,674.825073 509.991119,670.850708 
	C509.485687,669.540955 507.184998,668.186218 505.665344,668.139221 
	C500.361694,667.975281 495.114197,668.439026 493.503815,661.430725 
	C493.327637,660.664001 490.688904,660.078613 489.185516,660.062927 
	C478.188019,659.947876 467.188812,659.996765 456.190125,660.000671 
	C451.692841,660.002258 447.608093,659.695496 445.927490,654.197754 
	C445.570770,653.030884 442.454315,652.142517 440.592407,652.110474 
	C435.675720,652.025757 431.240601,651.934631 429.639099,645.832947 
	C429.381317,644.850830 426.329437,644.056824 424.625519,644.154907 
	C422.961456,644.250610 420.282867,645.282898 419.941223,646.455261 
	C418.712708,650.671204 415.978058,651.133728 412.305664,651.051575 
	C404.643768,650.879944 396.975250,651.019958 389.309601,650.992493 
	C383.623871,650.972107 382.386658,649.802124 382.044434,644.004333 
	C381.909912,641.725281 382.022400,639.431702 382.022400,637.144714 
	C381.504730,637.103333 380.987061,637.061890 380.469391,637.020447 
	C380.312012,639.233704 380.003845,641.448669 380.029877,643.659790 
	C380.078033,647.747681 378.888214,650.786133 374.512177,652.005798 
	C373.438965,652.304810 372.071381,653.458679 371.817810,654.466003 
	C370.526642,659.595154 366.611359,659.973389 362.473297,659.984375 
	C348.975006,660.020203 335.476166,660.055298 321.978485,659.958984 
	C318.761414,659.936035 317.037842,660.659302 315.118835,663.899353 
	C312.563782,668.213257 308.515442,672.149231 304.202820,674.739441 
	C301.272827,676.499146 296.794006,675.788635 292.997681,675.965454 
	C289.505676,676.128052 285.993591,676.133606 282.502350,675.962952 
	C278.925354,675.787903 276.303436,676.127869 275.037018,680.451111 
	C273.903595,684.320190 269.890350,684.081177 266.517548,683.984192 
	C263.066284,683.885010 259.614502,683.630310 259.522095,688.607178 
	C259.513855,689.049194 259.120239,689.834412 258.833466,689.869446 
	C251.938614,690.711487 251.694000,699.925659 244.855988,701.644714 
	C246.532593,701.763428 248.207840,701.957764 249.886032,701.987976 
	C258.080261,702.135620 259.912628,703.926575 259.998566,711.971863 
	C260.112000,722.587646 258.578552,724.117249 247.942154,723.998718 
	C239.915024,723.909302 238.045975,722.104675 238.047791,713.855835 
	C238.048569,710.313354 237.907379,707.733826 233.115921,707.876282 
	C229.216705,707.992126 227.714828,709.185547 227.944809,713.119751 
	C228.224854,717.910522 228.015427,722.614502 221.868454,724.057495 
	C221.052643,724.249084 220.656052,725.858887 219.870697,726.607727 
	C215.216415,731.046265 212.285202,737.158386 206.181274,740.271362 
	C204.954376,740.897095 204.126221,743.427917 204.108292,745.091064 
	C204.053589,750.160583 204.053650,754.821838 197.657257,756.425659 
	C196.732880,756.657349 196.140900,759.768677 196.076019,761.571960 
	C195.907486,766.256348 195.753067,770.645447 189.917542,772.058716 
	C189.013565,772.277588 188.543701,774.076172 187.759872,775.053406 
	C186.557999,776.551758 185.493774,778.827820 183.952103,779.252502 
	C180.341217,780.247070 179.901917,782.410828 179.953308,785.548950 
	C180.089752,793.879028 180.216400,802.221802 179.875229,810.540161 
	C179.760147,813.346130 177.818253,816.039978 177.443542,818.881592 
	C176.928299,822.789185 176.679642,826.837097 177.119827,830.734131 
	C177.511078,834.198242 179.794830,837.522888 179.862579,840.936523 
	C180.173355,856.595154 179.990494,872.263428 180.012192,887.928223 
	C180.014572,889.650879 180.192108,891.373291 180.344055,894.091003 
	C184.635834,886.449707 192.338257,883.638428 199.640396,886.573242 
	C201.263000,887.225403 203.016174,889.031311 203.517593,890.670532 
	C204.414627,893.602722 206.133316,894.055481 208.719315,894.029053 
	C216.884140,893.945557 225.053757,893.859558 233.214539,894.059326 
	C237.002472,894.151978 238.148148,892.632568 238.054230,889.035156 
	C237.867264,881.874329 237.954773,874.704590 238.017426,867.539368 
	C238.066269,861.954346 240.625854,859.812439 245.930923,861.279785 
	C253.895279,863.482666 261.581909,866.875977 270.151642,866.166565 
	C271.197937,866.079956 272.295074,866.989563 273.410187,867.275391 
	C275.952362,867.927063 278.535004,868.422058 281.073700,869.085938 
	C283.687805,869.769531 286.275909,870.555969 288.862610,871.339600 
	C290.557983,871.853271 292.238556,872.942932 293.912354,872.916931 
	C306.714966,872.717712 319.521149,872.463806 332.311981,871.902405 
	C339.556824,871.584473 346.771332,870.194153 354.005890,870.139954 
	C360.231323,870.093384 366.455292,871.410889 372.699646,871.834473 
	C377.449768,872.156738 382.232483,871.966736 386.997681,872.107117 
	C391.972290,872.253662 396.941956,872.566223 401.916443,872.718567 
	C404.056580,872.784119 406.237396,872.850403 408.340393,872.528687 
	C412.999878,871.815918 417.611328,870.213440 422.261383,870.137634 
	C431.221466,869.991394 440.194550,870.888184 449.162598,870.895630 
	C455.323120,870.900757 461.334808,871.527893 467.564575,868.481140 
	C472.967163,865.838867 479.764771,865.194336 485.946625,865.191101 
	C496.453430,865.185547 507.197479,863.139954 517.455994,867.703003 
	C519.561401,868.639465 522.420715,867.726196 524.884399,868.041443 
	C531.769653,868.922485 538.616943,870.104919 545.506104,870.948547 
	C548.223022,871.281311 551.021484,871.185974 553.764160,871.004700 
	C556.515564,870.822693 557.822021,869.595215 557.215698,866.384460 
	C556.568665,862.957947 558.476929,861.002258 562.076965,861.120239 
	C570.212952,861.386841 578.345215,861.795349 586.482849,861.973999 
	C594.175232,862.142944 595.894531,863.733643 595.999634,871.353088 
	C596.088684,877.804932 596.241211,878.081177 602.880859,877.951843 
	C607.019836,877.871216 610.634460,878.218872 611.782959,883.096008 
	C612.893433,887.811890 610.572998,890.859253 605.735962,890.989502 
	C603.904053,891.038879 602.064758,890.927917 600.238892,891.045593 
	C599.098267,891.119080 597.498901,891.088806 596.925537,891.782715 
	C593.758484,895.615540 590.917419,899.715332 587.822510,903.610779 
	C584.388855,907.932495 580.186035,911.683777 576.187317,915.303162 
	C572.643555,918.510864 568.527710,915.021729 565.757751,910.778748 
	C563.199280,906.859863 558.330627,904.762634 555.562927,900.379028 
	C554.723511,899.049500 548.500427,898.207275 547.443665,904.151062 
	C547.166870,905.707764 543.929138,907.562195 541.873474,907.810852 
	C537.273682,908.367249 532.546997,907.794006 527.905334,908.135254 
	C526.543152,908.235413 524.401306,909.668274 524.149414,910.824219 
	C523.164001,915.347656 520.175720,916.031433 516.333679,916.020020 
	C499.502533,915.970154 482.670868,916.046448 465.840027,915.959412 
	C462.569763,915.942505 459.903564,916.053833 459.546204,920.296021 
	C459.457001,921.355042 458.162750,922.510803 457.152740,923.257202 
	C454.056335,925.545227 450.932556,927.833923 447.612305,929.766602 
	C445.718750,930.868835 443.433716,931.855774 441.303986,931.900635 
	C430.976471,932.118347 420.641876,931.999573 410.309784,932.000061 
	C408.563965,932.000122 406.818146,932.000000 405.072296,932.000000 
	C405.040588,932.302063 405.008881,932.604126 404.977173,932.906128 
	C406.980194,934.037170 409.638824,934.721130 410.801117,936.425476 
	C411.955231,938.117798 412.050690,940.896362 411.760254,943.085754 
	C411.555542,944.628784 410.190948,946.308838 408.885406,947.355408 
	C406.016388,949.655579 402.668945,951.369690 399.858765,953.730103 
	C395.525421,957.370056 392.058624,956.472351 389.944824,951.216370 
	C389.390900,949.839111 387.443298,948.882996 385.949341,948.080383 
	C385.172302,947.663086 383.988861,948.003967 382.988983,947.999451 
	C375.075623,947.963989 374.040314,946.938599 374.000458,939.098450 
	C373.964386,932.009155 373.964417,932.005005 366.854095,932.001038 
	C359.688293,931.997009 352.520050,932.114136 345.357697,931.957764 
	C341.574097,931.875183 340.000000,933.232544 339.933289,937.173218 
	C339.764679,947.139587 339.007141,947.924194 328.955627,947.912231 
	C324.844025,947.907349 323.769196,949.551941 323.940887,953.287170 
	C324.105774,956.873047 324.928772,961.829529 320.632019,962.804932 
	C315.677643,963.929749 310.189209,963.404602 305.075867,962.639343 
	C303.775604,962.444702 302.402588,958.697571 302.144104,956.458008 
	C301.669525,952.345947 302.130859,948.134277 301.913422,943.979187 
	C301.846436,942.698975 301.147003,941.128845 300.206238,940.279785 
	C297.705475,938.022705 294.915314,936.086243 292.334595,934.095032 
	C292.504028,940.662781 286.953613,940.357666 281.638428,939.941650 
	C277.249451,939.598083 275.588135,941.191406 275.939148,945.613464 
	C276.328491,950.518799 276.658905,955.648315 269.564514,956.381836 
	C268.935089,956.446899 268.600891,958.267151 267.912079,959.090149 
	C266.524475,960.748169 265.225769,963.203430 263.458466,963.660767 
	C260.380402,964.457336 259.881653,966.074402 259.990051,968.660217 
	C260.206207,973.816223 260.379089,978.954651 253.549377,980.287964 
	C252.969513,980.401184 252.557327,981.461121 252.101395,982.105408 
	C250.361267,984.564392 248.635147,987.033325 246.756241,989.708679 
	C247.539764,989.763733 249.219070,989.980835 250.898819,989.984375 
	C264.397125,990.012512 277.895813,990.049072 291.393768,989.972046 
	C295.702057,989.947449 299.420044,990.252502 300.253296,995.736206 
	C300.392822,996.654541 302.633453,997.808899 303.962219,997.890137 
	C308.445221,998.164429 313.008911,997.596008 317.431519,998.181763 
	C319.655853,998.476440 322.855347,1000.132324 323.497772,1001.929199 
	C325.105286,1006.425659 328.532684,1007.549377 332.258362,1009.123230 
	C334.152802,1009.923584 336.226898,1011.931946 336.829865,1013.834656 
	C338.339081,1018.597046 338.894562,1023.655029 340.227081,1028.485596 
	C340.487762,1029.430664 342.493652,1030.384766 343.751770,1030.467651 
	C346.872437,1030.673340 350.049835,1030.092041 353.161041,1030.350098 
	C357.917572,1030.744629 361.360107,1032.974731 361.927277,1038.613037 
	C354.305847,1038.984985 346.619934,1038.989502 338.401794,1038.627930 
	C335.188690,1034.525024 332.507782,1030.788208 329.535461,1026.645142 
	C327.975159,1028.279175 326.793121,1029.516968 325.004272,1031.390259 
	C324.200256,1029.819946 323.274109,1028.702026 323.062561,1027.461914 
	C322.533844,1024.362793 322.070831,1021.208740 322.063110,1018.075562 
	C322.054321,1014.494751 320.717621,1012.856201 317.041504,1012.971436 
	C312.546082,1013.112488 308.038330,1013.115234 303.545105,1012.940918 
	C301.797791,1012.873047 300.054871,1012.229370 298.346527,1011.724365 
	C295.449066,1010.867859 292.635223,1009.486084 289.686066,1009.109131 
	C286.078064,1008.648132 282.366577,1008.964844 278.700409,1009.001221 
	C275.677002,1009.031250 273.363525,1008.077087 273.055084,1004.712341 
	C272.770142,1001.603943 270.854950,1000.978088 268.222107,1000.987976 
	C265.226410,1000.999207 261.801971,1001.788574 259.310699,1000.631592 
	C250.111069,996.359314 239.700317,998.252869 230.297256,993.582336 
	C223.230057,990.072021 213.913223,991.380310 206.258057,988.675232 
	C199.821381,986.400696 193.610382,984.640198 186.854095,984.585754 
	C186.567917,984.583496 186.276047,984.281860 186.000565,984.099060 
	C179.989288,980.109436 174.589859,975.083801 166.368317,975.787720 
	C162.507919,976.118225 161.703812,973.447754 163.611572,969.746887 
	C164.403809,968.210144 164.946869,966.291260 164.802521,964.615173 
	C164.707794,963.515320 163.179214,962.538940 162.292786,961.507202 
	C161.537598,962.428711 160.461914,963.235474 160.083633,964.291931 
	C158.588837,968.466980 157.493973,968.672852 154.448196,965.774048 
	C148.630310,960.237000 141.720139,958.721069 133.666870,960.999512 
	C132.889282,960.999878 132.444946,960.999939 131.933472,960.652954 
	C128.791443,954.747681 123.752861,952.092590 117.666939,952.999512 
	C116.889259,952.999878 116.444870,952.999939 115.920059,952.622803 
	C115.225998,948.710876 114.316582,945.195129 114.087318,941.635559 
	C113.884537,938.487122 113.538864,936.328613 109.696541,935.665283 
	C108.258202,935.416992 106.815948,932.897034 106.154205,931.122498 
	C105.552994,929.510376 105.934113,927.500732 106.015488,925.672302 
	C106.155594,922.523926 105.664612,920.112915 101.702545,919.766663 
	C100.575722,919.668152 99.581612,918.051147 98.526642,917.130493 
	C99.659729,916.108093 100.712105,914.329590 101.940338,914.198486 
	C106.474869,913.714478 106.060791,910.627747 105.943077,907.598083 
	C105.803055,903.994141 103.177513,903.998657 100.574593,903.999695 
	C98.575569,904.000427 96.475822,904.346558 94.609261,903.842102 
	C93.105026,903.435547 91.882469,901.986755 90.534927,901.000366 
	C91.709465,900.015869 92.790779,898.355591 94.076683,898.178223 
	C97.434937,897.714844 98.087379,895.776917 98.010963,892.955322 
	C97.952377,890.791992 97.594826,888.493591 98.190285,886.500244 
	C98.679237,884.863525 100.231316,882.527954 101.562370,882.347534 
	C105.764732,881.777710 106.161453,879.278564 106.018555,875.984436 
	C105.917557,873.656311 105.935059,871.318298 106.010841,868.988342 
	C106.104034,866.123230 105.328415,864.263794 102.007050,863.873047 
	C97.937912,863.394226 97.985664,860.129578 97.988724,857.017700 
	C97.991776,853.916504 97.901009,850.580261 101.971901,850.137756 
	C106.499992,849.645691 106.066719,846.578308 105.936554,843.557800 
	C105.780815,839.943726 103.118172,840.002686 100.533875,840.001770 
	C98.867836,840.001221 97.201408,839.967957 95.535843,839.994019 
	C91.823532,840.052002 89.977959,838.271118 90.006897,834.531677 
	C90.036545,830.700073 89.989609,826.867676 90.030098,823.036255 
	C90.064156,819.813477 91.727341,818.108948 94.968590,818.028503 
	C96.633759,817.987183 98.301384,818.039673 99.966217,817.991638 
	C102.577713,817.916260 105.627464,818.505554 105.935867,814.509521 
	C106.192932,811.178589 106.253563,808.092102 101.551682,807.732239 
	C100.479340,807.650146 99.531685,805.939087 98.526649,804.977783 
	C99.546295,804.028381 100.508614,802.343872 101.595657,802.259094 
	C105.909355,801.922668 106.062820,799.178955 106.023880,795.935303 
	C105.943893,789.272034 105.817230,782.598572 106.123627,775.948364 
	C106.209541,774.083679 107.589546,770.803040 108.629066,770.698914 
	C115.089508,770.051758 113.736504,765.510193 114.092377,761.479980 
	C114.295738,759.176941 115.193161,755.113525 116.171013,754.991516 
	C123.352997,754.095276 122.018211,748.972107 121.967918,744.502808 
	C121.927361,740.898804 122.818069,738.463379 126.942642,737.756714 
	C128.131546,737.553101 129.414978,735.462158 129.859558,733.995361 
	C130.369019,732.314514 130.062759,730.362427 129.987122,728.533325 
	C129.838943,724.950745 130.739853,722.381836 134.925247,721.815247 
	C136.024399,721.666504 137.596146,720.156555 137.786163,719.068237 
	C138.322372,715.997437 139.711121,714.480347 142.820206,713.780151 
	C144.079071,713.496765 145.433655,711.554749 145.844650,710.111267 
	C146.372009,708.259094 146.056305,706.146545 145.989426,704.150024 
	C145.873947,700.702026 146.967010,698.466248 150.802795,697.815308 
	C151.998932,697.612305 152.909988,695.781006 153.970261,694.713684 
	C155.551788,693.121704 157.147003,691.543274 158.741608,689.964294 
	C162.473999,686.268555 167.310608,683.159424 169.657150,678.730347 
	C172.415146,673.524597 179.474487,672.737732 180.687073,666.501648 
	C180.751968,666.167969 182.523788,666.072571 183.507919,666.031494 
	C185.337585,665.955017 187.174530,665.941284 189.004486,666.008789 
	C191.863388,666.114136 192.955765,664.879822 194.288803,662.118347 
	C196.583771,657.363831 202.702591,655.562561 205.030777,650.389648 
	C205.239334,649.926270 206.900146,650.056458 207.890564,650.018982 
	C209.387955,649.962280 210.893158,649.927368 212.387512,650.013611 
	C215.588989,650.198242 216.647781,648.606323 218.460312,645.851135 
	C221.277786,641.568420 226.273865,638.726196 229.358963,634.267273 
	C229.660278,633.831848 230.944031,634.034607 231.773468,634.015259 
	C233.272156,633.980286 234.774582,633.945435 236.271088,634.010559 
	C239.377808,634.145813 241.657394,633.705444 242.310852,629.779297 
	C242.552841,628.325439 245.030014,626.839539 246.792862,626.162354 
	C248.393707,625.547424 250.410980,625.948364 252.240753,626.013000 
	C255.325836,626.122070 257.647369,625.756165 258.304535,621.808960 
	C258.547913,620.347412 261.002777,618.704834 262.757416,618.184265 
	C264.905853,617.546875 267.473633,618.391052 269.678131,617.851318 
	C271.257568,617.464661 273.491608,616.061340 273.771576,614.755371 
	C274.584747,610.962219 276.748352,609.943481 280.183044,609.980835 
	C287.013062,610.055176 293.845123,609.943176 300.675018,610.022339 
	C303.689697,610.057312 305.536926,609.394348 306.312988,605.888123 
	C306.660431,604.318176 309.293915,602.741089 311.192902,602.163940 
	C313.332428,601.513672 315.870758,602.287842 318.123871,601.857300 
	C319.481323,601.598083 321.593353,600.331543 321.703796,599.333740 
	C322.275543,594.168091 325.651672,593.966125 329.617859,593.971436 
	C365.771301,594.020264 401.924866,593.999878 438.078369,594.000122 
	C449.907410,594.000183 461.736481,594.020447 473.565430,593.989441 
	C476.607422,593.981506 479.496033,594.325073 479.888000,598.055725 
	C480.241028,601.415649 482.206451,602.057495 485.039124,602.014893 
	C489.536316,601.947205 494.087433,601.611938 498.509918,602.193848 
	C500.453369,602.449707 503.362488,604.429382 503.701874,606.067810 
	C504.620636,610.503113 507.503906,610.064941 510.496521,609.925476 
	C514.123413,609.756470 513.985107,607.036621 513.996399,604.477478 
	C514.003723,602.811401 514.122131,601.134644 513.977722,599.481262 
	C513.469421,593.659668 516.142944,587.126099 509.749664,582.539490 
	C508.861420,581.902222 509.615753,578.541199 510.108032,576.565247 
	C510.440674,575.229980 511.647675,574.112549 512.472168,572.884888 
	C511.202332,571.465698 510.098114,570.231628 508.954407,568.688904 
	C508.512421,566.905579 508.109955,565.430908 507.731842,563.597656 
	C507.943604,562.801514 508.130981,562.363892 508.460480,561.773193 
	C508.769440,561.387268 508.869812,561.128662 508.904297,560.887695 
	C508.904907,560.931030 508.839478,560.874084 509.207397,560.891602 
	C510.716095,559.940979 511.856812,558.972961 513.331665,558.004150 
	C514.111389,558.002319 514.556885,558.001160 515.111633,558.323792 
	C517.593262,561.355164 520.289185,563.847595 522.264771,566.819031 
	C525.491882,571.672791 530.583313,572.296692 534.981323,567.974670 
	C534.989014,568.003235 534.941223,567.968323 535.279419,567.922852 
	C539.473816,565.784973 536.796143,563.353943 535.979126,560.579224 
	C537.390503,554.610962 538.712891,549.058533 540.300232,543.582825 
	C540.511292,542.854797 542.067017,542.516418 543.135132,542.326477 
	C543.193115,544.258728 542.706848,545.983765 543.138977,547.435547 
	C543.723511,549.399414 545.013000,551.153503 546.000732,553.458252 
	C545.785828,557.840027 545.108582,561.810486 545.521301,565.664307 
	C545.784729,568.123474 547.661865,570.409729 548.815430,572.773560 
	C550.258057,571.291809 551.700684,569.810120 553.203491,568.398193 
	C553.263672,568.468018 553.137573,568.602478 553.601807,568.691833 
	C556.715820,568.510315 559.365662,568.239441 562.233154,568.227661 
	C564.156006,571.851440 566.544312,572.411926 569.580505,570.200439 
	C572.263062,568.246582 574.880493,566.198059 577.628479,564.341980 
	C578.660400,563.644958 579.978943,563.372375 581.434387,562.998535 
	C582.105042,563.474304 582.508118,563.857788 582.955933,564.556763 
	C583.681885,565.571472 584.363098,566.270813 585.020752,566.983154 
	C584.997253,566.996094 585.034851,566.956726 585.039429,567.317261 
	C586.043030,568.782043 587.042114,569.886230 588.221802,571.267090 
	C593.269104,577.025757 598.135742,582.507690 602.924072,588.297668 
	C601.105286,590.628296 601.737549,592.093567 603.392578,594.055298 
	C605.852783,596.971313 608.073486,600.221313 609.721313,603.653198 
	C611.315002,606.972290 613.401672,608.503723 617.106934,608.112183 
	C617.213928,608.224365 617.644470,608.279480 617.644470,608.279480 
	C617.644470,608.279480 618.000000,608.000000 618.012512,607.599731 
	C618.070374,606.664124 618.115723,606.128784 618.421143,605.817749 
	C620.136536,607.662964 621.591797,609.283875 622.950928,611.217407 
	C621.146729,611.396301 619.438843,611.262634 617.803955,611.134705 
	C618.148438,614.212646 625.365051,619.477356 630.543213,620.065491 
	C633.875549,623.507446 636.932678,626.767212 640.012695,630.366577 
	C640.726624,631.443848 641.417664,632.181580 642.067139,632.954102 
	C642.025635,632.988770 642.082092,632.896423 642.132874,633.207458 
	C642.791931,634.011108 643.400208,634.503723 644.236084,635.171631 
	C644.732666,635.847168 645.001770,636.347229 644.938110,637.014771 
	C643.402832,639.120728 642.200195,641.059143 640.597351,642.999023 
	C638.132263,643.332825 636.067322,643.665161 633.978394,643.652954 
	C633.397949,642.646545 632.841553,641.984680 632.285156,641.322754 
	C631.190063,642.548462 630.095032,643.774231 628.708740,645.226318 
	C626.945007,645.635010 625.472534,645.817505 623.872925,645.659607 
	C620.194885,642.935791 617.696289,643.200256 617.000000,648.250000 
	C617.000000,648.500000 617.000000,649.000000 616.600281,649.000732 
	C615.134827,649.002625 614.069153,649.003723 613.003418,649.004883 
	C604.975464,649.986694 596.573975,649.835938 589.041443,652.319946 
	C583.342651,654.199219 578.332031,657.947205 571.814270,657.558411 
	C571.007507,657.510254 569.303467,659.980896 569.376221,661.203430 
	C569.450012,662.445190 570.999939,664.175415 572.280457,664.630432 
	C574.075562,665.268372 576.220398,665.006226 578.213928,664.983032 
	C581.781433,664.941467 583.516724,666.124634 585.669006,669.670349 
	C588.091858,673.661621 593.124573,676.188110 596.113525,680.481201 
	C596.927307,681.649963 600.525574,680.872864 602.845032,681.003296 
	C604.171692,681.077881 606.482727,680.819336 606.669922,681.328491 
	C608.536377,686.405884 614.555420,687.913513 616.524475,691.944275 
	C618.516785,696.022705 620.735840,696.981873 624.417358,696.989136 
	C635.916321,697.011658 647.415466,697.037354 658.914124,696.976196 
	C662.148865,696.958984 663.613098,697.898254 665.488281,701.194946 
	C668.137085,705.851807 672.961609,709.470337 677.367004,712.870728 
	C681.463135,716.032593 683.294312,721.384705 688.611938,723.511780 
	C689.831482,723.999634 689.298096,727.992493 685.885010,729.091431 
	C684.065002,729.677429 682.719666,731.737427 681.160156,733.132141 
	C682.806946,734.450317 684.429626,735.800537 686.114990,737.067444 
	C686.623413,737.449646 687.679443,737.449951 687.852905,737.879944 
	C690.956604,745.575928 697.895935,751.740356 697.026733,761.036621 
	C696.644348,765.126160 697.023621,768.733704 702.478088,769.366394 
	C703.461609,769.480408 704.765686,772.022583 704.873535,773.519226 
	C705.196167,777.995911 705.042969,782.510681 704.989563,787.009521 
	C704.951538,790.214722 705.947693,792.234802 709.443359,793.184998 
	C710.906372,793.582642 712.636597,795.892456 712.833191,797.505066 
	C713.400085,802.155457 711.963684,807.167175 717.172974,810.489807 
	C717.930908,810.973206 717.502869,815.511230 716.287170,816.459412 
	C713.296326,818.791870 712.863403,821.502136 712.978149,824.791748 
	C713.082703,827.787842 713.092651,830.793945 712.977844,833.789307 
	C712.829102,837.670410 713.437561,840.730286 718.249390,841.256653 
	C719.239990,841.364990 720.249023,843.113342 720.853943,844.306396 
	C721.261963,845.111084 720.987427,846.267639 720.997803,847.267029 
	C721.097595,856.832764 721.085571,856.635864 711.709167,857.146423 
	C709.500305,857.266724 706.099182,858.742981 705.507935,860.401489 
	C704.337769,863.683899 708.160156,863.787231 710.090942,865.327332 
	C713.824280,868.305420 715.651917,873.415344 720.633240,875.433533 
	C720.976257,875.572449 720.239075,879.431763 719.383057,881.272034 
	C717.077454,886.228271 720.753845,895.166687 725.778625,897.185364 
	C727.176331,897.746948 728.723083,899.842957 728.838989,901.340515 
	C729.223877,906.310364 728.910156,911.329895 729.016663,916.327209 
	C729.090393,919.787720 727.457947,921.165161 724.138123,921.010071 
	C722.309204,920.924622 720.472107,921.030945 718.639526,921.002930 
	C715.598206,920.956421 713.755432,922.087708 712.871643,925.303284 
	C712.467529,926.773560 710.392273,927.734680 709.222107,929.056946 
	C707.741577,930.729858 705.466187,932.406250 705.205872,934.309875 
	C704.601807,938.727783 704.884338,943.286865 705.031738,947.781372 
	C705.152039,951.451416 703.382080,952.955688 699.940125,952.979187 
	C697.940430,952.992859 695.742371,952.491699 693.994995,953.163025 
	C692.127808,953.880249 689.691284,955.450562 689.270264,957.099609 
	C688.452942,960.301086 686.784729,960.892029 683.966919,961.044983 
	C676.783142,961.434937 669.346008,959.426880 662.496521,963.653625 
	C661.310181,964.385620 658.583984,963.413208 656.979065,962.504150 
	C654.045105,960.842407 651.884094,961.144470 649.334351,963.336182 
	C647.757019,964.692078 645.467224,965.569580 643.378174,965.893372 
	C640.611511,966.322021 637.706238,965.770386 634.899231,966.041382 
	C625.030640,966.994019 615.091736,967.638367 605.360535,969.399963 
	C601.116882,970.168091 597.356018,973.450623 593.295044,975.424866 
	C589.457275,977.290527 584.390625,977.637329 584.213440,983.657898 
	C584.199097,984.143494 582.066528,984.338074 581.299011,985.068176 
	C577.148743,989.015991 573.127075,993.098694 569.041992,997.115417 
	C567.693359,998.441406 566.498474,1000.327332 564.867126,1000.883728 
	C560.557190,1002.353882 555.874451,1002.443359 552.433594,1006.393372 
	C551.228943,1007.776428 548.146240,1007.854980 545.898376,1007.942078 
	C534.165894,1008.396667 522.427246,1008.718872 510.688171,1008.962585 
	C506.696777,1009.045471 504.875031,1010.868774 504.996033,1014.810425 
	C505.057343,1016.808472 504.593842,1018.937317 505.148712,1020.770569 
	C505.625122,1022.344727 507.167572,1023.621155 508.320587,1024.958496 
	C509.459473,1026.279419 510.700928,1027.511963 511.897400,1028.783203 
	C510.333496,1029.853149 508.820953,1031.744751 507.196472,1031.846191 
	C501.220154,1032.219849 495.206177,1031.949707 489.207489,1032.019165 
	C484.402588,1032.074707 480.448914,1033.766724 478.076447,1038.630371 
	C459.990204,1038.976807 441.967041,1038.984375 423.476135,1038.986206 
M215.788422,908.007446 
	C214.530716,908.598145 212.461700,908.919861 212.160660,909.827820 
	C211.062073,913.140869 209.007751,914.839600 205.817215,916.148804 
	C202.738251,917.412231 203.433121,924.387878 206.270798,926.292969 
	C208.297592,927.653687 210.475006,929.372070 211.536118,931.463135 
	C213.497757,935.328979 216.711975,934.020447 219.360504,933.692261 
	C220.401825,933.563232 221.785110,930.926270 221.877396,929.364319 
	C222.191208,924.052734 221.792068,918.700684 222.065933,913.384644 
	C222.278534,909.257751 220.800247,907.439392 215.788422,908.007446 
M261.417450,948.871887 
	C261.164398,948.850891 260.911346,948.829834 260.658295,948.808838 
	C260.654663,948.952148 260.651001,949.095459 260.647369,949.238770 
	C260.901337,949.229126 261.155334,949.219482 261.417450,948.871887 
M397.128052,925.417480 
	C397.149139,925.164429 397.170258,924.911438 397.191345,924.658447 
	C397.048004,924.654724 396.904663,924.651001 396.761322,924.647278 
	C396.770966,924.901306 396.780579,925.155334 397.128052,925.417480 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M132.000610,961.000000 
	C132.444946,960.999939 132.889282,960.999878 133.917572,961.224243 
	C138.318817,963.423889 140.874161,968.120789 146.513489,966.108215 
	C150.362411,964.734619 152.570282,967.515442 154.187576,970.743774 
	C154.905319,972.176636 155.728790,974.181946 156.966843,974.619995 
	C162.222122,976.479248 163.123260,983.813782 169.452377,984.294373 
	C170.039322,984.338928 170.725571,986.486633 170.901443,987.723816 
	C171.156830,989.520386 171.130768,991.389465 170.971558,993.205444 
	C170.680252,996.528076 171.911209,998.133423 175.408508,998.165039 
	C182.598053,998.229980 189.483475,1005.723450 196.978546,999.041077 
	C197.166870,998.873169 197.626129,999.004211 197.959015,999.004150 
	C202.953415,999.003235 207.948044,999.032959 212.942108,998.992615 
	C215.851242,998.969116 218.471817,999.535400 218.851746,1002.942993 
	C219.220367,1006.249146 219.567520,1009.624756 215.158813,1011.113708 
	C213.844467,1011.557617 212.587769,1013.409180 212.116837,1014.876465 
	C210.822952,1018.907532 210.263916,1023.184631 208.853470,1027.165771 
	C206.865280,1032.777832 208.136658,1035.347046 214.040817,1034.896240 
	C222.376053,1034.260010 230.671997,1033.011353 238.943008,1031.750366 
	C240.020157,1031.586304 241.653702,1029.795410 241.654617,1028.756714 
	C241.658676,1024.127075 244.381912,1023.935425 247.929016,1023.952759 
	C267.906158,1024.050537 287.884003,1024.000000 307.861603,1024.000000 
	C319.555511,1024.000000 319.555511,1024.000000 319.770020,1036.003174 
	C323.177765,1036.003174 326.656250,1035.681030 330.029999,1036.135864 
	C331.779938,1036.371704 333.344513,1037.982666 334.992828,1038.972412 
	C239.015091,1038.987671 143.037262,1038.956543 47.059856,1039.138184 
	C41.572586,1039.148560 40.895008,1036.985718 40.899994,1032.358643 
	C41.011871,928.549438 40.999802,824.740112 41.389156,720.429077 
	C42.337906,719.629089 43.317188,719.430115 43.417355,719.017578 
	C44.685940,713.794006 45.699093,708.505920 47.059605,703.308228 
	C47.689919,700.900146 48.675770,696.818420 49.943222,696.639954 
	C56.548061,695.709961 56.023594,690.797424 57.146526,686.347778 
	C57.752499,683.946777 59.768330,681.882751 61.226772,679.719727 
	C63.994797,675.614441 66.969521,671.708252 71.564499,669.408325 
	C71.897926,669.241394 71.838753,668.409363 72.159248,668.076294 
	C73.481979,666.701782 74.847252,665.363403 76.262428,664.084229 
	C77.339203,663.110901 78.581261,662.316956 79.632278,661.319336 
	C81.567680,659.482300 83.451523,657.587158 85.287766,655.650757 
	C86.285210,654.598938 87.098129,653.374146 88.070457,652.296082 
	C89.348297,650.879272 90.702454,649.531372 92.021431,648.151550 
	C93.439888,646.667664 94.846146,645.171997 96.275642,643.698853 
	C97.394218,642.546082 98.542511,641.422180 99.673393,640.281250 
	C101.225327,638.715576 102.779633,637.152161 104.320618,635.575684 
	C105.335678,634.537231 106.311394,633.460083 107.336342,632.431763 
	C109.001701,630.760925 110.679794,629.102234 112.377831,627.464661 
	C113.533905,626.349792 114.759308,625.306396 115.906586,624.182922 
	C117.371979,622.747925 118.776199,621.250610 120.232498,619.806152 
	C121.480942,618.567749 122.719589,617.312561 124.048523,616.164246 
	C125.147774,615.214478 126.973099,614.648254 127.430122,613.496704 
	C128.854019,609.908997 131.529297,609.175354 134.939560,608.922424 
	C139.103790,608.613647 142.580902,607.765503 144.898666,603.035217 
	C146.834366,599.084656 151.094040,596.262390 154.380157,592.996460 
	C154.815628,592.563599 155.595459,592.206909 156.188446,592.244751 
	C162.276779,592.632324 166.300507,590.732727 170.640717,585.524292 
	C173.615845,581.954041 177.623871,577.429688 184.090958,575.944092 
	C191.509827,574.239929 197.962128,568.786743 205.993988,567.265991 
	C209.985580,566.510193 213.556564,563.670837 216.028427,571.122742 
	C216.764923,567.444641 217.136505,565.589050 217.600815,563.270264 
	C219.185928,563.529419 220.594360,563.759644 222.117905,564.162598 
	C222.567139,564.566406 222.901260,564.797668 223.371063,565.388367 
	C223.673141,566.498047 223.839554,567.248169 223.810730,568.130493 
	C223.377151,568.472717 223.180939,568.716736 223.032669,569.296814 
	C223.300552,570.144409 223.562637,570.689819 223.824707,571.235229 
	C224.220749,570.825439 224.616806,570.415710 225.411163,570.005798 
	C226.871353,570.002991 227.933243,570.000244 229.390228,570.019775 
	C234.239059,569.969666 236.251373,572.763977 237.022766,577.015381 
	C237.440002,576.985718 237.857254,576.955994 238.274506,576.926270 
	C239.184998,574.612061 240.095490,572.297913 241.403076,569.982422 
	C242.858414,569.981445 243.916656,569.981750 245.318954,570.055542 
	C248.158691,572.259888 250.501221,574.735291 254.322708,573.819885 
	C254.447083,573.790100 254.769730,574.587952 254.665985,575.096069 
	C251.570633,576.470703 248.741394,577.625244 246.077469,579.082031 
	C244.816330,579.771729 243.359131,580.842224 242.880936,582.083252 
	C241.538300,585.567749 239.099564,586.755981 235.591782,587.038269 
	C228.906448,587.576294 222.397324,586.079529 215.599747,590.290405 
	C210.560181,593.412292 204.213196,594.101807 201.436722,600.531677 
	C200.962494,601.629944 199.006973,602.066528 197.772858,602.866028 
	C196.856262,603.459778 195.384079,604.015381 195.214676,604.812317 
	C193.963562,610.697998 189.712906,611.070618 184.888809,611.095764 
	C182.974991,611.105713 179.668930,611.802917 179.386108,612.839294 
	C177.728165,618.914429 173.336227,619.262573 168.413071,618.993774 
	C165.578094,618.839050 164.096329,619.658142 162.303894,622.494080 
	C159.432159,627.037659 154.859390,630.506470 150.997772,634.421875 
	C150.890823,634.530273 150.655899,634.510559 150.545197,634.617432 
	C149.241852,635.875488 147.921463,637.117920 146.662186,638.419250 
	C142.839386,642.369629 139.046432,646.348877 135.220749,650.296387 
	C135.011612,650.512146 134.605286,650.655396 134.299515,650.641357 
	C128.309906,650.366577 124.440750,652.712036 119.827881,657.170898 
	C116.187515,660.689697 115.026741,663.205750 114.949646,667.511475 
	C114.857368,672.664612 112.717278,674.715515 107.620399,674.978882 
	C106.290756,675.047546 104.953339,675.051880 103.622818,675.000305 
	C100.544800,674.880920 98.890388,676.182922 99.008369,679.405457 
	C99.051010,680.569824 99.026245,681.738220 98.992889,682.903625 
	C98.816391,689.071899 96.895935,690.759888 90.773529,690.523254 
	C89.672310,690.480774 87.599518,691.265198 87.560219,691.787170 
	C87.127014,697.540405 81.479546,701.645569 82.750664,708.090820 
	C83.096886,709.846313 81.020668,713.501587 79.403740,713.954285 
	C74.646202,715.286072 74.839241,718.438660 74.984421,722.022400 
	C75.124069,725.469727 74.952904,729.219971 71.008598,730.281311 
	C67.047554,731.347290 67.095749,733.820862 66.884712,736.990295 
	C66.696068,739.823608 66.399834,743.726196 64.584305,745.128418 
	C61.691170,747.362915 59.184696,749.208435 57.767818,752.839905 
	C55.922035,757.570801 52.228462,761.733459 51.097111,766.555237 
	C49.298695,774.219971 48.557621,782.207275 48.162415,790.097839 
	C47.937351,794.591309 49.299519,799.161804 49.923908,803.700806 
	C50.976978,811.356140 52.902798,818.994690 48.668137,826.467957 
	C47.871582,827.873657 48.637627,830.247375 48.889103,832.147034 
	C49.392632,835.950684 50.599922,839.747070 50.521011,843.525696 
	C50.285381,854.808472 49.140888,866.087585 49.307732,877.353943 
	C49.356056,880.617188 49.170521,885.426331 54.079525,887.292236 
	C59.491135,889.349182 60.291538,892.765808 56.563156,897.202698 
	C54.404156,899.771973 54.632488,901.919617 56.823914,904.207031 
	C57.604965,905.022278 58.726559,905.962158 58.806927,906.919434 
	C59.149368,910.998840 61.881821,911.082214 64.888519,911.012756 
	C66.384048,910.978149 67.916298,910.905396 69.373688,911.170288 
	C72.960197,911.822266 75.281807,913.995728 74.701767,917.733276 
	C74.151405,921.279724 71.819878,923.396973 67.899109,922.438538 
	C64.218140,921.538696 62.971088,923.227905 63.007648,926.585083 
	C63.025787,928.250854 62.929310,929.921936 63.027203,931.582214 
	C63.382576,937.609253 68.785744,942.917664 74.733749,942.977600 
	C78.330177,943.013855 81.608780,943.691711 82.590126,947.638367 
	C83.335030,950.634155 85.118225,951.125000 87.711044,951.021790 
	C91.538879,950.869324 95.157974,950.832397 98.346870,954.031799 
	C99.303505,954.991577 102.672699,953.577209 104.928711,953.202698 
	C107.008087,952.857483 109.068230,952.219666 111.153053,952.141174 
	C112.755814,952.080750 114.383743,952.689026 116.000481,953.000000 
	C116.444870,952.999939 116.889259,952.999878 117.898544,953.249146 
	C122.975822,955.998962 127.488213,958.499512 132.000610,961.000000 
M226.981140,575.662476 
	C228.257904,576.083313 229.534683,576.504089 230.811447,576.924927 
	C230.886688,576.372314 230.961945,575.819702 231.037186,575.267090 
	C229.840530,575.267090 228.643875,575.267090 226.981140,575.662476 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M222.002777,563.989868 
	C220.594360,563.759644 219.185928,563.529419 217.600815,563.270264 
	C217.136505,565.589050 216.764923,567.444641 216.028427,571.122742 
	C213.556564,563.670837 209.985580,566.510193 205.993988,567.265991 
	C197.962128,568.786743 191.509827,574.239929 184.090958,575.944092 
	C177.623871,577.429688 173.615845,581.954041 170.640717,585.524292 
	C166.300507,590.732727 162.276779,592.632324 156.188446,592.244751 
	C155.595459,592.206909 154.815628,592.563599 154.380157,592.996460 
	C151.094040,596.262390 146.834366,599.084656 144.898666,603.035217 
	C142.580902,607.765503 139.103790,608.613647 134.939560,608.922424 
	C131.529297,609.175354 128.854019,609.908997 127.430122,613.496704 
	C126.973099,614.648254 125.147774,615.214478 124.048523,616.164246 
	C122.719589,617.312561 121.480942,618.567749 120.232498,619.806152 
	C118.776199,621.250610 117.371979,622.747925 115.906586,624.182922 
	C114.759308,625.306396 113.533905,626.349792 112.377831,627.464661 
	C110.679794,629.102234 109.001701,630.760925 107.336342,632.431763 
	C106.311394,633.460083 105.335678,634.537231 104.320618,635.575684 
	C102.779633,637.152161 101.225327,638.715576 99.673393,640.281250 
	C98.542511,641.422180 97.394218,642.546082 96.275642,643.698853 
	C94.846146,645.171997 93.439888,646.667664 92.021431,648.151550 
	C90.702454,649.531372 89.348297,650.879272 88.070457,652.296082 
	C87.098129,653.374146 86.285210,654.598938 85.287766,655.650757 
	C83.451523,657.587158 81.567680,659.482300 79.632278,661.319336 
	C78.581261,662.316956 77.339203,663.110901 76.262428,664.084229 
	C74.847252,665.363403 73.481979,666.701782 72.159248,668.076294 
	C71.838753,668.409363 71.897926,669.241394 71.564499,669.408325 
	C66.969521,671.708252 63.994797,675.614441 61.226772,679.719727 
	C59.768330,681.882751 57.752499,683.946777 57.146526,686.347778 
	C56.023594,690.797424 56.548061,695.709961 49.943222,696.639954 
	C48.675770,696.818420 47.689919,700.900146 47.059605,703.308228 
	C45.699093,708.505920 44.685940,713.794006 43.417355,719.017578 
	C43.317188,719.430115 42.337906,719.629089 41.397404,719.959961 
	C41.018513,699.306274 41.009388,678.619873 41.347427,657.400513 
	C42.145279,656.666992 42.678658,656.561279 43.034355,656.251526 
	C49.475433,650.642578 56.688599,648.101074 65.469849,648.498352 
	C68.555725,648.638000 71.815346,644.937378 75.063164,642.614136 
	C78.343918,633.558716 81.573776,624.885681 84.766243,616.198853 
	C88.993896,604.695312 93.026688,593.116821 97.447128,581.688171 
	C102.461014,568.725159 107.810783,555.892029 113.401985,542.993530 
	C114.827034,542.183716 116.657646,541.427063 116.705429,540.570862 
	C116.767937,539.450806 115.477402,538.211304 114.668182,537.096313 
	C113.300667,535.212036 110.533180,533.183228 110.777306,531.585449 
	C111.077972,529.617554 113.855316,528.023926 115.572052,526.278198 
	C116.457474,525.377808 117.910233,524.633118 118.168533,523.586548 
	C119.427254,518.486267 123.296478,517.901123 127.436333,518.057129 
	C131.243958,518.200684 134.378891,517.899414 135.234558,513.254883 
	C135.395294,512.382507 136.673660,511.427948 137.646835,511.041595 
	C142.853455,508.974518 149.047119,511.110077 153.755676,505.487610 
	C156.023697,502.779358 161.972092,502.902405 166.330887,502.304138 
	C168.435699,502.015259 170.740784,502.932800 172.912521,503.474945 
	C174.312271,503.824341 175.634537,504.484131 176.995728,505.002686 
	C176.999939,505.000549 176.996552,505.008179 177.074982,505.304688 
	C177.767670,506.067902 178.381927,506.534576 179.201660,507.201874 
	C179.605850,507.932953 179.804565,508.463409 180.021271,509.332825 
	C180.694260,510.447662 181.349274,511.223511 182.258453,512.226318 
	C186.077591,516.901978 189.609879,521.377502 193.224319,525.785706 
	C194.586487,527.447021 196.423401,530.760620 198.443787,527.698853 
	C201.869019,522.508057 204.215500,516.605469 207.352203,510.937256 
	C214.545853,507.319733 216.423706,501.019104 217.000305,494.000244 
	C217.000000,494.000000 217.000610,494.000000 217.364426,493.982605 
	C219.558578,492.314331 221.388931,490.663452 224.046173,488.266754 
	C225.309357,489.788177 226.867462,492.237976 228.964737,494.070862 
	C233.268387,497.832184 240.589676,496.689148 243.519699,502.843292 
	C251.193939,503.694031 253.753357,511.057953 258.722290,515.076538 
	C257.346252,520.164917 260.841827,516.327637 261.998352,517.397949 
	C261.999634,518.865112 262.000122,519.931946 261.606689,521.000244 
	C259.773834,521.579712 257.892242,521.772339 256.985687,522.813721 
	C254.936737,525.167297 253.468948,524.589294 251.674591,522.695923 
	C250.248932,521.191528 248.645721,519.855347 246.615524,517.976379 
	C248.827103,516.358765 250.655457,515.021545 252.483810,513.684265 
	C252.441208,513.172546 252.398621,512.660889 252.356018,512.149170 
	C249.649994,512.149170 246.943787,512.129761 244.238007,512.155884 
	C242.318390,512.174377 240.396317,512.344299 238.481018,512.279175 
	C236.869003,512.224304 235.059357,511.349060 233.691971,511.835114 
	C226.131165,514.522400 219.838043,518.706421 217.566116,527.608459 
	C219.166382,527.761414 220.590805,527.897583 222.013702,528.500000 
	C222.009018,540.640747 222.005890,552.315308 222.002777,563.989868 
M46.021946,668.746521 
	C45.536350,670.827759 45.132362,672.932373 44.546101,674.984863 
	C43.654327,678.106995 44.452080,680.075989 48.936066,680.916626 
	C47.940125,675.206848 50.355202,672.132080 55.730457,671.452942 
	C56.237026,671.388916 56.853542,669.868164 56.907646,668.992188 
	C57.061249,666.505249 57.272015,663.940430 56.811615,661.530090 
	C56.568287,660.256226 55.034290,658.389587 53.975822,658.315186 
	C52.531612,658.213684 50.424236,659.181641 49.603256,660.378418 
	C48.052715,662.638855 47.216839,665.389526 46.021946,668.746521 
M214.996216,550.236084 
	C214.861618,548.756836 214.727020,547.277588 214.592438,545.798340 
	C214.102036,545.829224 213.611649,545.860107 213.121246,545.890991 
	C213.121246,548.275635 213.121246,550.660339 213.121246,553.044983 
	C213.564804,553.076599 214.008362,553.108215 214.451904,553.139832 
	C214.629074,552.415894 214.806229,551.691895 214.996216,550.236084 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M41.097687,319.853363 
	C41.043564,289.873474 40.989437,259.893555 41.393539,229.450531 
	C50.044544,226.366089 58.241505,223.757797 66.429298,221.121002 
	C88.283554,214.083069 110.133888,207.032898 132.346313,199.989319 
	C134.138901,199.326660 135.570938,198.662277 137.392853,197.979187 
	C141.417877,196.866257 145.065140,195.810486 148.685120,194.668259 
	C153.412506,193.176590 158.074661,191.455795 162.852936,190.157730 
	C165.044586,189.562347 167.724609,189.488052 165.386932,193.317413 
	C164.390701,194.280121 163.507828,195.076385 163.019196,196.067078 
	C150.842453,220.756027 138.700912,245.462372 126.566772,270.172333 
	C116.258759,291.163544 105.967812,312.163086 95.284317,333.137085 
	C87.858208,331.346497 80.824135,329.549255 73.774384,327.815704 
	C62.887783,325.138672 51.990459,322.505280 41.097687,319.853363 
z"/>
<path fill="#EBECE9" opacity="1.000000" stroke="none" 
	d="
M41.066086,320.334442 
	C51.990459,322.505280 62.887783,325.138672 73.774384,327.815704 
	C80.824135,329.549255 87.858208,331.346497 95.348724,333.590271 
	C95.633080,349.514374 95.500031,364.963928 95.295677,380.412506 
	C94.962769,405.580078 94.583275,430.746979 93.921097,456.030426 
	C92.191231,455.633240 90.480568,455.459076 89.386559,454.546021 
	C85.237938,451.083740 81.364922,447.292755 77.243637,443.795868 
	C73.259071,440.414917 69.095680,437.244720 64.786728,433.739502 
	C60.748909,429.907166 57.097195,426.122131 53.074783,422.783722 
	C49.247135,419.606934 45.032257,416.896729 40.987644,413.981354 
	C41.003258,382.926086 41.018871,351.870789 41.066086,320.334442 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M113.014526,543.002441 
	C107.810783,555.892029 102.461014,568.725159 97.447128,581.688171 
	C93.026688,593.116821 88.993896,604.695312 84.766243,616.198853 
	C81.573776,624.885681 78.343918,633.558716 74.726646,642.670532 
	C72.698090,642.708008 70.560204,642.835144 69.536537,641.828857 
	C64.689217,637.063721 60.430019,631.677002 55.413105,627.114502 
	C50.612289,622.748535 47.826012,616.141724 41.030930,613.984009 
	C41.010960,589.303162 40.990986,564.622375 41.387867,539.475586 
	C43.989609,539.722107 46.157146,540.967468 48.362896,541.039368 
	C53.412945,541.203796 58.604652,541.330933 63.450756,540.218323 
	C64.572060,539.960938 64.541031,534.672607 65.033852,531.698303 
	C65.142250,531.044128 65.215797,530.384277 65.318115,529.729004 
	C66.894104,519.636169 68.399651,509.531616 70.075706,499.455383 
	C71.165100,492.906128 72.503670,486.398254 73.730606,479.871857 
	C74.179596,479.831055 74.628586,479.790283 75.077576,479.749512 
	C75.392929,482.170013 75.708282,484.590515 76.096664,487.420837 
	C76.441696,489.291626 76.910294,490.746643 76.954903,492.214569 
	C77.347198,505.122345 77.732719,518.031128 77.978767,530.942261 
	C78.140831,539.445923 79.648697,540.698181 88.057137,539.958069 
	C94.967972,539.349792 101.929878,539.339111 108.864632,538.976990 
	C111.807465,538.823425 113.262527,539.977966 113.014526,543.002441 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M76.023636,487.011017 
	C75.708282,484.590515 75.392929,482.170013 75.077576,479.749512 
	C74.628586,479.790283 74.179596,479.831055 73.730606,479.871857 
	C72.503670,486.398254 71.165100,492.906128 70.075706,499.455383 
	C68.399651,509.531616 66.894104,519.636169 65.318115,529.729004 
	C65.215797,530.384277 65.142250,531.044128 65.033852,531.698303 
	C64.541031,534.672607 64.572060,539.960938 63.450756,540.218323 
	C58.604652,541.330933 53.412945,541.203796 48.362896,541.039368 
	C46.157146,540.967468 43.989609,539.722107 41.410187,539.007690 
	C41.006458,525.315063 40.997265,511.624481 41.363060,497.451233 
	C44.949394,495.306274 48.304512,493.854370 51.268234,491.829590 
	C51.984413,491.340271 51.963547,487.967255 51.330982,487.611725 
	C48.066597,485.777069 44.493092,484.492432 41.027420,483.015900 
	C41.018005,473.319397 41.008595,463.622894 41.376518,453.443817 
	C42.389946,452.798828 43.145924,452.804230 43.643986,452.448547 
	C50.203247,447.763641 56.794704,443.117310 63.190643,438.216644 
	C64.254402,437.401581 64.426628,435.422943 65.010834,433.981995 
	C69.095680,437.244720 73.259071,440.414917 77.243637,443.795868 
	C81.364922,447.292755 85.237938,451.083740 89.386559,454.546021 
	C90.480568,455.459076 92.191231,455.633240 94.203339,455.858673 
	C96.330177,450.816956 97.974083,446.093109 99.383797,441.300385 
	C100.594078,437.185730 101.536682,432.992371 103.035179,428.891113 
	C105.920219,429.215820 108.393143,429.842285 110.809639,429.671295 
	C115.029755,429.372650 119.200500,428.272583 123.418594,428.073639 
	C129.549118,427.784576 135.712753,428.178864 141.840363,427.860413 
	C143.267410,427.786224 144.606323,426.015869 146.359406,425.037720 
	C148.363968,425.738464 150.038437,426.340637 151.617493,427.132935 
	C156.098450,429.381195 160.540390,431.707184 165.016693,434.361145 
	C166.333801,438.334198 166.834091,438.496552 169.360382,436.067993 
	C180.143204,441.088867 190.568481,446.049011 201.064987,451.360840 
	C202.921310,453.187164 204.788803,454.574738 206.466339,456.162964 
	C207.894714,457.515350 209.104370,459.098724 210.410782,460.579895 
	C208.706573,461.709290 206.992340,463.759033 205.300690,463.740570 
	C203.068283,463.716217 200.481186,462.519409 198.695999,461.041931 
	C191.114090,454.766937 183.802643,448.166046 176.296631,441.797058 
	C175.528992,441.145691 174.121964,441.244110 173.009735,441.003479 
	C171.242950,440.621277 169.429016,440.388947 167.715729,439.840942 
	C163.281036,438.422546 160.735779,439.519470 160.187012,444.049835 
	C159.773499,447.463654 158.164291,447.979614 155.277985,448.051056 
	C147.987473,448.231537 140.694977,448.560638 133.423416,449.101105 
	C131.797958,449.221893 129.017975,450.266022 128.875290,451.205048 
	C127.958511,457.238281 123.536812,455.607391 119.807419,456.109924 
	C117.390083,456.435638 113.299225,457.483063 113.077560,458.752014 
	C111.945778,465.230927 107.404442,463.728882 103.284409,464.113983 
	C101.071327,464.320831 97.300186,465.387634 97.093498,466.567627 
	C96.071381,472.402924 92.244568,472.107239 87.955063,472.051300 
	C80.663414,471.956177 78.311981,474.547943 77.917023,481.923859 
	C77.824677,483.648407 76.682693,485.316803 76.023636,487.011017 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M335.368378,1038.981201 
	C333.344513,1037.982666 331.779938,1036.371704 330.029999,1036.135864 
	C326.656250,1035.681030 323.177765,1036.003174 319.770020,1036.003174 
	C319.555511,1024.000000 319.555511,1024.000000 307.861603,1024.000000 
	C287.884003,1024.000000 267.906158,1024.050537 247.929016,1023.952759 
	C244.381912,1023.935425 241.658676,1024.127075 241.654617,1028.756714 
	C241.653702,1029.795410 240.020157,1031.586304 238.943008,1031.750366 
	C230.671997,1033.011353 222.376053,1034.260010 214.040817,1034.896240 
	C208.136658,1035.347046 206.865280,1032.777832 208.853470,1027.165771 
	C210.263916,1023.184631 210.822952,1018.907532 212.116837,1014.876465 
	C212.587769,1013.409180 213.844467,1011.557617 215.158813,1011.113708 
	C219.567520,1009.624756 219.220367,1006.249146 218.851746,1002.942993 
	C218.471817,999.535400 215.851242,998.969116 212.942108,998.992615 
	C207.948044,999.032959 202.953415,999.003235 197.959015,999.004150 
	C197.626129,999.004211 197.166870,998.873169 196.978546,999.041077 
	C189.483475,1005.723450 182.598053,998.229980 175.408508,998.165039 
	C171.911209,998.133423 170.680252,996.528076 170.971558,993.205444 
	C171.130768,991.389465 171.156830,989.520386 170.901443,987.723816 
	C170.725571,986.486633 170.039322,984.338928 169.452377,984.294373 
	C163.123260,983.813782 162.222122,976.479248 156.966843,974.619995 
	C155.728790,974.181946 154.905319,972.176636 154.187576,970.743774 
	C152.570282,967.515442 150.362411,964.734619 146.513489,966.108215 
	C140.874161,968.120789 138.318817,963.423889 134.250824,961.223999 
	C141.720139,958.721069 148.630310,960.237000 154.448196,965.774048 
	C157.493973,968.672852 158.588837,968.466980 160.083633,964.291931 
	C160.461914,963.235474 161.537598,962.428711 162.292786,961.507202 
	C163.179214,962.538940 164.707794,963.515320 164.802521,964.615173 
	C164.946869,966.291260 164.403809,968.210144 163.611572,969.746887 
	C161.703812,973.447754 162.507919,976.118225 166.368317,975.787720 
	C174.589859,975.083801 179.989288,980.109436 186.000565,984.099060 
	C186.276047,984.281860 186.567917,984.583496 186.854095,984.585754 
	C193.610382,984.640198 199.821381,986.400696 206.258057,988.675232 
	C213.913223,991.380310 223.230057,990.072021 230.297256,993.582336 
	C239.700317,998.252869 250.111069,996.359314 259.310699,1000.631592 
	C261.801971,1001.788574 265.226410,1000.999207 268.222107,1000.987976 
	C270.854950,1000.978088 272.770142,1001.603943 273.055084,1004.712341 
	C273.363525,1008.077087 275.677002,1009.031250 278.700409,1009.001221 
	C282.366577,1008.964844 286.078064,1008.648132 289.686066,1009.109131 
	C292.635223,1009.486084 295.449066,1010.867859 298.346527,1011.724365 
	C300.054871,1012.229370 301.797791,1012.873047 303.545105,1012.940918 
	C308.038330,1013.115234 312.546082,1013.112488 317.041504,1012.971436 
	C320.717621,1012.856201 322.054321,1014.494751 322.063110,1018.075562 
	C322.070831,1021.208740 322.533844,1024.362793 323.062561,1027.461914 
	C323.274109,1028.702026 324.200256,1029.819946 325.004272,1031.390259 
	C326.793121,1029.516968 327.975159,1028.279175 329.535461,1026.645142 
	C332.507782,1030.788208 335.188690,1034.525024 337.932922,1038.622070 
	C337.245514,1038.984985 336.494720,1038.987549 335.368378,1038.981201 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M131.985779,199.987595 
	C110.133888,207.032898 88.283554,214.083069 66.429298,221.121002 
	C58.241505,223.757797 50.044544,226.366089 41.448517,228.983215 
	C41.016628,221.965164 40.987988,214.951279 41.022171,207.472946 
	C44.485077,206.553085 47.207462,206.974442 50.545364,209.018539 
	C53.565849,210.868195 58.550446,209.695770 62.653973,209.515121 
	C64.773544,209.421814 66.862762,208.158066 68.961769,208.169113 
	C75.317650,208.202499 81.260246,207.761276 86.895874,203.902878 
	C89.187347,202.334030 93.200241,203.155121 96.431770,203.129852 
	C98.405403,203.114395 100.382622,203.557602 103.104164,203.884384 
	C101.750191,199.649826 100.729965,196.459045 99.364265,193.135040 
	C99.012283,190.959854 99.005775,188.917908 99.183228,186.156769 
	C100.570938,184.963501 101.774689,184.489395 103.200333,184.266113 
	C110.504829,192.191772 117.447479,200.109802 129.740433,196.814880 
	C130.174240,196.698593 131.223419,198.878098 131.985779,199.987595 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M41.011986,614.453857 
	C47.826012,616.141724 50.612289,622.748535 55.413105,627.114502 
	C60.430019,631.677002 64.689217,637.063721 69.536537,641.828857 
	C70.560204,642.835144 72.698090,642.708008 74.659111,643.046692 
	C71.815346,644.937378 68.555725,648.638000 65.469849,648.498352 
	C56.688599,648.101074 49.475433,650.642578 43.034355,656.251526 
	C42.678658,656.561279 42.145279,656.666992 41.355927,656.931641 
	C41.009193,642.971741 41.001118,628.947754 41.011986,614.453857 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M64.786728,433.739502 
	C64.426628,435.422943 64.254402,437.401581 63.190643,438.216644 
	C56.794704,443.117310 50.203247,447.763641 43.643986,452.448547 
	C43.145924,452.804230 42.389946,452.798828 41.395500,452.974731 
	C41.011524,440.299652 40.985901,427.611084 40.973961,414.451935 
	C45.032257,416.896729 49.247135,419.606934 53.074783,422.783722 
	C57.097195,426.122131 60.748909,429.907166 64.786728,433.739502 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M52.506294,132.931854 
	C53.751160,133.709824 55.478153,134.714783 55.346554,135.306152 
	C54.534016,138.957443 53.687691,142.693161 52.130543,146.067642 
	C48.902027,153.064087 45.222046,159.852219 41.386024,166.858826 
	C41.000546,156.977966 40.956730,146.964264 40.987122,136.488724 
	C44.750652,135.007141 48.439983,133.987396 52.506294,132.931854 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M41.009789,483.478729 
	C44.493092,484.492432 48.066597,485.777069 51.330982,487.611725 
	C51.963547,487.967255 51.984413,491.340271 51.268234,491.829590 
	C48.304512,493.854370 44.949394,495.306274 41.371582,496.982422 
	C41.000797,492.644653 40.996479,488.293121 41.009789,483.478729 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M41.025246,194.446320 
	C43.966812,195.983063 43.574337,198.279190 41.419590,200.851776 
	C41.045105,198.950226 41.005169,196.914642 41.025246,194.446320 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M771.001404,335.000549 
	C771.017273,338.030090 771.033142,341.059662 770.771790,344.773560 
	C770.278931,346.324768 770.063354,347.191559 769.542053,348.047638 
	C768.828430,348.034912 768.420532,348.032959 768.007080,347.623077 
	C768.647644,342.314728 764.501831,342.558868 761.782959,341.857727 
	C755.397156,340.211029 749.697693,335.335815 742.356934,337.698975 
	C741.418030,338.001221 739.000366,336.689331 738.800354,335.785950 
	C737.981323,332.085846 735.309265,331.158783 732.329590,329.768219 
	C727.876831,327.690125 723.886780,324.643677 719.587219,322.208069 
	C713.846924,318.956329 709.645691,312.938782 702.207642,312.611847 
	C701.442383,312.578217 700.586853,310.800018 700.081238,309.690826 
	C699.539978,308.503326 699.678711,306.259888 698.976990,306.011169 
	C693.997864,304.246307 690.938171,299.496674 686.720032,297.520355 
	C682.678528,295.626801 681.057129,294.227692 680.871704,289.964539 
	C680.677246,285.495270 680.009399,281.060974 674.473450,279.256500 
	C673.352539,278.891113 672.686401,275.428589 672.766663,273.432404 
	C672.937012,269.200256 674.064636,264.875122 668.067871,263.666840 
	C667.401611,263.532593 666.632141,262.715027 666.353699,262.033356 
	C663.044312,253.932114 657.664001,246.448547 658.893066,236.957184 
	C659.014404,236.020325 658.312073,234.442139 657.526001,234.010406 
	C652.932556,231.487717 653.535461,228.287354 655.565430,224.362640 
	C657.347473,220.917236 657.067078,218.119919 652.412231,216.773346 
	C650.957397,216.352509 649.587036,213.872787 649.160461,212.099243 
	C648.591858,209.735367 649.091797,207.133743 648.933167,204.646957 
	C648.745056,201.697342 647.484497,200.436249 644.085754,200.639664 
	C636.625732,201.086212 629.128113,200.887085 621.646606,201.002853 
	C618.943481,201.044662 617.675293,200.011917 616.741333,197.238464 
	C616.144104,195.465042 613.569092,193.421127 611.671204,193.185364 
	C603.729553,192.198807 603.553162,192.535934 600.396057,199.644287 
	C600.150513,200.197083 599.460815,200.552658 598.748718,200.715515 
	C598.010864,197.954483 597.501831,195.477859 597.192810,192.775696 
	C596.929321,191.699692 596.465881,190.849228 595.938232,189.639374 
	C595.581909,187.853531 595.289734,186.427078 595.269897,184.918823 
	C594.653870,184.236298 593.765442,183.635559 592.567200,183.134430 
	C586.190491,188.830048 580.123657,194.426086 573.765625,199.881012 
	C568.967651,196.637848 573.333130,195.230515 574.777710,193.408554 
	C576.105652,191.733627 577.737061,190.299286 579.234741,188.758957 
	C578.953369,188.213181 578.671997,187.667404 578.390625,187.121613 
	C575.848145,186.747910 573.311401,186.096863 570.762329,186.044418 
	C550.656860,185.630966 530.547913,185.380371 510.443970,184.910995 
	C508.717072,184.870667 505.654419,183.802444 505.526855,182.888611 
	C504.652740,176.626587 500.223328,176.996002 495.811371,176.996689 
	C456.533447,177.002762 417.255463,176.962082 377.977722,177.046371 
	C372.849457,177.057373 367.694946,177.495331 362.614227,178.187622 
	C361.341705,178.361008 359.140259,180.606873 359.345703,181.360626 
	C361.383789,188.838638 361.557068,197.257172 369.132721,202.228165 
	C370.144592,202.892136 370.396301,204.714615 370.665833,206.001862 
	C369.886108,206.005157 369.440552,206.007187 368.753052,205.784103 
	C366.005371,203.375961 363.499634,201.192932 360.857361,198.812836 
	C360.515442,198.366730 360.276215,198.156204 359.998047,197.583038 
	C358.025635,192.302231 359.662567,185.591171 352.645630,183.197708 
	C351.747437,182.891342 351.700500,180.514206 350.936951,179.298843 
	C349.415161,176.876495 347.977722,174.267105 345.932953,172.343613 
	C343.709076,170.251617 340.884735,168.767807 338.228180,167.175690 
	C336.207733,165.964798 333.891663,165.210846 331.977997,163.867691 
	C329.874329,162.391174 328.080719,160.472824 326.152039,158.746979 
	C328.198914,157.158157 330.023254,154.723755 332.328583,154.117111 
	C347.875793,150.025940 363.853210,151.673920 379.508942,152.536682 
	C385.921356,152.890030 391.800873,153.027405 398.291016,152.557343 
	C418.670990,151.081253 439.226715,151.915100 459.710388,152.171417 
	C461.705200,152.196381 463.744141,154.313889 465.628021,155.649872 
	C466.933136,156.575424 467.871918,158.376266 469.238312,158.712051 
	C469.971008,158.892120 471.643616,156.980392 472.233704,155.707397 
	C474.764862,150.246811 479.617676,152.378250 483.655304,152.061478 
	C486.809235,151.814041 489.404602,153.106750 489.807251,156.540543 
	C490.189728,159.802261 492.156189,160.042801 494.705170,160.018570 
	C501.200439,159.956741 507.701019,159.867203 514.190002,160.078934 
	C516.221191,160.145233 518.482727,160.722351 520.182068,161.791672 
	C523.583130,163.931824 526.473816,163.815506 529.881897,161.714554 
	C534.406006,158.925583 539.440613,158.845047 543.956604,161.609818 
	C547.561340,163.816727 550.551514,164.023895 554.027588,161.554138 
	C557.562805,159.042343 560.822083,159.952530 561.609314,164.029526 
	C562.313477,167.676743 564.186218,168.062256 567.159180,168.033127 
	C576.485657,167.941772 585.813721,168.028549 595.140930,167.991745 
	C598.710449,167.977661 601.074646,169.132355 602.129028,172.968124 
	C602.482056,174.252228 604.742981,175.781174 606.202332,175.857941 
	C612.348145,176.181229 618.522217,176.082443 624.682861,175.970596 
	C628.850220,175.894958 632.733765,175.778992 633.845093,181.231094 
	C634.073120,182.349457 635.975891,183.126419 637.236267,184.335663 
	C637.587036,185.382507 637.808167,186.154434 637.989197,187.137527 
	C639.004456,188.129532 640.059875,188.910385 641.142517,190.056305 
	C642.243896,192.651962 643.318176,194.882584 644.265442,196.849548 
	C646.320801,197.289932 648.496460,197.712006 650.649231,198.229691 
	C652.822510,198.752289 654.972900,199.369690 657.449707,200.065399 
	C660.293518,202.090500 662.820862,203.995773 665.001587,205.998474 
	C664.763611,208.472107 664.872253,210.848343 664.980957,213.224564 
	C665.369812,213.220337 665.758667,213.216110 666.147522,213.211884 
	C666.829712,212.026047 667.511902,210.840225 668.512451,209.100952 
	C669.858887,210.625076 670.869812,211.769379 671.880737,212.913681 
	C671.255798,212.971878 670.630859,213.030090 670.005920,213.088287 
	C669.650879,215.782761 668.220154,218.942673 669.146240,221.082779 
	C671.729736,227.053391 671.792419,232.990601 671.102051,239.186615 
	C670.991943,240.174820 671.852661,241.273224 672.271423,242.317932 
	C673.178650,244.581055 674.263367,246.790131 674.965027,249.115005 
	C676.520874,254.270325 676.369751,260.955139 679.663391,264.347412 
	C685.415161,270.271362 684.676819,279.199402 690.996399,284.458435 
	C693.557129,286.589447 695.312256,289.670776 697.543518,292.223877 
	C698.493103,293.310364 700.315186,293.954071 700.711304,295.142181 
	C701.739685,298.226685 703.239380,300.385376 706.598633,301.164856 
	C707.515259,301.377563 708.172546,302.682129 708.962402,303.472046 
	C710.690857,305.200745 712.412231,306.937103 714.168579,308.637115 
	C714.984375,309.426636 715.987183,310.044006 716.712219,310.902435 
	C719.520996,314.227875 722.252136,317.618835 725.410522,320.989227 
	C726.871765,320.995728 727.934631,320.998383 729.363159,321.074005 
	C736.544678,323.691864 743.300110,326.416687 750.193481,328.730927 
	C757.057434,331.035339 764.059753,332.927582 771.001404,335.000549 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M638.029358,186.926392 
	C637.808167,186.154434 637.587036,185.382507 637.545227,184.181503 
	C643.452820,180.524994 649.261230,177.429794 654.881714,174.024551 
	C658.759338,171.675293 662.377075,168.897110 666.408325,166.129578 
	C668.140564,165.308533 669.575500,164.668533 671.296265,163.980209 
	C672.053406,163.618744 672.524658,163.305588 673.326355,162.908508 
	C678.889282,159.672363 684.156128,156.575363 689.344788,153.352570 
	C694.636414,150.065796 699.851196,146.655396 705.470581,143.273651 
	C706.583435,143.207474 707.325317,143.166763 708.264648,143.265350 
	C708.978394,142.951080 709.494690,142.497528 710.236816,141.798126 
	C712.259705,140.681824 714.056763,139.811386 716.165344,138.891785 
	C716.983337,138.253052 717.489868,137.663452 718.215210,137.029617 
	C718.434021,136.985367 718.879272,136.951355 719.177368,136.905060 
	C719.980469,136.317978 720.485535,135.777176 721.323486,135.105774 
	C724.131592,133.659531 726.606873,132.343903 729.445496,131.002579 
	C731.857056,129.609329 733.905273,128.241791 736.090088,126.644417 
	C736.503540,125.958649 736.780457,125.502701 737.382324,125.035843 
	C738.141052,125.022118 738.574829,125.019302 739.219238,125.260803 
	C740.764160,126.391914 742.098328,127.278709 743.432617,128.165497 
	C743.312134,126.593483 743.193481,125.021324 743.068542,123.449677 
	C743.055542,123.285851 743.005371,123.124985 742.988770,122.976776 
	C743.005005,122.990814 742.964966,122.979904 743.259155,122.917099 
	C744.034302,122.280449 744.515259,121.706596 745.220093,121.076065 
	C745.443970,121.019394 745.904175,120.979881 746.111694,120.866898 
	C746.581055,120.573494 746.796265,120.348442 747.174927,120.025711 
	C747.384949,119.972649 747.815735,119.926659 748.122009,119.888565 
	C748.948853,119.307343 749.469421,118.764206 750.269897,118.139679 
	C750.932800,117.996559 751.315674,117.934830 752.051758,117.863007 
	C753.604431,116.898888 754.803894,115.944862 756.212280,114.787308 
	C756.948914,114.005547 757.476562,113.427315 758.345703,112.833527 
	C760.689209,111.828751 762.691101,110.839539 765.067993,109.943665 
	C767.292358,110.690002 769.141724,111.343002 770.991150,111.995987 
	C770.998779,127.016663 771.006409,142.037338 770.697021,157.620377 
	C769.915894,159.109924 769.451782,160.037125 768.920410,161.309052 
	C765.865967,166.078873 762.878723,170.503983 759.620239,174.747360 
	C757.978638,173.595825 756.608276,172.626007 755.237854,171.656189 
	C755.551392,173.366379 755.824463,175.085464 756.196716,176.782791 
	C756.367004,177.559158 756.732727,178.292648 756.824890,179.332626 
	C753.272888,185.075790 749.887817,190.520828 746.543213,195.990662 
	C742.012878,203.399673 737.513489,210.827667 732.595947,218.245087 
	C731.213013,218.075562 730.188477,218.042496 729.263855,217.721680 
	C709.290466,210.791382 689.360352,203.734833 669.350586,196.911606 
	C658.980957,193.375610 648.474182,190.241852 638.029358,186.926392 
M758.698303,117.505836 
	C758.386902,117.247162 758.075562,116.988487 757.764160,116.729805 
	C757.648743,117.141304 757.533325,117.552803 757.417969,117.964310 
	C757.806396,117.964508 758.194824,117.964706 758.698303,117.505836 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M764.693054,109.850311 
	C762.691101,110.839539 760.689209,111.828751 758.351929,112.460388 
	C758.954895,110.750824 759.679871,109.151787 760.886108,108.110130 
	C762.353149,106.843224 764.537292,106.339653 765.835327,104.962883 
	C767.138977,103.580269 767.734436,101.530075 768.640198,99.772415 
	C766.846008,98.850540 765.107544,97.297066 763.246460,97.132698 
	C758.783508,96.738548 754.235046,97.281982 749.780151,96.844749 
	C748.117188,96.681526 745.570190,95.092575 745.245789,93.722755 
	C744.352173,89.950027 742.218445,88.945419 738.795593,88.980637 
	C731.800598,89.052605 724.794556,89.191971 717.815552,88.848267 
	C716.181580,88.767799 713.541138,87.107018 713.255188,85.769012 
	C712.368774,81.621872 709.855164,80.935936 706.338562,80.977097 
	C699.510315,81.057022 692.673523,81.159821 685.856262,80.873367 
	C684.249573,80.805855 681.484680,79.393555 681.341492,78.321014 
	C680.655090,73.179604 677.347656,72.941833 673.381958,72.970146 
	C661.722900,73.053368 650.059814,73.129547 638.406433,72.858940 
	C636.604370,72.817093 633.611389,71.220100 633.297424,69.853340 
	C632.359985,65.773178 629.999756,64.944702 626.433655,64.974747 
	C617.106628,65.053322 607.773315,65.158798 598.455139,64.859688 
	C596.632141,64.801163 593.901245,63.386646 593.248230,61.878944 
	C590.973450,56.626701 590.158020,56.237949 584.808472,58.632095 
	C582.883789,59.493469 580.382080,59.584560 578.233704,59.313408 
	C574.381409,58.827187 570.621094,57.285656 566.779663,57.107922 
	C558.967712,56.746468 551.126404,56.927109 543.299133,57.032490 
	C539.836182,57.079121 537.079712,56.858822 536.622314,52.345776 
	C536.501038,51.148849 534.266479,49.561974 532.805237,49.313652 
	C531.801697,49.143124 529.869080,50.732891 529.366821,51.953518 
	C527.039917,57.608543 526.111084,57.949120 520.332031,55.789486 
	C518.415588,55.073288 515.794434,54.973053 513.906006,55.683083 
	C507.603302,58.052864 506.668884,57.767849 504.513000,51.213818 
	C504.046722,49.796242 503.507080,48.402782 503.383850,46.833576 
	C504.899994,46.449413 506.029144,46.065830 507.167755,46.035305 
	C521.206055,45.658901 535.246094,45.050495 549.284912,45.069324 
	C565.970276,45.091705 582.653931,45.751724 599.340515,45.940968 
	C603.793640,45.991470 608.254944,45.044765 612.712402,45.045975 
	C635.647278,45.052200 658.582214,45.239594 681.517090,45.240551 
	C682.337402,45.240585 683.157776,43.949356 683.978088,43.259930 
	C684.746094,43.217930 685.514160,43.175934 686.827759,43.528053 
	C688.422974,44.277573 689.462280,44.906010 690.523804,44.946194 
	C701.269226,45.352955 712.073120,45.119972 722.746460,46.197819 
	C729.535339,46.883392 735.792175,48.290268 742.913940,46.369633 
	C749.406250,44.618736 756.745728,46.100140 763.707947,45.962559 
	C767.292725,45.891720 768.737671,47.472111 769.064758,50.929279 
	C769.228699,52.662018 770.305969,54.308350 770.970215,55.993759 
	C770.989197,72.331940 771.008179,88.670120 770.656250,105.556931 
	C768.421204,107.353806 766.557129,108.602058 764.693054,109.850311 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M771.036316,525.971558 
	C771.069824,563.398804 771.103394,600.826111 770.770264,638.738098 
	C769.947021,639.328064 769.490540,639.433289 768.618774,639.443970 
	C766.787720,639.259216 765.371948,639.168945 763.691040,638.855591 
	C762.293823,638.551147 761.161743,638.469849 759.591919,638.309937 
	C751.602356,637.263550 744.048645,636.310303 736.499084,635.325012 
	C715.333069,632.562561 694.168701,629.787048 672.670532,626.926086 
	C671.218323,626.579590 670.099304,626.323608 668.991821,625.698120 
	C669.705200,624.083740 670.177551,622.612305 671.146423,621.631165 
	C677.024170,615.678894 683.015076,609.838440 689.304504,603.901428 
	C697.133911,596.632812 704.766602,589.559387 712.015869,582.112610 
	C713.695618,580.387085 714.066345,577.387329 715.280457,574.969604 
	C715.521484,574.965210 716.003662,574.966919 716.169312,575.240295 
	C717.578186,575.490723 719.239563,575.979919 719.996582,575.361633 
	C724.914368,571.345459 729.790833,567.250122 734.367493,562.854492 
	C737.505127,559.841064 740.136230,556.300171 742.996887,552.997559 
	C743.000000,553.000000 742.995117,552.994080 743.319336,552.909302 
	C749.126953,549.172852 753.421509,544.611023 755.368408,537.953857 
	C757.354370,536.911560 759.102112,536.042236 760.583069,534.843323 
	C764.134155,531.968445 767.559937,528.938721 771.036316,525.971558 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M634.002441,643.997559 
	C636.067322,643.665161 638.132263,643.332825 640.983765,642.993042 
	C644.227966,642.309326 646.685608,641.633057 649.485779,640.944092 
	C650.502075,640.829590 651.175781,640.727661 651.826782,640.993042 
	C650.375549,642.909790 648.947083,644.459290 647.336243,646.206665 
	C653.310181,647.210327 656.554993,644.588928 656.632080,638.108582 
	C656.695251,632.798645 661.029663,632.481934 663.722961,632.477295 
	C666.655090,632.472290 671.971802,630.880493 672.204590,637.288269 
	C672.250793,638.561035 674.340698,640.105225 675.821350,640.915283 
	C680.253967,643.340027 684.855103,645.456177 689.376343,647.720825 
	C689.643372,647.854492 689.837646,648.208923 689.981384,648.501953 
	C693.360962,655.395081 696.865662,662.231873 700.029236,669.223083 
	C701.237610,671.893311 702.818970,673.997009 705.538025,672.369324 
	C709.803650,669.815796 711.817261,672.339111 714.382507,674.921204 
	C715.759888,676.307739 717.742065,677.719604 719.577515,677.911255 
	C725.831299,678.564148 732.138245,678.787659 738.430786,678.961975 
	C742.403259,679.072021 745.016296,680.568176 747.382324,684.001038 
	C750.049805,687.871338 753.574280,693.343933 757.289062,693.831604 
	C764.471802,694.774658 768.739563,697.430847 770.980652,703.991577 
	C770.987427,714.680725 770.994202,725.369873 770.992371,736.527100 
	C761.708862,735.942200 752.959045,738.124756 744.344177,741.246216 
	C743.118958,741.690125 741.757935,741.934753 740.455261,741.953064 
	C737.684326,741.992126 733.848022,742.596313 733.648132,738.924805 
	C733.547180,737.071899 736.795898,735.096619 738.393372,733.068970 
	C739.389771,731.804382 740.695557,730.385986 740.815796,728.948975 
	C740.919861,727.705444 739.792969,725.451721 738.788269,725.174622 
	C733.570862,723.735840 732.701111,720.008545 733.017578,715.498535 
	C733.268005,711.930237 733.021057,708.872681 728.520020,708.109680 
	C727.578796,707.950134 726.281982,706.756226 726.085815,705.838684 
	C724.728821,699.490540 720.294250,700.860535 715.589905,701.397766 
	C716.302307,708.191833 718.717773,714.883423 715.326233,721.570068 
	C708.497681,720.353516 710.306335,713.997437 708.052429,710.254028 
	C707.902832,710.005432 707.941772,709.581360 708.016907,709.268677 
	C709.714355,702.201965 706.671204,696.925903 701.823181,692.187500 
	C700.859192,691.245300 700.266602,689.621460 700.081055,688.234558 
	C699.086243,680.799683 695.521484,678.161987 688.472656,676.768738 
	C682.056824,675.500488 676.058228,672.221436 669.775085,670.131287 
	C667.350952,669.324768 664.649231,669.060425 662.074585,669.063843 
	C657.676575,669.069702 654.289673,668.165039 652.826904,663.289246 
	C652.500854,662.202209 650.080322,661.148865 648.593811,661.104858 
	C641.103821,660.883057 633.588440,661.276672 626.114990,660.860901 
	C623.191223,660.698303 620.329956,659.047363 617.487915,657.950806 
	C616.283325,657.486023 615.198181,656.709106 614.064514,656.064026 
	C612.458435,655.150269 610.857666,654.227295 609.254700,653.308228 
	C610.427979,652.091431 611.601318,650.874634 612.889038,649.331299 
	C614.069153,649.003723 615.134827,649.002625 617.041504,649.000793 
	C623.462097,648.964539 629.041016,648.927002 633.254272,644.001404 
	C633.503662,644.000366 634.002441,643.997559 634.002441,643.997559 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M771.058838,525.505920 
	C767.559937,528.938721 764.134155,531.968445 760.583069,534.843323 
	C759.102112,536.042236 757.354370,536.911560 754.989136,537.878662 
	C750.888062,536.902405 747.553589,535.856750 744.157959,535.077942 
	C735.117981,533.004578 726.051575,531.046143 716.642273,529.006409 
	C710.163635,527.154663 714.738708,523.660400 715.678101,521.741028 
	C719.424500,514.086487 723.793701,506.736816 728.264038,498.676239 
	C725.760925,498.046906 723.887390,497.575836 721.816406,496.946472 
	C721.410095,496.526855 721.201233,496.265564 721.002197,495.561462 
	C721.207947,492.973267 721.405029,490.828003 721.599609,488.682556 
	C723.052063,472.665588 724.504089,456.648529 726.393616,440.638000 
	C732.929626,441.678375 739.028320,442.712280 745.004333,444.125275 
	C741.864685,449.907928 742.592102,454.733002 746.946838,460.426208 
	C748.825439,462.882324 750.143860,465.762543 751.979553,468.256714 
	C754.847656,472.153839 757.228821,477.407379 761.146973,479.237793 
	C764.683472,480.889893 768.463318,481.639923 770.972656,484.989807 
	C771.008911,498.339966 771.045105,511.690125 771.058838,525.505920 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M637.989197,187.137543 
	C648.474182,190.241852 658.980957,193.375610 669.350586,196.911606 
	C689.360352,203.734833 709.290466,210.791382 729.263855,217.721680 
	C730.188477,218.042496 731.213013,218.075562 732.636414,218.569122 
	C741.927124,227.752060 750.704773,236.677704 759.651550,245.430420 
	C763.161133,248.863815 767.049561,251.909882 770.765503,255.132339 
	C770.884338,260.785767 771.003235,266.439178 770.686401,272.484222 
	C764.185486,268.720184 758.157837,264.507721 752.016907,260.467224 
	C751.042725,259.826263 749.454773,259.583466 748.352356,259.887695 
	C747.761230,260.050842 746.983704,262.183289 747.344116,262.721924 
	C749.748901,266.315857 752.148499,269.999695 755.136169,273.086792 
	C757.136719,275.153900 760.008850,276.478394 762.664246,277.782318 
	C765.312500,279.082764 768.176819,279.943207 770.946411,280.996582 
	C770.985413,284.355255 771.024353,287.713898 770.598755,291.497253 
	C763.199646,291.379120 757.319397,288.957977 753.432556,282.785675 
	C752.951172,282.021332 751.945312,281.542603 751.111816,281.051544 
	C742.057129,275.716919 735.967407,267.588226 734.440735,257.626465 
	C733.319946,250.312729 729.394348,247.064194 723.691467,243.862793 
	C720.927368,242.148575 718.458191,240.592133 715.692627,238.881866 
	C713.254150,237.207962 711.111938,235.687851 708.683472,233.969818 
	C705.374084,232.104691 702.350891,230.437500 699.114441,228.502991 
	C696.318176,226.438461 693.735168,224.641251 690.923462,222.595154 
	C682.245850,216.864548 673.797058,211.382812 665.348267,205.901062 
	C662.820862,203.995773 660.293518,202.090500 657.245361,199.793304 
	C651.521545,196.164658 646.318420,192.927948 641.115356,189.691223 
	C640.059875,188.910385 639.004456,188.129532 637.989197,187.137543 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M763.956177,639.078674 
	C765.371948,639.168945 766.787720,639.259216 768.724243,639.671936 
	C769.835327,640.330933 770.425598,640.667419 771.015869,641.003906 
	C771.013000,653.640320 771.029541,666.276733 770.985535,678.913025 
	C770.979309,680.718811 770.680176,682.523621 769.893372,684.647339 
	C763.673401,682.261475 757.911865,679.846802 752.522278,676.778503 
	C745.989624,673.059448 739.856018,668.646729 733.399475,664.785278 
	C719.989197,656.765076 706.479797,648.910461 692.919678,640.668396 
	C691.549316,639.570435 690.270386,638.791870 688.660339,638.010071 
	C687.887756,638.003418 687.446228,638.000000 686.911499,637.668762 
	C684.879822,636.225708 682.941284,635.110413 680.757935,633.780273 
	C679.340881,633.044434 678.168762,632.523438 676.926636,631.659790 
	C675.568787,630.548462 674.280945,629.779785 672.996582,629.005615 
	C673.000000,629.000000 672.988586,629.007324 672.991943,628.675354 
	C672.998108,627.901123 673.000916,627.458862 673.003723,627.016602 
	C694.168701,629.787048 715.333069,632.562561 736.499084,635.325012 
	C744.048645,636.310303 751.602356,637.263550 759.710999,638.572144 
	C761.497253,638.968201 762.726746,639.023438 763.956177,639.078674 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M770.874817,254.663971 
	C767.049561,251.909882 763.161133,248.863815 759.651550,245.430420 
	C750.704773,236.677704 741.927124,227.752060 733.041382,218.571548 
	C737.513489,210.827667 742.012878,203.399673 746.543213,195.990662 
	C749.887817,190.520828 753.272888,185.075790 757.110046,179.170624 
	C758.350525,177.457397 759.120972,176.193237 759.891418,174.929077 
	C762.878723,170.503983 765.865967,166.078873 769.292114,161.283737 
	C770.228271,160.914886 770.725464,160.916061 771.222778,160.917236 
	C771.196106,190.016891 771.172424,219.116547 771.136169,248.216171 
	C771.133667,250.209381 771.036987,252.202469 770.874817,254.663971 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M687.004761,637.996582 
	C687.446228,638.000000 687.887756,638.003418 688.693420,638.375671 
	C690.375488,639.492188 691.693298,640.239990 693.011169,640.987793 
	C706.479797,648.910461 719.989197,656.765076 733.399475,664.785278 
	C739.856018,668.646729 745.989624,673.059448 752.522278,676.778503 
	C757.911865,679.846802 763.673401,682.261475 769.653442,684.985352 
	C770.358459,691.022644 770.680115,697.040344 770.991211,703.524841 
	C768.739563,697.430847 764.471802,694.774658 757.289062,693.831604 
	C753.574280,693.343933 750.049805,687.871338 747.382324,684.001038 
	C745.016296,680.568176 742.403259,679.072021 738.430786,678.961975 
	C732.138245,678.787659 725.831299,678.564148 719.577515,677.911255 
	C717.742065,677.719604 715.759888,676.307739 714.382507,674.921204 
	C711.817261,672.339111 709.803650,669.815796 705.538025,672.369324 
	C702.818970,673.997009 701.237610,671.893311 700.029236,669.223083 
	C696.865662,662.231873 693.360962,655.395081 689.981384,648.501953 
	C689.837646,648.208923 689.643372,647.854492 689.376343,647.720825 
	C684.855103,645.456177 680.253967,643.340027 675.821350,640.915283 
	C674.340698,640.105225 672.250793,638.561035 672.204590,637.288269 
	C671.971802,630.880493 666.655090,632.472290 663.722961,632.477295 
	C661.029663,632.481934 656.695251,632.798645 656.632080,638.108582 
	C656.554993,644.588928 653.310181,647.210327 647.336243,646.206665 
	C648.947083,644.459290 650.375549,642.909790 651.906860,640.667725 
	C655.690430,632.991943 659.371216,626.008606 663.345703,618.938599 
	C664.064392,618.211060 664.489258,617.570129 664.938110,616.922913 
	C664.962036,616.916626 664.918457,616.892700 665.231445,617.010620 
	C665.629089,618.766113 666.155090,620.539795 665.701172,622.011230 
	C664.900818,624.605408 665.574463,626.095703 668.120972,627.337769 
	C669.825378,628.121033 671.406982,628.564148 672.988586,629.007324 
	C672.988586,629.007324 673.000000,629.000000 673.029297,629.371033 
	C674.371277,630.495544 675.683899,631.249023 676.996582,632.002441 
	C678.168762,632.523438 679.340881,633.044434 680.926636,634.070496 
	C683.228394,635.715881 685.116577,636.856201 687.004761,637.996582 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M768.012634,348.031006 
	C768.420532,348.032959 768.828430,348.034912 769.582031,348.373596 
	C770.261780,349.480438 770.595764,350.250519 770.929749,351.020599 
	C770.957458,379.402100 770.985107,407.783600 770.546143,436.601227 
	C764.954651,435.222473 759.829895,433.407593 754.754517,431.188843 
	C756.211182,421.420502 757.563599,412.047333 759.037476,402.693329 
	C761.906799,384.482849 764.844238,366.283112 767.818970,348.070557 
	C767.884399,348.062164 768.012634,348.031006 768.012634,348.031006 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M162.991119,94.002228 
	C159.965927,90.129028 159.434219,86.455116 160.140182,81.084740 
	C161.196045,73.052635 159.363785,64.522697 157.963028,56.340965 
	C157.358810,52.811768 154.762054,49.623707 152.995499,46.132515 
	C154.813507,45.262695 156.231934,44.584057 157.784103,43.605228 
	C168.402084,43.227303 178.886322,43.082706 189.370560,43.083786 
	C227.998505,43.087772 266.626434,43.140003 305.619019,43.586304 
	C305.987030,44.997040 305.990479,45.995560 305.662262,46.994705 
	C304.888306,46.995800 304.445984,46.996273 303.854675,46.633659 
	C302.033508,45.845844 300.352539,45.025753 298.690674,45.062775 
	C290.125244,45.253605 281.564697,45.664242 272.865356,45.803253 
	C272.521149,45.362534 272.277588,45.160683 271.529053,45.000809 
	C248.042282,44.998299 225.024002,44.997772 201.753418,44.997242 
	C201.332886,44.997784 201.164673,44.998325 200.528030,44.998024 
	C188.259933,45.006752 176.460266,45.016323 163.900909,45.026512 
	C166.641495,51.511253 168.784851,56.411961 170.741821,61.385994 
	C171.058350,62.190514 170.533203,63.319309 170.420013,64.300858 
	C169.606812,71.353958 169.187897,78.484726 167.811798,85.425896 
	C167.205704,88.483078 164.657547,91.155251 162.991119,94.002228 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M683.514526,43.197792 
	C683.157776,43.949356 682.337402,45.240585 681.517090,45.240551 
	C658.582214,45.239594 635.647278,45.052200 612.712402,45.045975 
	C608.254944,45.044765 603.793640,45.991470 599.340515,45.940968 
	C582.653931,45.751724 565.970276,45.091705 549.284912,45.069324 
	C535.246094,45.050495 521.206055,45.658901 507.167755,46.035305 
	C506.029144,46.065830 504.899994,46.449413 503.188263,46.696953 
	C502.363098,46.519058 502.157928,46.277641 501.783844,45.798782 
	C500.715942,45.730724 499.858704,45.864590 498.751953,46.001579 
	C498.502441,46.004700 498.003418,46.009899 497.558167,46.006657 
	C494.075500,46.334251 491.038086,46.665089 487.716064,46.947769 
	C486.952637,46.599354 486.473877,46.299091 485.860535,45.803963 
	C485.521271,45.363773 485.280121,45.162106 484.534363,45.002220 
	C478.376556,45.001545 472.686829,45.002739 466.802307,45.138916 
	C466.362427,45.479317 466.160767,45.720936 465.711243,46.225327 
	C463.946899,46.631153 462.473694,46.810413 460.708954,46.764580 
	C458.946564,46.358536 457.475708,46.177589 455.604645,45.995972 
	C454.137085,45.994720 453.069794,45.994137 451.895020,45.881580 
	C451.473969,45.622814 451.212463,45.699547 450.672241,46.235611 
	C446.559387,46.314457 442.777252,46.157494 438.889587,45.886993 
	C438.472870,45.622871 438.210693,45.696220 437.553528,45.993725 
	C434.739899,45.993206 432.370239,45.992470 429.787811,45.792168 
	C428.716522,45.726299 427.858002,45.859997 426.666138,45.994392 
	C425.888306,45.996117 425.443848,45.997147 424.711426,45.773521 
	C422.282745,45.698982 420.141968,45.849094 417.751740,45.997887 
	C417.502258,45.996571 417.003296,45.993458 416.665100,45.754013 
	C407.630432,45.341560 398.930542,45.256176 390.240234,44.921989 
	C388.491730,44.854752 386.779541,43.842991 385.050629,43.266716 
	C409.840210,43.193428 434.629791,43.065811 459.419403,43.057156 
	C530.635254,43.032291 601.851135,43.054539 673.067017,43.063087 
	C676.395020,43.063488 679.723022,43.110405 683.514526,43.197792 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M723.986328,244.020584 
	C729.394348,247.064194 733.319946,250.312729 734.440735,257.626465 
	C735.967407,267.588226 742.057129,275.716919 751.111816,281.051544 
	C751.945312,281.542603 752.951172,282.021332 753.432556,282.785675 
	C757.319397,288.957977 763.199646,291.379120 770.530579,291.949982 
	C770.988647,298.674713 771.050293,305.371429 770.688843,312.520630 
	C768.845337,312.648132 767.424866,312.323151 766.007202,311.665558 
	C766.009766,310.889435 766.009583,310.445953 766.230347,309.718445 
	C768.952209,300.225983 768.696960,299.696014 759.226807,297.866730 
	C754.764221,297.004700 751.331116,295.238281 748.857300,291.091278 
	C747.318298,288.511353 744.433105,286.736877 742.161560,284.590942 
	C739.656555,282.224426 736.770752,280.139984 734.733765,277.420990 
	C727.351562,267.566895 724.072144,256.280151 723.986328,244.020584 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M754.705139,431.592712 
	C759.829895,433.407593 764.954651,435.222473 770.457458,437.069122 
	C770.915283,440.421417 770.995056,443.741943 770.627563,447.468811 
	C764.781921,446.930115 759.383423,445.985046 753.581787,444.980408 
	C750.494751,444.529297 747.810852,444.137756 745.127014,443.746216 
	C739.028320,442.712280 732.929626,441.678375 726.098938,440.462280 
	C723.955383,440.136383 722.543884,439.992645 720.755615,439.723450 
	C715.926941,438.412994 711.474976,437.227966 707.444092,436.015198 
	C723.478455,434.522552 739.091797,433.057648 754.705139,431.592712 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M745.004333,444.125275 
	C747.810852,444.137756 750.494751,444.529297 753.602173,445.344543 
	C757.791565,454.089661 761.052856,462.834106 770.980469,465.988983 
	C770.997681,472.011627 771.014832,478.034302 771.002319,484.523376 
	C768.463318,481.639923 764.683472,480.889893 761.146973,479.237793 
	C757.228821,477.407379 754.847656,472.153839 751.979553,468.256714 
	C750.143860,465.762543 748.825439,462.882324 746.946838,460.426208 
	C742.592102,454.733002 741.864685,449.907928 745.004333,444.125275 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M766.004395,311.998169 
	C767.424866,312.323151 768.845337,312.648132 770.631836,312.986298 
	C771.033569,320.022186 771.069214,327.044891 771.053101,334.534058 
	C764.059753,332.927582 757.057434,331.035339 750.193481,328.730927 
	C743.300110,326.416687 736.544678,323.691864 729.694885,320.830383 
	C735.101318,320.659485 740.541565,320.805237 746.425293,320.963623 
	C751.907654,320.978699 756.946472,320.981140 762.334351,320.853333 
	C763.790344,317.814758 764.897400,314.906464 766.004395,311.998169 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M771.019531,55.552704 
	C770.305969,54.308350 769.228699,52.662018 769.064758,50.929279 
	C768.737671,47.472111 767.292725,45.891720 763.707947,45.962559 
	C756.745728,46.100140 749.406250,44.618736 742.913940,46.369633 
	C735.792175,48.290268 729.535339,46.883392 722.746460,46.197819 
	C712.073120,45.119972 701.269226,45.352955 690.523804,44.946194 
	C689.462280,44.906010 688.422974,44.277573 687.208862,43.589714 
	C713.759705,43.190922 740.475220,43.095272 767.190369,43.148697 
	C768.466125,43.151245 769.739319,44.471912 770.783936,45.726654 
	C770.695496,46.853092 770.836792,47.431618 770.978027,48.010147 
	C771.008301,50.377316 771.038574,52.744484 771.019531,55.552704 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M771.040405,280.560669 
	C768.176819,279.943207 765.312500,279.082764 762.664246,277.782318 
	C760.008850,276.478394 757.136719,275.153900 755.136169,273.086792 
	C752.148499,269.999695 749.748901,266.315857 747.344116,262.721924 
	C746.983704,262.183289 747.761230,260.050842 748.352356,259.887695 
	C749.454773,259.583466 751.042725,259.826263 752.016907,260.467224 
	C758.157837,264.507721 764.185486,268.720184 770.589355,272.944397 
	C770.996887,275.383545 771.065674,277.754150 771.040405,280.560669 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M771.010742,465.522858 
	C761.052856,462.834106 757.791565,454.089661 754.005371,445.404114 
	C759.383423,445.985046 764.781921,446.930115 770.569214,447.920593 
	C770.985779,453.662903 771.013367,459.359833 771.010742,465.522858 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M384.666931,43.193653 
	C386.779541,43.842991 388.491730,44.854752 390.240234,44.921989 
	C398.930542,45.256176 407.630432,45.341560 416.327545,45.995136 
	C414.361389,46.651024 412.394958,46.973740 410.427765,46.978497 
	C393.817352,47.018654 377.206177,46.925972 360.596924,47.071499 
	C357.722595,47.096684 354.858582,48.453709 351.985840,48.469360 
	C349.326233,48.483852 346.661346,47.525337 344.320557,46.762360 
	C346.090088,46.345299 347.579285,46.316849 348.977112,45.945038 
	C351.995361,45.142193 354.966736,44.163139 357.957306,43.256180 
	C358.731689,43.213943 359.506042,43.171707 360.831482,43.530380 
	C367.964874,44.107826 374.546967,44.298836 381.130371,44.409046 
	C381.399261,44.413548 381.680817,43.657543 381.956482,43.255859 
	C382.732056,43.210773 383.507660,43.165680 384.666931,43.193653 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M357.569916,43.197426 
	C354.966736,44.163139 351.995361,45.142193 348.977112,45.945038 
	C347.579285,46.316849 346.090088,46.345299 344.036163,46.714821 
	C342.951508,46.600388 342.473022,46.300552 341.783844,45.798790 
	C340.715942,45.730732 339.858704,45.864601 338.751953,46.001583 
	C338.502441,46.004700 338.003418,46.009899 337.558167,46.006660 
	C334.075500,46.334259 331.038086,46.665096 327.716064,46.947765 
	C326.952667,46.599335 326.473877,46.299072 325.860535,45.803951 
	C325.521271,45.363773 325.280121,45.162106 324.566895,45.002289 
	C320.755951,45.330132 317.380585,45.659779 314.006348,45.650326 
	C314.014282,44.634995 314.021057,43.958763 314.027802,43.282528 
	C328.412720,43.234577 342.797607,43.186626 357.569916,43.197426 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M381.495178,43.195396 
	C381.680817,43.657543 381.399261,44.413548 381.130371,44.409046 
	C374.546967,44.298836 367.964874,44.107826 361.214813,43.598671 
	C367.709351,43.222351 374.371613,43.178642 381.495178,43.195396 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M313.585938,43.202374 
	C314.021057,43.958763 314.014282,44.634995 313.687531,45.886524 
	C310.909637,46.639236 308.451782,46.816658 305.993896,46.994080 
	C305.990479,45.995560 305.987030,44.997040 305.978790,43.626923 
	C308.364014,43.210957 310.754059,43.166588 313.585938,43.202374 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M765.067993,109.943649 
	C766.557129,108.602058 768.421204,107.353806 770.623413,106.020721 
	C770.939575,107.729065 770.917664,109.522255 770.943481,111.655716 
	C769.141724,111.343002 767.292358,110.690002 765.067993,109.943649 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M771.166992,350.743744 
	C770.595764,350.250519 770.261780,349.480438 769.887817,348.384338 
	C770.063354,347.191559 770.278931,346.324768 770.737061,345.226318 
	C771.121094,346.818787 771.262634,348.642822 771.166992,350.743744 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M771.373779,160.708344 
	C770.725464,160.916061 770.228271,160.914886 769.359375,160.939026 
	C769.451782,160.037125 769.915894,159.109924 770.699829,158.088684 
	C771.188049,158.829559 771.356445,159.664505 771.373779,160.708344 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M771.032715,640.705444 
	C770.425598,640.667419 769.835327,640.330933 769.139526,639.766479 
	C769.490540,639.433289 769.947021,639.328064 770.758057,639.220215 
	C771.091553,639.614014 771.070557,640.010498 771.032715,640.705444 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M771.160645,47.845467 
	C770.836792,47.431618 770.695496,46.853092 770.781067,46.136124 
	C771.119690,46.558716 771.231506,47.119755 771.160645,47.845467 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M92.028702,114.961197 
	C93.767120,117.516937 95.225670,120.276817 97.072166,123.770760 
	C100.267593,122.565422 103.702827,121.269630 107.775421,120.143677 
	C114.561180,120.100365 120.649437,117.883324 126.885872,119.863792 
	C127.667839,120.112114 128.888641,119.597221 129.681000,119.086624 
	C133.040817,116.921555 136.370285,116.979637 140.035187,118.393768 
	C141.800369,119.074890 143.927872,118.817085 145.886414,119.365509 
	C145.582977,121.497208 145.284409,123.247849 144.552673,124.999367 
	C139.704010,124.626915 134.912506,127.299210 130.910385,123.357933 
	C130.674988,123.126099 129.958817,123.391548 129.465820,123.410851 
	C121.423004,123.725655 113.375221,123.953087 105.340775,124.413376 
	C103.434738,124.522575 101.572472,125.407639 99.691589,125.941200 
	C97.154633,126.660896 94.619141,127.385788 91.081062,128.393951 
	C91.171356,129.238251 90.673264,131.954575 91.777733,133.585922 
	C95.791977,139.515137 96.985168,146.175629 98.181122,152.969879 
	C98.544250,155.032822 99.582527,158.022629 101.112221,158.656876 
	C108.453392,161.700714 118.346336,156.088455 121.317505,148.852478 
	C122.570129,145.801819 126.222466,141.990326 129.061905,141.745285 
	C133.358490,141.374481 137.898041,143.819321 142.317566,145.512527 
	C141.166946,154.983658 140.033112,164.010040 138.565796,173.149475 
	C134.092896,177.333237 131.652863,177.126221 128.119827,172.503540 
	C127.538567,171.742996 126.564697,170.581161 125.927788,170.673813 
	C120.064232,171.526825 113.444893,168.971878 108.510529,174.065811 
	C106.142418,176.510498 104.162842,179.331558 101.780289,181.779175 
	C100.707893,180.711258 99.862511,179.846329 99.017128,178.981415 
	C95.950912,174.268921 95.287819,174.296738 92.751228,179.244370 
	C90.873695,182.906494 86.561218,184.796814 82.675537,183.660873 
	C78.628777,182.477829 76.152733,178.812241 76.595032,174.677246 
	C76.682617,173.858398 77.103340,172.758820 76.734131,172.254929 
	C71.208183,164.713226 77.895187,158.159760 78.253822,151.134705 
	C78.614021,144.079117 78.975861,136.811127 77.675690,129.951080 
	C76.652130,124.550560 77.391136,119.816765 79.888123,115.684181 
	C80.847115,114.097023 85.236458,114.437538 88.087547,114.207993 
	C89.274590,114.112419 90.526451,114.821915 92.028702,114.961197 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M98.672134,179.114777 
	C99.862511,179.846329 100.707893,180.711258 101.896378,182.036148 
	C102.485809,183.002518 102.732124,183.508896 102.978439,184.015289 
	C101.774689,184.489395 100.570938,184.963501 99.182571,185.719299 
	C95.988190,184.026825 96.405739,181.712173 98.672134,179.114777 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M145.891296,118.984444 
	C143.927872,118.817085 141.800369,119.074890 140.035187,118.393768 
	C136.370285,116.979637 133.040817,116.921555 129.681000,119.086624 
	C128.888641,119.597221 127.667839,120.112114 126.885872,119.863792 
	C120.649437,117.883324 114.561180,120.100365 108.135307,120.067963 
	C113.402100,116.999489 118.946358,114.176567 124.806152,111.198990 
	C126.243340,111.548050 127.500984,112.667526 128.464447,112.454773 
	C134.372238,111.150200 140.213455,109.544075 146.536621,108.429932 
	C146.628036,112.209152 146.259659,115.596794 145.891296,118.984444 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M140.020981,54.408939 
	C144.685196,54.316986 147.501389,57.435417 148.098557,61.365623 
	C149.211258,68.688538 150.711609,76.410980 149.456787,83.508568 
	C148.155502,90.868927 142.051147,96.350052 135.968414,100.917114 
	C133.767685,102.569466 131.123627,103.631371 128.310364,104.974434 
	C128.635300,101.388092 129.331741,97.790016 130.453522,94.350471 
	C131.331116,95.089256 131.783386,95.669518 132.235641,96.249786 
	C135.453354,92.446617 139.319351,88.998764 141.665588,84.717957 
	C143.286118,81.761238 142.609940,77.614075 143.318390,74.070518 
	C144.072876,70.296776 141.650696,64.297516 138.110550,62.123211 
	C138.741623,59.683739 139.372696,57.244263 140.020981,54.408939 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M138.069290,62.512341 
	C141.650696,64.297516 144.072876,70.296776 143.318390,74.070518 
	C142.609940,77.614075 143.286118,81.761238 141.665588,84.717957 
	C139.319351,88.998764 135.453354,92.446617 132.235641,96.249786 
	C131.783386,95.669518 131.331116,95.089256 130.773148,94.191589 
	C132.774567,84.990372 134.881073,76.106407 136.989395,67.222870 
	C137.331497,65.781433 137.681610,64.341888 138.069290,62.512341 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M145.886414,119.365509 
	C146.259659,115.596794 146.628036,112.209152 146.945068,108.396309 
	C147.235458,107.189590 147.577179,106.408066 148.316452,105.317184 
	C149.860458,105.861710 151.234192,106.527634 152.119171,107.597748 
	C164.726883,122.843170 177.308075,138.110977 189.814636,153.439499 
	C201.686951,167.990662 213.464432,182.619202 225.058319,197.489334 
	C212.252762,203.799103 199.672485,209.833252 187.127563,215.482040 
	C187.928345,213.892746 188.693771,212.688812 190.139679,210.414581 
	C188.284485,208.971954 185.238449,207.776321 184.432465,205.707855 
	C183.150986,202.419083 181.188690,201.607773 178.361237,202.056992 
	C173.220306,202.873779 168.129028,204.003036 163.049042,204.613846 
	C163.948700,200.564377 164.816330,196.902985 165.683975,193.241577 
	C167.724609,189.488052 165.044586,189.562347 162.852936,190.157730 
	C158.074661,191.455795 153.412506,193.176590 148.685120,194.668259 
	C145.065140,195.810486 141.417877,196.866257 137.386322,197.531433 
	C137.626343,189.080399 138.262802,181.058395 138.899261,173.036407 
	C140.033112,164.010040 141.166946,154.983658 142.517731,145.206116 
	C143.485046,137.969452 144.235443,131.483963 144.985840,124.998489 
	C145.284409,123.247849 145.582977,121.497208 145.886414,119.365509 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M225.283630,197.213715 
	C213.464432,182.619202 201.686951,167.990662 189.814636,153.439499 
	C177.308075,138.110977 164.726883,122.843170 152.119171,107.597748 
	C151.234192,106.527634 149.860458,105.861710 148.353973,104.997772 
	C149.668365,103.437233 151.342804,101.886757 153.399963,99.981903 
	C154.721024,103.711243 155.779449,106.699181 157.173752,110.635323 
	C159.908554,105.432076 161.930618,101.584869 164.304382,97.370247 
	C165.103241,97.001984 165.550400,97.001144 166.066803,97.348122 
	C168.087921,100.467415 170.039810,103.238892 172.161530,106.302116 
	C175.883560,111.398308 179.435760,116.202744 183.067932,121.304153 
	C183.763138,122.071098 184.378357,122.541077 184.994720,123.258118 
	C184.995880,123.505188 185.001984,123.999298 185.078003,124.297607 
	C185.769226,125.064453 186.384415,125.532997 187.246124,126.004623 
	C187.492615,126.007713 187.985641,126.009712 188.043869,126.343307 
	C189.065277,128.120377 190.028458,129.563858 191.063171,131.358521 
	C192.332031,135.522354 194.317245,136.859756 198.786575,136.215286 
	C207.741913,134.923996 216.868652,133.982773 225.884720,134.248993 
	C231.800644,134.423660 238.005066,136.197189 243.403168,138.709503 
	C247.270157,140.509216 250.052231,144.681870 253.248169,147.866226 
	C255.234177,149.845078 257.140411,151.910568 258.987671,154.020157 
	C260.608734,155.871399 262.869598,157.612045 263.508942,159.793884 
	C266.021027,168.366806 268.128235,177.067352 270.052948,185.793915 
	C270.349823,187.139816 269.325043,189.619110 268.177338,190.260284 
	C266.022797,191.464005 263.200226,194.010986 261.239197,189.724182 
	C261.863190,187.444122 262.944183,185.273773 262.501099,183.479904 
	C261.022125,177.492523 259.044067,171.625122 257.171387,165.739044 
	C253.616638,154.565765 243.359802,150.132019 234.403503,145.628830 
	C228.346771,142.583542 220.182816,143.378113 212.946381,143.239975 
	C210.717621,143.197449 208.439438,145.743393 206.183899,147.104095 
	C208.118332,149.071915 210.052750,151.039734 212.160370,153.293243 
	C215.224823,156.047195 218.116104,158.515427 221.292572,161.158554 
	C224.853790,163.789505 228.244034,166.109207 231.378159,168.734711 
	C235.704193,172.358673 239.837067,176.213242 243.823837,180.244202 
	C244.561829,183.279053 246.536957,186.136169 246.281723,188.777786 
	C245.778915,193.981903 248.138382,194.338791 250.918594,193.570404 
	C252.600204,195.617722 254.030334,196.864334 254.775284,198.435776 
	C255.291901,199.525513 254.864883,201.062607 254.523193,202.266968 
	C249.046997,199.230759 243.907059,196.327423 238.225159,193.117950 
	C238.225159,194.822433 238.225159,195.761307 237.760040,196.808777 
	C233.291153,197.016159 229.287384,197.114944 225.283630,197.213715 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M165.997559,97.000305 
	C165.550400,97.001144 165.103241,97.001984 164.320435,97.005890 
	C163.662262,96.225739 163.339706,95.442528 163.004150,94.330765 
	C164.657547,91.155251 167.205704,88.483078 167.811798,85.425896 
	C169.187897,78.484726 169.606812,71.353958 170.420013,64.300858 
	C170.533203,63.319309 171.058350,62.190514 170.741821,61.385994 
	C168.784851,56.411961 166.641495,51.511253 163.900909,45.026512 
	C176.460266,45.016323 188.259933,45.006752 200.636383,45.110229 
	C201.213165,45.223278 201.646896,45.277493 201.646896,45.277493 
	C201.646896,45.277493 202.005737,44.997246 202.005737,44.997246 
	C225.024002,44.997772 248.042282,44.998299 271.667236,45.194649 
	C272.481964,45.634781 272.724792,45.837349 273.002441,45.998169 
	C281.564697,45.664242 290.125244,45.253605 298.690674,45.062775 
	C300.352539,45.025753 302.033508,45.845844 303.468506,46.639263 
	C301.346466,48.340576 298.012756,49.501316 297.830719,51.035950 
	C297.093689,57.248199 293.200104,57.079910 288.606873,57.054092 
	C266.993286,56.932594 245.378647,57.007618 223.764374,56.992588 
	C220.823502,56.990543 217.631927,57.004307 217.203033,60.717148 
	C216.748840,64.649048 214.462585,65.055069 211.326141,65.025291 
	C203.013733,64.946358 194.697861,64.889702 186.387955,65.054794 
	C183.129944,65.119514 178.500534,63.499836 177.587448,68.159821 
	C177.206406,70.104561 180.219604,72.714363 181.996368,75.503532 
	C176.673798,74.639465 174.987091,79.092087 173.441437,83.487167 
	C172.403412,86.438828 172.201904,89.741776 170.845612,92.504364 
	C169.938873,94.351234 167.661545,95.525208 165.997559,97.000305 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M115.920059,952.622803 
	C114.383743,952.689026 112.755814,952.080750 111.153053,952.141174 
	C109.068230,952.219666 107.008087,952.857483 104.928711,953.202698 
	C102.672699,953.577209 99.303505,954.991577 98.346870,954.031799 
	C95.157974,950.832397 91.538879,950.869324 87.711044,951.021790 
	C85.118225,951.125000 83.335030,950.634155 82.590126,947.638367 
	C81.608780,943.691711 78.330177,943.013855 74.733749,942.977600 
	C68.785744,942.917664 63.382576,937.609253 63.027203,931.582214 
	C62.929310,929.921936 63.025787,928.250854 63.007648,926.585083 
	C62.971088,923.227905 64.218140,921.538696 67.899109,922.438538 
	C71.819878,923.396973 74.151405,921.279724 74.701767,917.733276 
	C75.281807,913.995728 72.960197,911.822266 69.373688,911.170288 
	C67.916298,910.905396 66.384048,910.978149 64.888519,911.012756 
	C61.881821,911.082214 59.149368,910.998840 58.806927,906.919434 
	C58.726559,905.962158 57.604965,905.022278 56.823914,904.207031 
	C54.632488,901.919617 54.404156,899.771973 56.563156,897.202698 
	C60.291538,892.765808 59.491135,889.349182 54.079525,887.292236 
	C49.170521,885.426331 49.356056,880.617188 49.307732,877.353943 
	C49.140888,866.087585 50.285381,854.808472 50.521011,843.525696 
	C50.599922,839.747070 49.392632,835.950684 48.889103,832.147034 
	C48.637627,830.247375 47.871582,827.873657 48.668137,826.467957 
	C52.902798,818.994690 50.976978,811.356140 49.923908,803.700806 
	C49.299519,799.161804 47.937351,794.591309 48.162415,790.097839 
	C48.557621,782.207275 49.298695,774.219971 51.097111,766.555237 
	C52.228462,761.733459 55.922035,757.570801 57.767818,752.839905 
	C59.184696,749.208435 61.691170,747.362915 64.584305,745.128418 
	C66.399834,743.726196 66.696068,739.823608 66.884712,736.990295 
	C67.095749,733.820862 67.047554,731.347290 71.008598,730.281311 
	C74.952904,729.219971 75.124069,725.469727 74.984421,722.022400 
	C74.839241,718.438660 74.646202,715.286072 79.403740,713.954285 
	C81.020668,713.501587 83.096886,709.846313 82.750664,708.090820 
	C81.479546,701.645569 87.127014,697.540405 87.560219,691.787170 
	C87.599518,691.265198 89.672310,690.480774 90.773529,690.523254 
	C96.895935,690.759888 98.816391,689.071899 98.992889,682.903625 
	C99.026245,681.738220 99.051010,680.569824 99.008369,679.405457 
	C98.890388,676.182922 100.544800,674.880920 103.622818,675.000305 
	C104.953339,675.051880 106.290756,675.047546 107.620399,674.978882 
	C112.717278,674.715515 114.857368,672.664612 114.949646,667.511475 
	C115.026741,663.205750 116.187515,660.689697 119.827881,657.170898 
	C124.440750,652.712036 128.309906,650.366577 134.299515,650.641357 
	C134.605286,650.655396 135.011612,650.512146 135.220749,650.296387 
	C139.046432,646.348877 142.839386,642.369629 146.662186,638.419250 
	C147.921463,637.117920 149.241852,635.875488 150.545197,634.617432 
	C150.655899,634.510559 150.890823,634.530273 150.997772,634.421875 
	C154.859390,630.506470 159.432159,627.037659 162.303894,622.494080 
	C164.096329,619.658142 165.578094,618.839050 168.413071,618.993774 
	C173.336227,619.262573 177.728165,618.914429 179.386108,612.839294 
	C179.668930,611.802917 182.974991,611.105713 184.888809,611.095764 
	C189.712906,611.070618 193.963562,610.697998 195.214676,604.812317 
	C195.384079,604.015381 196.856262,603.459778 197.772858,602.866028 
	C199.006973,602.066528 200.962494,601.629944 201.436722,600.531677 
	C204.213196,594.101807 210.560181,593.412292 215.599747,590.290405 
	C222.397324,586.079529 228.906448,587.576294 235.591782,587.038269 
	C239.099564,586.755981 241.538300,585.567749 242.880936,582.083252 
	C243.359131,580.842224 244.816330,579.771729 246.077469,579.082031 
	C248.741394,577.625244 251.570633,576.470703 254.964752,575.165405 
	C256.397888,575.759827 257.198334,576.381104 258.079773,577.284668 
	C258.894592,577.629395 259.628448,577.691772 260.362274,577.754211 
	C259.908997,576.835327 259.455719,575.916443 259.065125,574.663879 
	C260.418396,573.220154 261.708984,572.110046 263.231262,571.269287 
	C263.865936,572.055847 264.590790,572.555237 264.620575,573.093201 
	C264.841217,577.076355 266.759460,577.683167 270.512604,577.214478 
	C273.801727,576.803711 277.267029,578.024963 280.672455,578.236084 
	C282.505402,578.349670 284.444763,578.094849 286.215210,577.585876 
	C289.852386,576.540405 293.408264,575.212036 297.118408,574.252319 
	C297.493256,575.003113 297.747223,575.501526 298.026428,576.362976 
	C299.472931,577.704163 300.813202,579.322388 302.333954,579.514893 
	C304.790619,579.825867 307.510101,579.628418 309.849640,578.843750 
	C312.078735,578.096130 313.963745,576.322571 316.353516,574.984680 
	C318.471558,573.647095 320.236053,572.324768 322.305481,570.856201 
	C324.072632,570.133606 325.534851,569.557190 327.279968,568.937988 
	C328.036163,568.597107 328.509460,568.299011 328.988892,568.000183 
	C328.995056,567.999451 328.997559,567.985352 329.340515,568.094116 
	C330.388458,569.909302 331.886292,572.992188 331.677612,573.112488 
	C329.193939,574.543091 326.456207,575.532654 323.444305,576.778870 
	C324.205658,579.933716 327.541412,579.762085 329.294220,578.384583 
	C332.820526,575.613281 335.990143,576.889221 339.528992,577.542847 
	C341.204803,577.852417 343.078766,577.089172 345.670959,576.686401 
	C343.409668,573.458008 337.977264,571.781677 344.218506,569.172729 
	C345.298553,569.555420 346.164337,569.761475 347.397308,569.973145 
	C348.498871,569.975647 349.233246,569.972595 350.352112,569.980469 
	C354.157745,571.994202 357.578857,573.997131 361.153381,576.250122 
	C362.204529,576.333496 363.102264,576.166748 364.215515,575.755737 
	C364.620728,574.674316 364.810364,573.837158 365.299377,572.921509 
	C366.066467,572.219727 366.534210,571.596375 367.433685,570.973877 
	C370.909729,571.326355 373.954071,571.678040 377.072815,572.370239 
	C378.563202,574.763794 379.979156,576.816895 381.369019,578.832153 
	C387.677246,576.621765 383.048737,572.965332 383.334900,569.938354 
	C384.753235,568.546082 385.835480,567.222778 387.016632,565.671509 
	C387.313629,565.156311 387.582611,564.973328 388.246124,564.839661 
	C388.911285,564.070862 389.252869,563.357056 389.721191,562.791870 
	C389.847931,562.940552 389.569000,562.666870 389.889893,562.656250 
	C390.794342,562.406616 391.377960,562.167603 391.989868,562.320801 
	C393.015076,565.017578 391.799011,568.723328 395.995544,569.358704 
	C395.327759,571.144958 394.663269,572.572449 393.643250,573.984924 
	C391.784515,573.833862 390.281281,573.697876 388.465973,573.533691 
	C390.290131,578.958252 396.288422,580.404907 400.374146,576.920898 
	C404.499054,576.227783 408.249878,575.613892 412.327209,575.092346 
	C413.060486,575.460754 413.434906,575.815735 413.880096,575.997803 
	C415.116852,576.503601 416.373444,576.975098 417.662018,577.304504 
	C417.737946,577.323975 418.573273,575.528320 418.359009,575.330200 
	C417.369324,574.414978 416.180054,573.672180 414.963531,573.065796 
	C414.430206,572.799927 413.660919,573.007446 412.999084,572.626953 
	C413.002441,571.508240 413.005493,570.762695 413.434753,570.015015 
	C416.907288,570.342773 419.953644,570.672607 423.000549,571.336548 
	C423.002777,572.116272 423.004456,572.561768 423.017120,573.334595 
	C423.352478,574.439819 423.676849,575.217712 424.087860,576.364502 
	C426.317017,580.473816 429.583221,578.727234 432.579376,578.509583 
	C435.223206,578.317627 437.982208,577.831299 440.508636,578.353699 
	C444.086761,579.093506 446.747986,578.914917 448.372559,574.989258 
	C450.160583,574.658081 451.578461,574.327820 453.396912,573.998413 
	C454.865784,573.998718 455.934113,573.998169 457.155457,574.264526 
	C458.206085,575.353455 459.103638,576.175537 460.074158,577.343445 
	C462.832520,580.378418 465.728851,579.995605 467.816254,576.904785 
	C468.598633,575.746399 469.922394,574.953674 471.249329,573.994995 
	C471.499268,573.995728 471.999207,573.997559 472.157410,574.269897 
	C473.448883,575.470947 474.348358,578.302917 476.445984,574.995483 
	C479.259552,574.998901 481.629181,575.001892 484.023376,575.360474 
	C485.287720,579.591370 487.376465,579.532104 490.373962,577.000122 
	C491.499542,577.000122 492.249908,576.999878 493.097046,577.338867 
	C494.059052,579.611877 494.478729,582.022217 495.905060,583.356018 
	C497.597595,584.938782 500.423218,586.728760 502.256927,586.297791 
	C503.755096,585.945618 504.832214,582.558472 505.505829,580.337952 
	C506.038300,578.582642 505.519592,576.536316 505.751465,574.654175 
	C505.986115,572.749512 506.569580,570.887756 507.332550,569.005005 
	C508.107880,569.001038 508.550903,568.999329 508.993896,568.997559 
	C510.098114,570.231628 511.202332,571.465698 512.472168,572.884888 
	C511.647675,574.112549 510.440674,575.229980 510.108032,576.565247 
	C509.615753,578.541199 508.861420,581.902222 509.749664,582.539490 
	C516.142944,587.126099 513.469421,593.659668 513.977722,599.481262 
	C514.122131,601.134644 514.003723,602.811401 513.996399,604.477478 
	C513.985107,607.036621 514.123413,609.756470 510.496521,609.925476 
	C507.503906,610.064941 504.620636,610.503113 503.701874,606.067810 
	C503.362488,604.429382 500.453369,602.449707 498.509918,602.193848 
	C494.087433,601.611938 489.536316,601.947205 485.039124,602.014893 
	C482.206451,602.057495 480.241028,601.415649 479.888000,598.055725 
	C479.496033,594.325073 476.607422,593.981506 473.565430,593.989441 
	C461.736481,594.020447 449.907410,594.000183 438.078369,594.000122 
	C401.924866,593.999878 365.771301,594.020264 329.617859,593.971436 
	C325.651672,593.966125 322.275543,594.168091 321.703796,599.333740 
	C321.593353,600.331543 319.481323,601.598083 318.123871,601.857300 
	C315.870758,602.287842 313.332428,601.513672 311.192902,602.163940 
	C309.293915,602.741089 306.660431,604.318176 306.312988,605.888123 
	C305.536926,609.394348 303.689697,610.057312 300.675018,610.022339 
	C293.845123,609.943176 287.013062,610.055176 280.183044,609.980835 
	C276.748352,609.943481 274.584747,610.962219 273.771576,614.755371 
	C273.491608,616.061340 271.257568,617.464661 269.678131,617.851318 
	C267.473633,618.391052 264.905853,617.546875 262.757416,618.184265 
	C261.002777,618.704834 258.547913,620.347412 258.304535,621.808960 
	C257.647369,625.756165 255.325836,626.122070 252.240753,626.013000 
	C250.410980,625.948364 248.393707,625.547424 246.792862,626.162354 
	C245.030014,626.839539 242.552841,628.325439 242.310852,629.779297 
	C241.657394,633.705444 239.377808,634.145813 236.271088,634.010559 
	C234.774582,633.945435 233.272156,633.980286 231.773468,634.015259 
	C230.944031,634.034607 229.660278,633.831848 229.358963,634.267273 
	C226.273865,638.726196 221.277786,641.568420 218.460312,645.851135 
	C216.647781,648.606323 215.588989,650.198242 212.387512,650.013611 
	C210.893158,649.927368 209.387955,649.962280 207.890564,650.018982 
	C206.900146,650.056458 205.239334,649.926270 205.030777,650.389648 
	C202.702591,655.562561 196.583771,657.363831 194.288803,662.118347 
	C192.955765,664.879822 191.863388,666.114136 189.004486,666.008789 
	C187.174530,665.941284 185.337585,665.955017 183.507919,666.031494 
	C182.523788,666.072571 180.751968,666.167969 180.687073,666.501648 
	C179.474487,672.737732 172.415146,673.524597 169.657150,678.730347 
	C167.310608,683.159424 162.473999,686.268555 158.741608,689.964294 
	C157.147003,691.543274 155.551788,693.121704 153.970261,694.713684 
	C152.909988,695.781006 151.998932,697.612305 150.802795,697.815308 
	C146.967010,698.466248 145.873947,700.702026 145.989426,704.150024 
	C146.056305,706.146545 146.372009,708.259094 145.844650,710.111267 
	C145.433655,711.554749 144.079071,713.496765 142.820206,713.780151 
	C139.711121,714.480347 138.322372,715.997437 137.786163,719.068237 
	C137.596146,720.156555 136.024399,721.666504 134.925247,721.815247 
	C130.739853,722.381836 129.838943,724.950745 129.987122,728.533325 
	C130.062759,730.362427 130.369019,732.314514 129.859558,733.995361 
	C129.414978,735.462158 128.131546,737.553101 126.942642,737.756714 
	C122.818069,738.463379 121.927361,740.898804 121.967918,744.502808 
	C122.018211,748.972107 123.352997,754.095276 116.171013,754.991516 
	C115.193161,755.113525 114.295738,759.176941 114.092377,761.479980 
	C113.736504,765.510193 115.089508,770.051758 108.629066,770.698914 
	C107.589546,770.803040 106.209541,774.083679 106.123627,775.948364 
	C105.817230,782.598572 105.943893,789.272034 106.023880,795.935303 
	C106.062820,799.178955 105.909355,801.922668 101.595657,802.259094 
	C100.508614,802.343872 99.546295,804.028381 98.526649,804.977783 
	C99.531685,805.939087 100.479340,807.650146 101.551682,807.732239 
	C106.253563,808.092102 106.192932,811.178589 105.935867,814.509521 
	C105.627464,818.505554 102.577713,817.916260 99.966217,817.991638 
	C98.301384,818.039673 96.633759,817.987183 94.968590,818.028503 
	C91.727341,818.108948 90.064156,819.813477 90.030098,823.036255 
	C89.989609,826.867676 90.036545,830.700073 90.006897,834.531677 
	C89.977959,838.271118 91.823532,840.052002 95.535843,839.994019 
	C97.201408,839.967957 98.867836,840.001221 100.533875,840.001770 
	C103.118172,840.002686 105.780815,839.943726 105.936554,843.557800 
	C106.066719,846.578308 106.499992,849.645691 101.971901,850.137756 
	C97.901009,850.580261 97.991776,853.916504 97.988724,857.017700 
	C97.985664,860.129578 97.937912,863.394226 102.007050,863.873047 
	C105.328415,864.263794 106.104034,866.123230 106.010841,868.988342 
	C105.935059,871.318298 105.917557,873.656311 106.018555,875.984436 
	C106.161453,879.278564 105.764732,881.777710 101.562370,882.347534 
	C100.231316,882.527954 98.679237,884.863525 98.190285,886.500244 
	C97.594826,888.493591 97.952377,890.791992 98.010963,892.955322 
	C98.087379,895.776917 97.434937,897.714844 94.076683,898.178223 
	C92.790779,898.355591 91.709465,900.015869 90.534927,901.000366 
	C91.882469,901.986755 93.105026,903.435547 94.609261,903.842102 
	C96.475822,904.346558 98.575569,904.000427 100.574593,903.999695 
	C103.177513,903.998657 105.803055,903.994141 105.943077,907.598083 
	C106.060791,910.627747 106.474869,913.714478 101.940338,914.198486 
	C100.712105,914.329590 99.659729,916.108093 98.526642,917.130493 
	C99.581612,918.051147 100.575722,919.668152 101.702545,919.766663 
	C105.664612,920.112915 106.155594,922.523926 106.015488,925.672302 
	C105.934113,927.500732 105.552994,929.510376 106.154205,931.122498 
	C106.815948,932.897034 108.258202,935.416992 109.696541,935.665283 
	C113.538864,936.328613 113.884537,938.487122 114.087318,941.635559 
	C114.316582,945.195129 115.225998,948.710876 115.920059,952.622803 
M95.141113,709.398743 
	C96.219543,710.972046 96.944054,713.167969 98.435013,714.015503 
	C106.788162,718.763489 114.914001,714.014893 114.984230,704.525452 
	C115.004349,701.808289 114.967567,699.680908 118.418282,698.363342 
	C120.378494,697.614868 122.342804,694.344421 122.444901,692.124207 
	C122.516808,690.560486 119.378563,687.455017 117.816902,687.544312 
	C115.595352,687.671326 113.410332,689.979370 111.395538,691.588318 
	C110.235954,692.514404 109.554810,694.016663 108.464912,695.056885 
	C104.183868,699.142822 99.814728,703.136292 95.520775,707.208984 
	C95.217712,707.496399 95.258438,708.146301 95.141113,709.398743 
M208.186905,616.212830 
	C212.583969,619.941528 216.684418,619.811707 218.121506,615.205688 
	C219.432404,611.004089 221.909698,610.855774 225.246948,610.962219 
	C227.054092,611.019897 228.974182,611.007996 230.667923,610.472595 
	C235.038284,609.090942 236.227493,603.997070 233.071854,600.885071 
	C228.951889,596.822083 217.565231,599.059204 215.049698,604.255859 
	C214.440460,605.514404 213.000504,606.625366 211.679810,607.236572 
	C207.936981,608.968994 206.154144,611.396606 208.186905,616.212830 
M195.146744,628.671875 
	C196.524689,627.881531 198.243195,627.376892 199.201553,626.234619 
	C200.675919,624.477295 202.734360,622.137085 202.494904,620.313354 
	C202.250107,618.448853 199.709030,615.834778 197.850555,615.532593 
	C196.036652,615.237610 192.473755,617.067505 192.027893,618.633362 
	C190.767685,623.059326 187.942490,623.148193 184.446014,623.043701 
	C182.647125,622.989868 180.714706,623.052917 179.051865,623.647949 
	C174.768692,625.180664 173.837326,630.387207 177.151093,633.327271 
	C181.593201,637.268433 192.630188,634.836731 195.146744,628.671875 
M145.437225,655.000000 
	C140.483795,654.738281 136.031006,655.158203 134.721313,661.315979 
	C134.540375,662.166748 132.445587,662.452148 131.684814,663.370483 
	C130.035187,665.361511 127.693275,667.522156 127.533112,669.750000 
	C127.421219,671.306458 130.510345,674.493958 132.074036,674.434570 
	C134.298019,674.350037 137.473511,672.419800 138.363098,670.435547 
	C139.681381,667.495117 141.295410,666.899719 144.017120,666.964905 
	C146.160721,667.016296 148.404236,667.085938 150.440887,666.540649 
	C154.688751,665.403320 156.485229,660.423401 153.438324,657.593201 
	C151.732941,656.009155 148.732712,655.819031 145.437225,655.000000 
M74.995583,803.189392 
	C74.958092,801.038940 75.114220,798.863342 74.838692,796.743835 
	C74.401848,793.383362 72.396614,791.166626 68.923683,791.213379 
	C65.452721,791.260071 63.520535,793.536499 63.134712,796.895569 
	C62.890480,799.021851 63.020969,801.192871 63.010284,803.343933 
	C62.990810,807.264099 64.398834,810.497192 68.650574,810.749451 
	C72.909485,811.002136 74.731293,808.057312 74.995583,803.189392 
M263.839752,586.421143 
	C262.230438,590.236023 263.481567,593.468506 267.221222,594.293823 
	C270.967896,595.120605 275.150909,594.941162 278.933411,594.172607 
	C280.519135,593.850403 282.410614,590.961548 282.600861,589.081543 
	C282.764374,587.465881 281.094147,585.057190 279.549713,584.000061 
	C276.122406,581.654236 267.644073,582.908936 263.839752,586.421143 
M170.735641,643.878601 
	C168.035019,638.762878 163.498627,637.411438 160.790634,641.248413 
	C159.687943,642.810852 159.278534,645.892273 160.097305,647.493164 
	C160.926056,649.113647 163.667191,650.644348 165.563126,650.635315 
	C168.924469,650.619263 170.732285,648.088196 170.735641,643.878601 
M87.537712,746.181824 
	C88.567673,743.973267 90.602921,741.665283 90.396667,739.578918 
	C90.071037,736.285156 86.955505,734.144409 83.834610,735.535950 
	C81.787781,736.448486 79.389832,739.540894 79.452530,741.586426 
	C79.575005,745.581665 82.583168,747.535522 87.537712,746.181824 
M71.506119,766.844849 
	C72.923775,769.949158 75.508430,772.002869 78.549210,770.345154 
	C80.519714,769.270935 82.730118,765.933472 82.384285,764.062805 
	C82.028023,762.135986 78.764595,759.519897 76.660225,759.412109 
	C72.905785,759.219849 70.883957,762.097229 71.506119,766.844849 
M74.493233,838.855042 
	C75.475372,835.412659 74.232834,832.625977 70.979118,831.766418 
	C69.130737,831.278137 66.251152,831.977295 64.812752,833.247681 
	C62.227638,835.530701 62.559666,838.643738 64.998711,841.154663 
	C67.708176,843.944031 70.819252,843.336487 74.493233,838.855042 
M87.263466,723.877502 
	C87.809029,729.698669 92.002655,732.747498 95.867279,729.813660 
	C97.406425,728.645264 98.796471,725.842834 98.494499,724.086975 
	C98.186226,722.294434 95.637764,719.514832 94.213921,719.598999 
	C91.915962,719.734985 89.735909,721.863831 87.263466,723.877502 
M74.493233,854.855042 
	C75.475372,851.412659 74.232834,848.625977 70.979118,847.766418 
	C69.130737,847.278137 66.251152,847.977295 64.812752,849.247681 
	C62.227680,851.530762 62.559666,854.643738 64.998749,857.154663 
	C67.708229,859.943970 70.819260,859.336487 74.493233,854.855042 
M246.855072,591.506775 
	C243.412704,590.524536 240.626022,591.767029 239.766541,595.020813 
	C239.278290,596.869263 239.977249,599.748840 241.247604,601.187256 
	C243.530655,603.772400 246.643646,603.440247 249.154617,601.001343 
	C251.944000,598.291870 251.336502,595.180725 246.855072,591.506775 
M352.787506,576.409302 
	C352.387726,575.824402 351.987946,575.239563 351.588226,574.654663 
	C351.170624,575.285400 350.753021,575.916077 350.335388,576.546753 
	C351.079163,576.695251 351.822968,576.843750 352.787506,576.409302 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M663.051941,619.025330 
	C659.371216,626.008606 655.690430,632.991943 651.929626,640.300537 
	C651.175781,640.727661 650.502075,640.829590 649.398315,640.621094 
	C647.735779,639.156250 646.503357,638.001770 645.270874,636.847290 
	C645.001770,636.347229 644.732666,635.847168 644.159668,634.875000 
	C643.264465,633.900757 642.673279,633.398621 642.082092,632.896423 
	C642.082092,632.896423 642.025635,632.988770 642.003784,632.648193 
	C641.317871,631.547424 640.653870,630.787231 639.989868,630.026978 
	C636.932678,626.767212 633.875549,623.507446 630.467529,619.715027 
	C627.760132,616.423157 625.403625,613.664001 623.047119,610.904785 
	C621.591797,609.283875 620.136536,607.662964 618.326355,605.512817 
	C612.981812,599.318970 607.992126,593.654297 603.002441,587.989685 
	C598.135742,582.507690 593.269104,577.025757 588.171814,570.930908 
	C586.972412,569.197571 586.003601,568.077148 585.034851,566.956726 
	C585.034851,566.956726 584.997253,566.996094 584.962891,566.680176 
	C584.256165,565.656555 583.583679,564.948975 582.911255,564.241333 
	C582.508118,563.857788 582.105042,563.474304 581.330688,562.654907 
	C580.232849,560.444824 579.506287,558.670715 579.249390,556.778015 
	C591.803223,556.183655 591.255554,556.344971 593.854370,565.250793 
	C594.261475,566.645996 596.934326,567.379944 598.559509,568.419678 
	C599.050903,566.643738 600.379822,564.591797 599.900085,563.135864 
	C596.313416,552.251404 592.507629,541.431580 588.396973,530.734985 
	C587.733032,529.007385 585.117310,526.824097 583.579102,526.952393 
	C581.776428,527.102905 579.444031,529.222595 578.604553,531.063965 
	C575.925842,536.940002 573.832520,543.082886 571.223267,549.058716 
	C569.956848,548.334290 568.980225,547.673889 568.004272,547.004272 
	C568.004883,546.995117 567.986145,546.996582 567.905518,546.704712 
	C567.214844,545.948242 566.604797,545.483643 565.890747,544.714966 
	C564.946533,543.834473 564.106201,543.258179 563.166626,542.348694 
	C561.015137,539.486816 558.962952,536.958069 557.015137,534.069458 
	C559.010498,533.458374 561.003662,533.496155 562.764038,532.874634 
	C564.519836,532.254822 566.042358,530.974365 567.667175,529.983582 
	C566.158752,528.886292 564.736023,527.022278 563.125122,526.842346 
	C559.070068,526.389099 554.930115,526.695679 550.559448,526.398438 
	C545.041992,520.100159 539.777649,514.111206 534.538635,508.100189 
	C521.324402,492.938568 508.190521,477.706116 494.883087,462.626801 
	C488.859283,455.800995 482.499786,449.271454 476.276306,442.295532 
	C477.525757,441.034515 478.791473,440.085480 480.325531,439.367737 
	C484.006653,440.444336 487.419403,441.289673 491.125519,442.357025 
	C495.745605,443.732178 500.055725,444.952637 504.402252,446.025696 
	C516.597046,449.036224 528.806824,451.985992 541.014648,455.344513 
	C543.585571,459.601532 544.018738,465.595520 550.876404,465.216309 
	C551.849548,465.162506 553.011414,466.835052 553.923401,467.854065 
	C554.767700,468.797485 555.163147,470.303894 556.155273,470.926605 
	C561.215332,474.102478 566.785522,476.812897 572.029907,479.144501 
	C580.320618,482.830444 590.040710,484.392914 599.224792,484.684906 
	C609.341248,485.006561 619.579834,483.166260 629.664856,481.615204 
	C632.189880,481.226868 636.345703,481.445618 637.364868,477.102692 
	C642.047913,478.349365 646.291504,479.803345 650.640015,480.798950 
	C667.414734,484.639404 684.223083,488.333374 700.996643,492.446991 
	C697.991821,497.519379 696.500305,502.523163 696.665161,508.229553 
	C696.709778,509.774261 694.091736,511.443024 692.613525,512.975403 
	C688.814392,516.913879 684.891479,520.737549 681.219727,524.789917 
	C680.620239,525.451599 681.014343,527.043762 681.007141,528.206238 
	C680.986877,531.533875 681.142944,534.870850 680.934875,538.185669 
	C680.824219,539.948364 680.627380,542.134277 679.569031,543.336670 
	C674.224426,549.408752 672.782288,556.484558 672.908997,564.297668 
	C672.975037,568.363647 672.834167,572.719971 671.441101,576.441406 
	C670.065796,580.115723 669.541870,582.762329 672.235291,585.993286 
	C673.385559,587.373047 674.085022,591.235413 673.443298,591.666077 
	C668.655518,594.879456 670.213013,599.977966 669.079468,604.612061 
	C667.696960,608.962769 666.307739,612.927734 664.918457,616.892700 
	C664.918457,616.892700 664.962036,616.916626 664.645081,617.009521 
	C663.902710,617.743408 663.477295,618.384338 663.051941,619.025330 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M471.999207,573.997559 
	C471.999207,573.997559 471.499268,573.995728 471.088501,573.715454 
	C469.740448,572.229797 468.803192,571.024353 467.420624,569.246155 
	C466.423218,574.069824 465.745209,578.292664 460.001221,576.997559 
	C459.103638,576.175537 458.206085,575.353455 457.137207,573.895508 
	C455.780762,571.806458 454.837128,570.007568 453.358429,568.974670 
	C450.566711,567.024780 446.908752,565.720459 451.517273,561.420715 
	C448.282410,562.068176 445.757812,562.307800 443.446228,563.139404 
	C442.135834,563.610779 440.493073,564.881226 440.188507,566.091003 
	C439.388794,569.267639 437.735718,570.123169 434.733826,569.454773 
	C432.084595,568.864868 431.120300,570.022278 431.345154,572.744995 
	C431.444122,573.943604 430.620789,575.218445 430.216187,576.458679 
	C429.321411,575.335144 427.601990,574.158325 427.670624,573.097107 
	C428.020599,567.686768 425.572906,569.389465 423.000000,571.002441 
	C419.953644,570.672607 416.907288,570.342773 412.973175,570.010376 
	C408.390808,570.004333 404.696198,570.000916 400.897461,569.886841 
	C400.490814,569.624451 400.234680,569.693176 399.814209,569.769043 
	C399.455475,568.987122 399.307434,568.418579 399.615570,567.870850 
	C407.045227,567.945374 414.022552,568.132629 420.988678,567.931396 
	C422.265594,567.894470 423.496918,566.278259 424.749268,565.391052 
	C423.564545,564.581360 422.436707,563.197693 421.184021,563.073486 
	C417.895264,562.747498 414.525787,562.706848 411.236908,563.036194 
	C406.389984,563.521729 404.407593,562.078552 404.691071,556.786865 
	C405.143463,548.341125 404.892944,539.851135 404.711151,531.384827 
	C404.682190,530.034790 403.460876,528.710327 402.790771,527.374084 
	C401.444275,528.254395 400.097778,529.134705 398.414978,530.190430 
	C397.718323,533.884766 397.242737,537.396423 397.022308,540.924011 
	C396.604736,547.607483 396.333496,554.299988 395.840881,560.617188 
	C395.120178,551.529358 394.646790,542.805847 393.926178,534.102844 
	C393.783386,532.378418 392.691284,530.732544 391.832520,529.032227 
	C391.628021,529.014038 391.217468,529.011169 391.069305,528.736877 
	C390.264343,527.987427 389.607574,527.512268 388.910370,526.826721 
	C388.544342,526.725098 388.218719,526.833862 387.575317,526.950317 
	C387.073181,526.853516 386.888855,526.748901 386.743469,526.401733 
	C386.695801,525.890930 386.609222,525.622803 386.522583,525.354675 
	C386.568268,525.780579 386.613953,526.206482 386.586945,526.619019 
	C386.514252,526.605713 386.547455,526.461609 386.232025,526.534790 
	C385.668884,527.165222 385.421143,527.722473 385.247681,528.726074 
	C385.218384,537.438354 385.114807,545.704285 384.770752,553.646667 
	C384.017822,546.752136 383.564056,540.175842 382.971222,533.612183 
	C382.604553,529.552551 381.888672,525.477966 376.987030,524.484680 
	C373.955627,523.870483 370.819885,523.771301 367.190826,523.383118 
	C366.822083,523.814148 365.860687,524.937988 364.516724,526.049683 
	C363.110443,526.030640 362.086700,526.023804 361.050629,525.627380 
	C361.038300,523.362366 361.038300,521.486938 361.038300,519.581604 
	C358.375519,520.270569 355.964294,520.894409 353.553101,521.518250 
	C353.271118,521.009521 352.989136,520.500732 352.707153,519.992004 
	C355.067657,518.166504 357.428162,516.341003 361.076385,513.519653 
	C355.905426,514.139465 353.753540,512.318298 351.089935,509.739044 
	C347.664124,506.421600 342.322266,505.082825 337.832520,502.904663 
	C343.823578,500.200500 350.378601,500.795563 352.905212,504.411621 
	C354.200470,503.725342 355.543518,502.385254 356.817719,502.447845 
	C362.230530,502.713562 367.145477,503.098755 372.178497,499.333588 
	C375.196808,497.075623 380.357178,497.329620 384.587708,497.120483 
	C392.136383,496.747284 399.712921,496.979919 407.272247,496.764160 
	C412.664276,496.610291 418.047211,496.137268 423.584961,495.758362 
	C423.735687,495.710022 423.982727,495.907990 424.206421,496.131287 
	C425.028900,496.511688 425.627655,496.668762 426.187317,496.833740 
	C426.148224,496.841583 426.147247,496.761841 426.119080,497.176331 
	C426.815063,498.986450 427.539246,500.382111 428.263428,501.777710 
	C429.815094,500.481110 431.931763,499.465912 432.757629,497.807800 
	C433.563232,496.190308 433.049591,493.915802 433.556458,491.945190 
	C445.331451,491.641266 456.665100,491.321838 468.184326,491.316193 
	C470.848724,500.435669 473.422791,509.215973 475.778625,518.054443 
	C477.982666,526.323303 476.734772,526.144653 485.732819,526.103149 
	C487.514862,526.094910 489.299622,526.671143 491.397461,527.089966 
	C492.104095,528.167114 492.496460,529.132080 492.542114,529.988159 
	C485.632324,525.646545 478.309570,527.585022 471.255493,527.254883 
	C467.930267,527.099121 466.965363,528.567139 467.026276,531.731201 
	C467.186523,540.054993 467.080597,548.383850 467.081085,556.710693 
	C467.081787,568.080627 467.095856,567.727173 478.334808,568.169800 
	C488.080750,568.553589 495.263367,565.629822 498.991211,555.943481 
	C499.552338,555.973450 499.776764,555.985474 500.331848,556.146606 
	C501.599884,556.554626 502.537262,556.813660 503.484436,557.465698 
	C502.521637,560.936768 501.549072,564.014771 500.537384,567.216614 
	C504.746399,569.047607 506.428680,566.906982 507.707458,563.956238 
	C508.109955,565.430908 508.512421,566.905579 508.954407,568.688965 
	C508.550903,568.999329 508.107880,569.001038 507.009399,569.088867 
	C504.215363,569.284546 502.077393,569.409180 499.937988,569.499695 
	C497.551514,569.600830 495.163666,569.670959 492.776428,569.754517 
	C493.017822,571.725891 493.296814,573.693909 493.470917,575.671204 
	C493.508392,576.096924 493.167084,576.555969 493.000275,576.999695 
	C492.249908,576.999878 491.499542,577.000122 490.110657,576.769836 
	C487.647675,576.027893 485.823242,575.516418 483.998779,575.004883 
	C481.629181,575.001892 479.259552,574.998901 476.424255,574.657593 
	C474.924042,570.700439 473.477661,572.151428 471.999207,573.997559 
M440.014648,529.517700 
	C435.565826,541.977539 431.116974,554.437317 426.597443,567.095154 
	C430.553680,568.760071 432.287384,567.760010 433.450775,564.280701 
	C434.407013,561.420898 435.693176,557.147034 437.760162,556.510620 
	C441.677612,555.304321 446.460663,555.531433 450.473480,556.644165 
	C452.249146,557.136536 453.341400,561.135193 454.177856,563.724976 
	C455.378235,567.441650 457.226501,569.029297 461.603973,567.078369 
	C457.152618,554.468750 452.983551,541.888611 448.191498,529.550476 
	C446.742798,525.820496 443.280182,526.478149 440.014648,529.517700 
M463.461365,569.546875 
	C463.229950,569.994934 462.998535,570.443054 462.767120,570.891113 
	C463.200531,571.059814 463.633942,571.228516 464.067322,571.397217 
	C464.015503,570.847534 463.963715,570.297913 463.461365,569.546875 
M497.039886,565.418518 
	C497.039948,565.806702 497.040009,566.194824 497.040070,566.583008 
	C497.452789,566.468994 497.865509,566.354980 498.278229,566.240967 
	C498.019562,565.929016 497.760895,565.617126 497.039886,565.418518 
M436.211548,565.617615 
	C436.014984,565.779907 435.818390,565.942200 435.621826,566.104492 
	C435.719482,566.209900 435.817139,566.315308 435.914795,566.420715 
	C436.089905,566.234802 436.265015,566.048950 436.211548,565.617615 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M492.888794,530.097107 
	C492.496460,529.132080 492.104095,528.167114 491.371399,526.627563 
	C489.342194,514.497498 488.070679,502.861206 485.853699,491.407928 
	C483.357422,478.511627 480.008972,465.780304 477.024963,452.577484 
	C476.776703,448.987671 476.534637,445.797607 476.292572,442.607513 
	C482.499786,449.271454 488.859283,455.800995 494.883087,462.626801 
	C508.190521,477.706116 521.324402,492.938568 534.538635,508.100189 
	C539.777649,514.111206 545.041992,520.100159 550.100708,526.533752 
	C545.940979,527.079285 541.958496,527.021851 538.020935,527.414978 
	C536.984802,527.518372 536.087830,529.016113 535.126953,529.873596 
	C536.092957,530.710266 536.936829,531.889221 538.058594,532.289429 
	C539.232971,532.708435 540.688110,532.502502 541.996338,532.355042 
	C546.574158,531.838745 548.582825,533.412720 548.352478,538.405884 
	C547.978943,546.502197 548.446838,554.635376 548.151062,562.738098 
	C548.009094,566.627319 548.805420,568.838806 553.143311,568.328369 
	C551.700684,569.810120 550.258057,571.291809 548.815430,572.773560 
	C547.661865,570.409729 545.784729,568.123474 545.521301,565.664307 
	C545.108582,561.810486 545.785828,557.840027 546.000732,552.997009 
	C545.988403,548.940796 546.131897,545.795959 545.919006,542.675415 
	C545.729126,539.894043 544.492004,540.009277 543.000000,541.997559 
	C542.067017,542.516418 540.511292,542.854797 540.300232,543.582825 
	C538.712891,549.058533 537.390503,554.610962 535.591797,560.592041 
	C534.682007,561.080994 534.165588,561.117249 533.421021,560.876587 
	C529.821106,551.033020 526.390076,541.486633 523.115723,531.886841 
	C522.190674,529.174561 521.303711,527.178711 517.765381,527.184387 
	C514.272461,527.189941 513.817932,529.478027 512.944885,531.820251 
	C509.815399,540.216125 506.633453,548.592407 503.360260,556.523804 
	C502.698120,553.975830 502.147095,551.880005 501.596039,549.784241 
	C501.064423,549.845276 500.532837,549.906372 500.001221,549.967407 
	C500.001221,551.977478 500.001221,553.987488 500.001221,555.997559 
	C499.776764,555.985474 499.552338,555.973450 499.007507,555.478516 
	C499.880737,545.968018 499.640808,537.274292 492.888794,530.097107 
M539.745239,537.965881 
	C541.269775,537.471375 542.794312,536.976868 544.318848,536.482361 
	C544.229309,535.995361 544.139771,535.508301 544.050232,535.021240 
	C542.032288,535.021240 540.014404,535.021240 537.338501,535.247986 
	C536.844727,536.795166 536.350952,538.342346 535.857239,539.889465 
	C536.291077,540.054260 536.724915,540.219055 537.158752,540.383789 
	C537.773315,539.590027 538.387878,538.796204 539.745239,537.965881 
M501.300781,541.852417 
	C500.962616,542.418701 500.624451,542.984985 500.286316,543.551331 
	C500.656952,543.715515 501.027557,543.879639 501.398193,544.043823 
	C501.489166,543.439514 501.580109,542.835144 501.300781,541.852417 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M503.471375,556.976074 
	C506.633453,548.592407 509.815399,540.216125 512.944885,531.820251 
	C513.817932,529.478027 514.272461,527.189941 517.765381,527.184387 
	C521.303711,527.178711 522.190674,529.174561 523.115723,531.886841 
	C526.390076,541.486633 529.821106,551.033020 533.443726,561.265259 
	C534.110168,563.943420 534.525696,565.955872 534.941223,567.968323 
	C534.941223,567.968323 534.989014,568.003235 534.569031,567.965820 
	C530.781372,568.606567 528.917236,567.289795 528.203918,563.873108 
	C527.843323,562.145996 526.846436,560.551697 526.120117,558.517273 
	C524.403076,557.502380 522.703125,556.867615 520.672974,556.136597 
	C519.891541,556.095764 519.440430,556.151184 518.561768,556.087646 
	C515.361328,556.635864 512.588501,557.302917 509.623779,558.218506 
	C509.234406,559.269409 509.036926,560.071716 508.839478,560.874084 
	C508.839478,560.874084 508.904907,560.931030 508.701111,560.998230 
	C508.256958,561.314880 508.197296,561.601807 508.318329,561.926270 
	C508.130981,562.363892 507.943604,562.801514 507.731842,563.597656 
	C506.428680,566.906982 504.746399,569.047607 500.537384,567.216614 
	C501.549072,564.014771 502.521637,560.936768 503.485657,557.440369 
	C503.477142,557.021973 503.471344,556.976074 503.471375,556.976074 
M519.768982,538.930969 
	C519.263123,537.805298 518.757324,536.679626 517.700562,534.327942 
	C515.530151,540.382874 513.791687,545.232727 511.870850,550.591431 
	C516.030579,550.591431 519.341309,550.591431 523.526123,550.591431 
	C522.213501,546.485840 521.119080,543.062744 519.768982,538.930969 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M553.203491,568.398193 
	C548.805420,568.838806 548.009094,566.627319 548.151062,562.738098 
	C548.446838,554.635376 547.978943,546.502197 548.352478,538.405884 
	C548.582825,533.412720 546.574158,531.838745 541.996338,532.355042 
	C540.688110,532.502502 539.232971,532.708435 538.058594,532.289429 
	C536.936829,531.889221 536.092957,530.710266 535.126953,529.873596 
	C536.087830,529.016113 536.984802,527.518372 538.020935,527.414978 
	C541.958496,527.021851 545.940979,527.079285 550.365417,526.833008 
	C554.930115,526.695679 559.070068,526.389099 563.125122,526.842346 
	C564.736023,527.022278 566.158752,528.886292 567.667175,529.983582 
	C566.042358,530.974365 564.519836,532.254822 562.764038,532.874634 
	C561.003662,533.496155 559.010498,533.458374 556.950317,534.103516 
	C556.781311,534.497375 556.780823,534.644958 556.725830,534.297668 
	C556.030762,533.662659 555.390808,533.374939 554.750793,533.087219 
	C554.571594,533.773865 554.392334,534.460449 554.114624,535.361511 
	C553.943848,535.898865 553.997070,536.199463 554.046997,536.901184 
	C553.891602,539.314636 553.887390,541.305359 553.834412,543.294678 
	C553.610046,551.730835 553.371155,560.166565 553.137573,568.602478 
	C553.137573,568.602478 553.263672,568.468018 553.203491,568.398193 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M216.218445,908.004700 
	C220.800247,907.439392 222.278534,909.257751 222.065933,913.384644 
	C221.792068,918.700684 222.191208,924.052734 221.877396,929.364319 
	C221.785110,930.926270 220.401825,933.563232 219.360504,933.692261 
	C216.711975,934.020447 213.497757,935.328979 211.536118,931.463135 
	C210.475006,929.372070 208.297592,927.653687 206.270798,926.292969 
	C203.433121,924.387878 202.738251,917.412231 205.817215,916.148804 
	C209.007751,914.839600 211.062073,913.140869 212.160660,909.827820 
	C212.461700,908.919861 214.530716,908.598145 216.218445,908.004700 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M553.601807,568.691833 
	C553.371155,560.166565 553.610046,551.730835 553.834412,543.294678 
	C553.887390,541.305359 553.891602,539.314636 554.385315,537.197021 
	C555.569092,539.708862 556.285767,542.348328 556.850830,545.333679 
	C556.263733,547.860657 555.828430,550.041748 555.326965,552.553955 
	C559.800415,553.364441 562.679321,552.770203 563.324219,547.947388 
	C564.427368,547.593262 565.216125,547.286865 566.335083,546.987244 
	C567.105591,546.994873 567.545898,546.995728 567.986145,546.996582 
	C567.986145,546.996582 568.004883,546.995117 567.986328,547.368286 
	C567.646851,549.158203 567.325867,550.575012 566.635681,552.024170 
	C564.771057,554.013916 563.275635,555.971191 561.486450,558.312927 
	C556.421021,558.549316 556.304077,558.682495 558.427795,562.558228 
	C559.964050,562.047668 561.492065,561.539856 563.018311,561.428345 
	C563.007812,562.881775 562.999207,563.938843 562.798340,565.016479 
	C562.405151,565.359619 562.204163,565.682129 562.005310,566.332275 
	C562.010132,567.096008 562.012756,567.532288 562.015381,567.968506 
	C559.365662,568.239441 556.715820,568.510315 553.601807,568.691833 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M562.990540,564.995911 
	C562.999207,563.938843 563.007812,562.881775 563.253296,561.217346 
	C566.454529,558.468384 566.860718,555.283508 567.004883,551.991760 
	C567.325867,550.575012 567.646851,549.158203 567.985718,547.377441 
	C568.980225,547.673889 569.956848,548.334290 571.259155,549.427795 
	C570.103943,554.201355 568.456665,558.495728 567.234253,562.907837 
	C566.820251,564.402100 567.480042,566.193970 567.649048,567.849792 
	C569.205933,567.021545 571.289673,566.563293 572.193298,565.276367 
	C573.473083,563.453552 573.566528,560.815918 574.785706,558.925476 
	C575.484802,557.841431 577.407715,557.546631 578.779663,556.896484 
	C579.506287,558.670715 580.232849,560.444824 581.063171,562.562622 
	C579.978943,563.372375 578.660400,563.644958 577.628479,564.341980 
	C574.880493,566.198059 572.263062,568.246582 569.580505,570.200439 
	C566.544312,572.411926 564.156006,571.851440 562.233093,568.227661 
	C562.012756,567.532288 562.010132,567.096008 562.198853,566.194153 
	C562.634827,565.520752 562.834900,565.276550 562.990540,564.995911 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M602.924072,588.297668 
	C607.992126,593.654297 612.981812,599.318970 618.066284,605.288452 
	C618.115723,606.128784 618.070374,606.664124 617.762512,607.599731 
	C617.500000,608.000000 617.000000,608.000000 617.000000,608.000000 
	C613.401672,608.503723 611.315002,606.972290 609.721313,603.653198 
	C608.073486,600.221313 605.852783,596.971313 603.392578,594.055298 
	C601.737549,592.093567 601.105286,590.628296 602.924072,588.297668 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M526.137207,558.897339 
	C526.846436,560.551697 527.843323,562.145996 528.203918,563.873108 
	C528.917236,567.289795 530.781372,568.606567 534.561279,567.937256 
	C530.583313,572.296692 525.491882,571.672791 522.264771,566.819031 
	C520.289185,563.847595 517.593262,561.355164 515.464233,558.318726 
	C516.801453,557.395386 517.895386,556.800964 518.989319,556.206543 
	C519.440430,556.151184 519.891541,556.095764 520.670349,556.426025 
	C521.001099,557.198059 521.004211,557.584290 521.058350,558.234253 
	C521.754761,558.660156 522.400146,558.822327 523.432312,558.982239 
	C524.591797,558.952454 525.364502,558.924927 526.137207,558.897339 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M622.950928,611.217407 
	C625.403625,613.664001 627.760132,616.423157 630.192383,619.532837 
	C625.365051,619.477356 618.148438,614.212646 617.803955,611.134705 
	C619.438843,611.262634 621.146729,611.396301 622.950928,611.217407 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M131.933456,960.652954 
	C127.488213,958.499512 122.975822,955.998962 118.231827,953.248779 
	C123.752861,952.092590 128.791443,954.747681 131.933456,960.652954 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M543.135132,542.326477 
	C544.492004,540.009277 545.729126,539.894043 545.919006,542.675415 
	C546.131897,545.795959 545.988403,548.940796 546.000000,552.536316 
	C545.013000,551.153503 543.723511,549.399414 543.138977,547.435547 
	C542.706848,545.983765 543.193115,544.258728 543.135132,542.326477 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M633.004883,644.002441 
	C629.041016,648.927002 623.462097,648.964539 617.441284,649.000061 
	C617.000000,649.000000 617.000000,648.500000 617.332031,648.102234 
	C619.776062,647.136292 621.888062,646.568115 624.000000,646.000000 
	C625.472534,645.817505 626.945007,645.635010 629.048950,645.170654 
	C630.788513,644.593262 631.896729,644.297852 633.004883,644.002441 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M644.938110,637.014771 
	C646.503357,638.001770 647.735779,639.156250 649.055786,640.633789 
	C646.685608,641.633057 644.227966,642.309326 641.383911,642.991577 
	C642.200195,641.059143 643.402832,639.120728 644.938110,637.014771 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M623.872925,645.659546 
	C621.888062,646.568115 619.776062,647.136292 617.332031,647.852234 
	C617.696289,643.200256 620.194885,642.935791 623.872925,645.659546 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M535.279419,567.922852 
	C534.525696,565.955872 534.110168,563.943420 533.671875,561.542236 
	C534.165588,561.117249 534.682007,561.080994 535.585693,561.031860 
	C536.796143,563.353943 539.473816,565.784973 535.279419,567.922852 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M518.561768,556.087646 
	C517.895386,556.800964 516.801453,557.395386 515.354980,557.994873 
	C514.556885,558.001160 514.111389,558.002319 512.934448,558.000854 
	C511.407318,557.988770 510.611511,557.979370 509.815704,557.969971 
	C512.588501,557.302917 515.361328,556.635864 518.561768,556.087646 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M633.254272,644.001404 
	C631.896729,644.297852 630.788513,644.593262 629.340210,644.944336 
	C630.095032,643.774231 631.190063,642.548462 632.285156,641.322754 
	C632.841553,641.984680 633.397949,642.646545 633.978394,643.653015 
	C634.002441,643.997559 633.503662,644.000366 633.254272,644.001404 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M509.623779,558.218506 
	C510.611511,557.979370 511.407318,557.988770 512.600342,558.001526 
	C511.856812,558.972961 510.716095,559.940979 509.207397,560.891602 
	C509.036926,560.071716 509.234406,559.269409 509.623779,558.218506 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M585.039429,567.317261 
	C586.003601,568.077148 586.972412,569.197571 587.991211,570.654175 
	C587.042114,569.886230 586.043030,568.782043 585.039429,567.317261 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M582.955933,564.556763 
	C583.583679,564.948975 584.256165,565.656555 584.986450,566.667175 
	C584.363098,566.270813 583.681885,565.571472 582.955933,564.556763 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M640.012695,630.366577 
	C640.653870,630.787231 641.317871,631.547424 642.045288,632.613525 
	C641.417664,632.181580 640.726624,631.443848 640.012695,630.366577 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M642.132874,633.207458 
	C642.673279,633.398621 643.264465,633.900757 643.932068,634.699585 
	C643.400208,634.503723 642.791931,634.011108 642.132874,633.207458 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M261.413391,949.040833 
	C261.155334,949.219482 260.901337,949.229126 260.647369,949.238770 
	C260.651001,949.095459 260.654663,948.952148 260.658295,948.808838 
	C260.911346,948.829834 261.164398,948.850891 261.413391,949.040833 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M396.959106,925.413452 
	C396.780579,925.155334 396.770966,924.901306 396.761322,924.647339 
	C396.904663,924.651001 397.048004,924.654724 397.191345,924.658447 
	C397.170258,924.911438 397.149139,925.164429 396.959106,925.413452 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M617.106934,608.112183 
	C617.000000,608.000000 617.500000,608.000000 617.750000,608.000000 
	C618.000000,608.000000 617.644470,608.279480 617.644470,608.279480 
	C617.644470,608.279480 617.213928,608.224365 617.106934,608.112183 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M508.460480,561.773193 
	C508.197296,561.601807 508.256958,561.314880 508.700531,560.954834 
	C508.869812,561.128662 508.769440,561.387268 508.460480,561.773193 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M423.434204,495.806671 
	C418.047211,496.137268 412.664276,496.610291 407.272247,496.764160 
	C399.712921,496.979919 392.136383,496.747284 384.587708,497.120483 
	C380.357178,497.329620 375.196808,497.075623 372.178497,499.333588 
	C367.145477,503.098755 362.230530,502.713562 356.817719,502.447845 
	C355.543518,502.385254 354.200470,503.725342 352.905212,504.411621 
	C350.378601,500.795563 343.823578,500.200500 337.832520,502.904663 
	C342.322266,505.082825 347.664124,506.421600 351.089935,509.739044 
	C353.753540,512.318298 355.905426,514.139465 361.076385,513.519653 
	C357.428162,516.341003 355.067657,518.166504 352.707153,519.992004 
	C352.989136,520.500732 353.271118,521.009521 353.553101,521.518250 
	C355.964294,520.894409 358.375519,520.270569 361.038300,519.581604 
	C361.038300,521.486938 361.038300,523.362366 361.033936,525.889282 
	C361.033752,526.715759 361.037903,526.890808 360.795868,527.279419 
	C360.237274,528.059998 359.924866,528.626892 359.444763,529.543091 
	C359.371185,534.841980 359.465363,539.791626 359.633728,545.217651 
	C359.712006,550.167847 359.716156,554.641785 359.561615,559.317383 
	C359.268097,560.014343 359.133240,560.509583 358.669556,560.888733 
	C357.873596,558.645691 357.406525,556.518738 356.925201,554.326904 
	C356.231171,554.611877 355.750305,554.660034 355.672302,554.863037 
	C353.401154,560.772888 356.812866,569.003418 362.462219,571.441284 
	C363.364380,571.830505 364.156830,572.473999 365.000000,573.000000 
	C364.810364,573.837158 364.620728,574.674316 363.840515,575.755737 
	C362.500000,576.000000 361.750000,576.000000 361.000000,576.000000 
	C357.578857,573.997131 354.157745,571.994202 350.485168,569.701477 
	C350.428375,568.828613 350.623016,568.245605 350.852478,567.243530 
	C351.010132,564.844604 351.132996,562.864807 351.448059,560.536133 
	C351.501953,554.496948 351.363617,548.806641 351.148376,542.697083 
	C351.122589,541.146790 351.173706,540.015686 351.420227,538.558228 
	C351.487671,535.184570 351.359650,532.137268 351.102966,528.793945 
	C350.820160,528.097351 350.665985,527.696777 350.811127,527.119995 
	C351.382690,525.902222 351.654907,524.860596 351.927124,523.819031 
	C351.272919,524.241089 350.618713,524.663147 349.584656,525.103638 
	C345.254883,525.458801 343.815002,527.687256 343.984619,531.528259 
	C344.175598,535.852478 344.037476,540.191345 343.590698,544.606750 
	C340.077606,544.640930 337.016266,544.592224 333.867798,544.291199 
	C332.866730,544.208008 331.952759,544.377075 330.696808,544.613953 
	C329.893402,544.644348 329.431915,544.606995 328.884644,544.302917 
	C327.654968,543.061340 326.511108,542.086426 325.284851,540.662964 
	C325.252258,538.090759 325.302002,535.967102 325.518127,533.576843 
	C325.510010,531.536560 325.335480,529.762939 324.883240,527.865417 
	C324.066589,527.459351 323.527679,527.177063 322.869385,526.576538 
	C318.964325,524.988342 318.262512,525.788086 317.987823,531.036804 
	C317.360504,543.022522 319.709900,555.173279 315.655273,567.021301 
	C313.629669,569.399719 311.964264,571.746277 309.905396,574.647217 
	C308.114868,573.027649 305.833099,570.963684 303.551300,568.899780 
	C303.147278,569.231689 302.743256,569.563660 302.339203,569.895630 
	C302.919434,572.026306 303.499664,574.157043 304.321686,577.175537 
	C301.957489,576.735840 299.979340,576.367920 298.001221,576.000000 
	C297.747223,575.501526 297.493256,575.003113 297.042603,573.902832 
	C293.154816,570.640564 290.029785,567.811218 284.421295,569.263672 
	C278.795135,570.720764 272.631378,570.083923 266.706329,570.419739 
	C265.463776,570.490173 264.234802,570.800354 262.999573,571.000000 
	C261.708984,572.110046 260.418396,573.220154 259.015900,574.948547 
	C258.602295,576.045410 258.300537,576.523865 257.998779,577.002380 
	C257.198334,576.381104 256.397888,575.759827 255.298691,575.069275 
	C254.769730,574.587952 254.447083,573.790100 254.322708,573.819885 
	C250.501221,574.735291 248.158691,572.259888 245.576752,569.844238 
	C246.644180,568.713928 247.797882,567.868408 249.257385,566.876526 
	C251.636841,565.483215 253.710480,564.236328 255.851852,563.228210 
	C256.120850,563.906677 256.322144,564.346313 256.602356,565.183228 
	C256.786102,566.383728 256.890900,567.186951 256.872009,568.183960 
	C257.814056,568.358582 258.879822,568.339417 260.241150,568.158325 
	C261.101776,567.322144 261.666809,566.647827 262.443298,565.961792 
	C262.654755,565.949951 263.073730,565.885498 263.175842,565.804443 
	C263.404877,565.460266 263.342194,565.238708 263.072510,564.714661 
	C263.061554,563.911682 263.067993,563.452698 263.299500,562.648560 
	C263.685760,556.534119 263.846954,550.764893 264.388977,545.000122 
	C266.336060,545.090332 267.902313,545.176086 269.018311,545.237183 
	C269.018311,541.407837 268.887451,537.963928 269.092102,534.540100 
	C269.160126,533.401794 269.951111,531.450867 270.646362,531.339722 
	C276.902985,530.339294 279.184845,524.778931 283.258026,521.003418 
	C283.684021,521.000977 283.854431,521.000000 283.964661,521.362549 
	C283.605377,523.406067 283.306244,525.086121 282.634125,526.855591 
	C280.898254,528.740234 279.039795,530.343323 278.256500,532.363342 
	C274.440186,542.204712 270.911346,552.157532 267.039917,562.160095 
	C266.871613,562.841125 266.944153,563.433044 267.074463,564.323975 
	C266.861847,565.378967 266.591492,566.134827 266.144714,567.004517 
	C265.858765,567.359924 265.749207,567.601562 265.639648,567.843140 
	C265.879944,567.733948 266.120239,567.624817 266.750671,567.610962 
	C270.767548,568.833374 272.777069,567.483948 273.488708,563.774292 
	C273.764008,562.339172 274.225739,560.788269 275.084442,559.655273 
	C276.146240,558.254272 277.727081,556.213501 279.052734,556.236511 
	C283.303406,556.309937 287.549347,557.154236 291.761322,557.902588 
	C292.055817,557.954956 292.057831,559.653442 291.850952,560.745972 
	C290.360779,561.756165 289.215485,562.606812 288.070221,563.457458 
	C288.616699,563.967468 289.163177,564.477539 289.709656,564.987549 
	C290.556458,563.538879 291.403229,562.090210 292.589661,560.812805 
	C294.825104,563.978455 294.187012,570.124512 301.355743,567.216064 
	C297.168671,554.796082 293.023651,542.479248 288.852325,530.171265 
	C288.541046,529.252869 288.041168,528.398438 287.717316,527.054810 
	C289.913757,518.511353 292.224640,510.471588 293.998566,502.315033 
	C294.490692,500.052246 293.389404,497.442902 293.406677,494.993805 
	C295.525787,495.329468 297.257324,495.663910 299.117554,496.261292 
	C299.779816,496.430939 300.771667,496.361816 300.778076,496.240753 
	C300.870697,494.484100 300.831299,492.720459 300.861633,490.494751 
	C300.964355,484.357941 301.028931,478.684875 301.447845,473.167358 
	C302.201355,475.005371 302.880554,476.677216 302.944885,478.372406 
	C303.109253,482.704529 302.999664,487.046997 302.999664,490.968628 
	C304.670776,490.226624 305.988129,489.253815 307.402191,489.083282 
	C312.569000,488.460327 312.950775,489.083740 312.635071,497.011108 
	C310.486450,497.388977 308.693512,497.625732 306.999451,498.170166 
	C306.580780,498.304688 306.483337,499.438721 306.125671,500.532104 
	C305.127930,506.148163 309.383148,505.348633 311.712433,505.012604 
	C320.071472,503.806671 328.567596,502.702362 336.571716,500.168976 
	C342.354340,498.338745 347.818787,497.729034 353.826965,497.493774 
	C366.717987,496.988892 379.536774,494.850952 392.414246,493.710052 
	C400.480988,492.995331 408.601929,492.620148 416.695801,492.659271 
	C418.946930,492.670135 421.188324,494.701996 423.434204,495.806671 
M305.190094,563.209595 
	C305.437866,565.160156 304.308929,567.864990 307.919098,567.920288 
	C311.704895,567.978271 311.026733,565.220093 311.035889,563.000244 
	C311.077789,552.834351 311.101685,542.667786 311.020782,532.502380 
	C311.003937,530.386292 311.976685,527.431763 308.191010,527.298828 
	C304.272186,527.161133 305.260498,530.157532 305.245697,532.271362 
	C305.175812,542.270264 305.218933,552.269958 305.190094,563.209595 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M256.995728,567.990112 
	C256.890900,567.186951 256.786102,566.383728 256.694641,564.816772 
	C256.643677,563.039124 256.579346,562.025391 256.578156,560.611206 
	C256.575836,559.139282 256.510376,558.067810 256.552429,556.540405 
	C256.659912,550.223328 256.659912,544.362122 256.659912,538.500977 
	C256.257050,538.422974 255.854187,538.345032 255.451324,538.267029 
	C254.160812,542.452209 252.870316,546.637329 251.413589,551.064453 
	C251.145203,551.881042 251.043030,552.455627 250.683319,553.256226 
	C250.131119,554.586548 249.836441,555.690735 249.378662,557.035889 
	C249.085327,557.840820 248.955109,558.404846 248.580505,559.225342 
	C246.419037,563.157166 247.122604,569.394775 239.948700,567.867432 
	C239.883820,567.740540 239.602493,567.695251 239.552429,567.494141 
	C239.502380,567.293030 239.176193,567.037292 239.140076,566.735596 
	C238.844788,565.891296 238.585617,565.348816 238.327042,564.595459 
	C238.327637,564.384705 238.059357,564.059387 238.063538,563.756714 
	C237.843597,562.899170 237.619476,562.344238 237.352661,561.529602 
	C237.052933,561.052490 236.795929,560.835144 236.634674,560.548340 
	C236.730423,560.478943 236.625870,560.266846 236.613068,560.256653 
	C236.600250,560.246460 236.638016,560.219360 236.648834,559.899475 
	C236.553726,559.394348 236.447830,559.209045 236.307831,558.707397 
	C235.965378,558.133606 235.657043,557.876343 235.495178,557.645020 
	C235.641617,557.670959 235.699493,557.379150 235.721680,557.289001 
	C235.743851,557.198853 235.640839,557.044373 235.623596,556.748474 
	C235.503937,556.283630 235.401489,556.114685 235.304688,555.548584 
	C233.564362,549.905701 231.818390,544.659973 230.072433,539.414185 
	C229.773514,539.519775 229.474609,539.625366 229.175705,539.730896 
	C229.175705,543.151489 229.175705,546.572021 229.092651,550.336914 
	C229.058502,551.147766 229.107422,551.614441 229.051239,552.455933 
	C229.013336,553.859131 229.080521,554.887573 229.056625,556.348145 
	C229.007980,558.540771 229.050415,560.301147 228.987396,562.440063 
	C228.977417,563.851990 229.072906,564.885437 229.060455,566.185425 
	C228.918625,566.830627 228.884735,567.209290 228.451141,567.665039 
	C226.702942,567.827454 225.354462,567.912903 224.005966,567.998352 
	C223.839554,567.248169 223.673141,566.498047 223.522797,564.971558 
	C223.515503,563.311340 223.492157,562.427490 223.585129,561.163635 
	C223.620544,558.354004 223.539658,555.924438 223.528961,553.000854 
	C223.546448,551.181091 223.493759,549.855225 223.562500,548.130981 
	C223.769745,541.126953 223.855560,534.521301 224.184418,527.755981 
	C224.576263,527.472168 224.725113,527.348022 225.083755,527.345154 
	C225.723190,527.368469 226.152863,527.270508 226.986298,527.209595 
	C227.921112,527.162842 228.452118,527.078979 229.264587,527.124023 
	C229.951614,527.317505 230.357178,527.382141 230.850861,527.732666 
	C231.521103,529.042603 232.103241,530.066711 232.709595,531.489502 
	C235.701248,541.220215 238.668640,550.552185 241.636047,559.884216 
	C245.198868,552.417358 247.288452,545.004761 250.185898,537.922668 
	C252.102310,533.238464 251.476349,526.028259 259.853455,526.431152 
	C260.701019,528.460327 261.755646,530.160278 261.802429,531.887451 
	C262.057495,541.302612 262.064423,550.724487 262.028320,560.543152 
	C262.010590,562.619385 262.121246,564.296448 262.231873,565.973572 
	C261.666809,566.647827 261.101776,567.322144 259.889160,568.030640 
	C258.492981,568.039978 257.744354,568.015076 256.995728,567.990112 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M228.850845,567.588013 
	C228.884735,567.209290 228.918625,566.830627 229.245972,565.943359 
	C229.390564,564.310425 229.241699,563.186035 229.092850,562.061584 
	C229.050415,560.301147 229.007980,558.540771 229.245850,556.112305 
	C229.402893,554.323181 229.279617,553.202087 229.156342,552.081055 
	C229.107422,551.614441 229.058502,551.147766 229.446411,550.374573 
	C231.688492,552.027222 233.493774,553.986450 235.299042,555.945679 
	C235.401489,556.114685 235.503937,556.283630 235.438858,556.787842 
	C235.414062,557.208374 235.556793,557.293762 235.699493,557.379150 
	C235.699493,557.379150 235.641617,557.670959 235.563446,557.939209 
	C235.770828,558.479614 236.056381,558.751709 236.341934,559.023804 
	C236.447830,559.209045 236.553726,559.394348 236.490631,559.905884 
	C236.321640,560.232239 236.223755,560.407349 236.223755,560.407349 
	C236.223755,560.407349 236.625870,560.266846 236.625870,560.266846 
	C236.625870,560.266846 236.730423,560.478943 236.655701,560.816406 
	C236.852432,561.365662 237.123886,561.577515 237.395355,561.789307 
	C237.619476,562.344238 237.843597,562.899170 237.951736,563.857300 
	C237.778839,564.611450 237.942398,564.793335 238.326447,564.806274 
	C238.585617,565.348816 238.844788,565.891296 238.926147,566.759277 
	C238.612305,567.279297 238.476242,567.473938 238.340195,567.668579 
	C238.760956,567.677490 239.181732,567.686401 239.602493,567.695312 
	C239.602493,567.695251 239.883820,567.740540 239.981232,568.153503 
	C240.387772,569.038879 240.696884,569.511292 241.005997,569.983704 
	C240.095490,572.297913 239.184998,574.612061 238.274506,576.926270 
	C237.857254,576.955994 237.440002,576.985718 237.022766,577.015381 
	C236.251373,572.763977 234.239059,569.969666 229.379150,569.618042 
	C228.932251,568.658691 228.891556,568.123352 228.850845,567.588013 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M223.441071,548.529358 
	C223.493759,549.855225 223.546448,551.181091 223.400711,553.292114 
	C223.291122,556.566101 223.379974,559.054871 223.468826,561.543640 
	C223.492157,562.427490 223.515503,563.311340 223.387115,564.612061 
	C222.901260,564.797668 222.567139,564.566406 222.117905,564.162537 
	C222.005890,552.315308 222.009018,540.640747 222.247589,528.495239 
	C222.483047,528.024231 222.951309,528.022644 222.972198,528.488525 
	C223.142410,535.479370 223.291733,542.004333 223.441071,548.529358 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M241.403076,569.982422 
	C240.696884,569.511292 240.387772,569.038879 240.046127,568.280396 
	C247.122604,569.394775 246.419037,563.157166 248.827560,559.472656 
	C249.196533,561.983398 249.074051,564.503174 248.951569,567.022888 
	C247.797882,567.868408 246.644180,568.713928 245.232697,569.770752 
	C243.916656,569.981750 242.858414,569.981445 241.403076,569.982422 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M227.214172,575.464783 
	C228.643875,575.267090 229.840530,575.267090 231.037186,575.267090 
	C230.961945,575.819702 230.886688,576.372314 230.811447,576.924927 
	C229.534683,576.504089 228.257904,576.083313 227.214172,575.464783 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M228.451141,567.665039 
	C228.891556,568.123352 228.932251,568.658691 228.984039,569.595764 
	C227.933243,570.000244 226.871353,570.002991 225.210419,569.800903 
	C224.083191,569.395630 223.555023,569.195129 223.026855,568.994629 
	C223.180939,568.716736 223.377151,568.472717 223.810730,568.130493 
	C225.354462,567.912903 226.702942,567.827454 228.451141,567.665039 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M223.032684,569.296814 
	C223.555023,569.195129 224.083191,569.395630 224.812103,569.801025 
	C224.616806,570.415710 224.220749,570.825439 223.824707,571.235229 
	C223.562637,570.689819 223.300552,570.144409 223.032684,569.296814 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M76.096664,487.420837 
	C76.682693,485.316803 77.824677,483.648407 77.917023,481.923859 
	C78.311981,474.547943 80.663414,471.956177 87.955063,472.051300 
	C92.244568,472.107239 96.071381,472.402924 97.093498,466.567627 
	C97.300186,465.387634 101.071327,464.320831 103.284409,464.113983 
	C107.404442,463.728882 111.945778,465.230927 113.077560,458.752014 
	C113.299225,457.483063 117.390083,456.435638 119.807419,456.109924 
	C123.536812,455.607391 127.958511,457.238281 128.875290,451.205048 
	C129.017975,450.266022 131.797958,449.221893 133.423416,449.101105 
	C140.694977,448.560638 147.987473,448.231537 155.277985,448.051056 
	C158.164291,447.979614 159.773499,447.463654 160.187012,444.049835 
	C160.735779,439.519470 163.281036,438.422546 167.715729,439.840942 
	C169.429016,440.388947 171.242950,440.621277 173.009735,441.003479 
	C174.121964,441.244110 175.528992,441.145691 176.296631,441.797058 
	C183.802643,448.166046 191.114090,454.766937 198.695999,461.041931 
	C200.481186,462.519409 203.068283,463.716217 205.300690,463.740570 
	C206.992340,463.759033 208.706573,461.709290 210.410782,460.579895 
	C209.104370,459.098724 207.894714,457.515350 206.466339,456.162964 
	C204.788803,454.574738 202.921310,453.187164 201.421875,451.419952 
	C211.999405,455.989716 222.335541,460.761078 232.572327,465.736755 
	C246.387085,472.451569 260.162933,479.249420 273.872528,486.175751 
	C277.730560,488.124878 281.298035,490.649139 284.996094,493.388367 
	C284.967865,500.914886 284.942261,507.962677 284.518555,515.007874 
	C276.745667,512.697205 269.371094,515.823669 261.621857,515.005249 
	C260.498199,515.002869 259.749115,515.001465 259.000000,515.000000 
	C253.753357,511.057953 251.193939,503.694031 243.519699,502.843292 
	C240.589676,496.689148 233.268387,497.832184 228.964737,494.070862 
	C226.867462,492.237976 225.309357,489.788177 224.046173,488.266754 
	C221.388931,490.663452 219.558578,492.314331 217.365326,493.583252 
	C217.575165,491.753998 218.296417,490.345551 218.687485,488.850708 
	C219.353378,486.305359 220.803391,483.400391 220.125961,481.200073 
	C219.096588,477.856537 216.295883,475.427063 212.081192,476.007324 
	C205.697601,476.886169 199.276077,477.521393 192.920532,478.564056 
	C184.882797,479.882721 176.881943,481.441101 168.896896,483.053192 
	C167.294403,483.376740 164.877029,484.116821 164.556625,485.182404 
	C164.177780,486.442261 165.442673,488.414093 166.385300,489.814636 
	C169.832886,494.937134 173.442780,499.950378 176.991531,505.004822 
	C175.634537,504.484131 174.312271,503.824341 172.912521,503.474945 
	C170.740784,502.932800 168.435699,502.015259 166.330887,502.304138 
	C161.972092,502.902405 156.023697,502.779358 153.755676,505.487610 
	C149.047119,511.110077 142.853455,508.974518 137.646835,511.041595 
	C136.673660,511.427948 135.395294,512.382507 135.234558,513.254883 
	C134.378891,517.899414 131.243958,518.200684 127.436333,518.057129 
	C123.296478,517.901123 119.427254,518.486267 118.168533,523.586548 
	C117.910233,524.633118 116.457474,525.377808 115.572052,526.278198 
	C113.855316,528.023926 111.077972,529.617554 110.777306,531.585449 
	C110.533180,533.183228 113.300667,535.212036 114.668182,537.096313 
	C115.477402,538.211304 116.767937,539.450806 116.705429,540.570862 
	C116.657646,541.427063 114.827034,542.183716 113.401978,542.993530 
	C113.262527,539.977966 111.807465,538.823425 108.864632,538.976990 
	C101.929878,539.339111 94.967972,539.349792 88.057137,539.958069 
	C79.648697,540.698181 78.140831,539.445923 77.978767,530.942261 
	C77.732719,518.031128 77.347198,505.122345 76.954903,492.214569 
	C76.910294,490.746643 76.441696,489.291626 76.096664,487.420837 
M134.654449,507.581604 
	C136.191605,506.269989 138.418121,505.245850 139.111725,503.582489 
	C140.286453,500.765320 142.038010,500.367554 144.722534,499.811798 
	C147.900467,499.153900 151.237610,497.590790 153.621643,495.440002 
	C154.920303,494.268402 155.282623,490.604370 154.411652,488.921082 
	C153.650787,487.450623 150.545517,486.460052 148.522842,486.534271 
	C144.763367,486.672180 141.059860,487.921234 137.292984,488.375427 
	C132.448380,488.959564 128.029907,489.725342 124.785919,494.209412 
	C123.576027,495.881805 120.222305,495.854462 118.120079,497.074280 
	C116.223267,498.174927 114.708954,499.930084 113.010223,501.378143 
	C111.571495,502.604523 110.263527,504.091522 108.630791,504.963348 
	C103.261215,507.830536 97.874535,510.709076 91.627480,511.350769 
	C90.805847,511.435150 90.073441,512.530090 89.328186,513.189148 
	C87.254807,515.022827 84.382835,516.504944 83.338394,518.810852 
	C82.038544,521.680664 81.394592,525.417297 82.034821,528.448547 
	C82.794815,532.046814 85.952065,530.740601 88.607590,529.821411 
	C90.437355,529.188110 92.571213,529.470032 94.448265,528.925964 
	C96.230980,528.409180 97.935730,527.478088 99.528008,526.492188 
	C100.999031,525.581299 102.279564,524.361206 103.636177,523.267578 
	C104.744629,522.374023 106.566986,521.630737 106.815559,520.535400 
	C107.795113,516.219299 110.628357,515.771606 114.291122,515.950500 
	C118.655426,516.163574 122.584000,515.470886 123.968658,510.204132 
	C124.220688,509.245514 126.108955,508.346741 127.371941,508.107697 
	C129.470062,507.710632 131.673569,507.870544 134.654449,507.581604 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M176.995728,505.002686 
	C173.442780,499.950378 169.832886,494.937134 166.385300,489.814636 
	C165.442673,488.414093 164.177780,486.442261 164.556625,485.182404 
	C164.877029,484.116821 167.294403,483.376740 168.896896,483.053192 
	C176.881943,481.441101 184.882797,479.882721 192.920532,478.564056 
	C199.276077,477.521393 205.697601,476.886169 212.081192,476.007324 
	C216.295883,475.427063 219.096588,477.856537 220.125961,481.200073 
	C220.803391,483.400391 219.353378,486.305359 218.687485,488.850708 
	C218.296417,490.345551 217.575165,491.753998 217.001526,493.600647 
	C217.000610,494.000000 217.000000,494.000000 216.669052,494.108093 
	C213.230545,499.811462 210.122986,505.406769 207.015427,511.002075 
	C204.215500,516.605469 201.869019,522.508057 198.443787,527.698853 
	C196.423401,530.760620 194.586487,527.447021 193.224319,525.785706 
	C189.609879,521.377502 186.077591,516.901978 182.207108,511.914246 
	C181.268814,510.581451 180.636047,509.787659 180.003296,508.993866 
	C179.804565,508.463409 179.605850,507.932953 179.125916,506.903717 
	C178.228668,505.939331 177.612610,505.473755 176.996552,505.008179 
	C176.996552,505.008179 176.999939,505.000549 176.995728,505.002686 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M222.951309,528.022644 
	C222.951309,528.022644 222.483047,528.024231 222.249146,528.028992 
	C220.590805,527.897583 219.166382,527.761414 217.566116,527.608459 
	C219.838043,518.706421 226.131165,514.522400 233.691971,511.835114 
	C235.059357,511.349060 236.869003,512.224304 238.481018,512.279175 
	C240.396317,512.344299 242.318390,512.174377 244.238007,512.155884 
	C246.943787,512.129761 249.649994,512.149170 252.356018,512.149170 
	C252.398621,512.660889 252.441208,513.172546 252.483810,513.684265 
	C250.655457,515.021545 248.827103,516.358765 246.615524,517.976379 
	C248.645721,519.855347 250.248932,521.191528 251.674591,522.695923 
	C253.468948,524.589294 254.936737,525.167297 256.985687,522.813721 
	C257.892242,521.772339 259.773834,521.579712 261.939392,521.001038 
	C263.109650,521.001831 263.553284,521.003357 264.088806,521.330078 
	C268.932953,526.815186 268.921112,527.475769 264.007385,530.590942 
	C264.321442,526.882996 262.737000,525.654236 259.595367,526.114441 
	C251.476349,526.028259 252.102310,533.238464 250.185898,537.922668 
	C247.288452,545.004761 245.198868,552.417358 241.636047,559.884216 
	C238.668640,550.552185 235.701248,541.220215 232.997925,531.353394 
	C233.833939,530.212036 234.405853,529.605530 235.168488,528.764038 
	C234.524200,523.244934 238.781815,524.448608 241.789841,524.060303 
	C241.691635,523.525574 241.593445,522.990784 241.495239,522.456055 
	C236.314011,522.726318 231.132797,522.996643 224.406631,523.347534 
	C226.833572,524.765747 227.898026,525.387756 228.621140,526.010864 
	C227.199188,526.260986 226.118576,526.509949 224.982025,526.869873 
	C224.926086,526.980774 224.873978,527.223816 224.873978,527.223816 
	C224.725113,527.348022 224.576263,527.472168 223.934814,527.764771 
	C223.442184,527.933289 222.951309,528.022644 222.951309,528.022644 
M243.302414,540.950684 
	C241.854309,539.593567 240.406204,538.236511 238.955658,536.877075 
	C237.389816,539.573669 240.204544,547.651978 243.541733,549.945984 
	C243.695877,546.940857 243.848236,543.970459 244.577393,540.840576 
	C244.918945,540.223328 245.260513,539.606079 245.602066,538.988831 
	C245.308273,538.864502 245.014481,538.740173 244.720703,538.615784 
	C244.480255,539.410522 244.239807,540.205261 243.302414,540.950684 
M232.938660,516.248840 
	C232.070724,516.893799 231.202789,517.538696 230.334854,518.183594 
	C230.612823,518.730896 230.890808,519.278137 231.168793,519.825439 
	C232.627991,519.113647 234.087189,518.401917 235.546402,517.690125 
	C234.898590,517.176941 234.250763,516.663757 232.938660,516.248840 
M250.964478,527.488708 
	C250.402893,526.993774 249.841293,526.498840 249.279694,526.003906 
	C249.345612,527.118408 249.411530,528.232971 249.477478,529.347473 
	C249.979095,528.925110 250.480713,528.502747 250.964478,527.488708 
M240.087830,533.906860 
	C240.218735,533.217224 240.349655,532.527649 240.480560,531.838013 
	C240.181427,531.809265 239.882294,531.780518 239.583160,531.751770 
	C239.583160,532.479919 239.583160,533.208069 240.087830,533.906860 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M46.052948,668.341492 
	C47.216839,665.389526 48.052715,662.638855 49.603256,660.378418 
	C50.424236,659.181641 52.531612,658.213684 53.975822,658.315186 
	C55.034290,658.389587 56.568287,660.256226 56.811615,661.530090 
	C57.272015,663.940430 57.061249,666.505249 56.907646,668.992188 
	C56.853542,669.868164 56.237026,671.388916 55.730457,671.452942 
	C50.355202,672.132080 47.940125,675.206848 48.936066,680.916626 
	C44.452080,680.075989 43.654327,678.106995 44.546101,674.984863 
	C45.132362,672.932373 45.536350,670.827759 46.052948,668.341492 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M263.996918,521.004883 
	C263.553284,521.003357 263.109650,521.001831 262.333313,520.999512 
	C262.000122,519.931946 261.999634,518.865112 261.999573,517.399170 
	C262.000000,517.000000 261.998779,516.997559 262.420593,516.998535 
	C266.530487,517.755127 270.251678,518.387878 273.880005,519.365479 
	C274.647369,519.572266 275.723602,521.213623 275.541321,521.895752 
	C274.911255,524.253601 273.788239,526.479614 272.638062,529.278564 
	C268.708862,521.514160 268.708862,521.514160 263.996918,521.004883 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M207.352203,510.937256 
	C210.122986,505.406769 213.230545,499.811462 216.669357,494.108337 
	C216.423706,501.019104 214.545853,507.319733 207.352203,510.937256 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M214.989807,550.601990 
	C214.806229,551.691895 214.629074,552.415894 214.451904,553.139832 
	C214.008362,553.108215 213.564804,553.076599 213.121246,553.044983 
	C213.121246,550.660339 213.121246,548.275635 213.121246,545.890991 
	C213.611649,545.860107 214.102036,545.829224 214.592438,545.798340 
	C214.727020,547.277588 214.861618,548.756836 214.989807,550.601990 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M258.722290,515.076538 
	C259.749115,515.001465 260.498199,515.002869 261.622437,515.337097 
	C261.997986,516.112488 261.998383,516.554993 261.998779,516.997559 
	C261.998779,516.997559 262.000000,517.000000 261.998779,516.998779 
	C260.841827,516.327637 257.346252,520.164917 258.722290,515.076538 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M180.021271,509.332825 
	C180.636047,509.787659 181.268814,510.581451 181.952927,511.687317 
	C181.349274,511.223511 180.694260,510.447662 180.021271,509.332825 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M177.074982,505.304688 
	C177.612610,505.473755 178.228668,505.939331 178.920456,506.703094 
	C178.381927,506.534576 177.767670,506.067902 177.074982,505.304688 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M165.386932,193.317413 
	C164.816330,196.902985 163.948700,200.564377 162.820343,204.904144 
	C160.371521,214.201370 158.183411,222.820236 155.890549,231.733856 
	C155.296188,233.081390 154.806549,234.134140 154.316925,235.186890 
	C154.600845,235.518723 154.884781,235.850555 155.168701,236.182388 
	C164.301651,233.024277 173.434601,229.866150 182.968597,226.735535 
	C188.145279,225.483932 192.996796,224.428436 197.668182,222.842377 
	C200.492126,221.883575 200.862442,223.185715 201.051910,225.594009 
	C201.037552,226.307816 201.023651,226.719269 200.668945,227.166809 
	C199.226440,227.508087 198.124756,227.813263 196.735352,228.141327 
	C195.973465,228.466324 195.499298,228.768402 194.659836,229.121719 
	C181.248901,234.042877 168.203278,238.912796 154.483276,244.034454 
	C159.619186,249.941360 165.304565,256.480225 171.082764,263.346008 
	C173.767044,267.448242 176.358505,271.223602 178.718353,275.228973 
	C177.535492,278.877563 177.330460,282.780151 175.488129,285.620819 
	C170.642471,293.092194 167.876999,302.096008 160.231079,307.581299 
	C153.893250,312.128143 147.433197,316.504669 140.953720,320.611877 
	C139.441940,317.588989 138.003738,314.910889 136.565536,312.232788 
	C136.069702,312.209534 135.573868,312.186279 135.078049,312.162994 
	C133.091248,317.784485 131.104462,323.405945 129.082367,329.433655 
	C127.351089,336.890289 125.655106,343.940674 123.853119,351.332458 
	C122.884193,354.461670 122.021271,357.249542 121.079559,360.414001 
	C120.317451,363.516876 119.634117,366.243134 118.881943,369.349274 
	C118.449120,370.984375 118.052040,372.231110 117.726524,373.496246 
	C115.725105,381.274628 113.739861,389.057129 111.645050,397.106995 
	C111.505646,397.564575 111.470413,397.753418 111.253967,398.149170 
	C110.975677,398.886230 110.878601,399.416443 110.735405,400.118591 
	C110.689285,400.290497 110.664673,400.645599 110.466751,400.871826 
	C110.095169,401.667664 109.921524,402.237274 109.515793,403.095062 
	C107.055023,411.866791 104.826340,420.350281 102.597656,428.833801 
	C101.536682,432.992371 100.594078,437.185730 99.383797,441.300385 
	C97.974083,446.093109 96.330177,450.816956 94.505676,455.742432 
	C94.583275,430.746979 94.962769,405.580078 95.295677,380.412506 
	C95.500031,364.963928 95.633080,349.514374 95.733734,333.612122 
	C105.967812,312.163086 116.258759,291.163544 126.566772,270.172333 
	C138.700912,245.462372 150.842453,220.756027 163.019196,196.067078 
	C163.507828,195.076385 164.390701,194.280121 165.386932,193.317413 
M140.018845,306.397888 
	C144.372543,302.078094 149.360535,298.215729 152.899704,293.308502 
	C156.367905,288.499664 159.953201,282.813080 160.611481,277.175964 
	C161.495941,269.601959 160.010178,261.638947 158.701050,253.986771 
	C158.287399,251.568771 155.177490,249.612030 153.301529,247.444199 
	C151.866486,249.461319 149.793457,251.283859 149.108459,253.529755 
	C146.357437,262.549530 143.915985,271.669891 141.594345,280.812561 
	C139.758301,288.042938 138.147980,295.337158 136.699936,302.654999 
	C136.333069,304.508972 135.136292,307.827271 140.018845,306.397888 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M138.565796,173.149475 
	C138.262802,181.058395 137.626343,189.080399 136.996429,197.550140 
	C135.570938,198.662277 134.138901,199.326660 132.346313,199.989319 
	C131.223419,198.878098 130.174240,196.698593 129.740433,196.814880 
	C117.447479,200.109802 110.504829,192.191772 103.200333,184.266113 
	C102.732124,183.508896 102.485809,183.002518 102.123398,182.239136 
	C104.162842,179.331558 106.142418,176.510498 108.510529,174.065811 
	C113.444893,168.971878 120.064232,171.526825 125.927788,170.673813 
	C126.564697,170.581161 127.538567,171.742996 128.119827,172.503540 
	C131.652863,177.126221 134.092896,177.333237 138.565796,173.149475 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M284.998779,492.909607 
	C281.298035,490.649139 277.730560,488.124878 273.872528,486.175751 
	C260.162933,479.249420 246.387085,472.451569 232.572327,465.736755 
	C222.335541,460.761078 211.999405,455.989716 201.350647,451.068237 
	C190.568481,446.049011 180.143204,441.088867 169.072571,435.928192 
	C167.284042,435.152618 166.140869,434.577606 164.997696,434.002594 
	C160.540390,431.707184 156.098450,429.381195 151.617493,427.132935 
	C150.038437,426.340637 148.363968,425.738464 146.094513,424.809052 
	C139.972565,421.718872 134.489151,418.869141 129.431580,416.011902 
	C132.238968,415.652710 134.620499,415.301025 137.470535,414.942993 
	C153.628052,414.637421 169.317093,414.338165 185.471909,414.064941 
	C190.960297,414.109711 195.982895,414.128479 201.359711,414.349792 
	C209.475952,414.360992 217.237946,414.169708 225.468613,413.976379 
	C243.556137,414.044647 261.175018,414.114929 278.881805,414.563354 
	C285.600861,440.321960 292.231995,465.702423 298.857117,491.493378 
	C298.897034,493.268707 298.942932,494.633514 298.988861,495.998352 
	C297.257324,495.663910 295.525787,495.329468 293.152008,494.974243 
	C292.340210,494.938934 292.170685,494.924408 291.897339,494.601288 
	C290.853668,493.866028 289.913879,493.439392 288.576477,493.006317 
	C287.118866,492.969788 286.058838,492.939697 284.998779,492.909607 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M129.005737,416.019440 
	C134.489151,418.869141 139.972565,421.718872 145.720871,424.797272 
	C144.606323,426.015869 143.267410,427.786224 141.840363,427.860413 
	C135.712753,428.178864 129.549118,427.784576 123.418594,428.073639 
	C119.200500,428.272583 115.029755,429.372650 110.809639,429.671295 
	C108.393143,429.842285 105.920219,429.215820 103.035179,428.891113 
	C104.826340,420.350281 107.055023,411.866791 109.778046,403.388062 
	C110.682632,407.523376 113.575569,408.677368 117.079964,408.895752 
	C119.862175,409.069153 122.663147,408.925293 125.449020,409.061066 
	C127.819710,409.176636 129.359818,410.049744 127.872002,412.959595 
	C127.825027,413.482361 127.922607,413.849030 128.145966,414.437469 
	C128.516418,415.112610 128.761078,415.566040 129.005737,416.019440 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M165.016693,434.361145 
	C166.140869,434.577606 167.284042,435.152618 168.715027,435.867432 
	C166.834091,438.496552 166.333801,438.334198 165.016693,434.361145 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M371.000000,206.000610 
	C370.396301,204.714615 370.144592,202.892136 369.132721,202.228165 
	C361.557068,197.257172 361.383789,188.838638 359.345703,181.360626 
	C359.140259,180.606873 361.341705,178.361008 362.614227,178.187622 
	C367.694946,177.495331 372.849457,177.057373 377.977722,177.046371 
	C417.255463,176.962082 456.533447,177.002762 495.811371,176.996689 
	C500.223328,176.996002 504.652740,176.626587 505.526855,182.888611 
	C505.654419,183.802444 508.717072,184.870667 510.443970,184.910995 
	C530.547913,185.380371 550.656860,185.630966 570.762329,186.044418 
	C573.311401,186.096863 575.848145,186.747910 578.390625,187.121613 
	C578.671997,187.667404 578.953369,188.213181 579.234741,188.758957 
	C577.737061,190.299286 576.105652,191.733627 574.777710,193.408554 
	C573.333130,195.230515 568.967651,196.637848 573.593567,200.167664 
	C564.167480,209.751511 554.622192,218.907623 544.777954,227.860138 
	C540.508179,223.588257 546.150818,221.077667 546.408447,217.296326 
	C543.882996,217.919907 541.720032,218.910797 539.554749,218.915878 
	C523.258057,218.954102 506.958893,218.909271 490.665649,218.609772 
	C487.391937,218.549606 486.866638,219.786957 487.341370,222.399643 
	C487.578644,223.705688 488.131927,224.968597 488.257812,226.278381 
	C489.516388,239.374084 490.856140,252.477737 480.581482,263.227905 
	C479.379333,264.485687 478.849792,266.386383 477.589722,267.991089 
	C475.773529,267.999573 474.376038,268.006012 472.647705,268.014465 
	C471.875793,268.013855 471.434692,268.011200 471.056091,267.656555 
	C473.017151,264.431152 474.950317,261.579803 476.801422,258.676117 
	C477.923615,256.915863 479.393951,255.191666 479.873199,253.245682 
	C480.900482,249.074509 482.030640,244.755234 481.933472,240.523819 
	C481.808014,235.057877 481.707916,229.073257 479.498718,224.278778 
	C474.573273,213.589355 463.409882,210.187820 453.843353,205.112518 
	C453.313965,204.831680 452.523987,205.081650 451.867493,204.993683 
	C449.572205,204.686111 447.282227,204.338684 444.707092,203.824036 
	C442.949280,202.761337 441.474640,201.880966 439.721893,201.183563 
	C436.970245,204.246933 434.496735,207.127380 431.633545,209.936127 
	C422.514893,207.686523 416.899933,212.504395 412.126923,226.349823 
	C410.984741,226.128815 409.834564,225.906265 409.533386,225.847992 
	C408.579102,228.922470 407.789307,231.466965 406.681488,233.865326 
	C405.025238,233.074722 403.499237,232.661270 402.378021,231.749832 
	C391.879150,223.215393 381.449585,214.595673 371.000000,206.000610 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M666.110962,166.310593 
	C662.377075,168.897110 658.759338,171.675293 654.881714,174.024551 
	C649.261230,177.429794 643.452820,180.524994 637.415649,183.906586 
	C635.975891,183.126419 634.073120,182.349457 633.845093,181.231094 
	C632.733765,175.778992 628.850220,175.894958 624.682861,175.970596 
	C618.522217,176.082443 612.348145,176.181229 606.202332,175.857941 
	C604.742981,175.781174 602.482056,174.252228 602.129028,172.968124 
	C601.074646,169.132355 598.710449,167.977661 595.140930,167.991745 
	C585.813721,168.028549 576.485657,167.941772 567.159180,168.033127 
	C564.186218,168.062256 562.313477,167.676743 561.609314,164.029526 
	C560.822083,159.952530 557.562805,159.042343 554.027588,161.554138 
	C550.551514,164.023895 547.561340,163.816727 543.956604,161.609818 
	C539.440613,158.845047 534.406006,158.925583 529.881897,161.714554 
	C526.473816,163.815506 523.583130,163.931824 520.182068,161.791672 
	C518.482727,160.722351 516.221191,160.145233 514.190002,160.078934 
	C507.701019,159.867203 501.200439,159.956741 494.705170,160.018570 
	C492.156189,160.042801 490.189728,159.802261 489.807251,156.540543 
	C489.404602,153.106750 486.809235,151.814041 483.655304,152.061478 
	C479.617676,152.378250 474.764862,150.246811 472.233704,155.707397 
	C471.643616,156.980392 469.971008,158.892120 469.238312,158.712051 
	C467.871918,158.376266 466.933136,156.575424 465.628021,155.649872 
	C463.744141,154.313889 461.705200,152.196381 459.710388,152.171417 
	C439.226715,151.915100 418.670990,151.081253 398.291016,152.557343 
	C391.800873,153.027405 385.921356,152.890030 379.508942,152.536682 
	C363.853210,151.673920 347.875793,150.025940 332.328583,154.117111 
	C330.023254,154.723755 328.198914,157.158157 326.152039,158.746979 
	C328.080719,160.472824 329.874329,162.391174 331.977997,163.867691 
	C333.891663,165.210846 336.207733,165.964798 338.228180,167.175690 
	C340.884735,168.767807 343.709076,170.251617 345.932953,172.343613 
	C347.977722,174.267105 349.415161,176.876495 350.936951,179.298843 
	C351.700500,180.514206 351.747437,182.891342 352.645630,183.197708 
	C359.662567,185.591171 358.025635,192.302231 359.652771,197.518921 
	C356.542603,195.235489 353.772736,192.615082 351.001160,189.598541 
	C349.403809,186.748596 347.261871,184.486359 346.320831,181.802994 
	C344.028412,175.265945 338.394226,172.458603 333.299500,168.923828 
	C330.119110,166.717224 327.078094,164.309647 323.976715,161.576447 
	C323.983459,159.774475 323.988007,158.388336 324.225281,156.739746 
	C325.968719,151.647766 327.479523,146.818237 328.991028,141.561584 
	C329.002716,139.425491 329.013763,137.716507 329.364868,135.938843 
	C331.908539,134.463272 334.112152,133.056396 336.315125,131.648529 
	C340.002686,135.998581 340.002686,135.999054 346.748779,135.999756 
	C349.415039,136.000031 352.083435,135.931046 354.747009,136.014938 
	C357.512329,136.102036 359.243652,135.398712 360.396729,132.387161 
	C360.998291,130.816101 363.784637,129.304047 365.697205,129.147644 
	C373.804596,128.484665 381.956635,128.013535 390.088226,128.069687 
	C410.888641,128.213333 431.687836,128.631622 452.483978,129.097855 
	C454.098206,129.134033 456.895935,130.164627 457.085785,131.135468 
	C458.229462,136.983246 462.534119,135.988251 466.423706,135.991501 
	C487.920563,136.009491 509.417389,136.001556 530.914246,135.999268 
	C533.782959,135.998962 537.241638,135.725327 537.692688,139.428696 
	C538.248291,143.991119 540.996887,144.040863 544.390991,144.026123 
	C558.721924,143.963867 573.056213,143.860016 587.381470,144.152588 
	C589.545898,144.196777 593.238647,145.961136 593.517395,147.440018 
	C594.356140,151.890411 596.943420,152.050293 600.361389,152.028748 
	C612.192505,151.954147 624.024475,152.025436 635.855957,151.987930 
	C639.001404,151.977966 641.609375,152.651505 641.955322,156.342041 
	C642.249207,159.476944 644.231567,160.030975 646.833130,160.005402 
	C650.998779,159.964447 655.172852,160.159058 659.329102,159.958466 
	C663.936523,159.736130 666.286316,161.590607 666.110962,166.310593 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M351.002869,189.994690 
	C353.772736,192.615082 356.542603,195.235489 359.657776,197.920013 
	C360.276215,198.156204 360.515442,198.366730 360.857147,199.201416 
	C360.751251,201.115524 360.509033,202.444000 360.249695,203.866348 
	C355.255768,202.967651 353.945557,196.476166 348.398773,197.693314 
	C349.310455,200.996704 351.266266,204.214417 350.757385,206.978394 
	C349.333862,214.710373 347.641052,222.571854 344.598114,229.766205 
	C342.422424,234.910187 337.835999,239.312454 331.781433,240.302231 
	C323.916840,241.587952 315.932159,242.138809 307.684052,242.966248 
	C307.159302,242.896744 306.949677,242.866959 306.830627,242.512894 
	C307.927582,240.449188 308.933990,238.709778 310.358826,237.003571 
	C317.091309,239.030685 322.594849,236.588104 328.571259,234.759216 
	C335.131012,232.751801 338.164856,228.057266 341.338715,223.122910 
	C347.306824,213.844345 345.558411,200.030380 336.681976,189.389450 
	C335.643494,191.565933 334.819824,193.292267 333.693787,195.162720 
	C331.370483,197.224289 329.257233,199.054306 327.344299,201.074005 
	C318.245331,210.680923 309.200867,220.339447 299.797516,229.874634 
	C290.227570,224.307297 280.945343,218.929977 271.785583,213.351562 
	C266.048279,209.857437 260.495209,206.060760 254.859451,202.399841 
	C254.864883,201.062607 255.291901,199.525513 254.775284,198.435776 
	C254.030334,196.864334 252.600204,195.617722 250.918594,193.570404 
	C248.138382,194.338791 245.778915,193.981903 246.281723,188.777786 
	C246.536957,186.136169 244.561829,183.279053 244.069183,180.368774 
	C245.029419,180.478851 245.515503,180.739532 246.134003,181.198288 
	C246.471420,181.644913 246.716003,181.845108 247.104736,182.114944 
	C247.209305,182.232925 247.641983,182.316879 247.641983,182.316879 
	C247.641983,182.316879 248.018982,182.056122 248.242859,182.257385 
	C249.309219,183.307419 250.151672,184.156204 251.144928,185.311752 
	C253.725571,188.859436 256.671448,190.959290 260.997955,190.006393 
	C263.200226,194.010986 266.022797,191.464005 268.177338,190.260284 
	C269.325043,189.619110 270.349823,187.139816 270.052948,185.793915 
	C268.128235,177.067352 266.021027,168.366806 263.508942,159.793884 
	C262.869598,157.612045 260.608734,155.871399 258.987671,154.020157 
	C257.140411,151.910568 255.234177,149.845078 253.248169,147.866226 
	C250.052231,144.681870 247.270157,140.509216 243.403168,138.709503 
	C238.005066,136.197189 231.800644,134.423660 225.884720,134.248993 
	C216.868652,133.982773 207.741913,134.923996 198.786575,136.215286 
	C194.317245,136.859756 192.332031,135.522354 191.426453,131.282410 
	C198.043228,128.706772 204.044479,126.150810 211.074493,125.718956 
	C221.035355,125.107079 229.780563,129.483109 239.243317,130.679779 
	C242.952530,131.148849 247.682159,131.830154 250.199097,136.480713 
	C251.063934,138.078644 254.659363,138.198746 257.313538,139.142609 
	C262.697144,143.261078 267.671753,147.373413 272.894562,151.141769 
	C275.575256,153.075958 278.387115,156.916702 282.165070,153.170547 
	C285.672119,149.692993 283.708923,146.829010 280.664459,143.928192 
	C277.500214,140.913269 274.857056,137.351471 272.344604,133.867920 
	C277.225189,132.796021 281.735779,131.823044 286.271545,130.987030 
	C295.506744,129.284805 304.755585,127.656448 314.417145,125.999115 
	C316.893250,125.336586 318.950958,124.672615 321.340393,124.008301 
	C322.114288,124.006554 322.556549,124.005150 323.263916,124.191711 
	C325.355011,124.246925 327.181000,124.114174 329.349609,124.006546 
	C330.166046,125.141960 331.160126,126.326241 331.014771,127.348511 
	C330.599335,130.270096 329.721069,133.125870 329.024811,136.007523 
	C329.013763,137.716507 329.002716,139.425491 328.697510,141.756226 
	C326.933075,147.252731 325.462830,152.127457 323.992584,157.002197 
	C323.988007,158.388336 323.983459,159.774475 323.679260,161.783447 
	C322.589264,164.801804 321.852997,167.217056 320.989014,169.585754 
	C320.435364,171.103683 319.693359,172.552872 318.952820,173.680878 
	C316.387573,171.899017 313.983856,169.499130 311.402740,169.290207 
	C308.328766,169.041397 304.736084,170.105469 302.134949,171.786972 
	C301.184143,172.401627 302.640167,176.420898 302.589874,178.878616 
	C302.563904,180.147720 301.951599,182.161514 301.070526,182.517288 
	C298.208740,183.672852 295.136505,184.444626 292.076416,184.929382 
	C289.471344,185.342041 286.737549,184.936737 284.129822,185.340195 
	C283.115662,185.497101 282.290955,186.878647 281.379944,187.702362 
	C282.333252,188.446915 283.212799,189.693893 284.253021,189.846664 
	C288.183044,190.423874 292.162140,190.901123 296.128510,190.948990 
	C305.177795,191.058197 314.234741,191.032806 323.279083,190.754608 
	C326.115509,190.667358 328.921753,189.598724 331.741791,188.979309 
	C331.712769,188.220215 331.683746,187.461121 331.654724,186.702026 
	C329.247467,186.134735 326.859161,185.194595 324.429352,185.070999 
	C317.530609,184.720062 316.683838,183.600662 319.500977,176.981567 
	C322.613190,176.995117 325.414154,176.464493 327.896301,177.127502 
	C333.328796,178.578537 337.350342,183.076508 343.265259,184.116760 
	C346.101349,184.615540 348.439301,187.946426 351.002869,189.994690 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M598.977661,200.999908 
	C599.460815,200.552658 600.150513,200.197083 600.396057,199.644287 
	C603.553162,192.535934 603.729553,192.198807 611.671204,193.185364 
	C613.569092,193.421127 616.144104,195.465042 616.741333,197.238464 
	C617.675293,200.011917 618.943481,201.044662 621.646606,201.002853 
	C629.128113,200.887085 636.625732,201.086212 644.085754,200.639664 
	C647.484497,200.436249 648.745056,201.697342 648.933167,204.646957 
	C649.091797,207.133743 648.591858,209.735367 649.160461,212.099243 
	C649.587036,213.872787 650.957397,216.352509 652.412231,216.773346 
	C657.067078,218.119919 657.347473,220.917236 655.565430,224.362640 
	C653.535461,228.287354 652.932556,231.487717 657.526001,234.010406 
	C658.312073,234.442139 659.014404,236.020325 658.893066,236.957184 
	C657.664001,246.448547 663.044312,253.932114 666.353699,262.033356 
	C666.632141,262.715027 667.401611,263.532593 668.067871,263.666840 
	C674.064636,264.875122 672.937012,269.200256 672.766663,273.432404 
	C672.686401,275.428589 673.352539,278.891113 674.473450,279.256500 
	C680.009399,281.060974 680.677246,285.495270 680.871704,289.964539 
	C681.057129,294.227692 682.678528,295.626801 686.720032,297.520355 
	C690.938171,299.496674 693.997864,304.246307 698.976990,306.011169 
	C699.678711,306.259888 699.539978,308.503326 700.081238,309.690826 
	C700.586853,310.800018 701.442383,312.578217 702.207642,312.611847 
	C709.645691,312.938782 713.846924,318.956329 719.587219,322.208069 
	C723.886780,324.643677 727.876831,327.690125 732.329590,329.768219 
	C735.309265,331.158783 737.981323,332.085846 738.800354,335.785950 
	C739.000366,336.689331 741.418030,338.001221 742.356934,337.698975 
	C749.697693,335.335815 755.397156,340.211029 761.782959,341.857727 
	C764.501831,342.558868 768.647644,342.314728 768.007080,347.623077 
	C768.012634,348.031006 767.884399,348.062164 767.498718,348.084717 
	C765.422913,349.750488 763.732788,351.393707 761.693481,352.863464 
	C759.297180,352.128510 757.280334,351.379761 755.196838,351.041809 
	C749.693298,350.149078 744.132507,349.589355 738.652100,348.583221 
	C735.932434,348.083954 732.168335,347.699310 730.909119,345.866486 
	C727.159729,340.409149 720.460266,340.705658 716.139832,336.729858 
	C716.500427,335.291626 716.713928,334.119537 716.831177,332.615479 
	C714.490540,331.519073 712.246155,330.754608 709.984314,329.628845 
	C709.026001,324.930298 706.972168,323.328644 703.246582,326.667969 
	C700.653748,321.895111 693.443665,321.646118 693.143250,315.282166 
	C693.139893,315.211578 692.859375,315.135345 692.700012,315.093536 
	C685.893616,313.307068 684.910034,305.650574 679.500366,302.105927 
	C675.386169,299.410126 673.168945,293.915894 669.168091,290.922821 
	C665.272583,288.008575 664.221985,284.708710 663.886292,280.377411 
	C663.748474,278.598969 662.920837,275.969696 661.621826,275.335419 
	C654.354675,271.787018 651.299805,265.549835 649.494690,258.251831 
	C649.099243,256.653198 648.728699,254.590637 647.595581,253.726089 
	C645.125549,251.841446 642.314758,249.820877 639.379822,249.277039 
	C635.802429,248.614166 636.241272,252.179245 635.832397,254.549957 
	C633.258423,269.473114 624.477051,280.454773 611.613037,287.051514 
	C602.972473,291.482330 593.593445,294.639832 582.531433,291.512268 
	C564.106812,286.303131 552.106689,275.806763 546.898376,257.472412 
	C545.909729,253.992325 544.966614,250.499344 544.187988,246.886292 
	C544.607910,246.570511 544.803833,246.346939 545.330811,246.081619 
	C549.275085,247.634827 552.849609,249.195984 556.026672,250.872177 
	C552.177612,253.987930 551.981812,265.195068 555.776733,269.112640 
	C559.803101,273.269043 563.934387,277.600189 568.795532,280.596558 
	C574.022339,283.818329 579.989319,287.039856 585.916321,287.692932 
	C601.696533,289.431641 614.374451,283.999023 623.394775,269.807129 
	C629.965088,259.470093 631.884277,249.137283 628.320618,237.783386 
	C627.043213,233.713562 623.550171,230.275101 622.573486,226.188782 
	C620.740356,218.518951 615.924133,216.505905 608.747192,217.002930 
	C608.497314,217.002792 607.997559,217.001831 607.926453,216.645325 
	C606.217590,213.246017 605.810181,209.042038 600.754883,208.718552 
	C600.009705,205.952423 599.493652,203.476166 598.977661,200.999908 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M598.748779,200.715515 
	C599.493652,203.476166 600.009705,205.952423 600.820740,209.076447 
	C601.747070,211.814163 602.378418,213.904144 603.005371,216.437500 
	C601.191833,224.106323 606.680725,228.390182 610.330322,232.817001 
	C616.637390,240.467102 618.627380,248.424484 616.705933,258.046600 
	C616.014465,261.509399 613.669006,265.216278 615.862671,269.253662 
	C615.491821,269.997223 615.238220,270.489777 614.610901,270.982910 
	C613.113037,270.564789 611.988831,270.146088 610.981995,269.476257 
	C610.663635,268.474762 610.416077,267.409119 609.764587,267.020599 
	C597.141602,259.492920 584.471436,252.044434 571.916260,244.243881 
	C575.362976,234.373779 578.669250,224.826965 582.055420,215.308548 
	C585.655457,205.188797 589.331116,195.095978 593.311157,184.994202 
	C594.098206,184.998215 594.547913,184.999405 594.997559,185.000610 
	C595.289734,186.427078 595.581909,187.853531 595.947754,189.968079 
	C596.345154,191.437851 596.669006,192.219543 596.992859,193.001221 
	C597.501831,195.477859 598.010864,197.954483 598.748779,200.715515 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M665.001587,205.998474 
	C673.797058,211.382812 682.245850,216.864548 690.664734,222.826874 
	C688.321167,222.851898 686.007568,222.396362 683.693909,221.940826 
	C688.576477,225.330994 688.026062,230.262466 688.031860,235.087112 
	C688.036804,239.220108 687.587891,243.548569 688.633728,247.443069 
	C690.554138,254.594101 693.234924,261.566406 695.997681,268.453186 
	C697.101990,271.206116 699.310364,273.505096 700.929749,276.065338 
	C702.725952,278.904938 704.287415,281.902283 706.211060,284.648834 
	C707.529968,286.531982 709.332275,288.070068 710.849365,289.822235 
	C714.468506,294.002289 718.074585,298.194427 721.632080,302.426941 
	C724.065186,305.321655 727.289612,307.987122 728.453186,311.346375 
	C729.129456,313.298767 726.400879,316.405487 725.279236,319.024872 
	C725.028992,319.609314 725.092896,320.328308 725.012207,320.985352 
	C722.252136,317.618835 719.520996,314.227875 716.712219,310.902435 
	C715.987183,310.044006 714.984375,309.426636 714.168579,308.637115 
	C712.412231,306.937103 710.690857,305.200745 708.962402,303.472046 
	C708.172546,302.682129 707.515259,301.377563 706.598633,301.164856 
	C703.239380,300.385376 701.739685,298.226685 700.711304,295.142181 
	C700.315186,293.954071 698.493103,293.310364 697.543518,292.223877 
	C695.312256,289.670776 693.557129,286.589447 690.996399,284.458435 
	C684.676819,279.199402 685.415161,270.271362 679.663391,264.347412 
	C676.369751,260.955139 676.520874,254.270325 674.965027,249.115005 
	C674.263367,246.790131 673.178650,244.581055 672.271423,242.317932 
	C671.852661,241.273224 670.991943,240.174820 671.102051,239.186615 
	C671.792419,232.990601 671.729736,227.053391 669.146240,221.082779 
	C668.220154,218.942673 669.650879,215.782761 670.005920,213.088287 
	C670.630859,213.030090 671.255798,212.971878 671.880737,212.913681 
	C670.869812,211.769379 669.858887,210.625076 668.512451,209.100952 
	C667.511902,210.840225 666.829712,212.026047 666.147522,213.211884 
	C665.758667,213.216110 665.369812,213.220337 664.980957,213.224564 
	C664.872253,210.848343 664.763611,208.472107 665.001587,205.998474 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M306.740082,242.837189 
	C306.949677,242.866959 307.159302,242.896744 307.763733,243.323669 
	C311.246857,244.810791 314.403015,246.959259 317.409210,246.766693 
	C323.364655,246.385239 329.058624,243.382904 335.316162,244.791901 
	C336.080994,244.964111 337.289062,244.252441 337.953003,243.603729 
	C341.078766,240.549606 344.829651,237.809738 346.973633,234.147568 
	C350.837372,227.547791 354.917969,220.807526 354.127228,212.476517 
	C353.971313,210.833817 355.105591,207.783447 356.066254,207.601013 
	C357.797241,207.272263 359.887482,208.522415 361.753143,209.293320 
	C364.166412,210.290527 366.506714,211.464478 368.878418,212.562332 
	C368.917267,210.377960 368.956146,208.193588 368.994995,206.009216 
	C369.440552,206.007187 369.886108,206.005157 370.665833,206.001862 
	C381.449585,214.595673 391.879150,223.215393 402.378021,231.749832 
	C403.499237,232.661270 405.025238,233.074722 406.893677,234.111786 
	C408.000580,235.416092 408.577240,236.327759 408.979919,237.531158 
	C405.131226,242.010025 404.336212,246.155411 408.971527,250.236664 
	C409.068054,250.321625 409.040070,250.548004 409.072693,251.095322 
	C410.074493,252.300247 411.073730,253.118286 412.079651,253.894257 
	C412.086304,253.852188 412.164307,253.818008 412.127106,254.244354 
	C411.432800,260.033691 414.181549,263.806763 417.772034,267.312592 
	C422.061279,271.500824 421.864868,272.651855 416.701691,275.828003 
	C415.873230,276.337616 414.898956,276.610199 413.989441,276.598389 
	C413.302521,274.048309 413.153473,271.469971 411.842590,269.808289 
	C405.735931,262.067535 399.308075,254.580215 392.932861,246.661331 
	C384.645142,234.959534 374.803070,225.086014 364.865601,214.382568 
	C363.084320,217.531494 361.056763,219.581619 360.831146,221.813858 
	C360.248291,227.581070 358.980225,232.313126 353.732666,236.030930 
	C350.090668,238.611237 348.238037,243.629562 344.719543,246.494598 
	C340.527893,249.907791 335.496368,251.941071 329.579102,249.960526 
	C327.267426,249.186783 324.445343,249.561081 321.919586,249.895248 
	C318.421814,250.358002 314.952728,251.152969 311.528839,252.030060 
	C306.940674,253.205383 305.380707,249.550613 303.176605,246.716400 
	C304.430023,245.218521 305.585052,244.027847 306.740082,242.837189 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M592.973755,184.991394 
	C589.331116,195.095978 585.655457,205.188797 582.055420,215.308548 
	C578.669250,224.826965 575.362976,234.373779 571.514160,244.250626 
	C559.788269,243.507324 548.569946,242.421494 537.024170,241.223145 
	C534.004761,240.539795 534.382324,239.523041 536.014465,237.864365 
	C539.133667,234.694290 542.066650,231.340988 545.076965,228.063751 
	C554.622192,218.907623 564.167480,209.751511 573.884766,200.308746 
	C580.123657,194.426086 586.190491,188.830048 592.587036,183.460114 
	C592.935730,184.121277 592.954773,184.556335 592.973755,184.991394 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M725.410522,320.989197 
	C725.092896,320.328308 725.028992,319.609314 725.279236,319.024872 
	C726.400879,316.405487 729.129456,313.298767 728.453186,311.346375 
	C727.289612,307.987122 724.065186,305.321655 721.632080,302.426941 
	C718.074585,298.194427 714.468506,294.002289 710.849365,289.822235 
	C709.332275,288.070068 707.529968,286.531982 706.211060,284.648834 
	C704.287415,281.902283 702.725952,278.904938 700.929749,276.065338 
	C699.310364,273.505096 697.101990,271.206116 695.997681,268.453186 
	C693.234924,261.566406 690.554138,254.594101 688.633728,247.443069 
	C687.587891,243.548569 688.036804,239.220108 688.031860,235.087112 
	C688.026062,230.262466 688.576477,225.330994 683.693909,221.940826 
	C686.007568,222.396362 688.321167,222.851898 690.893433,223.075729 
	C693.735168,224.641251 696.318176,226.438461 698.759644,228.749969 
	C698.359375,235.720718 697.224915,242.299973 698.107910,248.596329 
	C698.870178,254.032089 701.990173,259.122589 703.956116,264.407318 
	C707.587708,274.169220 711.295410,283.855011 719.440674,290.999237 
	C723.033630,294.150696 726.739685,297.231415 729.925232,300.766968 
	C731.772583,302.817444 732.579529,305.773682 734.056946,308.199036 
	C735.249207,310.156219 736.497009,313.217834 738.152771,313.535736 
	C742.678223,314.404572 744.420654,317.392151 745.981873,320.950989 
	C740.541565,320.805237 735.101318,320.659485 729.329285,320.757385 
	C727.934631,320.998383 726.871765,320.995728 725.410522,320.989197 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M368.753052,205.784103 
	C368.956146,208.193588 368.917267,210.377960 368.878418,212.562332 
	C366.506714,211.464478 364.166412,210.290527 361.753143,209.293320 
	C359.887482,208.522415 357.797241,207.272263 356.066254,207.601013 
	C355.105591,207.783447 353.971313,210.833817 354.127228,212.476517 
	C354.917969,220.807526 350.837372,227.547791 346.973633,234.147568 
	C344.829651,237.809738 341.078766,240.549606 337.953003,243.603729 
	C337.289062,244.252441 336.080994,244.964111 335.316162,244.791901 
	C329.058624,243.382904 323.364655,246.385239 317.409210,246.766693 
	C314.403015,246.959259 311.246857,244.810791 308.078827,243.363419 
	C315.932159,242.138809 323.916840,241.587952 331.781433,240.302231 
	C337.835999,239.312454 342.422424,234.910187 344.598114,229.766205 
	C347.641052,222.571854 349.333862,214.710373 350.757385,206.978394 
	C351.266266,204.214417 349.310455,200.996704 348.398773,197.693314 
	C353.945557,196.476166 355.255768,202.967651 360.249695,203.866348 
	C360.509033,202.444000 360.751251,201.115524 360.993683,199.398499 
	C363.499634,201.192932 366.005371,203.375961 368.753052,205.784103 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M641.142517,190.056290 
	C646.318420,192.927948 651.521545,196.164658 656.928955,199.673462 
	C654.972900,199.369690 652.822510,198.752289 650.649231,198.229691 
	C648.496460,197.712006 646.320801,197.289932 644.265442,196.849548 
	C643.318176,194.882584 642.243896,192.651962 641.142517,190.056290 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M593.311157,184.994202 
	C592.954773,184.556335 592.935730,184.121277 592.896851,183.360535 
	C593.765442,183.635559 594.653870,184.236298 595.269897,184.918823 
	C594.547913,184.999405 594.098206,184.998215 593.311157,184.994202 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M597.192810,192.775696 
	C596.669006,192.219543 596.345154,191.437851 596.011902,190.327469 
	C596.465881,190.849228 596.929321,191.699692 597.192810,192.775696 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M185.001984,123.999298 
	C185.001984,123.999298 184.995880,123.505188 184.919647,122.958115 
	C184.224930,121.943092 183.606430,121.475136 182.987946,121.007187 
	C179.435760,116.202744 175.883560,111.398308 172.164124,105.866295 
	C172.450104,102.603844 172.050247,99.290092 173.518524,97.681923 
	C177.717255,93.083122 182.640427,89.380821 189.749344,90.903938 
	C192.454590,91.483551 195.462906,91.348358 198.197998,90.828339 
	C199.856659,90.512993 202.443161,88.956703 202.564865,87.751160 
	C203.059235,82.854187 206.165710,82.955109 209.696671,82.975037 
	C221.357559,83.040855 233.022934,83.150642 244.676666,82.854164 
	C246.729538,82.801933 250.288055,81.104240 250.483002,79.775108 
	C251.186295,74.979759 254.075943,74.958405 257.651428,74.970009 
	C277.142181,75.033226 296.633270,74.994308 316.124237,75.004906 
	C319.040466,75.006493 322.300171,75.099358 322.768829,71.372986 
	C323.253143,67.522072 325.378937,66.824768 328.596527,66.979980 
	C330.923309,67.092216 333.261566,67.055496 335.591858,66.987602 
	C339.367981,66.877586 343.184052,66.548943 343.360779,72.049622 
	C343.382477,72.726044 344.159210,73.519981 344.781708,74.011749 
	C348.195648,76.708702 360.125946,75.068115 361.995422,71.087860 
	C363.832977,67.175560 366.538116,66.955940 370.032166,66.966064 
	C392.854736,67.032227 415.677582,66.995796 438.500336,67.004234 
	C442.242096,67.005615 446.323395,66.136711 448.057068,71.092575 
	C448.305267,71.802101 449.626587,72.344810 450.548279,72.573112 
	C454.071014,73.445709 457.618011,74.619980 461.187317,74.738670 
	C463.011963,74.799339 466.347534,73.046982 466.508148,71.794548 
	C467.125366,66.980789 470.054352,66.844849 473.641022,66.977859 
	C476.802124,67.095078 480.000854,66.769424 483.121002,67.148979 
	C484.450073,67.310669 486.591736,68.662712 486.701538,69.657341 
	C487.271088,74.816566 490.634766,75.043564 494.607544,75.030045 
	C514.098267,74.963737 533.589355,74.987038 553.080322,75.018227 
	C555.501831,75.022102 558.493042,74.454674 558.891235,78.082146 
	C559.352722,82.285408 561.977417,83.046631 565.551819,83.025574 
	C577.545837,82.954918 589.543335,82.874550 601.531982,83.137901 
	C603.349976,83.177834 606.381348,84.736504 606.690063,86.086792 
	C607.616211,90.138130 609.906677,91.057732 613.506775,91.026573 
	C623.001648,90.944382 632.501892,90.859650 641.989319,91.139214 
	C643.658997,91.188423 646.467712,92.772964 646.703979,94.032440 
	C647.542053,98.498749 650.285095,99.059547 653.965149,99.027153 
	C663.293396,98.945038 672.627258,98.858719 681.947937,99.137375 
	C683.629211,99.187637 686.463684,100.745071 686.695435,101.991562 
	C687.522217,106.438957 690.219666,107.063240 693.923706,107.028664 
	C703.251892,106.941597 712.581665,107.033432 721.910339,106.985809 
	C724.710449,106.971512 726.368835,107.647865 727.278503,110.834015 
	C727.772217,112.563164 730.454285,113.931839 732.428894,114.881767 
	C733.765747,115.524864 735.589355,115.156052 737.505127,115.256012 
	C737.340210,118.862762 737.198792,121.954765 737.057373,125.046761 
	C736.780457,125.502701 736.503540,125.958649 735.728760,126.689407 
	C733.181335,128.318909 731.131714,129.673599 729.082153,131.028275 
	C726.606873,132.343903 724.131592,133.659531 721.030640,135.134338 
	C719.896423,135.846130 719.387817,136.398743 718.879272,136.951355 
	C718.879272,136.951355 718.434021,136.985367 717.914429,137.084259 
	C716.881226,137.769089 716.367554,138.355011 715.853882,138.940933 
	C714.056763,139.811386 712.259705,140.681824 709.951172,141.844528 
	C708.982300,142.466553 708.524780,142.796310 708.067200,143.126068 
	C707.325317,143.166763 706.583435,143.207474 705.493591,142.904495 
	C707.234802,137.417725 705.842102,135.198837 700.251160,135.028366 
	C696.258301,134.906647 692.204956,135.373093 688.286438,134.817291 
	C686.429382,134.553909 683.626282,132.741211 683.339417,131.233292 
	C682.602722,127.360741 680.420959,126.952225 677.297180,126.975723 
	C667.972778,127.045860 658.647400,126.980118 649.322571,127.008301 
	C646.487305,127.016876 643.581787,126.732559 643.210388,123.326973 
	C642.784302,119.419563 640.531311,118.943405 637.357910,118.971634 
	C628.033691,119.054588 618.708191,118.981964 609.383301,119.007652 
	C606.565674,119.015419 603.588379,118.794586 603.231689,115.385704 
	C602.782959,111.097191 600.154114,110.964012 596.919250,110.976326 
	C582.432556,111.031487 567.943970,111.098244 553.460205,110.882927 
	C551.450073,110.853043 547.844543,109.448196 547.730408,108.384598 
	C547.039856,101.946243 542.569885,103.001472 538.496826,103.001190 
	C506.525604,102.998947 474.554352,102.975548 442.583221,103.030472 
	C438.597717,103.037323 435.295135,102.774269 434.673676,97.642868 
	C434.549622,96.618439 432.039856,95.142784 430.617523,95.128448 
	C413.468109,94.955620 396.315674,94.954880 379.166534,95.141731 
	C377.803314,95.156586 375.435730,96.753769 375.282471,97.858124 
	C374.612030,102.689339 371.516785,103.040855 367.700134,103.028000 
	C349.050476,102.965210 330.400452,102.999741 311.750580,103.000023 
	C297.430145,103.000244 283.109650,102.987793 268.789276,103.013428 
	C266.331482,103.017830 263.453705,102.578125 263.072388,106.205742 
	C262.663940,110.091911 260.260498,111.082024 256.824524,111.018005 
	C252.830215,110.943588 248.829956,110.956093 244.839218,111.110199 
	C244.002838,111.142487 242.959137,111.785416 242.466232,112.473213 
	C242.174149,112.880768 242.533600,114.349365 243.009537,114.619530 
	C246.922379,116.840645 250.752579,119.430397 254.965988,120.830643 
	C258.536652,122.017296 261.395630,123.231293 262.577545,127.035370 
	C263.308289,129.387421 264.198212,131.690018 264.695770,134.248154 
	C261.919403,134.663818 259.463989,134.846130 256.855652,134.707184 
	C255.919510,133.130035 255.418243,131.194855 254.305435,130.732925 
	C248.776764,128.437973 243.987991,125.116425 238.986603,121.970276 
	C233.326920,118.410027 225.878357,115.075157 218.017258,116.933830 
	C216.132721,117.379410 214.040482,117.143776 212.065857,116.970192 
	C207.898239,116.603851 204.531189,118.010071 200.987762,120.267517 
	C197.037582,122.784111 192.351425,124.145485 187.985641,126.009705 
	C187.985641,126.009712 187.492615,126.007713 187.169342,125.706665 
	C186.231369,124.936844 185.616669,124.468071 185.001984,123.999298 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M265.016693,134.014801 
	C264.198212,131.690018 263.308289,129.387421 262.577545,127.035370 
	C261.395630,123.231293 258.536652,122.017296 254.965988,120.830643 
	C250.752579,119.430397 246.922379,116.840645 243.009537,114.619530 
	C242.533600,114.349365 242.174149,112.880768 242.466232,112.473213 
	C242.959137,111.785416 244.002838,111.142487 244.839218,111.110199 
	C248.829956,110.956093 252.830215,110.943588 256.824524,111.018005 
	C260.260498,111.082024 262.663940,110.091911 263.072388,106.205742 
	C263.453705,102.578125 266.331482,103.017830 268.789276,103.013428 
	C283.109650,102.987793 297.430145,103.000244 311.750580,103.000023 
	C330.400452,102.999741 349.050476,102.965210 367.700134,103.028000 
	C371.516785,103.040855 374.612030,102.689339 375.282471,97.858124 
	C375.435730,96.753769 377.803314,95.156586 379.166534,95.141731 
	C396.315674,94.954880 413.468109,94.955620 430.617523,95.128448 
	C432.039856,95.142784 434.549622,96.618439 434.673676,97.642868 
	C435.295135,102.774269 438.597717,103.037323 442.583221,103.030472 
	C474.554352,102.975548 506.525604,102.998947 538.496826,103.001190 
	C542.569885,103.001472 547.039856,101.946243 547.730408,108.384598 
	C547.844543,109.448196 551.450073,110.853043 553.460205,110.882927 
	C567.943970,111.098244 582.432556,111.031487 596.919250,110.976326 
	C600.154114,110.964012 602.782959,111.097191 603.231689,115.385704 
	C603.588379,118.794586 606.565674,119.015419 609.383301,119.007652 
	C618.708191,118.981964 628.033691,119.054588 637.357910,118.971634 
	C640.531311,118.943405 642.784302,119.419563 643.210388,123.326973 
	C643.581787,126.732559 646.487305,127.016876 649.322571,127.008301 
	C658.647400,126.980118 667.972778,127.045860 677.297180,126.975723 
	C680.420959,126.952225 682.602722,127.360741 683.339417,131.233292 
	C683.626282,132.741211 686.429382,134.553909 688.286438,134.817291 
	C692.204956,135.373093 696.258301,134.906647 700.251160,135.028366 
	C705.842102,135.198837 707.234802,137.417725 705.122681,142.929962 
	C699.851196,146.655396 694.636414,150.065796 689.344788,153.352570 
	C684.156128,156.575363 678.889282,159.672363 673.129883,162.747055 
	C672.072083,163.122543 671.541260,163.575562 671.010437,164.028549 
	C669.575500,164.668533 668.140564,165.308533 666.408325,166.129547 
	C666.286316,161.590607 663.936523,159.736130 659.329102,159.958466 
	C655.172852,160.159058 650.998779,159.964447 646.833130,160.005402 
	C644.231567,160.030975 642.249207,159.476944 641.955322,156.342041 
	C641.609375,152.651505 639.001404,151.977966 635.855957,151.987930 
	C624.024475,152.025436 612.192505,151.954147 600.361389,152.028748 
	C596.943420,152.050293 594.356140,151.890411 593.517395,147.440018 
	C593.238647,145.961136 589.545898,144.196777 587.381470,144.152588 
	C573.056213,143.860016 558.721924,143.963867 544.390991,144.026123 
	C540.996887,144.040863 538.248291,143.991119 537.692688,139.428696 
	C537.241638,135.725327 533.782959,135.998962 530.914246,135.999268 
	C509.417389,136.001556 487.920563,136.009491 466.423706,135.991501 
	C462.534119,135.988251 458.229462,136.983246 457.085785,131.135468 
	C456.895935,130.164627 454.098206,129.134033 452.483978,129.097855 
	C431.687836,128.631622 410.888641,128.213333 390.088226,128.069687 
	C381.956635,128.013535 373.804596,128.484665 365.697205,129.147644 
	C363.784637,129.304047 360.998291,130.816101 360.396729,132.387161 
	C359.243652,135.398712 357.512329,136.102036 354.747009,136.014938 
	C352.083435,135.931046 349.415039,136.000031 346.748779,135.999756 
	C340.002686,135.999054 340.002686,135.998581 336.315125,131.648529 
	C334.112152,133.056396 331.908539,134.463272 329.364868,135.938843 
	C329.721069,133.125870 330.599335,130.270096 331.014771,127.348511 
	C331.160126,126.326241 330.166046,125.141960 329.249420,123.696228 
	C326.870636,123.575111 324.934692,123.789421 322.998779,124.003738 
	C322.556549,124.005150 322.114288,124.006554 320.921204,124.005630 
	C318.113190,124.668091 316.055969,125.332886 313.998779,125.997665 
	C304.755585,127.656448 295.506744,129.284805 286.271545,130.987030 
	C281.735779,131.823044 277.225189,132.796021 271.909271,133.862915 
	C269.082458,134.017471 267.049561,134.016129 265.016693,134.014801 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M737.382324,125.035851 
	C737.198792,121.954765 737.340210,118.862762 737.505127,115.256012 
	C735.589355,115.156052 733.765747,115.524864 732.428894,114.881767 
	C730.454285,113.931839 727.772217,112.563164 727.278503,110.834015 
	C726.368835,107.647865 724.710449,106.971512 721.910339,106.985809 
	C712.581665,107.033432 703.251892,106.941597 693.923706,107.028664 
	C690.219666,107.063240 687.522217,106.438957 686.695435,101.991562 
	C686.463684,100.745071 683.629211,99.187637 681.947937,99.137375 
	C672.627258,98.858719 663.293396,98.945038 653.965149,99.027153 
	C650.285095,99.059547 647.542053,98.498749 646.703979,94.032440 
	C646.467712,92.772964 643.658997,91.188423 641.989319,91.139214 
	C632.501892,90.859650 623.001648,90.944382 613.506775,91.026573 
	C609.906677,91.057732 607.616211,90.138130 606.690063,86.086792 
	C606.381348,84.736504 603.349976,83.177834 601.531982,83.137901 
	C589.543335,82.874550 577.545837,82.954918 565.551819,83.025574 
	C561.977417,83.046631 559.352722,82.285408 558.891235,78.082146 
	C558.493042,74.454674 555.501831,75.022102 553.080322,75.018227 
	C533.589355,74.987038 514.098267,74.963737 494.607544,75.030045 
	C490.634766,75.043564 487.271088,74.816566 486.701538,69.657341 
	C486.591736,68.662712 484.450073,67.310669 483.121002,67.148979 
	C480.000854,66.769424 476.802124,67.095078 473.641022,66.977859 
	C470.054352,66.844849 467.125366,66.980789 466.508148,71.794548 
	C466.347534,73.046982 463.011963,74.799339 461.187317,74.738670 
	C457.618011,74.619980 454.071014,73.445709 450.548279,72.573112 
	C449.626587,72.344810 448.305267,71.802101 448.057068,71.092575 
	C446.323395,66.136711 442.242096,67.005615 438.500336,67.004234 
	C415.677582,66.995796 392.854736,67.032227 370.032166,66.966064 
	C366.538116,66.955940 363.832977,67.175560 361.995422,71.087860 
	C360.125946,75.068115 348.195648,76.708702 344.781708,74.011749 
	C344.159210,73.519981 343.382477,72.726044 343.360779,72.049622 
	C343.184052,66.548943 339.367981,66.877586 335.591858,66.987602 
	C333.261566,67.055496 330.923309,67.092216 328.596527,66.979980 
	C325.378937,66.824768 323.253143,67.522072 322.768829,71.372986 
	C322.300171,75.099358 319.040466,75.006493 316.124237,75.004906 
	C296.633270,74.994308 277.142181,75.033226 257.651428,74.970009 
	C254.075943,74.958405 251.186295,74.979759 250.483002,79.775108 
	C250.288055,81.104240 246.729538,82.801933 244.676666,82.854164 
	C233.022934,83.150642 221.357559,83.040855 209.696671,82.975037 
	C206.165710,82.955109 203.059235,82.854187 202.564865,87.751160 
	C202.443161,88.956703 199.856659,90.512993 198.197998,90.828339 
	C195.462906,91.348358 192.454590,91.483551 189.749344,90.903938 
	C182.640427,89.380821 177.717255,93.083122 173.518524,97.681923 
	C172.050247,99.290092 172.450104,102.603844 171.994293,105.574547 
	C170.039810,103.238892 168.087921,100.467415 166.066803,97.348122 
	C167.661545,95.525208 169.938873,94.351234 170.845612,92.504364 
	C172.201904,89.741776 172.403412,86.438828 173.441437,83.487167 
	C174.987091,79.092087 176.673798,74.639465 181.996368,75.503532 
	C180.219604,72.714363 177.206406,70.104561 177.587448,68.159821 
	C178.500534,63.499836 183.129944,65.119514 186.387955,65.054794 
	C194.697861,64.889702 203.013733,64.946358 211.326141,65.025291 
	C214.462585,65.055069 216.748840,64.649048 217.203033,60.717148 
	C217.631927,57.004307 220.823502,56.990543 223.764374,56.992588 
	C245.378647,57.007618 266.993286,56.932594 288.606873,57.054092 
	C293.200104,57.079910 297.093689,57.248199 297.830719,51.035950 
	C298.012756,49.501316 301.346466,48.340576 303.617523,47.002350 
	C304.445984,46.996273 304.888306,46.995800 305.662262,46.994705 
	C308.451782,46.816658 310.909637,46.639236 313.686340,46.225620 
	C317.380585,45.659779 320.755951,45.330132 324.703522,45.195267 
	C325.481079,45.633667 325.720886,45.836590 325.995117,45.998810 
	C326.473877,46.299072 326.952667,46.599335 327.859131,47.304878 
	C333.209076,50.159401 336.337524,49.611977 338.003418,46.009899 
	C338.003418,46.009899 338.502441,46.004700 339.126129,46.002304 
	C340.498047,46.000175 341.246307,46.000446 341.994537,46.000717 
	C342.473022,46.300552 342.951508,46.600388 343.714386,46.947762 
	C346.661346,47.525337 349.326233,48.483852 351.985840,48.469360 
	C354.858582,48.453709 357.722595,47.096684 360.596924,47.071499 
	C377.206177,46.925972 393.817352,47.018654 410.427765,46.978497 
	C412.394958,46.973740 414.361389,46.651024 416.665710,46.234581 
	C417.003296,45.993458 417.502258,45.996571 418.048279,46.223751 
	C420.729309,46.300011 422.864349,46.149090 424.999390,45.998177 
	C425.443848,45.997147 425.888306,45.996117 426.878662,46.195572 
	C428.283234,46.261284 429.141907,46.126507 430.000580,45.991734 
	C432.370239,45.992470 434.739899,45.993206 437.802948,45.996048 
	C438.496338,45.998161 438.995117,46.000534 438.995117,46.000534 
	C442.777252,46.157494 446.559387,46.314457 450.922119,46.234638 
	C451.502686,45.997856 452.002472,45.993557 452.002472,45.993557 
	C453.069794,45.994137 454.137085,45.994720 455.629883,46.349831 
	C457.497498,50.374306 459.230194,49.007999 461.000519,46.989677 
	C462.473694,46.810413 463.946899,46.631153 465.904480,46.088551 
	C466.632263,45.519020 466.834991,45.278595 466.997131,45.003933 
	C472.686829,45.002739 478.376556,45.001545 484.670959,45.195198 
	C485.481079,45.633667 485.720886,45.836590 485.995117,45.998821 
	C486.473877,46.299091 486.952637,46.599354 487.859131,47.304886 
	C493.209076,50.159401 496.337524,49.611977 498.003418,46.009899 
	C498.003418,46.009899 498.502441,46.004700 499.126129,46.002304 
	C500.498047,46.000175 501.246307,46.000446 501.994537,46.000717 
	C502.157928,46.277641 502.363098,46.519058 502.805664,46.861595 
	C503.507080,48.402782 504.046722,49.796242 504.513000,51.213818 
	C506.668884,57.767849 507.603302,58.052864 513.906006,55.683083 
	C515.794434,54.973053 518.415588,55.073288 520.332031,55.789486 
	C526.111084,57.949120 527.039917,57.608543 529.366821,51.953518 
	C529.869080,50.732891 531.801697,49.143124 532.805237,49.313652 
	C534.266479,49.561974 536.501038,51.148849 536.622314,52.345776 
	C537.079712,56.858822 539.836182,57.079121 543.299133,57.032490 
	C551.126404,56.927109 558.967712,56.746468 566.779663,57.107922 
	C570.621094,57.285656 574.381409,58.827187 578.233704,59.313408 
	C580.382080,59.584560 582.883789,59.493469 584.808472,58.632095 
	C590.158020,56.237949 590.973450,56.626701 593.248230,61.878944 
	C593.901245,63.386646 596.632141,64.801163 598.455139,64.859688 
	C607.773315,65.158798 617.106628,65.053322 626.433655,64.974747 
	C629.999756,64.944702 632.359985,65.773178 633.297424,69.853340 
	C633.611389,71.220100 636.604370,72.817093 638.406433,72.858940 
	C650.059814,73.129547 661.722900,73.053368 673.381958,72.970146 
	C677.347656,72.941833 680.655090,73.179604 681.341492,78.321014 
	C681.484680,79.393555 684.249573,80.805855 685.856262,80.873367 
	C692.673523,81.159821 699.510315,81.057022 706.338562,80.977097 
	C709.855164,80.935936 712.368774,81.621872 713.255188,85.769012 
	C713.541138,87.107018 716.181580,88.767799 717.815552,88.848267 
	C724.794556,89.191971 731.800598,89.052605 738.795593,88.980637 
	C742.218445,88.945419 744.352173,89.950027 745.245789,93.722755 
	C745.570190,95.092575 748.117188,96.681526 749.780151,96.844749 
	C754.235046,97.281982 758.783508,96.738548 763.246460,97.132698 
	C765.107544,97.297066 766.846008,98.850540 768.640198,99.772415 
	C767.734436,101.530075 767.138977,103.580269 765.835327,104.962883 
	C764.537292,106.339653 762.353149,106.843224 760.886108,108.110130 
	C759.679871,109.151787 758.954895,110.750824 758.010437,112.475952 
	C757.476562,113.427315 756.948914,114.005547 755.861938,114.813560 
	C754.101318,115.986603 752.899963,116.929855 751.698608,117.873100 
	C751.315674,117.934830 750.932800,117.996559 749.973755,118.165207 
	C748.870361,118.823631 748.343018,119.375137 747.815735,119.926651 
	C747.815735,119.926659 747.384949,119.972649 746.971680,120.140053 
	C746.303345,120.495560 746.085266,120.719696 745.904175,120.979881 
	C745.904175,120.979881 745.443970,121.019394 744.922119,121.124344 
	C743.921875,121.812836 743.443420,122.396370 742.964966,122.979904 
	C742.964966,122.979904 743.005005,122.990814 742.651855,123.007835 
	C741.202026,123.688736 740.105286,124.352623 739.008606,125.016495 
	C738.574829,125.019302 738.141052,125.022118 737.382324,125.035851 
M745.522156,114.017357 
	C744.846191,114.654213 744.170288,115.291069 743.494324,115.927925 
	C743.718750,116.300247 743.943176,116.672569 744.167603,117.044899 
	C745.457397,116.613548 746.796631,116.282402 748.005554,115.686775 
	C748.346313,115.518860 748.305298,114.575966 748.439392,113.988525 
	C747.676941,113.933022 746.914551,113.877510 745.522156,114.017357 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M759.620239,174.747345 
	C759.120972,176.193237 758.350525,177.457397 757.294983,178.883575 
	C756.732727,178.292648 756.367004,177.559158 756.196716,176.782791 
	C755.824463,175.085464 755.551392,173.366379 755.237854,171.656189 
	C756.608276,172.626007 757.978638,173.595825 759.620239,174.747345 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M739.219238,125.260818 
	C740.105286,124.352623 741.202026,123.688736 742.635620,122.993805 
	C743.005371,123.124985 743.055542,123.285851 743.068542,123.449677 
	C743.193481,125.021324 743.312134,126.593483 743.432617,128.165497 
	C742.098328,127.278709 740.764160,126.391914 739.219238,125.260818 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M729.445496,131.002579 
	C731.131714,129.673599 733.181335,128.318909 735.592163,126.919235 
	C733.905273,128.241791 731.857056,129.609329 729.445496,131.002579 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M752.051758,117.863014 
	C752.899963,116.929855 754.101318,115.986603 755.653015,115.017090 
	C754.803894,115.944862 753.604431,116.898888 752.051758,117.863014 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M758.640747,117.735374 
	C758.194824,117.964706 757.806396,117.964508 757.417969,117.964310 
	C757.533325,117.552803 757.648743,117.141304 757.764160,116.729813 
	C758.075562,116.988487 758.386902,117.247162 758.640747,117.735374 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M748.122009,119.888565 
	C748.343018,119.375137 748.870361,118.823631 749.693848,118.246597 
	C749.469421,118.764206 748.948853,119.307343 748.122009,119.888565 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M743.259155,122.917099 
	C743.443420,122.396370 743.921875,121.812836 744.698242,121.181023 
	C744.515259,121.706596 744.034302,122.280449 743.259155,122.917099 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M671.296265,163.980240 
	C671.541260,163.575562 672.072083,163.122543 672.799438,162.830994 
	C672.524658,163.305588 672.053406,163.618744 671.296265,163.980240 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M708.264648,143.265350 
	C708.524780,142.796310 708.982300,142.466553 709.725403,142.090378 
	C709.494690,142.497528 708.978394,142.951080 708.264648,143.265350 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M719.177368,136.905060 
	C719.387817,136.398743 719.896423,135.846130 720.697754,135.264954 
	C720.485535,135.777176 719.980469,136.317978 719.177368,136.905060 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M716.165344,138.891785 
	C716.367554,138.355011 716.881226,137.769089 717.695679,137.128510 
	C717.489868,137.663452 716.983337,138.253052 716.165344,138.891785 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M746.111694,120.866898 
	C746.085266,120.719696 746.303345,120.495560 746.761719,120.193115 
	C746.796265,120.348442 746.581055,120.573494 746.111694,120.866898 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M722.013794,497.104797 
	C723.887390,497.575836 725.760925,498.046906 728.264038,498.676239 
	C723.793701,506.736816 719.424500,514.086487 715.678101,521.741028 
	C714.738708,523.660400 710.163635,527.154663 716.728210,529.361084 
	C718.328308,530.496643 719.397766,531.525879 720.667053,531.933716 
	C724.369568,533.123352 728.557678,533.369080 731.808411,535.265381 
	C736.762939,538.155579 740.976013,542.689270 746.994629,537.491821 
	C748.754822,543.889343 748.440491,544.840210 744.674133,549.047852 
	C743.771484,550.056274 743.536926,551.662659 742.995117,552.994080 
	C742.995117,552.994080 743.000000,553.000000 742.640320,553.021851 
	C739.279358,555.226196 736.124023,557.228027 733.308960,559.628845 
	C727.445129,564.629700 721.761353,569.841675 716.003662,574.966919 
	C716.003662,574.966919 715.521484,574.965210 715.055298,574.723877 
	C709.639038,569.004395 704.721252,563.496033 699.695801,558.087830 
	C699.098572,557.445129 697.912903,557.349304 696.658203,557.069580 
	C694.630249,558.420532 692.944092,559.701965 691.257996,560.983398 
	C690.845947,560.712341 690.433899,560.441223 690.021851,560.170166 
	C690.422668,558.562195 690.781311,556.942078 691.237366,555.349915 
	C691.627075,553.989380 692.444519,552.679565 692.525513,551.314880 
	C692.865967,545.577393 692.557373,539.682373 698.685608,536.276611 
	C699.372742,535.894775 699.559692,534.603149 699.966248,533.727722 
	C702.161072,529.001831 701.631348,522.883118 707.154602,519.820374 
	C707.685669,519.525818 707.575256,518.242004 708.013916,517.585449 
	C710.522095,513.831543 713.075195,510.106201 715.704712,506.436951 
	C716.029602,505.983551 716.881042,505.849487 717.518921,505.687622 
	C722.223389,504.494202 724.196594,501.894043 722.013794,497.104797 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M697.000000,557.000000 
	C697.912903,557.349304 699.098572,557.445129 699.695801,558.087830 
	C704.721252,563.496033 709.639038,569.004395 714.814331,574.728271 
	C714.066345,577.387329 713.695618,580.387085 712.015869,582.112610 
	C704.766602,589.559387 697.133911,596.632812 689.301758,603.510254 
	C688.752380,602.398743 688.585388,600.954590 688.325195,600.937622 
	C682.834351,600.578857 682.942383,595.740784 681.210510,592.345215 
	C680.735779,591.414429 679.732178,590.753357 678.994751,589.604370 
	C680.683960,586.027893 682.256714,582.763000 684.034241,579.613647 
	C688.305054,572.046936 692.671875,564.534363 697.000000,557.000000 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M678.971313,589.968506 
	C679.732178,590.753357 680.735779,591.414429 681.210510,592.345215 
	C682.942383,595.740784 682.834351,600.578857 688.325195,600.937622 
	C688.585388,600.954590 688.752380,602.398743 688.965454,603.569458 
	C683.015076,609.838440 677.024170,615.678894 671.146423,621.631165 
	C670.177551,622.612305 669.705200,624.083740 668.859131,625.882690 
	C668.507446,626.664001 668.268555,626.850952 667.998108,626.997559 
	C665.574463,626.095703 664.900818,624.605408 665.701172,622.011230 
	C666.155090,620.539795 665.629089,618.766113 665.231445,617.010620 
	C666.307739,612.927734 667.696960,608.962769 669.353455,604.398071 
	C671.103149,601.544800 672.585510,599.291321 674.401428,596.952759 
	C675.494324,595.583618 676.253784,594.299500 677.041016,592.775330 
	C677.068726,592.535217 677.108398,592.053406 677.393433,591.957275 
	C678.109863,591.232422 678.541260,590.603638 678.980164,589.979492 
	C678.987732,589.984009 678.971313,589.968506 678.971313,589.968506 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M743.319336,552.909302 
	C743.536926,551.662659 743.771484,550.056274 744.674133,549.047852 
	C748.440491,544.840210 748.754822,543.889343 746.994629,537.491821 
	C740.976013,542.689270 736.762939,538.155579 731.808411,535.265381 
	C728.557678,533.369080 724.369568,533.123352 720.667053,531.933716 
	C719.397766,531.525879 718.328308,530.496643 717.081604,529.396729 
	C726.051575,531.046143 735.117981,533.004578 744.157959,535.077942 
	C747.553589,535.856750 750.888062,536.902405 754.629639,537.901733 
	C753.421509,544.611023 749.126953,549.172852 743.319336,552.909302 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M716.169312,575.240295 
	C721.761353,569.841675 727.445129,564.629700 733.308960,559.628845 
	C736.124023,557.228027 739.279358,555.226196 742.637207,553.019409 
	C740.136230,556.300171 737.505127,559.841064 734.367493,562.854492 
	C729.790833,567.250122 724.914368,571.345459 719.996582,575.361633 
	C719.239563,575.979919 717.578186,575.490723 716.169312,575.240295 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M668.120972,627.337769 
	C668.268555,626.850952 668.507446,626.664001 668.847534,626.252197 
	C670.099304,626.323608 671.218323,626.579590 672.670532,626.926086 
	C673.000916,627.458862 672.998108,627.901123 672.991943,628.675354 
	C671.406982,628.564148 669.825378,628.121033 668.120972,627.337769 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M763.691040,638.855591 
	C762.726746,639.023438 761.497253,638.968201 760.148682,638.650757 
	C761.161743,638.469849 762.293823,638.551147 763.691040,638.855591 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M674.067871,597.037842 
	C672.585510,599.291321 671.103149,601.544800 669.346680,604.012268 
	C670.213013,599.977966 668.655518,594.879456 673.443298,591.666077 
	C674.085022,591.235413 673.385559,587.373047 672.235291,585.993286 
	C669.541870,582.762329 670.065796,580.115723 671.441101,576.441406 
	C672.834167,572.719971 672.975037,568.363647 672.908997,564.297668 
	C672.782288,556.484558 674.224426,549.408752 679.569031,543.336670 
	C680.627380,542.134277 680.824219,539.948364 680.934875,538.185669 
	C681.142944,534.870850 680.986877,531.533875 681.007141,528.206238 
	C681.014343,527.043762 680.620239,525.451599 681.219727,524.789917 
	C684.891479,520.737549 688.814392,516.913879 692.613525,512.975403 
	C694.091736,511.443024 696.709778,509.774261 696.665161,508.229553 
	C696.500305,502.523163 697.991821,497.519379 701.373047,492.499329 
	C706.497803,493.161621 711.223999,494.136688 716.055298,495.402161 
	C717.770996,495.796478 719.381714,495.900391 720.992371,496.004272 
	C721.201233,496.265564 721.410095,496.526855 721.816406,496.946472 
	C724.196594,501.894043 722.223389,504.494202 717.518921,505.687622 
	C716.881042,505.849487 716.029602,505.983551 715.704712,506.436951 
	C713.075195,510.106201 710.522095,513.831543 708.013916,517.585449 
	C707.575256,518.242004 707.685669,519.525818 707.154602,519.820374 
	C701.631348,522.883118 702.161072,529.001831 699.966248,533.727722 
	C699.559692,534.603149 699.372742,535.894775 698.685608,536.276611 
	C692.557373,539.682373 692.865967,545.577393 692.525513,551.314880 
	C692.444519,552.679565 691.627075,553.989380 691.237366,555.349915 
	C690.781311,556.942078 690.422668,558.562195 690.021851,560.170166 
	C690.433899,560.441223 690.845947,560.712341 691.257996,560.983398 
	C692.944092,559.701965 694.630249,558.420532 696.658203,557.069580 
	C692.671875,564.534363 688.305054,572.046936 684.034241,579.613647 
	C682.256714,582.763000 680.683960,586.027893 678.994751,589.604370 
	C678.971313,589.968506 678.987732,589.984009 678.682373,590.059570 
	C677.954102,590.774597 677.531250,591.414001 677.108398,592.053406 
	C677.108398,592.053406 677.068726,592.535217 676.756226,592.936768 
	C675.651794,594.571472 674.859863,595.804626 674.067871,597.037842 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M721.002197,495.561462 
	C719.381714,495.900391 717.770996,495.796478 716.409302,495.247223 
	C716.978210,492.998840 717.696533,491.163055 717.551514,489.398254 
	C716.801270,480.270325 715.830200,471.160522 714.933960,461.673340 
	C716.998535,454.151154 719.065430,447.000000 721.132324,439.848877 
	C722.543884,439.992645 723.955383,440.136383 725.661621,440.455841 
	C724.504089,456.648529 723.052063,472.665588 721.599609,488.682556 
	C721.405029,490.828003 721.207947,492.973267 721.002197,495.561462 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M766.007202,311.665558 
	C764.897400,314.906464 763.790344,317.814758 762.338379,320.419495 
	C761.917114,313.124664 761.833496,313.037628 755.128418,313.003479 
	C754.297424,312.999237 753.466431,313.005188 752.635437,313.000580 
	C743.755005,312.951447 738.960388,309.019806 736.889648,300.313507 
	C736.618591,299.173615 735.713867,298.074066 734.852661,297.203003 
	C729.525146,291.814636 724.021729,286.596069 718.805725,281.103607 
	C716.912476,279.110046 715.130127,276.707275 714.204102,274.161346 
	C712.180359,268.597473 710.765991,262.813721 709.047485,257.136505 
	C707.897827,253.338654 706.842896,249.495621 705.404663,245.805710 
	C703.478333,240.863708 707.474976,237.943542 708.969727,234.167755 
	C711.111938,235.687851 713.254150,237.207962 715.616455,239.284149 
	C715.693298,243.445969 714.651489,247.304291 715.580383,250.608673 
	C718.070679,259.467773 720.898865,268.314209 724.624512,276.707764 
	C726.394165,280.694702 730.359680,283.795074 733.614502,287.009399 
	C736.745117,290.101013 741.967163,292.230103 743.202515,295.833771 
	C745.450073,302.390167 750.614258,302.999786 755.480713,305.011841 
	C759.065308,306.493927 762.505066,308.326202 766.009399,310.002441 
	C766.009583,310.445953 766.009766,310.889435 766.007202,311.665558 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M708.683472,233.969818 
	C707.474976,237.943542 703.478333,240.863708 705.404663,245.805710 
	C706.842896,249.495621 707.897827,253.338654 709.047485,257.136505 
	C710.765991,262.813721 712.180359,268.597473 714.204102,274.161346 
	C715.130127,276.707275 716.912476,279.110046 718.805725,281.103607 
	C724.021729,286.596069 729.525146,291.814636 734.852661,297.203003 
	C735.713867,298.074066 736.618591,299.173615 736.889648,300.313507 
	C738.960388,309.019806 743.755005,312.951447 752.635437,313.000580 
	C753.466431,313.005188 754.297424,312.999237 755.128418,313.003479 
	C761.833496,313.037628 761.917114,313.124664 761.989380,320.549774 
	C756.946472,320.981140 751.907654,320.978699 746.425293,320.963623 
	C744.420654,317.392151 742.678223,314.404572 738.152771,313.535736 
	C736.497009,313.217834 735.249207,310.156219 734.056946,308.199036 
	C732.579529,305.773682 731.772583,302.817444 729.925232,300.766968 
	C726.739685,297.231415 723.033630,294.150696 719.440674,290.999237 
	C711.295410,283.855011 707.587708,274.169220 703.956116,264.407318 
	C701.990173,259.122589 698.870178,254.032089 698.107910,248.596329 
	C697.224915,242.299973 698.359375,235.720718 698.972900,229.017273 
	C702.350891,230.437500 705.374084,232.104691 708.683472,233.969818 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M766.230347,309.718445 
	C762.505066,308.326202 759.065308,306.493927 755.480713,305.011841 
	C750.614258,302.999786 745.450073,302.390167 743.202515,295.833771 
	C741.967163,292.230103 736.745117,290.101013 733.614502,287.009399 
	C730.359680,283.795074 726.394165,280.694702 724.624512,276.707764 
	C720.898865,268.314209 718.070679,259.467773 715.580383,250.608673 
	C714.651489,247.304291 715.693298,243.445969 715.912720,239.437958 
	C718.458191,240.592133 720.927368,242.148575 723.691467,243.862793 
	C724.072144,256.280151 727.351562,267.566895 734.733765,277.420990 
	C736.770752,280.139984 739.656555,282.224426 742.161560,284.590942 
	C744.433105,286.736877 747.318298,288.511353 748.857300,291.091278 
	C751.331116,295.238281 754.764221,297.004700 759.226807,297.866730 
	C768.696960,299.696014 768.952209,300.225983 766.230347,309.718445 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M686.911499,637.668701 
	C685.116577,636.856201 683.228394,635.715881 681.171509,634.285339 
	C682.941284,635.110413 684.879822,636.225708 686.911499,637.668701 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M676.926636,631.659790 
	C675.683899,631.249023 674.371277,630.495544 673.025879,629.376587 
	C674.280945,629.779785 675.568787,630.548462 676.926636,631.659790 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M692.919678,640.668396 
	C691.693298,640.239990 690.375488,639.492188 689.024536,638.378845 
	C690.270386,638.791870 691.549316,639.570435 692.919678,640.668396 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M663.345703,618.938599 
	C663.477295,618.384338 663.902710,617.743408 664.621094,617.015808 
	C664.489258,617.570129 664.064392,618.211060 663.345703,618.938599 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M762.042664,353.036896 
	C763.732788,351.393707 765.422913,349.750488 767.433228,348.093140 
	C764.844238,366.283112 761.906799,384.482849 759.037476,402.693329 
	C757.563599,412.047333 756.211182,421.420502 754.754517,431.188873 
	C739.091797,433.057648 723.478455,434.522552 707.259888,435.769318 
	C706.828735,434.219635 706.599792,432.639282 707.243042,431.597382 
	C711.113586,425.328827 715.184143,419.183777 719.492065,412.899292 
	C723.812073,407.402649 727.916199,402.067078 731.822937,396.590698 
	C737.629639,388.450958 743.296692,380.211578 749.305054,371.827179 
	C753.202087,366.774048 756.871460,361.947083 760.391663,357.013641 
	C761.204102,355.875061 761.505920,354.372162 762.042664,353.036896 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M272.865356,45.803253 
	C272.724792,45.837349 272.481964,45.634781 272.135742,45.196629 
	C272.277588,45.160683 272.521149,45.362534 272.865356,45.803253 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M201.753418,44.997242 
	C202.005737,44.997246 201.646896,45.277493 201.646896,45.277493 
	C201.646896,45.277493 201.213165,45.223278 201.104813,45.111073 
	C201.164673,44.998325 201.332886,44.997784 201.753418,44.997242 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M497.558167,46.006657 
	C496.337524,49.611977 493.209076,50.159401 488.143738,47.353043 
	C491.038086,46.665089 494.075500,46.334251 497.558167,46.006657 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M460.708954,46.764584 
	C459.230194,49.007999 457.497498,50.374306 456.030121,46.350502 
	C457.475708,46.177589 458.946564,46.358536 460.708954,46.764584 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M424.711426,45.773521 
	C422.864349,46.149090 420.729309,46.300011 418.297729,46.225067 
	C420.141968,45.849094 422.282745,45.698982 424.711426,45.773521 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M429.787811,45.792168 
	C429.141907,46.126507 428.283234,46.261284 427.212036,46.194878 
	C427.858002,45.859997 428.716522,45.726299 429.787811,45.792168 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M501.783844,45.798786 
	C501.246307,46.000446 500.498047,46.000175 499.375671,45.999184 
	C499.858704,45.864590 500.715942,45.730724 501.783844,45.798786 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M485.860535,45.803955 
	C485.720886,45.836590 485.481079,45.633667 485.139069,45.197067 
	C485.280121,45.162106 485.521271,45.363773 485.860535,45.803955 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M466.802307,45.138916 
	C466.834991,45.278595 466.632263,45.519020 466.195679,45.861984 
	C466.160767,45.720936 466.362427,45.479317 466.802307,45.138916 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M451.895020,45.881580 
	C452.002472,45.993557 451.502686,45.997856 451.252838,45.998829 
	C451.212463,45.699547 451.473969,45.622814 451.895020,45.881580 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M438.889587,45.886997 
	C438.995117,46.000534 438.496338,45.998161 438.246948,45.995834 
	C438.210693,45.696220 438.472870,45.622871 438.889587,45.886997 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M719.187805,412.997375 
	C715.184143,419.183777 711.113586,425.328827 707.243042,431.597382 
	C706.599792,432.639282 706.828735,434.219635 706.838867,435.797058 
	C711.474976,437.227966 715.926941,438.412994 720.755615,439.723450 
	C719.065430,447.000000 716.998535,454.151154 714.597046,461.557465 
	C711.164124,460.875641 708.117554,459.407898 704.957947,459.099884 
	C694.918152,458.121033 684.835205,457.586945 674.772766,456.829620 
	C674.164612,456.783844 673.590149,456.290161 672.999084,455.579834 
	C672.467590,449.093964 669.461731,443.033569 673.101685,436.642853 
	C675.117676,435.185669 677.034424,434.069794 678.935303,432.927490 
	C683.306458,430.300507 687.669922,427.660706 692.432129,425.023590 
	C693.952332,424.811768 695.190796,424.837280 696.183533,424.355927 
	C703.878357,420.624847 711.525696,416.795959 719.187805,412.997375 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M337.558167,46.006660 
	C336.337524,49.611977 333.209076,50.159401 328.143738,47.353043 
	C331.038086,46.665096 334.075500,46.334259 337.558167,46.006660 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M341.783844,45.798790 
	C341.246307,46.000446 340.498047,46.000175 339.375671,45.999187 
	C339.858704,45.864601 340.715942,45.730732 341.783844,45.798790 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M325.860535,45.803955 
	C325.720886,45.836590 325.481079,45.633667 325.139069,45.197067 
	C325.280121,45.162106 325.521271,45.363773 325.860535,45.803955 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M144.552689,124.999367 
	C144.235443,131.483963 143.485046,137.969452 142.534500,144.761353 
	C137.898041,143.819321 133.358490,141.374481 129.061905,141.745285 
	C126.222466,141.990326 122.570129,145.801819 121.317505,148.852478 
	C118.346336,156.088455 108.453392,161.700714 101.112221,158.656876 
	C99.582527,158.022629 98.544250,155.032822 98.181122,152.969879 
	C96.985168,146.175629 95.791977,139.515137 91.777733,133.585922 
	C90.673264,131.954575 91.171356,129.238251 91.081062,128.393951 
	C94.619141,127.385788 97.154633,126.660896 99.691589,125.941200 
	C101.572472,125.407639 103.434738,124.522575 105.340775,124.413376 
	C113.375221,123.953087 121.423004,123.725655 129.465820,123.410851 
	C129.958817,123.391548 130.674988,123.126099 130.910385,123.357933 
	C134.912506,127.299210 139.704010,124.626915 144.552689,124.999367 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M201.052383,225.291626 
	C200.862442,223.185715 200.492126,221.883575 197.668182,222.842377 
	C192.996796,224.428436 188.145279,225.483932 183.146286,226.468628 
	C186.541794,224.278183 185.031998,221.143570 184.796005,218.058044 
	C185.545959,217.151749 186.319092,216.509598 187.092224,215.867432 
	C199.672485,209.833252 212.252762,203.799103 225.058319,197.489334 
	C229.287384,197.114944 233.291153,197.016159 238.023666,197.024750 
	C241.320663,201.099091 243.888901,205.066040 246.036255,209.022827 
	C240.255325,211.141541 234.946381,213.411972 229.523972,215.367874 
	C220.070251,218.777878 210.546677,221.994156 201.052383,225.291626 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M187.127563,215.482056 
	C186.319092,216.509598 185.545959,217.151749 184.420609,218.134277 
	C174.710693,222.796127 165.352997,227.117599 155.995300,231.439087 
	C158.183411,222.820236 160.371521,214.201370 162.788330,205.292206 
	C168.129028,204.003036 173.220306,202.873779 178.361237,202.056992 
	C181.188690,201.607773 183.150986,202.419083 184.432465,205.707855 
	C185.238449,207.776321 188.284485,208.971954 190.139679,210.414581 
	C188.693771,212.688812 187.928345,213.892746 187.127563,215.482056 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M246.457153,209.032990 
	C243.888901,205.066040 241.320663,201.099091 238.488800,196.916153 
	C238.225159,195.761307 238.225159,194.822433 238.225159,193.117950 
	C243.907059,196.327423 249.046997,199.230759 254.523193,202.266968 
	C260.495209,206.060760 266.048279,209.857437 271.785583,213.351562 
	C280.945343,218.929977 290.227570,224.307297 299.727783,230.260712 
	C296.060181,243.853516 292.122833,256.955261 287.806427,270.032532 
	C285.296265,267.654022 282.982483,265.438202 281.074005,262.915741 
	C277.628479,258.361786 274.425232,253.624573 271.089386,248.604446 
	C269.418457,245.771545 267.778961,243.298080 266.067139,240.492554 
	C264.421265,237.737228 262.847717,235.313950 261.191254,232.542572 
	C259.169830,229.365433 257.231323,226.536377 255.209625,223.460815 
	C254.828537,222.797134 254.530670,222.379959 254.154907,221.619202 
	C252.718689,219.183853 251.360367,217.092102 249.924255,214.712494 
	C249.103897,213.569992 248.361313,212.715363 247.581238,211.543808 
	C247.181549,210.495575 246.819351,209.764282 246.457153,209.032990 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M261.239197,189.724182 
	C256.671448,190.959290 253.725571,188.859436 251.503662,185.326828 
	C253.230545,185.483185 254.749496,185.931244 256.927094,186.573593 
	C256.166290,183.248123 255.564056,180.458115 254.887543,177.686218 
	C252.092545,166.234344 244.167709,158.637390 234.316559,153.758743 
	C228.684967,150.969788 221.562149,151.097214 215.065918,150.259216 
	C214.172699,150.144012 213.019852,152.041748 211.987183,153.007538 
	C210.052750,151.039734 208.118332,149.071915 206.183899,147.104095 
	C208.439438,145.743393 210.717621,143.197449 212.946381,143.239975 
	C220.182816,143.378113 228.346771,142.583542 234.403503,145.628830 
	C243.359802,150.132019 253.616638,154.565765 257.171387,165.739044 
	C259.044067,171.625122 261.022125,177.492523 262.501099,183.479904 
	C262.944183,185.273773 261.863190,187.444122 261.239197,189.724182 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M188.043869,126.343300 
	C192.351425,124.145485 197.037582,122.784111 200.987762,120.267517 
	C204.531189,118.010071 207.898239,116.603851 212.065857,116.970192 
	C214.040482,117.143776 216.132721,117.379410 218.017258,116.933830 
	C225.878357,115.075157 233.326920,118.410027 238.986603,121.970276 
	C243.987991,125.116425 248.776764,128.437973 254.305435,130.732925 
	C255.418243,131.194855 255.919510,133.130035 256.855164,135.102997 
	C257.007538,136.878021 257.007416,137.936005 257.007324,138.993988 
	C254.659363,138.198746 251.063934,138.078644 250.199097,136.480713 
	C247.682159,131.830154 242.952530,131.148849 239.243317,130.679779 
	C229.780563,129.483109 221.035355,125.107079 211.074493,125.718956 
	C204.044479,126.150810 198.043228,128.706772 191.354904,130.931229 
	C190.028458,129.563858 189.065277,128.120377 188.043869,126.343300 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M212.160370,153.293243 
	C213.019852,152.041748 214.172699,150.144012 215.065918,150.259216 
	C221.562149,151.097214 228.684967,150.969788 234.316559,153.758743 
	C244.167709,158.637390 252.092545,166.234344 254.887543,177.686218 
	C255.564056,180.458115 256.166290,183.248123 256.927094,186.573593 
	C254.749496,185.931244 253.230545,185.483185 251.352875,185.020050 
	C250.151672,184.156204 249.309219,183.307419 248.368317,181.930954 
	C248.610168,173.723892 244.116653,168.696198 238.390472,164.844360 
	C234.231812,162.046936 229.427551,160.198868 224.877197,157.997635 
	C221.708755,156.464890 221.135391,158.479614 221.007385,160.983673 
	C218.116104,158.515427 215.224823,156.047195 212.160370,153.293243 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M221.292572,161.158554 
	C221.135391,158.479614 221.708755,156.464890 224.877197,157.997635 
	C229.427551,160.198868 234.231812,162.046936 238.390472,164.844360 
	C244.116653,168.696198 248.610168,173.723892 248.144424,181.729691 
	C248.018982,182.056122 247.641983,182.316879 247.641983,182.316879 
	C247.641983,182.316879 247.209305,182.232925 246.968445,181.920334 
	C246.521179,181.362686 246.279190,181.160172 246.001572,181.000214 
	C245.515503,180.739532 245.029419,180.478851 244.298004,180.093597 
	C239.837067,176.213242 235.704193,172.358673 231.378159,168.734711 
	C228.244034,166.109207 224.853790,163.789505 221.292572,161.158554 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M183.067932,121.304153 
	C183.606430,121.475136 184.224930,121.943092 184.918488,122.711044 
	C184.378357,122.541077 183.763138,122.071098 183.067932,121.304153 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M185.078003,124.297607 
	C185.616669,124.468071 186.231369,124.936844 186.922836,125.703575 
	C186.384415,125.532997 185.769226,125.064453 185.078003,124.297607 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M359.612488,529.193787 
	C359.924866,528.626892 360.237274,528.059998 361.147034,527.395142 
	C362.723724,527.361633 363.703094,527.426086 364.786804,527.917542 
	C365.071991,537.922852 365.252869,547.501099 365.365417,557.338013 
	C365.528259,558.055237 365.759460,558.513855 366.014221,559.307007 
	C366.451691,560.138245 366.865601,560.634888 367.293243,561.289429 
	C367.419861,561.661743 367.596558,561.753357 368.134521,561.997681 
	C373.519501,564.781372 378.464020,565.467224 383.322144,561.003662 
	C383.883087,560.297302 384.136169,559.716248 384.333801,559.119385 
	C384.278320,559.103516 384.262573,559.217834 384.502625,558.974976 
	C384.801666,557.844482 384.860626,556.956787 385.028076,555.727661 
	C385.094818,554.914307 385.053009,554.442261 385.011230,553.970276 
	C385.114807,545.704285 385.218384,537.438354 385.498505,528.563171 
	C385.965851,527.456543 386.256653,526.959045 386.547455,526.461609 
	C386.547455,526.461609 386.514252,526.605713 386.597809,526.602417 
	C386.681366,526.599060 386.704559,526.644348 386.704559,526.644348 
	C386.888855,526.748901 387.073181,526.853516 387.730804,527.065430 
	C388.204102,527.172852 388.595917,527.207092 388.595917,527.207092 
	C388.595917,527.207092 388.950775,527.037109 388.950775,527.037109 
	C389.607574,527.512268 390.264343,527.987427 390.968628,529.166016 
	C391.090637,532.194397 391.165192,534.519409 391.168701,537.290222 
	C391.151459,539.536865 391.205231,541.337646 391.150085,543.573608 
	C391.112610,547.623718 391.184021,551.238708 391.167053,555.252930 
	C391.059387,556.467224 391.040070,557.282166 390.847229,558.394653 
	C390.305481,560.017029 389.937256,561.341919 389.569031,562.666870 
	C389.569000,562.666870 389.847931,562.940552 389.452942,562.915405 
	C388.679443,563.558350 388.300995,564.226501 387.922516,564.894592 
	C387.582611,564.973328 387.313629,565.156311 386.651306,565.731873 
	C373.968811,572.024536 364.849396,569.645508 359.720306,559.115662 
	C359.716156,554.641785 359.712006,550.167847 359.744141,544.791138 
	C359.724457,538.990173 359.668457,534.091980 359.612488,529.193787 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M95.140205,709.013184 
	C95.258438,708.146301 95.217712,707.496399 95.520775,707.208984 
	C99.814728,703.136292 104.183868,699.142822 108.464912,695.056885 
	C109.554810,694.016663 110.235954,692.514404 111.395538,691.588318 
	C113.410332,689.979370 115.595352,687.671326 117.816902,687.544312 
	C119.378563,687.455017 122.516808,690.560486 122.444901,692.124207 
	C122.342804,694.344421 120.378494,697.614868 118.418282,698.363342 
	C114.967567,699.680908 115.004349,701.808289 114.984230,704.525452 
	C114.914001,714.014893 106.788162,718.763489 98.435013,714.015503 
	C96.944054,713.167969 96.219543,710.972046 95.140205,709.013184 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M344.004303,568.996155 
	C337.977264,571.781677 343.409668,573.458008 345.670959,576.686401 
	C343.078766,577.089172 341.204803,577.852417 339.528992,577.542847 
	C335.990143,576.889221 332.820526,575.613281 329.294220,578.384583 
	C327.541412,579.762085 324.205658,579.933716 323.444305,576.778870 
	C326.456207,575.532654 329.193939,574.543091 331.677612,573.112488 
	C331.886292,572.992188 330.388458,569.909302 329.339844,567.721741 
	C328.994324,566.494995 328.992340,565.749329 329.322906,564.897583 
	C332.626282,562.998596 335.597137,561.205750 339.791443,558.674500 
	C337.296509,557.954712 335.646088,557.478638 333.621948,557.002441 
	C332.500854,557.002441 331.753479,557.002441 330.758972,557.153320 
	C329.347443,558.534912 328.183075,559.765625 327.015259,560.662537 
	C327.013611,559.883667 327.015320,559.438538 327.272430,558.736206 
	C328.019165,556.321777 328.510559,554.164551 329.452942,552.003967 
	C330.893341,551.999329 331.882843,551.987244 332.872101,551.998413 
	C343.566376,552.118652 344.005005,552.555847 344.002289,563.059692 
	C344.001770,565.038513 344.003571,567.017334 344.004303,568.996155 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M208.002899,615.877197 
	C206.154144,611.396606 207.936981,608.968994 211.679810,607.236572 
	C213.000504,606.625366 214.440460,605.514404 215.049698,604.255859 
	C217.565231,599.059204 228.951889,596.822083 233.071854,600.885071 
	C236.227493,603.997070 235.038284,609.090942 230.667923,610.472595 
	C228.974182,611.007996 227.054092,611.019897 225.246948,610.962219 
	C221.909698,610.855774 219.432404,611.004089 218.121506,615.205688 
	C216.684418,619.811707 212.583969,619.941528 208.002899,615.877197 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M195.048218,629.038879 
	C192.630188,634.836731 181.593201,637.268433 177.151093,633.327271 
	C173.837326,630.387207 174.768692,625.180664 179.051865,623.647949 
	C180.714706,623.052917 182.647125,622.989868 184.446014,623.043701 
	C187.942490,623.148193 190.767685,623.059326 192.027893,618.633362 
	C192.473755,617.067505 196.036652,615.237610 197.850555,615.532593 
	C199.709030,615.834778 202.250107,618.448853 202.494904,620.313354 
	C202.734360,622.137085 200.675919,624.477295 199.201553,626.234619 
	C198.243195,627.376892 196.524689,627.881531 195.048218,629.038879 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M145.876572,655.000366 
	C148.732712,655.819031 151.732941,656.009155 153.438324,657.593201 
	C156.485229,660.423401 154.688751,665.403320 150.440887,666.540649 
	C148.404236,667.085938 146.160721,667.016296 144.017120,666.964905 
	C141.295410,666.899719 139.681381,667.495117 138.363098,670.435547 
	C137.473511,672.419800 134.298019,674.350037 132.074036,674.434570 
	C130.510345,674.493958 127.421219,671.306458 127.533112,669.750000 
	C127.693275,667.522156 130.035187,665.361511 131.684814,663.370483 
	C132.445587,662.452148 134.540375,662.166748 134.721313,661.315979 
	C136.031006,655.158203 140.483795,654.738281 145.876572,655.000366 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M423.000549,571.336548 
	C425.572906,569.389465 428.020599,567.686768 427.670624,573.097107 
	C427.601990,574.158325 429.321411,575.335144 430.216187,576.458679 
	C430.620789,575.218445 431.444122,573.943604 431.345154,572.744995 
	C431.120300,570.022278 432.084595,568.864868 434.733826,569.454773 
	C437.735718,570.123169 439.388794,569.267639 440.188507,566.091003 
	C440.493073,564.881226 442.135834,563.610779 443.446228,563.139404 
	C445.757812,562.307800 448.282410,562.068176 451.517273,561.420715 
	C446.908752,565.720459 450.566711,567.024780 453.358429,568.974670 
	C454.837128,570.007568 455.780762,571.806458 456.984192,573.628601 
	C455.934113,573.998169 454.865784,573.998718 453.333618,573.662354 
	C451.376404,572.102234 449.883026,570.879089 448.389618,569.655945 
	C448.260559,571.434021 448.131500,573.212158 448.002441,574.990234 
	C446.747986,578.914917 444.086761,579.093506 440.508636,578.353699 
	C437.982208,577.831299 435.223206,578.317627 432.579376,578.509583 
	C429.583221,578.727234 426.317017,580.473816 424.285339,576.138916 
	C423.932861,574.698792 423.469482,573.853027 423.006104,573.007324 
	C423.004456,572.561768 423.002777,572.116272 423.000549,571.336548 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M327.017059,558.993469 
	C327.015320,559.438538 327.013611,559.883667 327.060120,560.988159 
	C327.735687,562.766296 328.363037,563.885010 328.990356,565.003723 
	C328.992340,565.749329 328.994324,566.494995 328.996918,567.612976 
	C328.997559,567.985352 328.995056,567.999451 328.707855,568.046997 
	C327.946106,568.389893 327.471588,568.685364 326.997070,568.980835 
	C325.534851,569.557190 324.072632,570.133606 322.000916,570.709229 
	C319.928864,570.137146 318.466248,569.565857 316.955994,568.710571 
	C316.610687,567.947571 316.313049,567.468445 316.015442,566.989380 
	C319.709900,555.173279 317.360504,543.022522 317.987823,531.036804 
	C318.262512,525.788086 318.964325,524.988342 322.584290,526.698364 
	C321.571808,528.282715 320.008911,529.414978 319.989349,530.573303 
	C319.798737,541.861084 319.793243,553.154419 319.969025,564.442444 
	C319.987274,565.614319 321.518646,566.762695 322.347931,567.921936 
	C323.262695,566.694092 324.800446,565.550110 324.978912,564.223206 
	C325.587219,559.700623 324.036926,553.033569 326.455963,551.080139 
	C329.447968,548.664001 335.593597,550.272339 340.357117,549.894592 
	C344.441559,549.570618 345.925629,551.109009 345.866058,555.271362 
	C345.803467,559.639832 346.576355,564.020386 346.984558,568.658020 
	C347.000031,569.269470 347.015076,569.618469 347.030151,569.967529 
	C346.164337,569.761475 345.298553,569.555420 344.218506,569.172729 
	C344.003571,567.017334 344.001770,565.038513 344.002289,563.059692 
	C344.005005,552.555847 343.566376,552.118652 332.872101,551.998413 
	C331.882843,551.987244 330.893341,551.999329 329.148254,552.216553 
	C327.934052,554.619446 327.475555,556.806458 327.017059,558.993469 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M263.231262,571.269287 
	C264.234802,570.800354 265.463776,570.490173 266.706329,570.419739 
	C272.631378,570.083923 278.795135,570.720764 284.421295,569.263672 
	C290.029785,567.811218 293.154816,570.640564 296.921753,573.650513 
	C293.408264,575.212036 289.852386,576.540405 286.215210,577.585876 
	C284.444763,578.094849 282.505402,578.349670 280.672455,578.236084 
	C277.267029,578.024963 273.801727,576.803711 270.512604,577.214478 
	C266.759460,577.683167 264.841217,577.076355 264.620575,573.093201 
	C264.590790,572.555237 263.865936,572.055847 263.231262,571.269287 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M74.988098,803.621948 
	C74.731293,808.057312 72.909485,811.002136 68.650574,810.749451 
	C64.398834,810.497192 62.990810,807.264099 63.010284,803.343933 
	C63.020969,801.192871 62.890480,799.021851 63.134712,796.895569 
	C63.520535,793.536499 65.452721,791.260071 68.923683,791.213379 
	C72.396614,791.166626 74.401848,793.383362 74.838692,796.743835 
	C75.114220,798.863342 74.958092,801.038940 74.988098,803.621948 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M264.031494,586.079163 
	C267.644073,582.908936 276.122406,581.654236 279.549713,584.000061 
	C281.094147,585.057190 282.764374,587.465881 282.600861,589.081543 
	C282.410614,590.961548 280.519135,593.850403 278.933411,594.172607 
	C275.150909,594.941162 270.967896,595.120605 267.221222,594.293823 
	C263.481567,593.468506 262.230438,590.236023 264.031494,586.079163 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M493.097046,577.338867 
	C493.167084,576.555969 493.508392,576.096924 493.470917,575.671204 
	C493.296814,573.693909 493.017822,571.725891 492.776428,569.754517 
	C495.163666,569.670959 497.551514,569.600830 499.937988,569.499695 
	C502.077393,569.409180 504.215363,569.284546 506.677063,569.091064 
	C506.569580,570.887756 505.986115,572.749512 505.751465,574.654175 
	C505.519592,576.536316 506.038300,578.582642 505.505829,580.337952 
	C504.832214,582.558472 503.755096,585.945618 502.256927,586.297791 
	C500.423218,586.728760 497.597595,584.938782 495.905060,583.356018 
	C494.478729,582.022217 494.059052,579.611877 493.097046,577.338867 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M315.655273,567.021301 
	C316.313049,567.468445 316.610687,567.947571 316.936218,569.046631 
	C316.642761,571.444458 316.321381,573.222229 316.000000,575.000000 
	C313.963745,576.322571 312.078735,578.096130 309.849640,578.843750 
	C307.510101,579.628418 304.790619,579.825867 302.333954,579.514893 
	C300.813202,579.322388 299.472931,577.704163 298.026428,576.362976 
	C299.979340,576.367920 301.957489,576.735840 304.321686,577.175537 
	C303.499664,574.157043 302.919434,572.026306 302.339203,569.895630 
	C302.743256,569.563660 303.147278,569.231689 303.551300,568.899780 
	C305.833099,570.963684 308.114868,573.027649 309.905396,574.647217 
	C311.964264,571.746277 313.629669,569.399719 315.655273,567.021301 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M170.798080,644.250305 
	C170.732285,648.088196 168.924469,650.619263 165.563126,650.635315 
	C163.667191,650.644348 160.926056,649.113647 160.097305,647.493164 
	C159.278534,645.892273 159.687943,642.810852 160.790634,641.248413 
	C163.498627,637.411438 168.035019,638.762878 170.798080,644.250305 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M87.193130,746.337830 
	C82.583168,747.535522 79.575005,745.581665 79.452530,741.586426 
	C79.389832,739.540894 81.787781,736.448486 83.834610,735.535950 
	C86.955505,734.144409 90.071037,736.285156 90.396667,739.578918 
	C90.602921,741.665283 88.567673,743.973267 87.193130,746.337830 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M71.385544,766.480469 
	C70.883957,762.097229 72.905785,759.219849 76.660225,759.412109 
	C78.764595,759.519897 82.028023,762.135986 82.384285,764.062805 
	C82.730118,765.933472 80.519714,769.270935 78.549210,770.345154 
	C75.508430,772.002869 72.923775,769.949158 71.385544,766.480469 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M74.337402,839.200500 
	C70.819252,843.336487 67.708176,843.944031 64.998711,841.154663 
	C62.559666,838.643738 62.227638,835.530701 64.812752,833.247681 
	C66.251152,831.977295 69.130737,831.278137 70.979118,831.766418 
	C74.232834,832.625977 75.475372,835.412659 74.337402,839.200500 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M87.384766,723.514282 
	C89.735909,721.863831 91.915962,719.734985 94.213921,719.598999 
	C95.637764,719.514832 98.186226,722.294434 98.494499,724.086975 
	C98.796471,725.842834 97.406425,728.645264 95.867279,729.813660 
	C92.002655,732.747498 87.809029,729.698669 87.384766,723.514282 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M74.337402,855.200500 
	C70.819260,859.336487 67.708229,859.943970 64.998749,857.154663 
	C62.559666,854.643738 62.227680,851.530762 64.812752,849.247681 
	C66.251152,847.977295 69.130737,847.278137 70.979118,847.766418 
	C74.232834,848.625977 75.475372,851.412659 74.337402,855.200500 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M247.200516,591.662598 
	C251.336502,595.180725 251.944000,598.291870 249.154617,601.001343 
	C246.643646,603.440247 243.530655,603.772400 241.247604,601.187256 
	C239.977249,599.748840 239.278290,596.869263 239.766541,595.020813 
	C240.626022,591.767029 243.412704,590.524536 247.200516,591.662598 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M391.259003,543.138428 
	C391.205231,541.337646 391.151459,539.536865 391.363098,536.970581 
	C391.764679,533.820190 391.900818,531.435364 392.036987,529.050476 
	C392.691284,530.732544 393.783386,532.378418 393.926178,534.102844 
	C394.646790,542.805847 395.120178,551.529358 395.872223,560.900757 
	C396.042755,563.037231 396.021362,564.518616 396.000671,566.374512 
	C396.002136,567.497803 396.002899,568.246582 396.001831,568.997681 
	C396.000000,569.000000 395.998779,569.000000 395.998779,569.000000 
	C391.799011,568.723328 393.015076,565.017578 391.988129,561.977905 
	C391.645691,560.194275 391.333221,559.145752 391.020752,558.097168 
	C391.040070,557.282166 391.059387,556.467224 391.359802,554.919434 
	C391.513611,550.503967 391.386322,546.821167 391.259003,543.138428 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M401.001587,569.997559 
	C404.696198,570.000916 408.390808,570.004333 412.546997,570.012451 
	C413.005493,570.762695 413.002441,571.508240 412.794983,572.829224 
	C412.393951,573.936401 412.197296,574.468201 412.000671,575.000000 
	C408.249878,575.613892 404.499054,576.227783 400.038208,576.772461 
	C397.551727,575.802063 395.775238,574.901062 393.998779,574.000000 
	C394.663269,572.572449 395.327759,571.144958 395.995544,569.358704 
	C395.998779,569.000000 396.000000,569.000000 396.306580,568.859497 
	C397.462463,568.441650 398.311798,568.164246 399.138855,567.886963 
	C399.116577,567.886963 399.159393,567.849976 399.159393,567.849976 
	C399.307434,568.418579 399.455475,568.987122 400.058167,569.774292 
	C400.512817,569.992859 401.001587,569.997559 401.001587,569.997559 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M359.561615,559.317383 
	C364.849396,569.645508 373.968811,572.024536 386.552429,565.959900 
	C385.835480,567.222778 384.753235,568.546082 382.974182,569.958496 
	C380.517731,570.708435 378.758057,571.369080 376.998383,572.029785 
	C373.954071,571.678040 370.909729,571.326355 367.188477,570.748291 
	C365.258972,569.465881 363.765900,568.589905 362.809631,567.312134 
	C361.344971,565.355103 360.250214,563.121216 358.998413,561.004883 
	C359.133240,560.509583 359.268097,560.014343 359.561615,559.317383 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M358.669556,560.888733 
	C360.250214,563.121216 361.344971,565.355103 362.809631,567.312134 
	C363.765900,568.589905 365.258972,569.465881 366.756775,570.747437 
	C366.534210,571.596375 366.066467,572.219727 365.299377,572.921509 
	C364.156830,572.473999 363.364380,571.830505 362.462219,571.441284 
	C356.812866,569.003418 353.401154,560.772888 355.672302,554.863037 
	C355.750305,554.660034 356.231171,554.611877 356.925201,554.326904 
	C357.406525,556.518738 357.873596,558.645691 358.669556,560.888733 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M377.072815,572.370239 
	C378.758057,571.369080 380.517731,570.708435 382.638092,570.027527 
	C383.048737,572.965332 387.677246,576.621765 381.369019,578.832153 
	C379.979156,576.816895 378.563202,574.763794 377.072815,572.370239 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M393.643250,573.984924 
	C395.775238,574.901062 397.551727,575.802063 399.664093,576.851562 
	C396.288422,580.404907 390.290131,578.958252 388.465973,573.533691 
	C390.281281,573.697876 391.784515,573.833862 393.643250,573.984924 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M460.074158,577.343445 
	C465.745209,578.292664 466.423218,574.069824 467.420624,569.246155 
	C468.803192,571.024353 469.740448,572.229797 470.838562,573.714722 
	C469.922394,574.953674 468.598633,575.746399 467.816254,576.904785 
	C465.728851,579.995605 462.832520,580.378418 460.074158,577.343445 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M448.372559,574.989258 
	C448.131500,573.212158 448.260559,571.434021 448.389618,569.655945 
	C449.883026,570.879089 451.376404,572.102234 452.933044,573.661499 
	C451.578461,574.327820 450.160583,574.658081 448.372559,574.989258 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M316.353516,574.984680 
	C316.321381,573.222229 316.642761,571.444458 316.983887,569.330566 
	C318.466248,569.565857 319.928864,570.137146 321.695984,570.855469 
	C320.236053,572.324768 318.471558,573.647095 316.353516,574.984680 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M412.327209,575.092346 
	C412.197296,574.468201 412.393951,573.936401 412.794678,573.202271 
	C413.660919,573.007446 414.430206,572.799927 414.963531,573.065796 
	C416.180054,573.672180 417.369324,574.414978 418.359009,575.330200 
	C418.573273,575.528320 417.737946,577.323975 417.662018,577.304504 
	C416.373444,576.975098 415.116852,576.503601 413.880096,575.997803 
	C413.434906,575.815735 413.060486,575.460754 412.327209,575.092346 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M484.023376,575.360474 
	C485.823242,575.516418 487.647675,576.027893 489.735474,576.769653 
	C487.376465,579.532104 485.287720,579.591370 484.023376,575.360474 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M472.157410,574.269897 
	C473.477661,572.151428 474.924042,570.700439 475.980255,574.657166 
	C474.348358,578.302917 473.448883,575.470947 472.157410,574.269897 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M352.677124,576.700745 
	C351.822968,576.843750 351.079163,576.695251 350.335388,576.546753 
	C350.753021,575.916077 351.170624,575.285400 351.588196,574.654663 
	C351.987946,575.239563 352.387726,575.824402 352.677124,576.700745 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M258.079773,577.284668 
	C258.300537,576.523865 258.602295,576.045410 258.953247,575.282227 
	C259.455719,575.916443 259.908997,576.835327 260.362274,577.754211 
	C259.628448,577.691772 258.894592,577.629395 258.079773,577.284668 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M390.847229,558.394653 
	C391.333221,559.145752 391.645691,560.194275 391.959839,561.585693 
	C391.377960,562.167603 390.794342,562.406616 389.889893,562.656250 
	C389.937256,561.341919 390.305481,560.017029 390.847229,558.394653 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M347.397278,569.973145 
	C347.015076,569.618469 347.000031,569.269470 347.350037,568.515198 
	C348.749298,567.960815 349.783508,567.811707 350.817688,567.662598 
	C350.623016,568.245605 350.428375,568.828613 350.100708,569.690613 
	C349.233246,569.972595 348.498871,569.975647 347.397278,569.973145 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M423.017090,573.334595 
	C423.469482,573.853027 423.932861,574.698792 424.198730,575.770020 
	C423.676849,575.217712 423.352478,574.439819 423.017090,573.334595 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M361.153381,576.250122 
	C361.750000,576.000000 362.500000,576.000000 363.625000,576.000000 
	C363.102264,576.166748 362.204529,576.333496 361.153381,576.250122 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M388.246124,564.839661 
	C388.300995,564.226501 388.679443,563.558350 389.326172,562.766724 
	C389.252869,563.357056 388.911285,564.070862 388.246124,564.839661 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M327.279968,568.937988 
	C327.471588,568.685364 327.946106,568.389893 328.701691,568.047729 
	C328.509460,568.299011 328.036163,568.597107 327.279968,568.937988 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M673.000000,456.004883 
	C673.590149,456.290161 674.164612,456.783844 674.772766,456.829620 
	C684.835205,457.586945 694.918152,458.121033 704.957947,459.099884 
	C708.117554,459.407898 711.164124,460.875641 714.599243,461.928528 
	C715.830200,471.160522 716.801270,480.270325 717.551514,489.398254 
	C717.696533,491.163055 716.978210,492.998840 716.304199,494.956787 
	C711.223999,494.136688 706.497803,493.161621 701.395264,492.134277 
	C684.223083,488.333374 667.414734,484.639404 650.640015,480.798950 
	C646.291504,479.803345 642.047913,478.349365 637.038452,477.068054 
	C635.882874,477.013458 635.445129,476.996735 634.799744,476.785431 
	C634.055969,476.431610 633.519714,476.272400 632.563965,476.080475 
	C623.414429,473.707703 614.684509,471.367615 605.963379,468.618958 
	C604.713074,462.808044 603.471069,457.401581 602.188293,452.004791 
	C601.416992,448.759644 599.607178,447.901245 597.618286,450.832031 
	C594.513245,455.407593 591.861389,460.290710 588.618408,465.040192 
	C586.475830,464.697998 584.732422,464.359131 583.104370,463.742279 
	C583.111267,462.215759 583.002686,460.967194 583.020447,461.171631 
	C584.700623,458.130890 587.026306,455.869263 586.669922,454.170074 
	C585.876526,450.386749 584.715210,445.307495 581.948792,443.600403 
	C576.404968,440.179321 573.599304,434.771729 570.055054,430.123291 
	C566.068298,424.894501 563.893616,418.281860 560.946350,412.263580 
	C559.621338,409.558044 559.012878,405.635498 556.861023,404.417419 
	C554.089355,402.848480 553.999451,401.251282 554.450317,398.992584 
	C556.887024,399.645325 559.013855,403.004364 561.351440,398.831909 
	C563.675537,397.072021 565.190125,396.326569 566.261536,399.822113 
	C567.372620,403.447144 569.101929,406.898346 570.730408,410.345490 
	C573.069824,415.297852 572.701355,421.120483 577.138733,425.526825 
	C584.608765,432.944580 591.511963,440.837311 602.926392,442.402527 
	C603.630371,444.886932 604.280945,446.995392 604.940430,449.480774 
	C605.488220,452.041168 604.682678,455.542816 609.312256,453.926666 
	C610.072937,453.233185 610.537659,452.618835 611.001221,452.002258 
	C611.000000,452.000000 611.003784,452.002838 611.384033,451.985168 
	C616.012207,450.694916 620.285767,448.308472 624.502197,448.405518 
	C631.164307,448.558838 636.265015,444.272888 639.864929,441.085632 
	C645.922729,435.722321 652.916382,430.596008 655.031311,420.707947 
	C656.464355,414.007629 658.164307,407.829681 657.269714,400.822021 
	C661.009338,397.057159 664.501831,393.519226 668.019165,390.376892 
	C668.695923,394.958130 669.545288,399.125092 669.943848,403.334686 
	C670.333191,407.447601 670.997192,411.749878 670.212463,415.705536 
	C669.169312,420.963593 666.864197,425.968536 667.428162,431.527161 
	C667.452881,431.771545 666.933044,432.038330 666.890442,432.327606 
	C666.562073,434.556122 666.289001,436.792786 665.740906,439.249817 
	C657.371460,443.698730 649.259766,447.924744 641.148132,452.150787 
	C641.245911,452.602966 641.343628,453.055145 641.441406,453.507294 
	C643.291260,453.673981 645.141174,453.840668 647.073120,454.299805 
	C648.102112,454.722107 649.048950,454.851898 650.463989,454.984955 
	C658.288025,455.327118 665.644043,455.665985 673.000000,456.004883 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M476.276306,442.295532 
	C476.534637,445.797607 476.776703,448.987671 476.675659,452.712097 
	C475.883392,454.245453 475.197357,455.205414 475.025177,456.250061 
	C474.195587,461.283142 473.735168,466.386566 472.707397,471.375122 
	C471.350494,477.961365 469.587311,484.463928 467.998779,491.002441 
	C456.665100,491.321838 445.331451,491.641266 433.554413,491.556244 
	C433.065582,486.142059 433.348328,481.102783 432.900452,476.129272 
	C432.164093,467.952026 428.128632,461.353638 421.880432,455.692505 
	C421.157623,454.309052 420.574188,453.206238 420.011505,451.767914 
	C420.780762,450.810211 421.461395,449.764435 422.288361,449.631897 
	C429.761047,448.434387 432.834747,442.114716 436.631744,436.965912 
	C439.401276,433.210327 440.450500,428.170380 443.278076,424.477478 
	C446.410767,420.386230 443.123413,417.895447 441.890625,415.028229 
	C441.508545,414.139557 438.688049,414.299225 437.081360,413.649048 
	C438.676147,412.065216 440.059204,410.633118 441.689728,409.586609 
	C451.439392,403.329315 461.245972,397.160828 471.413696,390.969727 
	C474.867615,392.941345 477.941376,394.905792 481.082031,397.283936 
	C481.246887,405.541016 481.547577,413.388794 481.376923,421.226288 
	C481.246765,427.203125 480.519073,433.166901 480.057190,439.136475 
	C478.791473,440.085480 477.525757,441.034515 476.276306,442.295532 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M541.010681,454.959747 
	C528.806824,451.985992 516.597046,449.036224 504.402252,446.025696 
	C500.055725,444.952637 495.745605,443.732178 491.168671,441.875305 
	C490.940552,428.115906 490.962585,415.060181 491.338013,402.063568 
	C493.429993,403.051453 495.235413,403.874786 496.896271,404.926392 
	C506.432739,410.964417 515.902893,417.108063 525.488708,423.066071 
	C526.709290,423.824738 528.473145,423.709290 530.348511,423.994720 
	C532.259949,423.636230 533.808289,423.281830 536.398987,422.688843 
	C536.946838,427.335297 537.340332,431.816315 538.059204,436.244537 
	C538.376953,438.201660 539.097168,440.199829 540.087097,441.918793 
	C542.005493,445.249878 544.391113,448.316406 546.226624,451.686676 
	C546.597229,452.367188 545.387451,453.908447 544.516357,455.061188 
	C543.087952,455.032928 542.049316,454.996338 541.010681,454.959747 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M541.014648,455.344513 
	C542.049316,454.996338 543.087952,455.032928 544.679321,455.403076 
	C549.851379,456.770813 554.470703,457.804932 559.347656,459.109894 
	C567.399780,460.927216 575.194397,462.473755 582.988953,464.020264 
	C584.732422,464.359131 586.475830,464.697998 588.908813,465.266174 
	C595.050415,466.672821 600.502502,467.850159 605.954529,469.027527 
	C614.684509,471.367615 623.414429,473.707703 632.627441,476.325653 
	C633.742737,476.729004 634.375000,476.854523 635.007324,476.980011 
	C635.445129,476.996735 635.882874,477.013458 636.647095,477.064819 
	C636.345703,481.445618 632.189880,481.226868 629.664856,481.615204 
	C619.579834,483.166260 609.341248,485.006561 599.224792,484.684906 
	C590.040710,484.392914 580.320618,482.830444 572.029907,479.144501 
	C566.785522,476.812897 561.215332,474.102478 556.155273,470.926605 
	C555.163147,470.303894 554.767700,468.797485 553.923401,467.854065 
	C553.011414,466.835052 551.849548,465.162506 550.876404,465.216309 
	C544.018738,465.595520 543.585571,459.601532 541.014648,455.344513 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M579.249390,556.777954 
	C577.407715,557.546631 575.484802,557.841431 574.785706,558.925476 
	C573.566528,560.815918 573.473083,563.453552 572.193298,565.276367 
	C571.289673,566.563293 569.205933,567.021545 567.649048,567.849792 
	C567.480042,566.193970 566.820251,564.402100 567.234253,562.907837 
	C568.456665,558.495728 570.103943,554.201355 571.548950,549.491821 
	C573.832520,543.082886 575.925842,536.940002 578.604553,531.063965 
	C579.444031,529.222595 581.776428,527.102905 583.579102,526.952393 
	C585.117310,526.824097 587.733032,529.007385 588.396973,530.734985 
	C592.507629,541.431580 596.313416,552.251404 599.900085,563.135864 
	C600.379822,564.591797 599.050903,566.643738 598.559509,568.419678 
	C596.934326,567.379944 594.261475,566.645996 593.854370,565.250793 
	C591.255554,556.344971 591.803223,556.183655 579.249390,556.777954 
M579.103699,547.132629 
	C578.288208,550.549988 580.620789,550.920898 582.857849,550.481262 
	C584.478699,550.162720 587.276917,548.647034 587.184326,548.007385 
	C586.714294,544.759155 585.472168,541.622681 584.500488,538.447021 
	C583.889404,538.440552 583.278320,538.434143 582.667236,538.427673 
	C581.566406,541.063416 580.465576,543.699219 579.103699,547.132629 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M490.984650,402.004456 
	C490.962585,415.060181 490.940552,428.115906 490.875336,441.653320 
	C487.419403,441.289673 484.006653,440.444336 480.325562,439.367737 
	C480.519073,433.166901 481.246765,427.203125 481.376923,421.226288 
	C481.547577,413.388794 481.246887,405.541016 481.454224,397.330994 
	C484.834564,398.644348 487.909607,400.324402 490.984650,402.004456 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M557.002441,544.987793 
	C556.285767,542.348328 555.569092,539.708862 554.514099,536.773621 
	C553.997070,536.199463 553.943848,535.898865 554.761414,535.256836 
	C555.931396,534.840088 556.356140,534.742554 556.780823,534.645020 
	C556.780823,534.644958 556.781311,534.497375 556.846069,534.463379 
	C558.962952,536.958069 561.015137,539.486816 563.097900,542.432983 
	C563.128601,542.850525 562.990234,543.009766 562.623413,543.010986 
	C560.505188,543.670776 558.753784,544.329285 557.002441,544.987793 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M566.004883,546.980469 
	C565.216125,547.286865 564.427368,547.593262 563.322571,547.531982 
	C563.001099,545.779358 562.995667,544.394592 562.990234,543.009766 
	C562.990234,543.009766 563.128601,542.850525 563.197266,542.766235 
	C564.106201,543.258179 564.946533,543.834473 565.892456,545.041809 
	C566.000305,546.108704 566.002625,546.544617 566.004883,546.980469 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M566.335083,546.987244 
	C566.002625,546.544617 566.000305,546.108704 565.996399,545.345947 
	C566.604797,545.483643 567.214844,545.948242 567.905518,546.704712 
	C567.545898,546.995728 567.105591,546.994873 566.335083,546.987244 
z"/>
<path fill="#383735" opacity="1.000000" stroke="none" 
	d="
M423.584961,495.758362 
	C421.188324,494.701996 418.946930,492.670135 416.695801,492.659271 
	C408.601929,492.620148 400.480988,492.995331 392.414246,493.710052 
	C379.536774,494.850952 366.717987,496.988892 353.826965,497.493774 
	C347.818787,497.729034 342.354340,498.338745 336.571716,500.168976 
	C328.567596,502.702362 320.071472,503.806671 311.712433,505.012604 
	C309.383148,505.348633 305.127930,506.148163 306.536316,500.584656 
	C316.772675,497.945801 326.496155,495.715088 336.179321,493.321075 
	C336.954895,493.129395 337.452148,491.811890 338.184692,490.713806 
	C338.575134,489.599945 338.859650,488.789764 339.341461,488.143005 
	C341.046448,488.888550 342.644714,489.306854 344.046265,490.081207 
	C350.229218,493.497223 359.759003,491.966614 364.743225,486.932800 
	C365.784760,485.880890 367.613464,485.386444 369.165039,485.101715 
	C374.487152,484.124878 374.901794,483.244690 371.864807,479.107635 
	C371.005585,477.937256 370.542297,476.478302 369.881378,475.159790 
	C368.636047,472.675629 367.134003,471.356567 363.974609,472.525726 
	C359.112610,474.324982 354.068054,475.630920 349.378296,476.749695 
	C349.982697,473.418549 350.309692,470.483887 350.971436,467.524231 
	C352.221039,467.124146 353.167572,466.811157 354.045563,466.363678 
	C359.339996,463.665283 359.529938,462.234894 354.991364,458.306824 
	C353.280243,456.825867 351.386230,455.556213 349.797974,454.000641 
	C351.075714,452.255066 352.131104,450.699036 353.129333,449.109131 
	C353.072205,449.075287 353.151123,449.182129 353.513306,449.166626 
	C357.977875,446.891815 362.080261,444.632477 366.528381,442.366516 
	C367.936920,442.047211 368.999786,441.734558 370.391296,441.350586 
	C371.148590,441.164551 371.577209,441.049866 372.435730,440.974060 
	C385.038666,439.052490 396.992432,439.497711 407.742157,446.158112 
	C412.557953,449.141998 416.563141,453.434204 421.048645,457.449493 
	C431.316589,469.344910 431.936340,483.121887 430.562683,497.756653 
	C428.875427,497.376526 427.511353,497.069183 426.147247,496.761841 
	C426.147247,496.761841 426.148224,496.841583 426.061340,496.502472 
	C425.310516,496.078247 424.646637,495.993103 423.982727,495.907990 
	C423.982727,495.907990 423.735687,495.710022 423.584961,495.758362 
M369.253693,468.342133 
	C369.559601,468.585815 369.865509,468.829498 370.171417,469.073151 
	C370.254547,468.916046 370.337646,468.758942 370.420776,468.601837 
	C369.936401,468.408478 369.452026,468.215149 368.927734,467.420258 
	C371.354523,463.422943 365.411774,462.184753 366.091766,458.530151 
	C363.992126,460.603516 359.782684,461.233521 362.062164,465.563690 
	C362.674194,466.726318 363.707855,467.666962 365.357330,469.713898 
	C365.796844,469.505188 367.400848,468.743439 369.253693,468.342133 
M426.646027,486.339783 
	C428.311646,480.708618 427.844055,475.126068 423.456909,471.200409 
	C419.096863,467.298981 413.338165,467.888458 408.314148,470.098785 
	C402.838287,472.507935 400.602386,477.660645 398.170502,484.197723 
	C408.578461,481.713593 417.240417,483.851532 426.646027,486.339783 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M468.184326,491.316193 
	C469.587311,484.463928 471.350494,477.961365 472.707397,471.375122 
	C473.735168,466.386566 474.195587,461.283142 475.025177,456.250061 
	C475.197357,455.205414 475.883392,454.245453 476.681885,453.111816 
	C480.008972,465.780304 483.357422,478.511627 485.853699,491.407928 
	C488.070679,502.861206 489.342194,514.497498 491.057068,526.515381 
	C489.299622,526.671143 487.514862,526.094910 485.732819,526.103149 
	C476.734772,526.144653 477.982666,526.323303 475.778625,518.054443 
	C473.422791,509.215973 470.848724,500.435669 468.184326,491.316193 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M384.770752,553.646667 
	C385.053009,554.442261 385.094818,554.914307 384.820557,555.987427 
	C384.423859,557.465027 384.343231,558.341431 384.262573,559.217834 
	C384.262573,559.217834 384.278320,559.103516 384.045044,559.269104 
	C383.545959,559.999451 383.280121,560.564148 383.014282,561.128906 
	C378.464020,565.467224 373.519501,564.781372 368.078033,561.870117 
	C367.614288,561.304321 367.466064,561.192505 367.279480,561.131531 
	C366.865601,560.634888 366.451691,560.138245 366.180237,559.152222 
	C366.026367,558.135010 365.730072,557.607239 365.433777,557.079407 
	C365.252869,547.501099 365.071991,537.922852 364.858917,527.562744 
	C364.850891,526.541260 364.875061,526.301514 364.899261,526.061829 
	C365.860687,524.937988 366.822083,523.814148 367.190826,523.383118 
	C370.819885,523.771301 373.955627,523.870483 376.987030,524.484680 
	C381.888672,525.477966 382.604553,529.552551 382.971222,533.612183 
	C383.564056,540.175842 384.017822,546.752136 384.770752,553.646667 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M492.542114,529.988159 
	C499.640808,537.274292 499.880737,545.968018 498.670868,555.460510 
	C495.263367,565.629822 488.080750,568.553589 478.334808,568.169800 
	C467.095856,567.727173 467.081787,568.080627 467.081085,556.710693 
	C467.080597,548.383850 467.186523,540.054993 467.026276,531.731201 
	C466.965363,528.567139 467.930267,527.099121 471.255493,527.254883 
	C478.309570,527.585022 485.632324,525.646545 492.542114,529.988159 
M490.689941,536.518311 
	C487.605255,532.657959 483.400421,532.291077 478.151611,532.485840 
	C475.573578,532.346436 472.450226,531.367920 472.413574,535.657654 
	C472.345703,543.606445 472.299377,551.558044 472.479279,559.503235 
	C472.504730,560.626892 473.945160,562.726624 474.572449,562.668396 
	C479.367493,562.223816 484.123260,561.355835 489.407593,560.491821 
	C489.638336,560.031494 489.869080,559.571228 490.692230,558.711853 
	C495.059906,552.345459 495.169434,543.537415 490.689941,536.518311 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M440.231995,529.200928 
	C443.280182,526.478149 446.742798,525.820496 448.191498,529.550476 
	C452.983551,541.888611 457.152618,554.468750 461.603973,567.078369 
	C457.226501,569.029297 455.378235,567.441650 454.177856,563.724976 
	C453.341400,561.135193 452.249146,557.136536 450.473480,556.644165 
	C446.460663,555.531433 441.677612,555.304321 437.760162,556.510620 
	C435.693176,557.147034 434.407013,561.420898 433.450775,564.280701 
	C432.287384,567.760010 430.553680,568.760071 426.597443,567.095154 
	C431.116974,554.437317 435.565826,541.977539 440.231995,529.200928 
M439.863770,545.248169 
	C439.388397,546.913269 438.913025,548.578308 438.302582,550.716553 
	C442.378326,550.716553 445.718658,550.716553 449.895142,550.716553 
	C448.063629,545.261719 446.433594,540.407043 444.803558,535.552307 
	C444.390991,535.542114 443.978394,535.531860 443.565796,535.521606 
	C442.421204,538.523926 441.276611,541.526306 439.863770,545.248169 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M398.751282,530.014954 
	C400.097778,529.134705 401.444275,528.254395 402.790771,527.374084 
	C403.460876,528.710327 404.682190,530.034790 404.711151,531.384827 
	C404.892944,539.851135 405.143463,548.341125 404.691071,556.786865 
	C404.407593,562.078552 406.389984,563.521729 411.236908,563.036194 
	C414.525787,562.706848 417.895264,562.747498 421.184021,563.073486 
	C422.436707,563.197693 423.564545,564.581360 424.749268,565.391052 
	C423.496918,566.278259 422.265594,567.894470 420.988678,567.931396 
	C414.022552,568.132629 407.045227,567.945374 399.615570,567.870850 
	C399.159393,567.849976 399.116577,567.886963 399.137390,567.455078 
	C399.022705,564.719177 398.887268,562.415100 398.841248,559.642944 
	C398.870911,549.454834 398.811096,539.734924 398.751282,530.014954 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M426.119080,497.176331 
	C427.511353,497.069183 428.875427,497.376526 430.562683,497.756653 
	C431.936340,483.121887 431.316589,469.344910 421.199158,457.229553 
	C421.498108,456.452911 421.758911,456.213013 422.019745,455.973114 
	C428.128632,461.353638 432.164093,467.952026 432.900452,476.129272 
	C433.348328,481.102783 433.065582,486.142059 433.113098,491.540771 
	C433.049591,493.915802 433.563232,496.190308 432.757629,497.807800 
	C431.931763,499.465912 429.815094,500.481110 428.263428,501.777710 
	C427.539246,500.382111 426.815063,498.986450 426.119080,497.176331 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M398.414978,530.190430 
	C398.811096,539.734924 398.870911,549.454834 398.656433,559.940186 
	C397.588074,562.470398 396.794037,564.235229 396.000000,566.000000 
	C396.021362,564.518616 396.042755,563.037231 396.032806,561.272339 
	C396.333496,554.299988 396.604736,547.607483 397.022308,540.924011 
	C397.242737,537.396423 397.718323,533.884766 398.414978,530.190430 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M503.360229,556.523804 
	C503.471344,556.976074 503.477142,557.021973 503.475891,557.047302 
	C502.537262,556.813660 501.599884,556.554626 500.331848,556.146606 
	C500.001221,553.987488 500.001221,551.977478 500.001221,549.967407 
	C500.532837,549.906372 501.064423,549.845276 501.596039,549.784241 
	C502.147095,551.880005 502.698120,553.975830 503.360229,556.523804 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M391.832520,529.032227 
	C391.900818,531.435364 391.764679,533.820190 391.434143,536.524719 
	C391.165192,534.519409 391.090637,532.194397 391.116760,529.440308 
	C391.217468,529.011169 391.628021,529.014038 391.832520,529.032227 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M463.686646,569.647583 
	C463.963715,570.297913 464.015503,570.847534 464.067322,571.397217 
	C463.633942,571.228516 463.200531,571.059814 462.767090,570.891113 
	C462.998535,570.443054 463.229950,569.994934 463.686646,569.647583 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M497.271057,565.361816 
	C497.760895,565.617126 498.019562,565.929016 498.278229,566.240967 
	C497.865509,566.354980 497.452789,566.468994 497.040070,566.583008 
	C497.040009,566.194824 497.039948,565.806702 497.271057,565.361816 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M436.325836,565.740356 
	C436.265015,566.048950 436.089905,566.234802 435.914795,566.420715 
	C435.817139,566.315308 435.719482,566.209900 435.621826,566.104492 
	C435.818390,565.942200 436.014984,565.779907 436.325836,565.740356 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M364.516724,526.049683 
	C364.875061,526.301514 364.850891,526.541260 364.754578,527.135742 
	C363.703094,527.426086 362.723724,527.361633 361.393188,527.181519 
	C361.037903,526.890808 361.033752,526.715759 361.046265,526.278809 
	C362.086700,526.023804 363.110443,526.030640 364.516724,526.049683 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M424.206421,496.131287 
	C424.646637,495.993103 425.310516,496.078247 426.100403,496.494629 
	C425.627655,496.668762 425.028900,496.511688 424.206421,496.131287 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M386.743469,526.401733 
	C386.704559,526.644348 386.681366,526.599060 386.670502,526.615723 
	C386.613953,526.206482 386.568268,525.780579 386.522583,525.354675 
	C386.609222,525.622803 386.695801,525.890930 386.743469,526.401733 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M388.910370,526.826721 
	C388.950775,527.037109 388.595917,527.207092 388.595917,527.207092 
	C388.595917,527.207092 388.204102,527.172852 388.048584,527.057739 
	C388.218719,526.833862 388.544342,526.725098 388.910370,526.826721 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M386.232025,526.534790 
	C386.256653,526.959045 385.965851,527.456543 385.424225,528.116821 
	C385.421143,527.722473 385.668884,527.165222 386.232025,526.534790 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M400.897461,569.886841 
	C401.001587,569.997559 400.512817,569.992859 400.268860,569.987610 
	C400.234680,569.693176 400.490814,569.624451 400.897461,569.886841 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M537.996521,535.021240 
	C540.014404,535.021240 542.032288,535.021240 544.050232,535.021240 
	C544.139771,535.508301 544.229309,535.995361 544.318848,536.482361 
	C542.794312,536.976868 541.269775,537.471375 539.241699,537.695740 
	C538.490906,536.624146 538.243713,535.822693 537.996521,535.021240 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M537.667480,535.134644 
	C538.243713,535.822693 538.490906,536.624146 538.870300,537.713989 
	C538.387878,538.796204 537.773315,539.590027 537.158752,540.383789 
	C536.724915,540.219055 536.291077,540.054260 535.857239,539.889465 
	C536.350952,538.342346 536.844727,536.795166 537.667480,535.134644 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M501.485901,542.041626 
	C501.580109,542.835144 501.489166,543.439514 501.398193,544.043823 
	C501.027557,543.879639 500.656952,543.715515 500.286316,543.551331 
	C500.624451,542.984985 500.962616,542.418701 501.485901,542.041626 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M519.896851,539.285278 
	C521.119080,543.062744 522.213501,546.485840 523.526123,550.591431 
	C519.341309,550.591431 516.030579,550.591431 511.870850,550.591431 
	C513.791687,545.232727 515.530151,540.382874 517.700562,534.327942 
	C518.757324,536.679626 519.263123,537.805298 519.896851,539.285278 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M521.007324,557.970581 
	C521.004211,557.584290 521.001099,557.198059 521.000610,556.522278 
	C522.703125,556.867615 524.403076,557.502380 526.120117,558.517334 
	C525.364502,558.924927 524.591797,558.952454 523.221558,558.779663 
	C522.085144,558.376404 521.546265,558.173462 521.007324,557.970581 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M556.725830,534.297729 
	C556.356140,534.742554 555.931396,534.840088 554.859924,535.042358 
	C554.392334,534.460449 554.571594,533.773865 554.750793,533.087219 
	C555.390808,533.374939 556.030762,533.662659 556.725830,534.297729 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M562.623413,543.010986 
	C562.995667,544.394592 563.001099,545.779358 563.008179,547.579651 
	C562.679321,552.770203 559.800415,553.364441 555.326965,552.553955 
	C555.828430,550.041748 556.263733,547.860657 556.850769,545.333679 
	C558.753784,544.329285 560.505188,543.670776 562.623413,543.010986 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M566.635681,552.024170 
	C566.860718,555.283508 566.454529,558.468384 563.255127,560.821045 
	C561.492065,561.539856 559.964050,562.047668 558.427795,562.558228 
	C556.304077,558.682495 556.421021,558.549316 561.486450,558.312927 
	C563.275635,555.971191 564.771057,554.013916 566.635681,552.024170 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M562.798340,565.016479 
	C562.834900,565.276550 562.634827,565.520752 562.196655,565.866577 
	C562.204163,565.682129 562.405151,565.359619 562.798340,565.016479 
z"/>
<path fill="#EAEDF1" opacity="1.000000" stroke="none" 
	d="
M521.058350,558.234253 
	C521.546265,558.173462 522.085144,558.376404 522.834839,558.781921 
	C522.400146,558.822327 521.754761,558.660156 521.058350,558.234253 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M298.863129,491.082855 
	C292.231995,465.702423 285.600861,440.321960 278.933746,414.124207 
	C279.236115,411.010681 279.495911,408.699646 279.924896,406.420441 
	C284.525696,381.975800 289.176849,357.540558 293.760376,333.092682 
	C295.317810,324.785461 296.723175,316.449707 298.506714,308.096069 
	C299.230225,308.038696 299.645111,308.012238 300.061157,308.429443 
	C300.141571,311.231964 300.220764,313.590729 300.223083,316.399109 
	C300.165649,319.537567 300.185089,322.226471 300.161621,325.345490 
	C300.401764,329.521698 300.684784,333.267761 300.965332,337.443817 
	C299.871490,346.924469 308.818359,349.884644 310.557892,354.872894 
	C312.734772,353.087708 314.820953,351.376892 317.529388,349.155823 
	C319.070496,352.047760 320.232758,353.524994 320.686005,355.194702 
	C323.412323,365.238190 320.462433,374.569855 316.278595,383.477997 
	C314.754272,386.723572 312.185608,390.479309 309.136383,391.810883 
	C301.531036,395.132050 300.019073,400.937195 300.179871,408.524292 
	C300.430786,412.643127 300.719116,416.323822 300.993103,420.472473 
	C301.014984,427.635406 301.051208,434.330414 301.045746,441.490295 
	C301.129822,449.683777 301.255554,457.412354 301.316833,465.572632 
	C301.199463,468.340179 301.146484,470.676025 301.093506,473.011841 
	C301.028931,478.684875 300.964355,484.357941 300.535217,490.517761 
	C299.734833,491.030640 299.298981,491.056732 298.863129,491.082855 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M301.447845,473.167358 
	C301.146484,470.676025 301.199463,468.340179 301.667877,465.541382 
	C303.065155,463.720367 304.046936,462.362366 305.357178,460.887451 
	C306.117401,459.502747 306.549164,458.234924 306.986389,456.726379 
	C306.991821,456.485596 307.014130,456.004425 307.364594,455.942810 
	C313.425018,451.542511 319.361145,447.883789 327.034515,448.738739 
	C327.578339,448.799316 328.758636,447.505615 328.825684,446.767395 
	C329.213074,442.502502 331.913696,442.194672 335.441986,441.827728 
	C338.636505,441.495453 344.006561,440.305634 344.396393,438.583649 
	C346.006714,431.470245 352.098755,432.024017 356.612610,430.194916 
	C359.003815,429.225922 361.882599,429.646759 362.234375,433.721741 
	C362.335815,434.896942 364.460266,436.727448 365.747192,436.807251 
	C368.987732,437.008209 370.309998,438.296631 370.062622,441.421936 
	C368.999786,441.734558 367.936920,442.047211 366.292877,442.117615 
	C358.349335,441.977844 354.494202,444.220459 353.151123,449.182129 
	C353.151123,449.182129 353.072205,449.075287 352.816254,449.085693 
	C347.498718,449.203827 346.113525,452.723724 345.540283,456.979248 
	C345.787384,457.422607 345.721497,457.692169 345.121582,457.986633 
	C343.624298,458.926025 341.885773,459.596466 341.514709,460.718964 
	C339.649292,466.361603 335.945160,471.562744 336.824219,478.259186 
	C330.763306,482.366852 324.881653,486.183411 318.743652,490.064026 
	C318.650970,490.752533 318.814636,491.377045 318.986267,492.001526 
	C318.994293,492.001556 318.996338,492.022156 318.978516,492.394287 
	C318.533325,496.353546 316.092804,497.108215 313.027283,496.983521 
	C312.950775,489.083740 312.569000,488.460327 307.402191,489.083282 
	C305.988129,489.253815 304.670776,490.226624 302.999664,490.968628 
	C302.999664,487.046997 303.109253,482.704529 302.944885,478.372406 
	C302.880554,476.677216 302.201355,475.005371 301.447845,473.167358 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M266.321106,566.890747 
	C266.591492,566.134827 266.861847,565.378967 267.168915,564.006592 
	C267.230682,562.950439 267.255737,562.510681 267.280792,562.070923 
	C270.911346,552.157532 274.440186,542.204712 278.256500,532.363342 
	C279.039795,530.343323 280.898254,528.740234 283.016479,526.944885 
	C285.057526,527.134583 286.343231,527.324463 287.628937,527.514282 
	C288.041168,528.398438 288.541046,529.252869 288.852325,530.171265 
	C293.023651,542.479248 297.168671,554.796082 301.355743,567.216064 
	C294.187012,570.124512 294.825104,563.978455 292.552490,560.823364 
	C292.175720,560.662659 292.195862,560.586426 292.195862,560.586426 
	C292.057831,559.653442 292.055817,557.954956 291.761322,557.902588 
	C287.549347,557.154236 283.303406,556.309937 279.052734,556.236511 
	C277.727081,556.213501 276.146240,558.254272 275.084442,559.655273 
	C274.225739,560.788269 273.764008,562.339172 273.488708,563.774292 
	C272.777069,567.483948 270.767548,568.833374 266.808655,567.450684 
	C266.476532,567.195129 266.321106,566.890747 266.321106,566.890747 
M279.024048,547.378662 
	C278.835297,551.449768 281.790802,552.313599 289.437195,550.000122 
	C287.815460,545.161377 286.201477,540.345764 284.587494,535.530151 
	C284.186279,535.564331 283.785065,535.598450 283.383850,535.632568 
	C282.000885,539.300171 280.617889,542.967712 279.024048,547.378662 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M350.852478,567.243530 
	C349.783508,567.811707 348.749298,567.960815 347.349609,568.252808 
	C346.576355,564.020386 345.803467,559.639832 345.866058,555.271362 
	C345.925629,551.109009 344.441559,549.570618 340.357117,549.894592 
	C335.593597,550.272339 329.447968,548.664001 326.455963,551.080139 
	C324.036926,553.033569 325.587219,559.700623 324.978912,564.223206 
	C324.800446,565.550110 323.262695,566.694092 322.347931,567.921936 
	C321.518646,566.762695 319.987274,565.614319 319.969025,564.442444 
	C319.793243,553.154419 319.798737,541.861084 319.989349,530.573303 
	C320.008911,529.414978 321.571808,528.282715 322.703674,527.016602 
	C323.527679,527.177063 324.066589,527.459351 324.832275,528.286133 
	C325.156586,530.501648 325.254150,532.172546 325.351746,533.843445 
	C325.302002,535.967102 325.252258,538.090759 325.182404,541.022095 
	C324.867371,544.917358 326.663727,545.098206 328.970459,544.569580 
	C329.431915,544.606995 329.893402,544.644348 331.055756,544.700562 
	C332.489410,544.660828 333.222168,544.602112 333.954926,544.543457 
	C337.016266,544.592224 340.077606,544.640930 343.955414,544.602417 
	C345.109711,540.400391 345.677765,536.287537 345.710114,532.170410 
	C345.734528,529.061890 346.427216,527.067993 350.064911,527.253174 
	C350.193359,527.351135 350.511810,527.296204 350.511810,527.296204 
	C350.665985,527.696777 350.820160,528.097351 350.995056,529.226746 
	C351.085480,532.931885 351.155182,535.908264 351.224823,538.884583 
	C351.173706,540.015686 351.122589,541.146790 351.048553,543.143188 
	C351.102356,549.633972 351.179108,555.259460 351.255859,560.884949 
	C351.132996,562.864807 351.010132,564.844604 350.852478,567.243530 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M262.420593,516.998535 
	C261.998383,516.554993 261.997986,516.112488 261.997009,515.338074 
	C269.371094,515.823669 276.745667,512.697205 284.531311,515.338623 
	C284.935211,516.113342 284.928253,516.554810 284.605347,517.060364 
	C283.007416,518.135071 279.481781,518.401489 283.002441,521.004883 
	C279.184845,524.778931 276.902985,530.339294 270.646362,531.339722 
	C269.951111,531.450867 269.160126,533.401794 269.092102,534.540100 
	C268.887451,537.963928 269.018311,541.407837 269.018311,545.237183 
	C267.902313,545.176086 266.336060,545.090332 264.386871,544.535889 
	C264.006958,539.713135 264.009979,535.359009 264.012970,531.004883 
	C268.921112,527.475769 268.932953,526.815186 264.088806,521.330078 
	C268.708862,521.514160 268.708862,521.514160 272.638062,529.278564 
	C273.788239,526.479614 274.911255,524.253601 275.541321,521.895752 
	C275.723602,521.213623 274.647369,519.572266 273.880005,519.365479 
	C270.251678,518.387878 266.530487,517.755127 262.420593,516.998535 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M305.205933,562.739502 
	C305.218933,552.269958 305.175812,542.270264 305.245697,532.271362 
	C305.260498,530.157532 304.272186,527.161133 308.191010,527.298828 
	C311.976685,527.431763 311.003937,530.386292 311.020782,532.502380 
	C311.101685,542.667786 311.077789,552.834351 311.035889,563.000244 
	C311.026733,565.220093 311.704895,567.978271 307.919098,567.920288 
	C304.308929,567.864990 305.437866,565.160156 305.205933,562.739502 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M284.921295,516.996216 
	C284.928253,516.554810 284.935211,516.113342 284.929443,515.341187 
	C284.942261,507.962677 284.967865,500.914886 284.996094,493.388367 
	C286.058838,492.939697 287.118866,492.969788 288.743958,493.273224 
	C290.206421,494.001007 291.103821,494.455444 292.001190,494.909912 
	C292.170685,494.924408 292.340210,494.938934 292.764465,494.973022 
	C293.389404,497.442902 294.490692,500.052246 293.998566,502.315033 
	C292.224640,510.471588 289.913757,518.511353 287.717316,527.054810 
	C286.343231,527.324463 285.057526,527.134583 283.389465,526.855469 
	C283.306244,525.086121 283.605377,523.406067 284.179962,521.087158 
	C284.610718,519.297607 284.765991,518.146912 284.921295,516.996216 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M312.635071,497.011108 
	C316.092804,497.108215 318.533325,496.353546 319.322632,492.335968 
	C325.427307,491.593048 331.170013,491.280579 337.204224,490.980713 
	C337.690063,491.001404 337.884399,491.009460 338.078766,491.017548 
	C337.452148,491.811890 336.954895,493.129395 336.179321,493.321075 
	C326.496155,495.715088 316.772675,497.945801 306.648865,500.165833 
	C306.483337,499.438721 306.580780,498.304688 306.999451,498.170166 
	C308.693512,497.625732 310.486450,497.388977 312.635071,497.011108 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M264.007385,530.590942 
	C264.009979,535.359009 264.006958,539.713135 264.006042,544.531433 
	C263.846954,550.764893 263.685760,556.534119 263.265381,562.338562 
	C262.723022,561.630493 262.439850,560.887268 262.156677,560.144043 
	C262.064423,550.724487 262.057495,541.302612 261.802429,531.887451 
	C261.755646,530.160278 260.701019,528.460327 259.853455,526.431152 
	C262.737000,525.654236 264.321442,526.882996 264.007385,530.590942 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M249.257385,566.876526 
	C249.074051,564.503174 249.196533,561.983398 249.071930,559.216309 
	C248.955109,558.404846 249.085327,557.840820 249.673996,556.923218 
	C250.401901,555.389893 250.671371,554.210022 250.940842,553.030151 
	C251.043030,552.455627 251.145203,551.881042 251.706879,550.942383 
	C252.882675,550.031860 253.598938,549.485535 255.018661,548.402649 
	C255.018661,551.793640 255.018661,554.383789 254.697891,557.086182 
	C251.789215,559.680786 253.398605,560.551392 255.878540,561.407715 
	C255.851715,562.146545 255.817917,562.567932 255.784119,562.989380 
	C253.710480,564.236328 251.636841,565.483215 249.257385,566.876526 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M349.936462,527.155151 
	C346.427216,527.067993 345.734528,529.061890 345.710114,532.170410 
	C345.677765,536.287537 345.109711,540.400391 344.407166,544.519409 
	C344.037476,540.191345 344.175598,535.852478 343.984619,531.528259 
	C343.815002,527.687256 345.254883,525.458801 349.584686,525.447388 
	C349.955200,526.233521 349.945862,526.694336 349.936462,527.155151 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M351.448059,560.536133 
	C351.179108,555.259460 351.102356,549.633972 351.125427,543.562378 
	C351.363617,548.806641 351.501953,554.496948 351.448059,560.536133 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M359.444763,529.543091 
	C359.668457,534.091980 359.724457,538.990173 359.669983,544.314819 
	C359.465363,539.791626 359.371185,534.841980 359.444763,529.543091 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M298.857117,491.493378 
	C299.298981,491.056732 299.734833,491.030640 300.497070,490.981506 
	C300.831299,492.720459 300.870697,494.484100 300.778076,496.240753 
	C300.771667,496.361816 299.779816,496.430939 299.117554,496.261261 
	C298.942932,494.633514 298.897034,493.268707 298.857117,491.493378 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M292.212891,560.652100 
	C291.403229,562.090210 290.556458,563.538879 289.709656,564.987549 
	C289.163177,564.477539 288.616699,563.967468 288.070221,563.457458 
	C289.215485,562.606812 290.360779,561.756165 291.850952,560.745972 
	C292.195862,560.586426 292.175720,560.662659 292.212891,560.652100 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M284.605347,517.060364 
	C284.765991,518.146912 284.610718,519.297607 284.240112,520.723633 
	C283.854431,521.000000 283.684021,521.000977 283.258026,521.003418 
	C279.481781,518.401489 283.007416,518.135071 284.605347,517.060364 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M328.884644,544.302979 
	C326.663727,545.098206 324.867371,544.917358 325.264771,541.470703 
	C326.511108,542.086426 327.654968,543.061340 328.884644,544.302979 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M350.064941,527.253174 
	C349.945862,526.694336 349.955200,526.233521 349.964539,525.428955 
	C350.618713,524.663147 351.272919,524.241089 351.927124,523.819031 
	C351.654907,524.860596 351.382690,525.902222 350.811127,527.119995 
	C350.511810,527.296204 350.193359,527.351135 350.064941,527.253174 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M351.420288,538.558228 
	C351.155182,535.908264 351.085480,532.931885 351.123718,529.522705 
	C351.359650,532.137268 351.487671,535.184570 351.420288,538.558228 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M325.518127,533.576843 
	C325.254150,532.172546 325.156586,530.501648 325.109985,528.410034 
	C325.335480,529.762939 325.510010,531.536560 325.518127,533.576843 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M256.872009,568.183960 
	C257.744354,568.015076 258.492981,568.039978 259.593628,568.192627 
	C258.879822,568.339417 257.814056,568.358582 256.872009,568.183960 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M262.028320,560.543152 
	C262.439850,560.887268 262.723022,561.630493 263.040314,562.683716 
	C263.067993,563.452698 263.061554,563.911682 263.057922,564.920776 
	C263.060730,565.470764 263.073730,565.885498 263.073730,565.885498 
	C263.073730,565.885498 262.654755,565.949951 262.443298,565.961792 
	C262.121246,564.296448 262.010590,562.619385 262.028320,560.543152 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M333.867798,544.291199 
	C333.222168,544.602112 332.489410,544.660828 331.397705,544.632812 
	C331.952759,544.377075 332.866730,544.208008 333.867798,544.291199 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M255.851837,563.228210 
	C255.817917,562.567932 255.851715,562.146545 256.037842,561.370056 
	C256.190155,561.015015 256.515045,561.011597 256.515045,561.011597 
	C256.579346,562.025391 256.643677,563.039124 256.615692,564.419434 
	C256.322144,564.346313 256.120850,563.906677 255.851837,563.228210 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M267.039917,562.160095 
	C267.255737,562.510681 267.230682,562.950439 267.111176,563.707520 
	C266.944153,563.433044 266.871613,562.841125 267.039917,562.160095 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M266.144714,567.004517 
	C266.321106,566.890747 266.476532,567.195129 266.418518,567.355408 
	C266.120239,567.624817 265.879944,567.733948 265.639648,567.843140 
	C265.749207,567.601562 265.858765,567.359924 266.144714,567.004517 
z"/>
<path fill="#EEEFF4" opacity="1.000000" stroke="none" 
	d="
M263.175842,565.804443 
	C263.073730,565.885498 263.060730,565.470764 263.075317,565.264648 
	C263.342194,565.238708 263.404877,565.460266 263.175842,565.804443 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M255.018661,556.973938 
	C255.018661,554.383789 255.018661,551.793640 255.018661,548.402649 
	C253.598938,549.485535 252.882675,550.031860 251.873108,550.700317 
	C252.870316,546.637329 254.160812,542.452209 255.451324,538.267029 
	C255.854187,538.345032 256.257050,538.422974 256.659912,538.500977 
	C256.659912,544.362122 256.659912,550.223328 256.314880,556.551514 
	C255.652832,557.002319 255.335785,556.987488 255.018661,556.973938 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M228.962463,526.009766 
	C227.898026,525.387756 226.833572,524.765747 224.406631,523.347534 
	C231.132797,522.996643 236.314011,522.726318 241.495239,522.456055 
	C241.593445,522.990784 241.691635,523.525574 241.789841,524.060303 
	C238.781815,524.448608 234.524200,523.244934 234.936020,528.549194 
	C233.262802,528.195068 232.012787,527.820923 230.762756,527.446716 
	C230.357178,527.382141 229.951614,527.317505 229.264404,526.877563 
	C228.982773,526.502258 228.962463,526.009766 228.962463,526.009766 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M235.304688,555.548584 
	C233.493774,553.986450 231.688492,552.027222 229.529465,550.030273 
	C229.175705,546.572021 229.175705,543.151489 229.175705,539.730896 
	C229.474609,539.625366 229.773514,539.519775 230.072433,539.414185 
	C231.818390,544.659973 233.564362,549.905701 235.304688,555.548584 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M222.972198,528.488525 
	C222.951309,528.022644 223.442184,527.933289 223.691788,527.924438 
	C223.855560,534.521301 223.769745,541.126953 223.562500,548.130981 
	C223.291733,542.004333 223.142410,535.479370 222.972198,528.488525 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M254.697891,557.086182 
	C255.335785,556.987488 255.652832,557.002319 256.207367,557.007446 
	C256.510376,558.067810 256.575836,559.139282 256.578156,560.611206 
	C256.515045,561.011597 256.190155,561.015015 256.030853,561.052734 
	C253.398605,560.551392 251.789215,559.680786 254.697891,557.086182 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M230.850861,527.732666 
	C232.012787,527.820923 233.262802,528.195068 234.745300,528.784180 
	C234.405853,529.605530 233.833939,530.212036 232.973694,530.954651 
	C232.103241,530.066711 231.521103,529.042603 230.850861,527.732666 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M223.585129,561.163635 
	C223.379974,559.054871 223.291122,556.566101 223.330505,553.786072 
	C223.539658,555.924438 223.620544,558.354004 223.585129,561.163635 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M250.683304,553.256226 
	C250.671371,554.210022 250.401901,555.389893 249.837097,556.682373 
	C249.836441,555.690735 250.131119,554.586548 250.683304,553.256226 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M229.051239,552.455933 
	C229.279617,553.202087 229.402893,554.323181 229.336945,555.680054 
	C229.080521,554.887573 229.013336,553.859131 229.051239,552.455933 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M228.987396,562.440063 
	C229.241699,563.186035 229.390564,564.310425 229.353912,565.676880 
	C229.072906,564.885437 228.977417,563.851990 228.987396,562.440063 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M228.621140,526.010864 
	C228.962463,526.009766 228.982773,526.502258 228.982956,526.748657 
	C228.452118,527.078979 227.921112,527.162842 226.741699,527.125244 
	C225.741516,526.922119 225.389740,526.840515 225.037949,526.758911 
	C226.118576,526.509949 227.199188,526.260986 228.621140,526.010864 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M224.982025,526.869873 
	C225.389740,526.840515 225.741516,526.922119 226.337906,527.088135 
	C226.152863,527.270508 225.723190,527.368469 225.083755,527.345154 
	C224.873978,527.223816 224.926086,526.980774 224.982025,526.869873 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M239.552429,567.494141 
	C239.181732,567.686401 238.760956,567.677490 238.340195,567.668579 
	C238.476242,567.473938 238.612305,567.279297 238.962280,567.060974 
	C239.176193,567.037292 239.502380,567.293030 239.552429,567.494141 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M235.721680,557.289001 
	C235.556793,557.293762 235.414062,557.208374 235.456085,557.083740 
	C235.640839,557.044373 235.743851,557.198853 235.721680,557.289001 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M236.307831,558.707397 
	C236.056381,558.751709 235.770828,558.479614 235.417007,557.913330 
	C235.657043,557.876343 235.965378,558.133606 236.307831,558.707397 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M236.613068,560.256653 
	C236.625870,560.266846 236.223755,560.407349 236.223755,560.407349 
	C236.223755,560.407349 236.321640,560.232239 236.479828,560.225830 
	C236.638016,560.219360 236.600250,560.246460 236.613068,560.256653 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M237.352631,561.529602 
	C237.123886,561.577515 236.852432,561.365662 236.559952,560.885742 
	C236.795929,560.835144 237.052933,561.052490 237.352631,561.529602 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M238.327042,564.595459 
	C237.942398,564.793335 237.778839,564.611450 237.947556,564.159973 
	C238.059357,564.059387 238.327637,564.384705 238.327042,564.595459 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M134.243561,507.688721 
	C131.673569,507.870544 129.470062,507.710632 127.371941,508.107697 
	C126.108955,508.346741 124.220688,509.245514 123.968658,510.204132 
	C122.584000,515.470886 118.655426,516.163574 114.291122,515.950500 
	C110.628357,515.771606 107.795113,516.219299 106.815559,520.535400 
	C106.566986,521.630737 104.744629,522.374023 103.636177,523.267578 
	C102.279564,524.361206 100.999031,525.581299 99.528008,526.492188 
	C97.935730,527.478088 96.230980,528.409180 94.448265,528.925964 
	C92.571213,529.470032 90.437355,529.188110 88.607590,529.821411 
	C85.952065,530.740601 82.794815,532.046814 82.034821,528.448547 
	C81.394592,525.417297 82.038544,521.680664 83.338394,518.810852 
	C84.382835,516.504944 87.254807,515.022827 89.328186,513.189148 
	C90.073441,512.530090 90.805847,511.435150 91.627480,511.350769 
	C97.874535,510.709076 103.261215,507.830536 108.630791,504.963348 
	C110.263527,504.091522 111.571495,502.604523 113.010223,501.378143 
	C114.708954,499.930084 116.223267,498.174927 118.120079,497.074280 
	C120.222305,495.854462 123.576027,495.881805 124.785919,494.209412 
	C128.029907,489.725342 132.448380,488.959564 137.292984,488.375427 
	C141.059860,487.921234 144.763367,486.672180 148.522842,486.534271 
	C150.545517,486.460052 153.650787,487.450623 154.411652,488.921082 
	C155.282623,490.604370 154.920303,494.268402 153.621643,495.440002 
	C151.237610,497.590790 147.900467,499.153900 144.722534,499.811798 
	C142.038010,500.367554 140.286453,500.765320 139.111725,503.582489 
	C138.418121,505.245850 136.191605,506.269989 134.243561,507.688721 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M244.000610,541.000000 
	C243.848236,543.970459 243.695877,546.940857 243.541733,549.945984 
	C240.204544,547.651978 237.389816,539.573669 238.955658,536.877075 
	C240.406204,538.236511 241.854309,539.593567 243.651215,540.975342 
	C244.000000,541.000000 244.000610,541.000000 244.000610,541.000000 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M233.270798,516.199707 
	C234.250763,516.663757 234.898590,517.176941 235.546402,517.690125 
	C234.087189,518.401917 232.627991,519.113647 231.168793,519.825439 
	C230.890808,519.278137 230.612823,518.730896 230.334854,518.183594 
	C231.202789,517.538696 232.070724,516.893799 233.270798,516.199707 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M250.973419,527.784546 
	C250.480713,528.502747 249.979095,528.925110 249.477463,529.347473 
	C249.411530,528.232971 249.345612,527.118408 249.279694,526.003906 
	C249.841293,526.498840 250.402893,526.993774 250.973419,527.784546 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M239.835495,533.921509 
	C239.583160,533.208069 239.583160,532.479919 239.583160,531.751770 
	C239.882294,531.780518 240.181427,531.809265 240.480560,531.838013 
	C240.349655,532.527649 240.218735,533.217224 239.835495,533.921509 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M243.999680,541.000000 
	C244.239807,540.205261 244.480255,539.410522 244.720703,538.615784 
	C245.014481,538.740173 245.308273,538.864502 245.602066,538.988831 
	C245.260513,539.606079 244.918945,540.223328 244.289001,540.920288 
	C244.000610,541.000000 244.000000,541.000000 243.999680,541.000000 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M128.020187,414.215729 
	C127.922607,413.849030 127.825027,413.482361 128.231750,412.980988 
	C134.121307,409.413208 139.556564,406.055634 144.880951,402.530548 
	C154.629639,396.076355 164.312820,389.523193 174.333282,382.862854 
	C184.092941,376.472992 193.542648,370.231628 203.335052,363.927094 
	C208.246353,360.078522 213.011536,356.495422 217.253174,352.373596 
	C218.394730,351.264313 218.497238,348.400604 218.104950,346.548065 
	C217.400696,343.222321 216.858292,338.633057 214.587753,337.196655 
	C212.065659,335.601105 207.686981,336.946564 204.116455,336.998779 
	C202.787125,337.018188 201.455658,337.043488 200.127914,336.993530 
	C195.517380,336.820068 190.896515,336.759247 186.300659,336.391876 
	C176.392731,335.600006 171.106476,344.830872 162.570206,347.168884 
	C157.805771,348.473846 153.527435,351.491333 148.791901,352.978943 
	C142.065674,355.091980 135.119278,356.503540 128.268631,358.221527 
	C125.895958,358.816528 123.528297,359.431519 121.158348,360.037415 
	C122.021271,357.249542 122.884193,354.461670 124.268524,351.334259 
	C125.620079,350.958099 126.522202,351.122192 127.269272,350.854095 
	C135.158325,348.023438 143.005783,345.076172 150.906387,342.278839 
	C152.758301,341.623138 154.788620,341.489929 156.697632,340.971863 
	C158.584641,340.459717 160.709152,340.165161 162.228088,339.075958 
	C167.057587,335.612793 171.567581,331.706238 176.368149,328.199829 
	C179.874573,325.638702 183.379410,322.607635 187.378174,321.331177 
	C190.793182,320.241028 192.441071,318.919708 193.122696,315.589325 
	C193.321350,314.618744 194.604019,313.401215 195.609512,313.138885 
	C203.571823,311.061157 203.588150,311.123718 202.345917,303.985535 
	C203.122101,303.988495 203.565842,303.988037 204.235352,304.235138 
	C206.042328,306.328888 207.436096,308.395416 209.264648,309.950867 
	C210.703247,311.174622 212.896683,312.775757 214.374802,312.455505 
	C219.152664,311.420135 223.732132,309.500427 228.430420,308.059723 
	C236.267456,305.656525 244.137268,303.360291 252.093887,301.351929 
	C255.091217,303.048218 257.864258,304.902649 260.911102,305.669952 
	C266.739594,307.137787 272.796478,307.706604 278.612885,309.211487 
	C281.524811,309.964905 281.917236,313.711029 279.464844,315.557617 
	C275.759186,318.347870 271.810364,320.813049 267.999023,323.465332 
	C262.623383,327.206116 255.233780,329.220642 256.524597,338.384247 
	C256.929260,341.256927 255.523575,343.890778 251.804260,344.954376 
	C250.448227,345.342133 249.412003,348.517212 249.102692,350.553284 
	C248.433594,354.957520 248.944046,359.768005 242.791183,360.791595 
	C242.005890,360.922211 240.938583,362.014893 240.783707,362.813477 
	C240.143311,366.115387 237.151840,367.008759 235.346420,368.995117 
	C232.240219,372.412567 231.409821,377.926788 225.530762,378.609589 
	C225.491577,383.216034 223.158569,385.445526 218.564621,385.591248 
	C217.992996,385.609375 217.071960,386.658447 216.967300,387.331604 
	C216.423264,390.830414 213.109222,391.341034 211.206436,393.276184 
	C208.016129,396.520721 205.652740,400.516296 201.096695,402.232849 
	C200.752060,402.362701 200.445099,403.071350 200.429443,403.521698 
	C200.265106,408.248596 197.167725,408.633667 193.333908,409.260834 
	C190.309708,409.755554 185.270386,408.214264 185.006119,414.038879 
	C169.317093,414.338165 153.628052,414.637421 137.134232,414.734009 
	C133.559692,414.426147 130.789932,414.320923 128.020187,414.215729 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M174.023300,383.011292 
	C164.312820,389.523193 154.629639,396.076355 144.880951,402.530548 
	C139.556564,406.055634 134.121307,409.413208 128.376312,412.824890 
	C129.359818,410.049744 127.819710,409.176636 125.449020,409.061066 
	C122.663147,408.925293 119.862175,409.069153 117.079964,408.895752 
	C113.575569,408.677368 110.682632,407.523376 110.010132,403.099854 
	C109.921524,402.237274 110.095169,401.667664 110.656738,401.064789 
	C111.428711,401.076691 111.812767,401.121887 112.196823,401.167114 
	C111.725060,400.760284 111.253288,400.353485 110.781525,399.946655 
	C110.878601,399.416443 110.975677,398.886230 111.443420,398.078186 
	C111.907280,397.707489 112.095291,397.584900 112.077118,397.527039 
	C112.001686,397.286835 111.864044,397.066132 111.749229,396.838287 
	C113.739861,389.057129 115.725105,381.274628 117.726524,373.496246 
	C118.052040,372.231110 118.449120,370.984375 119.313622,369.365997 
	C121.535179,369.007507 123.256218,369.012146 125.109467,369.358887 
	C126.860863,370.398743 128.418030,371.408722 130.109085,371.744690 
	C142.947372,374.295044 155.835907,376.599121 168.642731,379.293884 
	C170.609894,379.707825 172.238251,381.731873 174.023300,383.011292 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M124.977264,369.016815 
	C123.256218,369.012146 121.535179,369.007507 119.382462,368.986115 
	C119.634117,366.243134 120.317451,363.516876 121.079559,360.414001 
	C123.528297,359.431519 125.895958,358.816528 128.268631,358.221527 
	C135.119278,356.503540 142.065674,355.091980 148.791901,352.978943 
	C153.527435,351.491333 157.805771,348.473846 162.570206,347.168884 
	C171.106476,344.830872 176.392731,335.600006 186.300659,336.391876 
	C190.896515,336.759247 195.517380,336.820068 200.127914,336.993530 
	C201.455658,337.043488 202.787125,337.018188 204.116455,336.998779 
	C207.686981,336.946564 212.065659,335.601105 214.587753,337.196655 
	C216.858292,338.633057 217.400696,343.222321 218.104950,346.548065 
	C218.497238,348.400604 218.394730,351.264313 217.253174,352.373596 
	C213.011536,356.495422 208.246353,360.078522 203.269791,363.582245 
	C199.235199,356.750854 199.124863,356.824554 191.981949,358.381653 
	C190.308258,358.746521 187.178635,358.396149 186.739929,357.443817 
	C184.698868,353.013214 181.070099,353.419830 177.848892,354.417694 
	C173.426895,355.787506 169.381912,358.328461 165.017334,359.939392 
	C156.346756,363.139587 147.576370,366.071289 138.824783,369.047974 
	C137.612503,369.460327 136.265793,369.709320 134.993256,369.667786 
	C131.651077,369.558685 128.315475,369.247467 124.977264,369.016815 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M202.013489,303.982086 
	C203.588150,311.123718 203.571823,311.061157 195.609512,313.138885 
	C194.604019,313.401215 193.321350,314.618744 193.122696,315.589325 
	C192.441071,318.919708 190.793182,320.241028 187.378174,321.331177 
	C183.379410,322.607635 179.874573,325.638702 176.368149,328.199829 
	C171.567581,331.706238 167.057587,335.612793 162.228088,339.075958 
	C160.709152,340.165161 158.584641,340.459717 156.697632,340.971863 
	C154.788620,341.489929 152.758301,341.623138 150.906387,342.278839 
	C143.005783,345.076172 135.158325,348.023438 127.269272,350.854095 
	C126.522202,351.122192 125.620079,350.958099 124.374527,350.992889 
	C125.655106,343.940674 127.351089,336.890289 129.376358,329.344360 
	C131.602386,328.483246 133.499115,328.117645 134.585159,327.908325 
	C137.102341,326.374176 139.052078,325.185883 141.231628,324.212433 
	C142.299530,325.626984 143.137634,326.826630 144.004593,328.425446 
	C144.618988,331.469360 145.204544,334.114105 145.869537,337.117645 
	C152.550751,333.787964 159.112106,331.478699 164.527817,327.578583 
	C171.479721,322.572174 177.902771,316.658844 183.808716,310.429901 
	C188.133652,305.868469 191.263809,300.172882 194.900970,294.964996 
	C195.066330,294.728241 194.980209,294.315826 195.296494,294.167114 
	C197.725677,297.560028 199.869583,300.771057 202.013489,303.982086 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M247.618729,211.860748 
	C248.361313,212.715363 249.103897,213.569992 249.559753,214.732254 
	C248.196945,215.703369 246.995361,216.225952 246.065765,217.053909 
	C241.184753,221.401154 237.026978,227.151337 231.392929,229.932495 
	C221.334503,234.897659 211.567596,241.507080 199.502487,241.108185 
	C198.435181,241.072891 197.269135,241.682480 196.273026,242.221771 
	C190.497574,245.348434 184.074661,246.525101 179.021774,252.065491 
	C175.839767,255.554459 174.008972,259.659302 170.989944,263.019104 
	C165.304565,256.480225 159.619186,249.941360 154.483276,244.034454 
	C168.203278,238.912796 181.248901,234.042877 194.852722,229.271973 
	C195.948288,228.953476 196.485687,228.535950 197.023087,228.118439 
	C198.124756,227.813263 199.226440,227.508087 200.744537,227.512573 
	C203.182205,228.847305 205.250671,230.835754 207.216965,230.739380 
	C219.025284,230.160629 229.037872,225.130905 238.040771,217.624908 
	C240.869232,215.266724 244.406097,213.758179 247.618729,211.860748 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M171.082764,263.346008 
	C174.008972,259.659302 175.839767,255.554459 179.021774,252.065491 
	C184.074661,246.525101 190.497574,245.348434 196.273026,242.221771 
	C197.269135,241.682480 198.435181,241.072891 199.502487,241.108185 
	C211.567596,241.507080 221.334503,234.897659 231.392929,229.932495 
	C237.026978,227.151337 241.184753,221.401154 246.065765,217.053909 
	C246.995361,216.225952 248.196945,215.703369 249.637543,215.020142 
	C251.360367,217.092102 252.718689,219.183853 253.774765,221.615173 
	C248.490967,220.634628 246.433273,225.179276 243.370956,227.327347 
	C235.461685,232.875305 228.838196,240.541656 218.235291,241.288452 
	C217.240234,241.358536 216.349304,242.549469 215.361664,243.138947 
	C208.302048,247.352310 201.513992,252.164093 194.083115,255.572296 
	C186.400742,259.095856 182.659485,263.577972 182.492554,272.473602 
	C182.470383,273.654724 181.533340,274.818665 180.759918,275.870178 
	C179.983246,275.499542 179.466599,275.249237 178.949966,274.998962 
	C176.358505,271.223602 173.767044,267.448242 171.082764,263.346008 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M139.779480,306.631012 
	C135.136292,307.827271 136.333069,304.508972 136.699936,302.654999 
	C138.147980,295.337158 139.758301,288.042938 141.594345,280.812561 
	C143.915985,271.669891 146.357437,262.549530 149.108459,253.529755 
	C149.793457,251.283859 151.866486,249.461319 153.301544,247.444199 
	C155.177490,249.612030 158.287399,251.568771 158.701050,253.986771 
	C160.010178,261.638947 161.495941,269.601959 160.611481,277.175964 
	C159.953201,282.813080 156.367905,288.499664 152.899704,293.308502 
	C149.360535,298.215729 144.372543,302.078094 139.779480,306.631012 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M178.718353,275.228973 
	C179.466599,275.249237 179.983246,275.499542 180.823792,276.207153 
	C183.101593,279.441986 185.055481,282.219482 186.760529,285.237488 
	C185.304947,287.832458 184.557678,290.622345 182.811050,292.465057 
	C175.685150,299.983002 170.738190,309.190338 162.412994,315.958771 
	C156.564163,320.713867 150.498505,324.577637 143.975754,328.026306 
	C143.137634,326.826630 142.299530,325.626984 141.232025,323.832825 
	C141.010849,322.477814 141.019089,321.717224 141.027313,320.956665 
	C147.433197,316.504669 153.893250,312.128143 160.231079,307.581299 
	C167.876999,302.096008 170.642471,293.092194 175.488129,285.620819 
	C177.330460,282.780151 177.535492,278.877563 178.718353,275.228973 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M247.581238,211.543808 
	C244.406097,213.758179 240.869232,215.266724 238.040771,217.624908 
	C229.037872,225.130905 219.025284,230.160629 207.216965,230.739380 
	C205.250671,230.835754 203.182205,228.847305 201.085358,227.476471 
	C201.023651,226.719269 201.037552,226.307816 201.051910,225.593994 
	C210.546677,221.994156 220.070251,218.777878 229.523972,215.367874 
	C234.946381,213.411972 240.255325,211.141541 246.036255,209.022827 
	C246.819351,209.764282 247.181549,210.495575 247.581238,211.543808 
z"/>
<path fill="#383735" opacity="1.000000" stroke="none" 
	d="
M155.890564,231.733856 
	C165.352997,227.117599 174.710693,222.796127 184.443787,218.398407 
	C185.031998,221.143570 186.541794,224.278183 182.745239,226.441132 
	C173.434601,229.866150 164.301651,233.024277 155.168701,236.182388 
	C154.884781,235.850555 154.600845,235.518723 154.316925,235.186890 
	C154.806549,234.134140 155.296188,233.081390 155.890564,231.733856 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M140.953720,320.611877 
	C141.019089,321.717224 141.010849,322.477814 141.002228,323.617981 
	C139.052078,325.185883 137.102341,326.374176 134.585159,327.908325 
	C133.499115,328.117645 131.602386,328.483246 129.411652,328.938110 
	C131.104462,323.405945 133.091248,317.784485 135.078049,312.162994 
	C135.573868,312.186279 136.069702,312.209534 136.565536,312.232788 
	C138.003738,314.910889 139.441940,317.588989 140.953720,320.611877 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M110.735397,400.118591 
	C111.253288,400.353485 111.725060,400.760284 112.196823,401.167114 
	C111.812767,401.121887 111.428711,401.076691 110.854660,400.838562 
	C110.664673,400.645599 110.689285,400.290497 110.735397,400.118591 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M196.735352,228.141327 
	C196.485687,228.535950 195.948288,228.953476 195.218018,229.220734 
	C195.499298,228.768402 195.973465,228.466324 196.735352,228.141327 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M111.645050,397.106995 
	C111.864044,397.066132 112.001686,397.286835 112.077118,397.527039 
	C112.095291,397.584900 111.907280,397.707489 111.624634,397.871307 
	C111.470413,397.753418 111.505646,397.564575 111.645050,397.106995 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M185.471909,414.064941 
	C185.270386,408.214264 190.309708,409.755554 193.333908,409.260834 
	C197.167725,408.633667 200.265106,408.248596 200.429443,403.521698 
	C200.445099,403.071350 200.752060,402.362701 201.096695,402.232849 
	C205.652740,400.516296 208.016129,396.520721 211.206436,393.276184 
	C213.109222,391.341034 216.423264,390.830414 216.967300,387.331604 
	C217.071960,386.658447 217.992996,385.609375 218.564621,385.591248 
	C223.158569,385.445526 225.491577,383.216034 225.530762,378.609589 
	C231.409821,377.926788 232.240219,372.412567 235.346420,368.995117 
	C237.151840,367.008759 240.143311,366.115387 240.783707,362.813477 
	C240.938583,362.014893 242.005890,360.922211 242.791183,360.791595 
	C248.944046,359.768005 248.433594,354.957520 249.102692,350.553284 
	C249.412003,348.517212 250.448227,345.342133 251.804260,344.954376 
	C255.523575,343.890778 256.929260,341.256927 256.524597,338.384247 
	C255.233780,329.220642 262.623383,327.206116 267.999023,323.465332 
	C271.810364,320.813049 275.759186,318.347870 279.464844,315.557617 
	C281.917236,313.711029 281.524811,309.964905 278.612885,309.211487 
	C272.796478,307.706604 266.739594,307.137787 260.911102,305.669952 
	C257.864258,304.902649 255.091217,303.048218 252.344086,301.201996 
	C252.992081,300.138062 253.490479,299.556946 254.362885,298.976166 
	C255.484283,298.963348 256.231689,298.950195 257.323364,298.940887 
	C258.777710,298.294220 259.887726,297.643738 261.402100,296.988647 
	C264.190521,296.643951 266.574585,296.303864 269.314453,295.919098 
	C272.209290,296.256439 274.789001,296.465363 277.280212,297.050903 
	C287.156158,299.372192 298.069672,297.845245 306.679260,305.113251 
	C307.581268,305.874725 310.807678,305.538086 311.499908,304.616943 
	C315.247986,299.629333 318.537872,294.297394 322.281372,289.188477 
	C323.719818,290.190460 324.862518,291.088074 325.938538,292.347107 
	C323.289307,300.924988 320.712372,309.143188 318.121765,317.357086 
	C316.827484,321.460846 314.592316,322.331299 311.815948,319.090851 
	C308.468628,315.184052 304.826721,314.473145 300.299957,315.949524 
	C300.220764,313.590729 300.141571,311.231964 300.232788,308.198669 
	C300.094086,306.526642 299.784943,305.529114 299.475769,304.531555 
	C299.110504,304.629944 298.745270,304.728333 298.380005,304.826721 
	C298.319336,305.926819 298.258698,307.026917 298.198059,308.127014 
	C296.723175,316.449707 295.317810,324.785461 293.760376,333.092682 
	C289.176849,357.540558 284.525696,381.975800 279.924896,406.420441 
	C279.495911,408.699646 279.236115,411.010681 278.845825,413.746063 
	C261.175018,414.114929 243.556137,414.044647 225.123505,413.751831 
	C216.541672,413.735291 208.773575,413.941254 201.005493,414.147217 
	C195.982895,414.128479 190.960297,414.109711 185.471909,414.064941 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M201.359711,414.349762 
	C208.773575,413.941254 216.541672,413.735291 224.654846,413.753845 
	C217.237946,414.169708 209.475952,414.360992 201.359711,414.349762 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M128.145966,414.437469 
	C130.789932,414.320923 133.559692,414.426147 136.665741,414.740356 
	C134.620499,415.301025 132.238968,415.652710 129.431580,416.011902 
	C128.761078,415.566040 128.516418,415.112610 128.145966,414.437469 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M291.897339,494.601257 
	C291.103821,494.455444 290.206421,494.001007 289.141541,493.279663 
	C289.913879,493.439392 290.853668,493.866028 291.897339,494.601257 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M413.992096,276.992371 
	C414.898956,276.610199 415.873230,276.337616 416.701691,275.828003 
	C421.864868,272.651855 422.061279,271.500824 417.772034,267.312592 
	C414.181549,263.806763 411.432800,260.033691 412.474060,254.278046 
	C416.002228,256.158661 419.146271,258.431946 422.794983,261.070160 
	C425.070709,253.465332 427.270844,246.402710 429.293671,239.289658 
	C432.918701,226.542648 436.437073,213.765289 440.000000,201.000610 
	C441.474640,201.880966 442.949280,202.761337 444.775574,204.168701 
	C448.901794,211.736053 454.278442,217.198029 461.463745,220.698517 
	C469.064819,224.401550 471.225311,231.408554 473.027283,238.766647 
	C473.550415,240.902710 474.358154,242.987869 475.222443,245.016693 
	C476.257294,247.445816 477.898499,249.687210 475.201172,252.185455 
	C470.306335,256.719055 465.537994,261.389221 460.716858,266.002411 
	C460.966675,266.671112 461.216492,267.339844 461.466339,268.008545 
	C464.642090,268.008545 467.817841,268.008545 470.993591,268.008545 
	C471.434692,268.011200 471.875793,268.013855 472.649811,268.392395 
	C472.665558,273.598297 469.142517,276.055664 465.479401,277.901123 
	C454.636871,283.363647 444.307983,281.680878 434.497192,274.978149 
	C433.183197,274.080444 431.571686,273.618164 429.655090,272.754761 
	C431.325867,277.095581 432.648834,280.532715 433.579468,283.982178 
	C432.138092,284.014771 431.089020,284.035034 429.737366,284.110779 
	C428.939667,284.757874 428.444611,285.349518 427.581177,285.946045 
	C426.476044,285.961761 425.739319,285.972565 424.985718,285.616730 
	C422.626862,283.237183 420.342590,281.150879 417.917938,279.243011 
	C416.744904,278.319977 415.308258,277.731934 413.995178,276.995544 
	C413.999420,276.998871 413.992096,276.992371 413.992096,276.992371 
M453.337372,271.531708 
	C452.883331,270.696991 452.613342,269.645935 451.937958,269.071381 
	C450.404388,267.766693 448.671417,266.696381 446.067902,264.859344 
	C450.780853,262.939636 454.096252,261.841827 457.134796,260.224091 
	C458.309753,259.598511 458.857239,257.794434 459.690613,256.527252 
	C458.398041,256.060547 457.006714,255.017151 455.828613,255.218903 
	C446.926971,256.743286 438.047516,258.417847 429.205261,260.257538 
	C426.243195,260.873810 425.040619,262.553284 427.920471,265.015411 
	C429.295166,266.190704 430.571747,268.013824 432.140900,268.336365 
	C438.922852,269.730499 445.796509,270.678345 453.337372,271.531708 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M433.971802,283.969849 
	C432.648834,280.532715 431.325867,277.095581 429.655090,272.754761 
	C431.571686,273.618164 433.183197,274.080444 434.497192,274.978149 
	C444.307983,281.680878 454.636871,283.363647 465.479401,277.901123 
	C469.142517,276.055664 472.665558,273.598297 472.980652,268.390350 
	C474.376038,268.006012 475.773529,267.999573 477.969910,267.974609 
	C480.510468,267.640411 482.252075,267.324768 484.329163,267.245056 
	C488.431152,267.492065 492.197754,267.511017 495.964325,267.512604 
	C506.332672,267.517029 516.701050,267.514130 527.325012,267.514130 
	C527.210266,269.098358 527.105164,270.549194 526.780762,272.256592 
	C514.476868,291.375488 502.358276,310.216217 490.340912,329.121307 
	C488.337891,332.272369 486.775482,335.703491 484.861603,339.294006 
	C483.477844,341.059998 482.241974,342.537140 480.677124,344.008667 
	C479.910126,343.997253 479.472046,343.991455 479.033295,343.659454 
	C479.032684,342.898682 479.032745,342.464081 479.373047,341.894531 
	C482.798767,331.941772 486.096069,322.182800 488.847382,312.272247 
	C489.733673,309.079742 488.823212,306.363220 484.799774,304.426208 
	C477.414673,300.870880 470.692383,295.964569 463.531860,291.907257 
	C459.250610,289.481415 454.298553,285.349976 450.295959,285.996521 
	C444.235504,286.975525 439.157043,286.934570 433.971802,283.969849 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M483.993713,267.009125 
	C482.252075,267.324768 480.510468,267.640411 478.388611,267.972534 
	C478.849792,266.386383 479.379333,264.485687 480.581482,263.227905 
	C490.856140,252.477737 489.516388,239.374084 488.257812,226.278381 
	C488.131927,224.968597 487.578644,223.705688 487.341370,222.399643 
	C486.866638,219.786957 487.391937,218.549606 490.665649,218.609772 
	C506.958893,218.909271 523.258057,218.954102 539.554749,218.915878 
	C541.720032,218.910797 543.882996,217.919907 546.408447,217.296326 
	C546.150818,221.077667 540.508179,223.588257 544.777954,227.860138 
	C542.066650,231.340988 539.133667,234.694290 536.014465,237.864365 
	C534.382324,239.523041 534.004761,240.539795 536.950928,241.482986 
	C537.229675,242.395157 537.254272,242.934967 537.025452,243.785294 
	C528.469849,250.065918 518.903442,248.912338 509.524933,249.041809 
	C503.967834,249.118500 498.168365,250.129181 496.252319,242.721710 
	C496.238525,242.668427 495.941376,242.688431 495.381439,242.640137 
	C493.609100,246.095154 491.705994,249.686478 489.914520,253.332672 
	C488.290436,256.638031 486.755219,259.988495 485.249939,263.350067 
	C484.725006,264.522186 484.406464,265.786743 483.993713,267.009125 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M439.721893,201.183563 
	C436.437073,213.765289 432.918701,226.542648 429.293671,239.289658 
	C427.270844,246.402710 425.070709,253.465332 422.794983,261.070160 
	C419.146271,258.431946 416.002228,256.158661 412.511261,253.851700 
	C412.164307,253.818008 412.086304,253.852188 412.031311,253.571289 
	C411.007629,252.429733 410.038879,251.569077 409.070190,250.708420 
	C409.040070,250.548004 409.068054,250.321625 408.971527,250.236664 
	C404.336212,246.155411 405.131226,242.010025 409.294128,237.405121 
	C417.195984,227.994171 424.609589,219.001007 432.023193,210.007843 
	C434.496735,207.127380 436.970245,204.246933 439.721893,201.183563 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M471.056091,267.656555 
	C467.817841,268.008545 464.642090,268.008545 461.466339,268.008545 
	C461.216492,267.339844 460.966675,266.671112 460.716858,266.002411 
	C465.537994,261.389221 470.306335,256.719055 475.201172,252.185455 
	C477.898499,249.687210 476.257294,247.445816 475.222443,245.016693 
	C474.358154,242.987869 473.550415,240.902710 473.027283,238.766647 
	C471.225311,231.408554 469.064819,224.401550 461.463745,220.698517 
	C454.278442,217.198029 448.901794,211.736053 445.058716,204.351044 
	C447.282227,204.338684 449.572205,204.686111 451.867493,204.993683 
	C452.523987,205.081650 453.313965,204.831680 453.843353,205.112518 
	C463.409882,210.187820 474.573273,213.589355 479.498718,224.278778 
	C481.707916,229.073257 481.808014,235.057877 481.933472,240.523819 
	C482.030640,244.755234 480.900482,249.074509 479.873199,253.245682 
	C479.393951,255.191666 477.923615,256.915863 476.801422,258.676117 
	C474.950317,261.579803 473.017151,264.431152 471.056091,267.656555 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M431.633545,209.936127 
	C424.609589,219.001007 417.195984,227.994171 409.468140,237.113388 
	C408.577240,236.327759 408.000580,235.416092 407.211700,234.257935 
	C407.789307,231.466965 408.579102,228.922470 409.533386,225.847992 
	C409.834564,225.906265 410.984741,226.128815 412.126923,226.349823 
	C416.899933,212.504395 422.514893,207.686523 431.633545,209.936127 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M351.001160,189.598541 
	C348.439301,187.946426 346.101349,184.615540 343.265259,184.116760 
	C337.350342,183.076508 333.328796,178.578537 327.896301,177.127502 
	C325.414154,176.464493 322.613190,176.995117 319.494568,176.613495 
	C319.033447,175.506042 319.034607,174.769455 319.035797,174.032883 
	C319.693359,172.552872 320.435364,171.103683 320.989014,169.585754 
	C321.852997,167.217056 322.589264,164.801804 323.677124,162.199280 
	C327.078094,164.309647 330.119110,166.717224 333.299500,168.923828 
	C338.394226,172.458603 344.028412,175.265945 346.320831,181.802994 
	C347.261871,184.486359 349.403809,186.748596 351.001160,189.598541 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M324.225281,156.739746 
	C325.462830,152.127457 326.933075,147.252731 328.696838,142.183350 
	C327.479523,146.818237 325.968719,151.647766 324.225281,156.739746 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M288.185486,270.057007 
	C292.122833,256.955261 296.060181,243.853516 300.067261,230.365692 
	C309.200867,220.339447 318.245331,210.680923 327.344299,201.074005 
	C329.257233,199.054306 331.370483,197.224289 333.670776,195.525055 
	C332.990509,198.165558 332.030823,200.587891 330.739685,203.224930 
	C329.939362,205.120331 328.854126,206.898361 329.101715,208.465881 
	C330.707886,218.635330 325.342194,224.095932 316.898346,228.014709 
	C315.187408,228.808777 314.251007,231.271698 312.675629,233.130402 
	C311.575928,234.520477 310.758179,235.745422 309.940430,236.970367 
	C308.933990,238.709778 307.927582,240.449188 306.830627,242.512894 
	C305.585052,244.027847 304.430023,245.218521 303.095428,247.028442 
	C302.315704,248.768707 301.715546,249.889740 300.931213,251.307373 
	C300.559998,251.874451 300.371124,252.143723 300.186157,252.415634 
	C296.185669,258.295898 292.185608,264.176483 288.185486,270.057007 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M318.952820,173.680878 
	C319.034607,174.769455 319.033447,175.506042 319.038696,176.610687 
	C316.683838,183.600662 317.530609,184.720062 324.429352,185.070999 
	C326.859161,185.194595 329.247467,186.134735 331.654724,186.702026 
	C331.683746,187.461121 331.712769,188.220215 331.741791,188.979309 
	C328.921753,189.598724 326.115509,190.667358 323.279083,190.754608 
	C314.234741,191.032806 305.177795,191.058197 296.128510,190.948990 
	C292.162140,190.901123 288.183044,190.423874 284.253021,189.846664 
	C283.212799,189.693893 282.333252,188.446915 281.379944,187.702362 
	C282.290955,186.878647 283.115662,185.497101 284.129822,185.340195 
	C286.737549,184.936737 289.471344,185.342041 292.076416,184.929382 
	C295.136505,184.444626 298.208740,183.672852 301.070526,182.517288 
	C301.951599,182.161514 302.563904,180.147720 302.589874,178.878616 
	C302.640167,176.420898 301.184143,172.401627 302.134949,171.786972 
	C304.736084,170.105469 308.328766,169.041397 311.402740,169.290207 
	C313.983856,169.499130 316.387573,171.899017 318.952820,173.680878 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M310.358826,237.003586 
	C310.758179,235.745422 311.575928,234.520477 313.053558,233.065659 
	C319.701385,230.611862 325.806763,229.401215 331.186951,224.901901 
	C335.579010,221.228928 337.309326,218.068909 337.122833,212.668900 
	C336.960876,207.979080 333.899811,205.707611 331.071167,203.010223 
	C332.030823,200.587891 332.990509,198.165558 333.973145,195.380905 
	C334.819824,193.292267 335.643494,191.565933 336.681976,189.389450 
	C345.558411,200.030380 347.306824,213.844345 341.338715,223.122910 
	C338.164856,228.057266 335.131012,232.751801 328.571259,234.759216 
	C322.594849,236.588104 317.091309,239.030685 310.358826,237.003586 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M257.313538,139.142609 
	C257.007416,137.936005 257.007538,136.878021 257.008087,135.424225 
	C259.463989,134.846130 261.919403,134.663818 264.695770,134.248154 
	C267.049561,134.016129 269.082458,134.017471 271.550659,134.023819 
	C274.857056,137.351471 277.500214,140.913269 280.664459,143.928192 
	C283.708923,146.829010 285.672119,149.692993 282.165070,153.170547 
	C278.387115,156.916702 275.575256,153.075958 272.894562,151.141769 
	C267.671753,147.373413 262.697144,143.261078 257.313538,139.142609 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M314.417145,125.999115 
	C316.055969,125.332886 318.113190,124.668091 320.589539,124.005966 
	C318.950958,124.672615 316.893250,125.336586 314.417145,125.999115 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M323.263916,124.191704 
	C324.934692,123.789421 326.870636,123.575111 328.906738,123.671112 
	C327.181000,124.114174 325.355011,124.246925 323.263916,124.191704 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M246.134003,181.198288 
	C246.279190,181.160172 246.521179,181.362686 246.863861,181.802353 
	C246.716003,181.845108 246.471420,181.644913 246.134003,181.198288 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M609.016296,454.005859 
	C604.682678,455.542816 605.488220,452.041168 605.192139,449.582306 
	C605.936035,448.889069 606.887695,448.450256 606.869385,448.056549 
	C606.654114,443.432861 606.246887,438.818665 605.979187,434.196808 
	C604.926758,416.031036 603.975830,397.859070 602.832397,379.699158 
	C602.480469,374.109863 601.634521,368.551666 601.435974,363.005859 
	C605.082703,362.357544 608.344727,361.821533 611.527832,360.984772 
	C630.310669,356.047058 649.077637,351.048523 667.845154,346.052582 
	C684.209167,341.696503 700.566895,337.316772 716.927368,332.947449 
	C716.713928,334.119537 716.500427,335.291626 715.896973,336.952759 
	C710.000916,343.625183 704.494812,349.808533 698.994385,355.995239 
	C699.000000,355.998566 698.993103,355.989471 698.692871,356.062866 
	C697.915405,356.750946 697.438171,357.365631 696.681824,358.164185 
	C691.939270,363.239105 687.475708,368.130127 682.755737,373.156616 
	C682.003662,373.871155 681.508118,374.450226 680.804077,375.221527 
	C680.380188,375.931610 680.164734,376.449463 679.629761,377.059387 
	C675.538208,381.428070 671.766235,385.704681 667.994324,389.981293 
	C664.501831,393.519226 661.009338,397.057159 656.972290,400.975983 
	C654.416016,403.765472 652.529053,406.293030 650.368164,408.559357 
	C644.925537,414.267517 639.206848,419.718475 633.890625,425.539093 
	C629.579773,430.258942 625.764343,435.427338 621.595886,440.282349 
	C618.166748,444.276398 614.541931,448.102386 611.003784,452.002838 
	C611.003784,452.002838 611.000000,452.000000 610.702026,452.075806 
	C609.941528,452.769653 609.478943,453.387756 609.016296,454.005859 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M716.831177,332.615509 
	C700.566895,337.316772 684.209167,341.696503 667.845154,346.052582 
	C649.077637,351.048523 630.310669,356.047058 611.527832,360.984772 
	C608.344727,361.821533 605.082703,362.357544 601.309082,362.682800 
	C590.156982,343.257782 579.552979,324.182465 568.976440,304.707825 
	C568.996887,303.508698 568.989868,302.708954 569.359375,301.931396 
	C570.489197,301.956604 571.242554,301.959595 572.375610,302.049622 
	C580.460144,303.771423 588.152222,305.470703 595.875305,307.014404 
	C599.888489,307.816559 603.956665,308.343719 608.342957,309.158447 
	C610.440063,309.583405 612.194214,309.845093 614.201294,310.311035 
	C615.295471,310.701996 616.136658,310.888702 617.223022,311.290039 
	C618.313721,311.660522 619.159119,311.816284 620.340515,311.984344 
	C621.124146,311.975006 621.571838,311.953339 622.375488,312.100342 
	C635.135132,314.801849 647.538879,317.334686 660.339355,319.971436 
	C669.490784,321.714447 678.245422,323.353546 687.329651,325.006958 
	C688.099121,325.030090 688.538940,325.038879 689.224304,325.262939 
	C690.313416,325.646545 691.156921,325.814911 692.388794,326.065491 
	C698.518616,327.428528 704.260193,328.709320 710.001770,329.990143 
	C712.246155,330.754608 714.490540,331.519073 716.831177,332.615509 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M709.984314,329.628845 
	C704.260193,328.709320 698.518616,327.428528 692.308289,325.772095 
	C690.885925,325.280212 689.932373,325.163940 688.978760,325.047668 
	C688.538940,325.038879 688.099121,325.030090 687.329834,324.597717 
	C686.395569,322.019531 686.314880,319.484772 685.082336,317.785461 
	C682.053467,313.609375 675.264893,312.607452 675.020325,305.955597 
	C675.012573,305.744965 674.516541,305.566010 674.269104,305.348419 
	C670.306702,301.864105 666.040039,298.656769 662.504517,294.782410 
	C660.410217,292.487396 658.867798,289.274780 658.176453,286.225555 
	C657.431030,282.938110 656.211243,281.382538 652.830566,280.821289 
	C651.407776,280.585083 649.511353,278.737762 649.156799,277.296448 
	C647.537354,270.712952 646.437622,269.774261 639.665955,269.975830 
	C634.264587,270.136658 633.112305,273.571808 632.648438,278.066437 
	C629.119873,279.383392 628.314758,281.829315 627.883667,285.085327 
	C627.138428,290.714478 625.732727,296.265442 624.387756,301.796539 
	C624.174072,302.675201 622.829956,303.278931 622.006104,303.565613 
	C621.984070,301.288727 622.163696,299.427582 621.907166,297.628601 
	C621.132812,292.198029 614.370361,288.602570 610.768372,292.191254 
	C606.726624,296.218048 602.280518,296.223419 597.470093,295.974121 
	C591.667725,295.673431 585.847229,294.611725 580.085083,294.897766 
	C570.344299,295.381317 564.686646,288.228638 557.561707,283.842377 
	C555.249207,282.418762 552.716309,280.532471 551.555603,278.219727 
	C548.636475,272.403259 546.437927,266.224701 543.964172,260.185730 
	C543.714783,259.576904 543.757996,258.672363 543.328613,258.340057 
	C538.400452,254.525345 540.468445,250.115524 542.461548,245.763504 
	C543.184631,245.895828 543.595032,245.986862 544.003418,246.311508 
	C544.001526,246.545120 544.002441,247.012344 544.002441,247.012329 
	C544.966614,250.499344 545.909729,253.992325 546.898376,257.472412 
	C552.106689,275.806763 564.106812,286.303131 582.531433,291.512268 
	C593.593445,294.639832 602.972473,291.482330 611.613037,287.051514 
	C624.477051,280.454773 633.258423,269.473114 635.832397,254.549957 
	C636.241272,252.179245 635.802429,248.614166 639.379822,249.277039 
	C642.314758,249.820877 645.125549,251.841446 647.595581,253.726089 
	C648.728699,254.590637 649.099243,256.653198 649.494690,258.251831 
	C651.299805,265.549835 654.354675,271.787018 661.621826,275.335419 
	C662.920837,275.969696 663.748474,278.598969 663.886292,280.377411 
	C664.221985,284.708710 665.272583,288.008575 669.168091,290.922821 
	C673.168945,293.915894 675.386169,299.410126 679.500366,302.105927 
	C684.910034,305.650574 685.893616,313.307068 692.700012,315.093536 
	C692.859375,315.135345 693.139893,315.211578 693.143250,315.282166 
	C693.443665,321.646118 700.653748,321.895111 703.246582,326.667969 
	C706.972168,323.328644 709.026001,324.930298 709.984314,329.628845 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M698.988770,355.991913 
	C704.494812,349.808533 710.000916,343.625183 715.749817,337.218872 
	C720.460266,340.705658 727.159729,340.409149 730.909119,345.866486 
	C732.168335,347.699310 735.932434,348.083954 738.652100,348.583221 
	C744.132507,349.589355 749.693298,350.149078 755.196838,351.041809 
	C757.280334,351.379761 759.297180,352.128510 761.693481,352.863464 
	C761.505920,354.372162 761.204102,355.875061 760.391663,357.013641 
	C756.871460,361.947083 753.202087,366.774048 748.880005,371.822479 
	C740.232727,373.568573 732.832153,371.320435 726.822510,366.712921 
	C718.437439,360.284241 708.164185,359.772858 698.988770,355.991913 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M614.984619,270.982361 
	C615.238220,270.489777 615.491821,269.997223 616.156982,269.082916 
	C623.069458,262.454559 626.170288,255.258148 624.059753,246.055679 
	C622.624634,239.798264 623.363098,233.153091 617.239502,228.326981 
	C613.709656,225.545059 611.694214,220.841599 608.997131,217.003052 
	C615.924133,216.505905 620.740356,218.518951 622.573486,226.188782 
	C623.550171,230.275101 627.043213,233.713562 628.320618,237.783386 
	C631.884277,249.137283 629.965088,259.470093 623.394775,269.807129 
	C614.374451,283.999023 601.696533,289.431641 585.916321,287.692932 
	C579.989319,287.039856 574.022339,283.818329 568.795532,280.596558 
	C563.934387,277.600189 559.803101,273.269043 555.776733,269.112640 
	C551.981812,265.195068 552.177612,253.987930 556.181274,250.858826 
	C556.733398,250.730408 557.042664,250.803925 557.030029,251.274277 
	C556.955444,264.392242 565.411987,276.041046 576.886536,281.328247 
	C584.190857,284.693939 591.497437,284.096252 598.863770,283.809906 
	C605.142151,283.565857 613.817932,276.100739 614.984619,270.982361 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M557.042664,250.803925 
	C557.042664,250.803925 556.733398,250.730408 556.578735,250.743774 
	C552.849609,249.195984 549.275085,247.634827 545.092285,246.056183 
	C544.483948,246.038666 544.005371,246.077896 544.005371,246.077896 
	C543.595032,245.986862 543.184631,245.895828 542.221558,245.522568 
	C540.205566,244.651810 538.742188,244.063293 537.278870,243.474777 
	C537.254272,242.934967 537.229675,242.395157 537.278320,241.595520 
	C548.569946,242.421494 559.788269,243.507324 571.408813,244.586395 
	C584.471436,252.044434 597.141602,259.492920 609.764587,267.020599 
	C610.416077,267.409119 610.663635,268.474762 610.694275,269.458008 
	C609.903381,269.654968 609.517456,269.619110 608.986389,269.289856 
	C606.227478,267.997650 603.613708,266.998840 600.686890,265.750885 
	C598.409851,265.378479 596.253418,264.666626 594.515564,265.234833 
	C587.388489,267.565186 582.500671,264.718018 577.895935,259.517914 
	C575.989807,257.365326 572.728577,256.412628 569.754822,254.774597 
	C566.954346,253.746445 564.482727,252.857956 561.681519,251.803192 
	C559.915527,251.359253 558.479126,251.081589 557.042664,250.803925 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M608.747192,217.002930 
	C611.694214,220.841599 613.709656,225.545059 617.239502,228.326981 
	C623.363098,233.153091 622.624634,239.798264 624.059753,246.055679 
	C626.170288,255.258148 623.069458,262.454559 616.274231,268.831909 
	C613.669006,265.216278 616.014465,261.509399 616.705933,258.046600 
	C618.627380,248.424484 616.637390,240.467102 610.330322,232.817001 
	C606.680725,228.390182 601.191833,224.106323 603.370239,216.455719 
	C605.158813,216.354355 606.578186,216.678085 607.997559,217.001831 
	C607.997559,217.001831 608.497314,217.002792 608.747192,217.002930 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M607.926453,216.645325 
	C606.578186,216.678085 605.158813,216.354355 603.374634,216.012375 
	C602.378418,213.904144 601.747070,211.814163 601.049927,209.366302 
	C605.810181,209.042038 606.217590,213.246017 607.926453,216.645325 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M544.003418,246.311508 
	C544.005371,246.077896 544.483948,246.038666 544.722534,246.064117 
	C544.803833,246.346939 544.607910,246.570511 544.187927,246.886307 
	C544.002441,247.012344 544.001526,246.545120 544.003418,246.311508 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M557.030029,251.274277 
	C558.479126,251.081589 559.915527,251.359253 561.628540,252.143890 
	C561.373047,262.218323 575.910583,278.050751 585.240356,279.045319 
	C595.078491,280.094086 602.887146,277.080658 609.131592,269.583252 
	C609.517456,269.619110 609.903381,269.654968 610.576965,269.709106 
	C611.988831,270.146088 613.113037,270.564789 614.610901,270.982910 
	C613.817932,276.100739 605.142151,283.565857 598.863770,283.809906 
	C591.497437,284.096252 584.190857,284.693939 576.886536,281.328247 
	C565.411987,276.041046 556.955444,264.392242 557.030029,251.274277 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M392.995117,247.002243 
	C399.308075,254.580215 405.735931,262.067535 411.842590,269.808289 
	C413.153473,271.469971 413.302521,274.048309 413.989441,276.598389 
	C413.992096,276.992371 413.999420,276.998871 413.619324,277.013214 
	C410.642456,278.102356 407.945190,278.990631 405.483856,280.316864 
	C403.816406,281.215302 402.480621,282.729340 400.660553,283.919983 
	C397.220428,284.906952 394.116211,285.942047 390.942139,286.609894 
	C388.905640,277.051697 382.003082,271.327209 375.958191,264.564667 
	C376.098114,261.846710 376.367157,259.565063 376.345123,257.286285 
	C376.298737,252.502151 376.099426,247.719498 376.006104,242.596802 
	C375.708557,240.868195 375.367798,239.479034 375.145813,237.734940 
	C378.572144,234.938766 380.546814,235.678787 383.124054,239.114014 
	C385.571320,242.375946 389.639618,244.421707 392.995117,247.002243 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M392.932861,246.661331 
	C389.639618,244.421707 385.571320,242.375946 383.124054,239.114014 
	C380.546814,235.678787 378.572144,234.938766 374.883301,237.953644 
	C366.019562,242.891769 357.577454,247.337143 349.041656,251.594711 
	C342.433380,254.890854 335.707947,257.952026 329.018768,260.714844 
	C328.367737,255.127823 325.039246,257.206696 322.310059,257.876831 
	C315.345612,259.586884 308.513489,260.348755 302.281097,255.867874 
	C302.689850,255.521500 303.098602,255.175125 303.507355,254.828735 
	C302.710022,253.556076 301.912689,252.283417 301.115356,251.010773 
	C301.715546,249.889740 302.315704,248.768707 302.997040,247.335632 
	C305.380707,249.550613 306.940674,253.205383 311.528839,252.030060 
	C314.952728,251.152969 318.421814,250.358002 321.919586,249.895248 
	C324.445343,249.561081 327.267426,249.186783 329.579102,249.960526 
	C335.496368,251.941071 340.527893,249.907791 344.719543,246.494598 
	C348.238037,243.629562 350.090668,238.611237 353.732666,236.030930 
	C358.980225,232.313126 360.248291,227.581070 360.831146,221.813858 
	C361.056763,219.581619 363.084320,217.531494 364.865601,214.382568 
	C374.803070,225.086014 384.645142,234.959534 392.932861,246.661331 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M409.072693,251.095322 
	C410.038879,251.569077 411.007629,252.429733 412.024658,253.613342 
	C411.073730,253.118286 410.074493,252.300247 409.072693,251.095322 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M745.837158,113.919678 
	C746.914551,113.877510 747.676941,113.933022 748.439392,113.988525 
	C748.305298,114.575966 748.346313,115.518860 748.005554,115.686775 
	C746.796631,116.282402 745.457397,116.613548 744.167603,117.044899 
	C743.943176,116.672569 743.718750,116.300247 743.494324,115.927925 
	C744.170288,115.291069 744.846191,114.654213 745.837158,113.919678 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M674.401367,596.952759 
	C674.859863,595.804626 675.651794,594.571472 676.728516,593.176880 
	C676.253784,594.299500 675.494324,595.583618 674.401367,596.952759 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M677.393433,591.957275 
	C677.531250,591.414001 677.954102,590.774597 678.674805,590.055054 
	C678.541260,590.603638 678.109863,591.232422 677.393433,591.957275 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M698.994385,355.995239 
	C708.164185,359.772858 718.437439,360.284241 726.822510,366.712921 
	C732.832153,371.320435 740.232727,373.568573 748.598022,372.009888 
	C743.296692,380.211578 737.629639,388.450958 731.822937,396.590698 
	C727.916199,402.067078 723.812073,407.402649 719.492065,412.899261 
	C711.525696,416.795959 703.878357,420.624847 696.183533,424.355927 
	C695.190796,424.837280 693.952332,424.811768 692.093262,425.066650 
	C687.406372,425.028046 687.406372,425.028046 690.456299,418.594513 
	C684.911133,416.403412 685.255798,412.586029 687.173584,407.812042 
	C687.762634,406.345734 686.662170,402.718323 685.526550,402.305573 
	C681.264404,400.756256 680.975159,397.819458 680.927307,394.078491 
	C680.854248,388.371521 680.299561,382.670746 679.949341,376.967316 
	C680.164734,376.449463 680.380188,375.931610 681.030151,375.453522 
	C682.630432,375.935547 683.796265,376.377838 686.106140,377.254120 
	C684.485779,375.037201 683.748962,374.029175 683.012207,373.021149 
	C687.475708,368.130127 691.939270,363.239105 696.985962,358.094574 
	C698.043762,357.223846 698.518433,356.606689 698.993164,355.989502 
	C698.993103,355.989471 699.000000,355.998566 698.994385,355.995239 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M679.629761,377.059387 
	C680.299561,382.670746 680.854248,388.371521 680.927307,394.078491 
	C680.975159,397.819458 681.264404,400.756256 685.526550,402.305573 
	C686.662170,402.718323 687.762634,406.345734 687.173584,407.812042 
	C685.255798,412.586029 684.911133,416.403412 690.456299,418.594513 
	C687.406372,425.028046 687.406372,425.028046 691.697632,425.069183 
	C687.669922,427.660706 683.306458,430.300507 678.935303,432.927490 
	C677.034424,434.069794 675.117676,435.185669 672.773499,436.661987 
	C671.556213,437.335388 670.773621,437.660339 669.661987,438.001160 
	C668.560974,438.364258 667.789001,438.711456 666.762329,439.051636 
	C666.338074,439.038666 666.168396,439.032806 665.998718,439.026917 
	C666.289001,436.792786 666.562073,434.556122 666.890442,432.327606 
	C666.933044,432.038330 667.452881,431.771545 667.428162,431.527161 
	C666.864197,425.968536 669.169312,420.963593 670.212463,415.705536 
	C670.997192,411.749878 670.333191,407.447601 669.943848,403.334686 
	C669.545288,399.125092 668.695923,394.958130 668.019165,390.376892 
	C671.766235,385.704681 675.538208,381.428070 679.629761,377.059387 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M669.991028,437.985352 
	C670.773621,437.660339 671.556213,437.335388 672.666992,436.991272 
	C669.461731,443.033569 672.467590,449.093964 672.999084,455.579834 
	C665.644043,455.665985 658.288025,455.327118 650.218567,454.771912 
	C648.667053,454.372864 647.829102,454.190094 646.991089,454.007324 
	C645.141174,453.840668 643.291260,453.673981 641.441406,453.507294 
	C641.343628,453.055145 641.245911,452.602966 641.148132,452.150787 
	C649.259766,447.924744 657.371460,443.698730 665.740906,439.249817 
	C666.168396,439.032806 666.338074,439.038666 666.991821,439.239685 
	C668.314270,438.951660 669.152649,438.468506 669.991028,437.985352 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M253.988876,298.975830 
	C253.490479,299.556946 252.992081,300.138062 252.243469,300.869080 
	C244.137268,303.360291 236.267456,305.656525 228.430420,308.059723 
	C223.732132,309.500427 219.152664,311.420135 214.374802,312.455505 
	C212.896683,312.775757 210.703247,311.174622 209.264648,309.950867 
	C207.436096,308.395416 206.042328,306.328888 204.161835,303.846680 
	C202.130798,295.353668 205.212875,288.268555 207.490692,281.079193 
	C208.925568,276.550385 210.102203,271.841461 214.413086,268.879456 
	C225.940796,260.958862 237.599747,253.213135 248.859207,244.925995 
	C253.458633,241.540710 257.162750,236.938995 261.274170,232.890686 
	C262.847717,235.313950 264.421265,237.737228 265.950562,240.834534 
	C265.745422,241.796478 265.640778,242.141174 265.415649,242.364166 
	C256.039978,251.650345 244.289734,258.467224 236.877289,269.804718 
	C236.633499,270.177643 236.053970,270.334442 235.625549,270.582703 
	C229.728989,274.000000 229.096863,277.972382 234.573730,281.791504 
	C238.967636,284.855438 239.269684,292.028534 245.890717,292.566498 
	C246.000549,292.575439 246.163239,292.807068 246.180984,292.949707 
	C246.758331,297.589142 249.984772,298.784241 253.988876,298.975830 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M254.362885,298.976166 
	C249.984772,298.784241 246.758331,297.589142 246.180984,292.949707 
	C246.163239,292.807068 246.000549,292.575439 245.890717,292.566498 
	C239.269684,292.028534 238.967636,284.855438 234.573730,281.791504 
	C229.096863,277.972382 229.728989,274.000000 235.625549,270.582703 
	C236.053970,270.334442 236.633499,270.177643 236.877289,269.804718 
	C244.289734,258.467224 256.039978,251.650345 265.415649,242.364166 
	C265.640778,242.141174 265.745422,241.796478 266.022827,241.166595 
	C267.778961,243.298080 269.418457,245.771545 270.692810,248.621338 
	C269.260620,249.590607 267.417725,250.023392 267.256744,250.803345 
	C266.426422,254.825912 263.478271,256.320007 260.222321,257.871582 
	C257.248749,259.288544 257.151215,263.515198 259.757477,265.668976 
	C260.851410,266.572998 261.876495,267.565216 262.889496,268.561493 
	C267.380554,272.978363 271.845428,277.421936 276.359833,281.814697 
	C276.668030,282.114594 277.324890,282.024994 277.785461,282.204285 
	C281.861359,283.790771 285.929535,285.397095 289.792908,287.299500 
	C288.238586,288.105530 286.876923,288.574493 285.546570,289.119843 
	C280.012146,291.388611 274.487122,293.680420 268.958649,295.963745 
	C266.574585,296.303864 264.190521,296.643951 261.161255,296.784058 
	C259.337067,297.368347 258.158081,298.152710 256.979095,298.937073 
	C256.231689,298.950195 255.484283,298.963348 254.362885,298.976166 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M290.000000,286.997559 
	C285.929535,285.397095 281.861359,283.790771 277.785461,282.204285 
	C277.324890,282.024994 276.668030,282.114594 276.359833,281.814697 
	C271.845428,277.421936 267.380554,272.978363 262.889496,268.561493 
	C261.876495,267.565216 260.851410,266.572998 259.757477,265.668976 
	C257.151215,263.515198 257.248749,259.288544 260.222321,257.871582 
	C263.478271,256.320007 266.426422,254.825912 267.256744,250.803345 
	C267.417725,250.023392 269.260620,249.590607 270.724243,248.980774 
	C274.425232,253.624573 277.628479,258.361786 281.074005,262.915741 
	C282.982483,265.438202 285.296265,267.654022 287.806427,270.032532 
	C292.185608,264.176483 296.185669,258.295898 300.186157,252.415634 
	C300.371124,252.143723 300.559998,251.874451 300.931213,251.307358 
	C301.912689,252.283417 302.710022,253.556076 303.507355,254.828735 
	C303.098602,255.175125 302.689850,255.521500 302.281097,255.867874 
	C308.513489,260.348755 315.345612,259.586884 322.310059,257.876831 
	C325.039246,257.206696 328.367737,255.127823 328.894806,261.030090 
	C322.446259,264.927399 316.040100,267.985413 309.805664,271.360565 
	C307.952728,272.363678 306.582214,274.257904 305.240845,276.033325 
	C306.479797,276.534637 307.468567,276.746460 308.575562,277.002197 
	C308.848694,277.141754 308.935730,277.280060 309.146240,277.742981 
	C309.909393,278.355438 310.481262,278.686035 311.019531,279.015320 
	C310.985931,279.014069 310.991211,278.947021 311.013062,279.278442 
	C311.150848,280.036957 311.266754,280.464050 311.000366,280.965790 
	C303.745361,283.026154 296.872681,285.011871 290.000000,286.997559 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M261.191254,232.542572 
	C257.162750,236.938995 253.458633,241.540710 248.859207,244.925995 
	C237.599747,253.213135 225.940796,260.958862 214.413086,268.879456 
	C210.102203,271.841461 208.925568,276.550385 207.490692,281.079193 
	C205.212875,288.268555 202.130798,295.353668 203.936066,303.599121 
	C203.565842,303.988037 203.122101,303.988495 202.345932,303.985535 
	C199.869583,300.771057 197.725677,297.560028 195.121918,293.875061 
	C193.428940,291.279755 192.195801,289.158386 191.161835,286.704437 
	C191.907150,283.684845 193.142502,280.924133 192.864685,278.325195 
	C192.151566,271.653564 195.640274,267.859924 200.685120,264.633698 
	C206.768494,260.743317 212.969635,256.980682 218.708359,252.621140 
	C227.198486,246.171432 238.220169,243.120148 244.383606,233.559372 
	C250.483749,233.003479 252.442261,227.861511 255.292831,223.707336 
	C257.231323,226.536377 259.169830,229.365433 261.191254,232.542572 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M255.209625,223.460815 
	C252.442261,227.861511 250.483749,233.003479 244.383606,233.559372 
	C238.220169,243.120148 227.198486,246.171432 218.708359,252.621140 
	C212.969635,256.980682 206.768494,260.743317 200.685120,264.633698 
	C195.640274,267.859924 192.151566,271.653564 192.864685,278.325195 
	C193.142502,280.924133 191.907150,283.684845 190.848038,286.640991 
	C189.226486,286.272430 188.117935,285.634705 187.009369,284.996979 
	C185.055481,282.219482 183.101593,279.441986 181.083832,276.327515 
	C181.533340,274.818665 182.470383,273.654724 182.492554,272.473602 
	C182.659485,263.577972 186.400742,259.095856 194.083115,255.572296 
	C201.513992,252.164093 208.302048,247.352310 215.361664,243.138947 
	C216.349304,242.549469 217.240234,241.358536 218.235291,241.288452 
	C228.838196,240.541656 235.461685,232.875305 243.370956,227.327347 
	C246.433273,225.179276 248.490967,220.634628 253.852661,221.958786 
	C254.530670,222.379959 254.828537,222.797134 255.209625,223.460815 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M391.150085,543.573608 
	C391.386322,546.821167 391.513611,550.503967 391.448181,554.520142 
	C391.184021,551.238708 391.112610,547.623718 391.150085,543.573608 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M365.365417,557.338013 
	C365.730072,557.607239 366.026367,558.135010 366.156647,558.817627 
	C365.759460,558.513855 365.528259,558.055237 365.365417,557.338013 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M384.502625,558.974976 
	C384.343231,558.341431 384.423859,557.465027 384.712036,556.328857 
	C384.860626,556.956787 384.801666,557.844482 384.502625,558.974976 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M383.322144,561.003662 
	C383.280121,560.564148 383.545959,559.999451 384.100525,559.284912 
	C384.136169,559.716248 383.883087,560.297302 383.322144,561.003662 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M367.293213,561.289429 
	C367.466064,561.192505 367.614288,561.304321 367.780640,561.594482 
	C367.596558,561.753357 367.419861,561.661743 367.293213,561.289429 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M329.322906,564.897583 
	C328.363037,563.885010 327.735687,562.766296 327.063507,561.321960 
	C328.183075,559.765625 329.347443,558.534912 330.842773,557.451111 
	C332.114380,557.399475 333.055023,557.200928 333.995667,557.002441 
	C335.646088,557.478638 337.296509,557.954712 339.791443,558.674500 
	C335.597137,561.205750 332.626282,562.998596 329.322906,564.897583 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M327.272430,558.736206 
	C327.475555,556.806458 327.934052,554.619446 328.697235,552.219849 
	C328.510559,554.164551 328.019165,556.321777 327.272430,558.736206 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M333.621948,557.002441 
	C333.055023,557.200928 332.114380,557.399475 331.089905,557.300232 
	C331.753479,557.002441 332.500854,557.002441 333.621948,557.002441 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M396.000702,566.374512 
	C396.794037,564.235229 397.588074,562.470398 398.566956,560.408325 
	C398.887268,562.415100 399.022705,564.719177 399.159668,567.455078 
	C398.311798,568.164246 397.462463,568.441650 396.308411,568.857239 
	C396.002899,568.246582 396.002136,567.497803 396.000702,566.374512 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M568.948914,305.107178 
	C579.552979,324.182465 590.156982,343.257782 600.887939,362.656189 
	C601.634521,368.551666 602.480469,374.109863 602.832397,379.699158 
	C603.975830,397.859070 604.926758,416.031036 605.979187,434.196808 
	C606.246887,438.818665 606.654114,443.432861 606.869385,448.056549 
	C606.887695,448.450256 605.936035,448.889069 605.183228,449.205383 
	C604.280945,446.995392 603.630371,444.886932 602.954834,442.010040 
	C601.974670,437.486755 601.019409,433.731873 600.057739,429.572723 
	C598.748657,425.086090 597.446167,421.003754 596.123535,416.514191 
	C594.077026,408.403503 592.050720,400.700043 590.002563,392.594482 
	C588.685486,387.138336 587.390198,382.084229 586.069214,376.626038 
	C584.286011,369.311676 582.592896,362.384094 580.733276,355.501434 
	C580.149841,353.342133 579.118408,351.303802 578.311279,348.771881 
	C575.247192,335.778748 572.118469,323.234558 569.132385,310.656555 
	C568.713318,308.891724 568.992737,306.961060 568.948914,305.107178 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M600.064209,429.977020 
	C601.019409,433.731873 601.974670,437.486755 602.901489,441.634094 
	C591.511963,440.837311 584.608765,432.944580 577.138733,425.526825 
	C572.701355,421.120483 573.069824,415.297852 570.730408,410.345490 
	C569.101929,406.898346 567.372620,403.447144 566.261536,399.822113 
	C565.190125,396.326569 563.675537,397.072021 561.145386,398.563721 
	C562.083557,382.324005 563.519531,366.175018 564.969238,349.559814 
	C564.987000,346.444946 564.895691,343.791809 565.020508,341.148834 
	C565.157471,338.245514 564.077332,336.717407 561.009094,336.614105 
	C561.672180,333.475220 562.337280,330.739441 563.004089,327.671021 
	C563.009888,326.894928 563.014038,326.451416 563.415222,326.006409 
	C564.871582,326.004883 565.930908,326.004883 566.992493,326.459229 
	C566.901062,330.217957 566.821777,333.522797 566.710693,336.826569 
	C566.467834,344.041718 567.624146,345.712738 574.509705,348.002747 
	C575.765503,348.420349 577.031006,348.808411 578.292053,349.210083 
	C579.118408,351.303802 580.149841,353.342133 580.733276,355.501434 
	C582.592896,362.384094 584.286011,369.311676 585.695557,376.635162 
	C583.570801,379.030884 581.793945,381.013397 579.903748,383.354370 
	C575.098938,394.342804 577.305603,404.742432 581.737061,414.627197 
	C582.990967,417.424042 587.160400,418.913818 589.996338,421.001526 
	C590.000000,421.000000 589.996155,421.007019 590.007446,421.335632 
	C590.349976,422.442871 590.681091,423.221466 591.112427,424.325134 
	C593.783142,427.057251 595.568909,430.768341 600.064209,429.977020 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M583.104370,463.742310 
	C575.194397,462.473755 567.399780,460.927216 559.626648,458.913330 
	C562.497009,458.808411 565.345947,459.170807 567.775879,459.479919 
	C572.080688,452.691559 567.276489,448.711060 563.821777,444.256683 
	C562.415344,442.443207 561.349304,440.366119 560.123169,438.412231 
	C558.589111,435.967804 557.067200,433.515564 555.508179,431.087158 
	C553.766907,428.374847 551.574890,425.860382 550.343384,422.937775 
	C549.073608,419.924316 548.993469,416.424438 547.860779,413.332306 
	C547.269409,411.718048 545.792236,409.875793 544.264343,409.266022 
	C540.472778,407.752716 536.422180,406.888519 532.497681,405.328949 
	C532.797485,401.936279 533.075256,398.978271 533.693481,396.039886 
	C540.234436,400.351532 547.042542,400.392426 554.075745,398.929199 
	C554.084961,398.934875 554.109619,398.906738 554.109619,398.906738 
	C553.999451,401.251282 554.089355,402.848480 556.861023,404.417419 
	C559.012878,405.635498 559.621338,409.558044 560.946350,412.263580 
	C563.893616,418.281860 566.068298,424.894501 570.055054,430.123291 
	C573.599304,434.771729 576.404968,440.179321 581.948792,443.600403 
	C584.715210,445.307495 585.876526,450.386749 586.669922,454.170074 
	C587.026306,455.869263 584.700623,458.130890 583.020447,461.171631 
	C583.002686,460.967194 583.111267,462.215759 583.104370,463.742310 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M564.955444,350.026031 
	C563.519531,366.175018 562.083557,382.324005 560.853638,398.741211 
	C559.013855,403.004364 556.887024,399.645325 554.450317,398.992584 
	C554.109619,398.906738 554.084961,398.934875 553.999451,398.599365 
	C550.914307,395.532471 547.914612,392.801056 545.291382,390.056091 
	C547.200012,390.227020 548.732178,390.411560 550.402771,390.612762 
	C549.280701,384.122345 547.748047,382.450317 539.958130,378.660156 
	C537.646240,376.781464 534.977722,375.526123 536.422852,372.024994 
	C543.566467,370.516785 545.377014,368.134949 544.964111,360.804993 
	C544.719971,356.472351 543.370361,352.896057 538.010864,352.546326 
	C538.081482,349.718811 538.162781,347.336487 538.609863,344.985260 
	C543.939941,348.441742 548.904236,351.867188 554.142029,355.481262 
	C556.725708,352.366180 557.945129,345.356262 564.955444,350.026031 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M611.384033,451.985168 
	C614.541931,448.102386 618.166748,444.276398 621.595886,440.282349 
	C625.764343,435.427338 629.579773,430.258942 633.890625,425.539093 
	C639.206848,419.718475 644.925537,414.267517 650.368164,408.559357 
	C652.529053,406.293030 654.416016,403.765472 656.725159,401.202942 
	C658.164307,407.829681 656.464355,414.007629 655.031311,420.707947 
	C652.916382,430.596008 645.922729,435.722321 639.864929,441.085632 
	C636.265015,444.272888 631.164307,448.558838 624.502197,448.405518 
	C620.285767,448.308472 616.012207,450.694916 611.384033,451.985168 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M605.963379,468.618958 
	C600.502502,467.850159 595.050415,466.672821 589.307983,465.269531 
	C591.861389,460.290710 594.513245,455.407593 597.618286,450.832031 
	C599.607178,447.901245 601.416992,448.759644 602.188293,452.004791 
	C603.471069,457.401581 604.713074,462.808044 605.963379,468.618958 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M647.073120,454.299805 
	C647.829102,454.190094 648.667053,454.372864 649.750488,454.768646 
	C649.048950,454.851898 648.102112,454.722107 647.073120,454.299805 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M634.799744,476.785431 
	C634.375000,476.854523 633.742737,476.729004 633.046997,476.358337 
	C633.519714,476.272400 634.055969,476.431610 634.799744,476.785431 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M609.312256,453.926697 
	C609.478943,453.387756 609.941528,452.769653 610.703247,452.078064 
	C610.537659,452.618835 610.072937,453.233185 609.312256,453.926697 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M471.033508,390.962585 
	C461.245972,397.160828 451.439392,403.329315 441.689728,409.586609 
	C440.059204,410.633118 438.676147,412.065216 436.703217,413.660767 
	C435.215302,414.416168 434.202759,414.829620 433.190552,415.243835 
	C430.118774,416.500671 427.047150,417.757843 423.962708,418.694458 
	C423.939362,417.946930 423.928711,417.519867 423.944580,416.637085 
	C424.246704,413.384399 424.410034,410.570862 424.819824,407.793671 
	C425.902740,400.453888 423.120667,395.343201 417.106873,393.540344 
	C414.938965,385.761200 412.810913,378.371185 410.565643,370.574158 
	C409.188019,371.608551 408.168915,372.373749 406.908997,372.936584 
	C403.113922,374.491486 399.559692,376.248718 395.714569,377.781708 
	C393.234589,377.377930 391.045502,377.198395 389.432404,377.066101 
	C389.964691,375.076538 390.807709,373.162598 390.930450,371.203522 
	C391.169525,367.388580 390.920593,363.545990 391.027466,359.719147 
	C391.188049,353.968445 393.951935,351.164093 399.688477,351.033569 
	C405.014099,350.912384 410.344604,351.002167 415.672943,351.001465 
	C430.325928,350.999603 444.979126,350.952545 459.631714,351.028259 
	C463.666229,351.049103 465.805145,349.862427 464.411194,345.400116 
	C464.243439,344.863129 464.572327,344.170929 464.865295,342.329742 
	C471.763153,347.026611 477.460480,351.838959 481.703430,358.178162 
	C472.552002,363.078308 463.670044,367.758057 454.891083,372.623749 
	C453.670502,373.300232 452.957916,374.893250 452.080750,376.403076 
	C453.449310,377.883911 454.672211,379.125854 456.058807,380.144989 
	C461.020386,383.791687 466.037445,387.362854 471.033508,390.962585 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M471.413696,390.969727 
	C466.037445,387.362854 461.020386,383.791687 456.058807,380.144989 
	C454.672211,379.125854 453.449310,377.883911 452.511292,376.388611 
	C476.350555,378.166199 499.829926,380.299377 523.404297,382.853882 
	C525.038635,393.599670 526.532410,403.931335 528.149719,414.243652 
	C528.550476,416.799255 529.351746,419.292023 529.973816,422.177856 
	C529.980835,423.027618 529.983093,423.513214 529.985352,423.998779 
	C528.473145,423.709290 526.709290,423.824738 525.488708,423.066071 
	C515.902893,417.108063 506.432739,410.964417 496.896271,404.926392 
	C495.235413,403.874786 493.429993,403.051453 491.338013,402.063568 
	C487.909607,400.324402 484.834564,398.644348 481.387329,396.917267 
	C477.941376,394.905792 474.867615,392.941345 471.413696,390.969727 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M423.975464,419.014862 
	C427.047150,417.757843 430.118774,416.500671 433.190552,415.243835 
	C434.202759,414.829620 435.215302,414.416168 436.605835,413.990662 
	C438.688049,414.299225 441.508545,414.139557 441.890625,415.028229 
	C443.123413,417.895447 446.410767,420.386230 443.278076,424.477478 
	C440.450500,428.170380 439.401276,433.210327 436.631744,436.965912 
	C432.834747,442.114716 429.761047,448.434387 422.288361,449.631897 
	C421.461395,449.764435 420.780762,450.810211 419.723938,451.577942 
	C417.903809,449.544250 416.391968,447.365021 414.982483,445.009247 
	C414.721863,444.556458 414.358948,444.280182 414.083313,443.680084 
	C418.065735,439.671295 421.995270,436.021454 425.827820,432.272491 
	C426.618286,431.499237 427.328247,430.425415 427.587067,429.370361 
	C428.211029,426.826569 428.546112,424.211914 429.108582,420.990875 
	C427.656097,421.352600 426.321198,421.685028 424.770844,421.771851 
	C424.362061,420.689087 424.168762,419.851990 423.975464,419.014862 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M414.880157,445.185760 
	C416.391968,447.365021 417.903809,449.544250 419.703186,451.913452 
	C420.574188,453.206238 421.157623,454.309052 421.880432,455.692505 
	C421.758911,456.213013 421.498108,456.452911 421.086731,456.912750 
	C416.563141,453.434204 412.557953,449.141998 407.742157,446.158112 
	C396.992432,439.497711 385.038666,439.052490 372.511353,440.626648 
	C373.769592,439.495758 375.382141,438.751221 377.460297,438.008575 
	C382.297455,437.968781 386.668976,437.927094 391.425568,437.968750 
	C394.137604,438.421600 396.464600,438.791077 399.061584,439.396545 
	C402.535614,440.847687 405.739655,442.062927 409.167542,443.532501 
	C410.908478,444.217438 412.425537,444.648010 414.177002,445.105286 
	C414.411407,445.132019 414.880157,445.185760 414.880157,445.185760 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M530.348511,423.994720 
	C529.983093,423.513214 529.980835,423.027618 530.129639,421.850281 
	C530.827393,418.997772 531.580322,416.864288 531.870422,414.669647 
	C532.259583,411.725739 532.290771,408.734497 532.475586,405.763580 
	C536.422180,406.888519 540.472778,407.752716 544.264343,409.266022 
	C545.792236,409.875793 547.269409,411.718048 547.860779,413.332306 
	C548.993469,416.424438 549.073608,419.924316 550.343384,422.937775 
	C551.574890,425.860382 553.766907,428.374847 555.508179,431.087158 
	C557.067200,433.515564 558.589111,435.967804 560.123169,438.412231 
	C561.349304,440.366119 562.415344,442.443207 563.821777,444.256683 
	C567.276489,448.711060 572.080688,452.691559 567.775879,459.479919 
	C565.345947,459.170807 562.497009,458.808411 559.369019,458.642517 
	C554.470703,457.804932 549.851379,456.770813 545.069092,455.394775 
	C545.387451,453.908447 546.597229,452.367188 546.226624,451.686676 
	C544.391113,448.316406 542.005493,445.249878 540.087097,441.918793 
	C539.097168,440.199829 538.376953,438.201660 538.059204,436.244537 
	C537.340332,431.816315 536.946838,427.335297 536.398987,422.688843 
	C533.808289,423.281830 532.259949,423.636230 530.348511,423.994720 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M579.234253,546.733765 
	C580.465576,543.699219 581.566406,541.063416 582.667236,538.427673 
	C583.278320,538.434143 583.889404,538.440552 584.500488,538.447021 
	C585.472168,541.622681 586.714294,544.759155 587.184326,548.007385 
	C587.276917,548.647034 584.478699,550.162720 582.857849,550.481262 
	C580.620789,550.920898 578.288208,550.549988 579.234253,546.733765 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M376.994690,438.006653 
	C375.382141,438.751221 373.769592,439.495758 372.081451,440.587708 
	C371.577209,441.049866 371.148590,441.164551 370.391296,441.350586 
	C370.309998,438.296631 368.987732,437.008209 365.747192,436.807251 
	C364.460266,436.727448 362.335815,434.896942 362.234375,433.721741 
	C361.882599,429.646759 359.003815,429.225922 356.612610,430.194916 
	C352.098755,432.024017 346.006714,431.470245 344.396393,438.583649 
	C344.006561,440.305634 338.636505,441.495453 335.441986,441.827728 
	C331.913696,442.194672 329.213074,442.502502 328.825684,446.767395 
	C328.758636,447.505615 327.578339,448.799316 327.034515,448.738739 
	C319.361145,447.883789 313.425018,451.542511 307.360046,455.538147 
	C308.338593,449.131561 309.672119,443.068085 311.024078,436.685150 
	C311.055603,435.939331 311.068756,435.512970 311.369324,434.985474 
	C312.074188,434.240967 312.491608,433.597565 312.944641,432.718994 
	C312.980225,432.483826 313.049255,432.013184 313.402344,432.024689 
	C316.332428,431.896942 318.936707,431.453461 321.480621,431.682098 
	C326.709198,432.151947 329.543854,427.995941 333.357269,425.816620 
	C334.909393,424.929565 336.649323,422.989624 336.828979,421.365601 
	C337.222809,417.805542 338.979156,416.853668 342.092560,416.991119 
	C343.747772,417.064240 345.411560,416.940247 347.067078,417.009918 
	C350.482056,417.153687 353.074036,416.571289 354.238617,412.560547 
	C355.265198,409.025116 358.279663,409.107635 360.393188,411.460144 
	C362.073364,413.330261 362.728546,416.093201 363.984436,418.379761 
	C365.493378,421.127014 367.124054,423.809448 368.760742,426.484009 
	C371.230072,430.519135 376.669952,432.433960 376.994690,438.006653 
z"/>
<path fill="#DE5951" opacity="1.000000" stroke="none" 
	d="
M349.100952,477.146149 
	C354.068054,475.630920 359.112610,474.324982 363.974609,472.525726 
	C367.134003,471.356567 368.636047,472.675629 369.881378,475.159790 
	C370.542297,476.478302 371.005585,477.937256 371.864807,479.107635 
	C374.901794,483.244690 374.487152,484.124878 369.165039,485.101715 
	C367.613464,485.386444 365.784760,485.880890 364.743225,486.932800 
	C359.759003,491.966614 350.229218,493.497223 344.046265,490.081207 
	C342.644714,489.306854 341.046448,488.888550 339.320007,487.757324 
	C339.460358,485.251648 339.819458,483.295044 340.478912,481.365051 
	C343.553162,479.976532 346.327057,478.561340 349.100952,477.146149 
M352.928406,483.616852 
	C353.976318,483.310120 355.033722,483.031952 356.069214,482.687836 
	C356.925049,482.403351 357.755005,482.040924 358.596436,481.713104 
	C357.712128,481.258179 356.836395,480.438324 355.941925,480.417297 
	C353.334290,480.356018 350.719147,480.614044 348.106964,480.746887 
	C347.973145,481.342041 347.839325,481.937195 347.705536,482.532349 
	C349.191925,482.899872 350.678314,483.267426 352.928406,483.616852 
z"/>
<path fill="#E1E4E8" opacity="1.000000" stroke="none" 
	d="
M426.242798,486.421539 
	C417.240417,483.851532 408.578461,481.713593 398.170502,484.197723 
	C400.602386,477.660645 402.838287,472.507935 408.314148,470.098785 
	C413.338165,467.888458 419.096863,467.298981 423.456909,471.200409 
	C427.844055,475.126068 428.311646,480.708618 426.242798,486.421539 
M412.432556,471.553589 
	C407.790497,473.000793 405.044128,476.256622 403.363373,480.957581 
	C410.539398,480.957581 417.432343,480.957581 424.486237,480.957581 
	C423.718018,474.579712 421.436951,471.340424 415.321991,472.671906 
	C414.670593,472.813751 413.829041,472.082428 412.432556,471.553589 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M349.378296,476.749695 
	C346.327057,478.561340 343.553162,479.976532 340.430359,481.012421 
	C340.870148,479.640472 341.658783,478.647858 342.294556,477.847687 
	C341.662750,476.299408 340.366760,474.726898 340.645477,473.517212 
	C341.630585,469.241821 343.154755,465.090637 344.807526,460.945740 
	C346.972595,463.182495 348.804657,465.365845 350.636719,467.549194 
	C350.309692,470.483887 349.982697,473.418549 349.378296,476.749695 
z"/>
<path fill="#EBECE9" opacity="1.000000" stroke="none" 
	d="
M344.474548,460.892334 
	C343.154755,465.090637 341.630585,469.241821 340.645477,473.517212 
	C340.366760,474.726898 341.662750,476.299408 342.294556,477.847687 
	C341.658783,478.647858 340.870148,479.640472 340.130005,480.985779 
	C339.819458,483.295044 339.460358,485.251648 339.122742,487.593933 
	C338.859650,488.789764 338.575134,489.599945 338.184692,490.713806 
	C337.884399,491.009460 337.690063,491.001404 337.232056,490.533508 
	C336.980103,486.038513 336.991791,482.003296 337.003479,477.968079 
	C335.945160,471.562744 339.649292,466.361603 341.514709,460.718964 
	C341.885773,459.596466 343.624298,458.926025 345.098877,458.308136 
	C345.135162,459.343536 344.804840,460.117920 344.474548,460.892334 
z"/>
<path fill="#DDBE30" opacity="1.000000" stroke="none" 
	d="
M344.807495,460.945740 
	C344.804840,460.117920 345.135162,459.343536 345.488220,458.247620 
	C345.721497,457.692169 345.787384,457.422607 345.882019,456.924194 
	C347.228882,455.883972 348.402252,455.037109 349.575592,454.190247 
	C351.386230,455.556213 353.280243,456.825867 354.991364,458.306824 
	C359.529938,462.234894 359.339996,463.665283 354.045563,466.363678 
	C353.167572,466.811157 352.221039,467.124146 350.971436,467.524231 
	C348.804657,465.365845 346.972595,463.182495 344.807495,460.945740 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M369.004852,467.981689 
	C367.400848,468.743439 365.796844,469.505188 365.357330,469.713898 
	C363.707855,467.666962 362.674194,466.726318 362.062164,465.563690 
	C359.782684,461.233521 363.992126,460.603516 366.091766,458.530151 
	C365.411774,462.184753 371.354523,463.422943 368.959290,467.712646 
	C368.990814,468.005005 369.004852,467.981689 369.004852,467.981689 
z"/>
<path fill="#E7E9EC" opacity="1.000000" stroke="none" 
	d="
M353.513306,449.166626 
	C354.494202,444.220459 358.349335,441.977844 365.947144,442.124298 
	C362.080261,444.632477 357.977875,446.891815 353.513306,449.166626 
z"/>
<path fill="#EBECE9" opacity="1.000000" stroke="none" 
	d="
M349.797974,454.000671 
	C348.402252,455.037109 347.228882,455.883972 345.713745,456.785950 
	C346.113525,452.723724 347.498718,449.203827 352.873413,449.119568 
	C352.131104,450.699036 351.075714,452.255066 349.797974,454.000671 
z"/>
<path fill="#6A7E8B" opacity="1.000000" stroke="none" 
	d="
M368.979248,468.013428 
	C369.452026,468.215149 369.936401,468.408478 370.420776,468.601837 
	C370.337646,468.758942 370.254547,468.916046 370.171417,469.073151 
	C369.865509,468.829498 369.559601,468.585815 369.129272,468.161926 
	C369.004852,467.981689 368.990814,468.005005 368.979248,468.013428 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M488.891266,560.619629 
	C484.123260,561.355835 479.367493,562.223816 474.572449,562.668396 
	C473.945160,562.726624 472.504730,560.626892 472.479279,559.503235 
	C472.299377,551.558044 472.345703,543.606445 472.413574,535.657654 
	C472.450226,531.367920 475.573578,532.346436 478.572998,532.950806 
	C481.298248,541.135376 483.516083,548.882690 485.979309,556.551147 
	C486.464966,558.063110 487.896149,559.271301 488.891266,560.619629 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M489.149414,560.555725 
	C487.896149,559.271301 486.464966,558.063110 485.979309,556.551147 
	C483.516083,548.882690 481.298248,541.135376 478.962921,533.081909 
	C483.400421,532.291077 487.605255,532.657959 490.835693,537.307373 
	C490.687561,545.101257 490.393707,552.106079 490.099854,559.110901 
	C489.869080,559.571228 489.638336,560.031494 489.149414,560.555725 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M490.396057,558.911377 
	C490.393707,552.106079 490.687561,545.101257 490.970612,537.643372 
	C495.169434,543.537415 495.059906,552.345459 490.396057,558.911377 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M439.997894,544.888428 
	C441.276611,541.526306 442.421204,538.523926 443.565796,535.521606 
	C443.978394,535.531860 444.390991,535.542114 444.803558,535.552307 
	C446.433594,540.407043 448.063629,545.261719 449.895142,550.716553 
	C445.718658,550.716553 442.378326,550.716553 438.302582,550.716553 
	C438.913025,548.578308 439.388397,546.913269 439.997894,544.888428 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M300.967834,337.013855 
	C300.684784,333.267761 300.401764,329.521698 300.509521,325.387268 
	C307.019287,327.345490 312.272858,330.672516 315.792572,336.524597 
	C318.424316,340.900360 319.864807,340.881866 322.252991,336.570038 
	C325.278717,331.107025 328.060089,325.487213 330.549347,319.762207 
	C331.258575,318.131104 330.683777,315.891449 330.522278,313.945404 
	C330.340820,311.758972 329.627258,309.571564 329.740082,307.415741 
	C329.941040,303.575104 330.589447,299.757904 331.386597,296.007202 
	C338.617035,300.613403 339.178802,302.524719 335.839081,309.838043 
	C335.292694,311.034546 334.196930,312.553253 334.544037,313.475647 
	C335.525970,316.085388 336.902771,318.641479 338.639862,320.804413 
	C339.066620,321.335785 341.462952,320.285309 342.951691,320.353607 
	C341.632874,325.163055 340.316162,329.580902 338.971985,334.352478 
	C338.920166,335.415100 338.895782,336.124084 338.570038,336.972931 
	C337.882202,338.083344 337.495728,339.053894 337.074829,340.433716 
	C334.662201,349.227783 332.283936,357.612549 329.686218,365.658203 
	C327.585266,357.269379 325.703766,349.219635 323.472534,339.673584 
	C319.982788,343.524078 317.704285,346.038177 314.646729,349.411804 
	C312.625610,346.888153 310.403107,344.756409 308.984772,342.181641 
	C307.152924,338.856079 305.232452,336.287933 300.967834,337.013855 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M300.965332,337.443817 
	C305.232452,336.287933 307.152924,338.856079 308.984772,342.181641 
	C310.403107,344.756409 312.625610,346.888153 314.646729,349.411804 
	C317.704285,346.038177 319.982788,343.524078 323.472534,339.673584 
	C325.703766,349.219635 327.585266,357.269379 329.691162,366.054840 
	C327.876740,374.505157 325.837921,382.219757 323.500122,390.062378 
	C322.835205,391.167328 322.469238,392.144287 322.095276,393.437744 
	C322.069061,394.176208 322.050812,394.598175 321.667664,395.030518 
	C314.274292,399.389313 307.245789,403.737732 300.217285,408.086182 
	C300.019073,400.937195 301.531036,395.132050 309.136383,391.810883 
	C312.185608,390.479309 314.754272,386.723572 316.278595,383.477997 
	C320.462433,374.569855 323.412323,365.238190 320.686005,355.194702 
	C320.232758,353.524994 319.070496,352.047760 317.529388,349.155823 
	C314.820953,351.376892 312.734772,353.087708 310.557892,354.872894 
	C308.818359,349.884644 299.871490,346.924469 300.965332,337.443817 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M331.050751,295.930908 
	C330.589447,299.757904 329.941040,303.575104 329.740082,307.415741 
	C329.627258,309.571564 330.340820,311.758972 330.522278,313.945404 
	C330.683777,315.891449 331.258575,318.131104 330.549347,319.762207 
	C328.060089,325.487213 325.278717,331.107025 322.252991,336.570038 
	C319.864807,340.881866 318.424316,340.900360 315.792572,336.524597 
	C312.272858,330.672516 307.019287,327.345490 300.552429,324.957153 
	C300.185089,322.226471 300.165649,319.537567 300.223083,316.399109 
	C304.826721,314.473145 308.468628,315.184052 311.815948,319.090851 
	C314.592316,322.331299 316.827484,321.460846 318.121765,317.357086 
	C320.712372,309.143188 323.289307,300.924988 326.233093,292.484436 
	C328.079773,293.483826 329.565247,294.707367 331.050751,295.930908 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M300.179871,408.524292 
	C307.245789,403.737732 314.274292,399.389313 321.669250,395.413361 
	C321.693604,397.525208 321.351471,399.264557 320.756683,401.274048 
	C319.622620,405.335724 318.741211,409.127319 317.498108,412.950745 
	C315.382629,413.858185 313.667297,414.824280 311.866821,415.590271 
	C308.271729,417.119751 304.629944,418.539459 301.007446,420.004517 
	C300.719116,416.323822 300.430786,412.643127 300.179871,408.524292 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M300.993103,420.472473 
	C304.629944,418.539459 308.271729,417.119751 311.866821,415.590271 
	C313.667297,414.824280 315.382629,413.858185 317.476807,413.320221 
	C317.240082,415.777405 316.663025,417.896912 315.991730,420.384827 
	C314.948090,424.506531 313.998657,428.259857 313.049255,432.013184 
	C313.049255,432.013184 312.980225,432.483826 312.653870,432.812775 
	C311.912323,433.790009 311.497101,434.438293 311.081909,435.086578 
	C311.068756,435.512970 311.055603,435.939331 310.640808,436.688538 
	C308.854645,437.638428 307.476288,438.279541 306.084045,438.888916 
	C304.424683,439.615265 302.753479,440.314453 301.087402,441.025391 
	C301.051208,434.330414 301.014984,427.635406 300.993103,420.472473 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M301.045746,441.490295 
	C302.753479,440.314453 304.424683,439.615265 306.084045,438.888916 
	C307.476288,438.279541 308.854645,437.638428 310.622375,437.007965 
	C309.672119,443.068085 308.338593,449.131561 307.009583,455.599731 
	C307.014130,456.004425 306.991821,456.485596 306.657532,456.846191 
	C305.891724,458.472656 305.460236,459.738495 305.028748,461.004333 
	C304.046936,462.362366 303.065155,463.720367 301.732300,465.109619 
	C301.255554,457.412354 301.129822,449.683777 301.045746,441.490295 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M298.506714,308.096069 
	C298.258698,307.026917 298.319336,305.926819 298.380005,304.826721 
	C298.745270,304.728333 299.110504,304.629944 299.475769,304.531555 
	C299.784943,305.529114 300.094086,306.526642 300.231598,307.754974 
	C299.645111,308.012238 299.230225,308.038696 298.506714,308.096069 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M336.824219,478.259186 
	C336.991791,482.003296 336.980103,486.038513 336.940582,490.520935 
	C331.170013,491.280579 325.427307,491.593048 319.340454,491.963837 
	C318.996338,492.022156 318.994293,492.001556 318.991455,491.667999 
	C318.992432,490.889618 318.996216,490.444824 319.000000,490.000000 
	C324.881653,486.183411 330.763306,482.366852 336.824219,478.259186 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M305.357208,460.887451 
	C305.460236,459.738495 305.891724,458.472656 306.652069,457.086975 
	C306.549164,458.234924 306.117401,459.502747 305.357208,460.887451 
z"/>
<path fill="#E3E6EB" opacity="1.000000" stroke="none" 
	d="
M318.743652,490.064026 
	C318.996216,490.444824 318.992432,490.889618 318.983459,491.667969 
	C318.814636,491.377045 318.650970,490.752533 318.743652,490.064026 
z"/>
<path fill="#DCDFE4" opacity="1.000000" stroke="none" 
	d="
M279.129486,547.006958 
	C280.617889,542.967712 282.000885,539.300171 283.383850,535.632568 
	C283.785065,535.598450 284.186279,535.564331 284.587494,535.530151 
	C286.201477,540.345764 287.815460,545.161377 289.437195,550.000122 
	C281.790802,552.313599 278.835297,551.449768 279.129486,547.006958 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M125.109467,369.358887 
	C128.315475,369.247467 131.651077,369.558685 134.993256,369.667786 
	C136.265793,369.709320 137.612503,369.460327 138.824783,369.047974 
	C147.576370,366.071289 156.346756,363.139587 165.017334,359.939392 
	C169.381912,358.328461 173.426895,355.787506 177.848892,354.417694 
	C181.070099,353.419830 184.698868,353.013214 186.739929,357.443817 
	C187.178635,358.396149 190.308258,358.746521 191.981949,358.381653 
	C199.124863,356.824554 199.235199,356.750854 202.927078,363.645386 
	C193.542648,370.231628 184.092941,376.472992 174.333267,382.862823 
	C172.238251,381.731873 170.609894,379.707825 168.642731,379.293884 
	C155.835907,376.599121 142.947372,374.295044 130.109085,371.744690 
	C128.418030,371.408722 126.860863,370.398743 125.109467,369.358887 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M186.760529,285.237488 
	C188.117935,285.634705 189.226486,286.272430 190.648865,286.973572 
	C192.195801,289.158386 193.428940,291.279755 194.836639,293.693176 
	C194.980209,294.315826 195.066330,294.728241 194.900970,294.964996 
	C191.263809,300.172882 188.133652,305.868469 183.808716,310.429901 
	C177.902771,316.658844 171.479721,322.572174 164.527817,327.578583 
	C159.112106,331.478699 152.550751,333.787964 145.869537,337.117645 
	C145.204544,334.114105 144.618988,331.469360 144.004593,328.425446 
	C150.498505,324.577637 156.564163,320.713867 162.412994,315.958771 
	C170.738190,309.190338 175.685150,299.983002 182.811050,292.465057 
	C184.557678,290.622345 185.304947,287.832458 186.760529,285.237488 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M289.792908,287.299500 
	C296.872681,285.011871 303.745361,283.026154 311.278046,280.960907 
	C312.312988,280.915039 312.687897,280.948730 313.030090,280.974762 
	C312.997314,280.967072 313.021606,280.904266 313.154083,281.216248 
	C316.186249,284.046844 319.085938,286.565460 321.985626,289.084076 
	C318.537872,294.297394 315.247986,299.629333 311.499908,304.616943 
	C310.807678,305.538086 307.581268,305.874725 306.679260,305.113251 
	C298.069672,297.845245 287.156158,299.372192 277.280212,297.050903 
	C274.789001,296.465363 272.209290,296.256439 269.314453,295.919128 
	C274.487122,293.680420 280.012146,291.388611 285.546570,289.119843 
	C286.876923,288.574493 288.238586,288.105530 289.792908,287.299500 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M322.281372,289.188477 
	C319.085938,286.565460 316.186249,284.046844 313.528290,281.132599 
	C319.853271,283.996704 325.953186,287.225769 332.015778,290.523499 
	C340.676025,295.234192 349.308929,299.995148 358.076324,305.077393 
	C361.079651,309.779327 364.095001,314.058350 366.763092,318.543945 
	C367.626312,319.995209 367.578339,321.988464 367.708008,323.531616 
	C365.325531,321.828552 363.182220,320.327606 360.866089,318.544250 
	C358.380310,316.683563 356.225098,314.769287 353.722717,313.594788 
	C347.953186,310.886841 343.835083,313.485565 343.015747,320.002594 
	C342.999451,319.983398 342.953796,319.962036 342.953796,319.962036 
	C341.462952,320.285309 339.066620,321.335785 338.639862,320.804413 
	C336.902771,318.641479 335.525970,316.085388 334.544037,313.475647 
	C334.196930,312.553253 335.292694,311.034546 335.839081,309.838043 
	C339.178802,302.524719 338.617035,300.613403 331.386597,296.007202 
	C329.565247,294.707367 328.079773,293.483826 326.299744,292.122986 
	C324.862518,291.088074 323.719818,290.190460 322.281372,289.188477 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M257.323364,298.940887 
	C258.158081,298.152710 259.337067,297.368347 260.756897,296.788635 
	C259.887726,297.643738 258.777710,298.294220 257.323364,298.940887 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M316.085938,420.016449 
	C316.663025,417.896912 317.240082,415.777405 317.838440,413.288391 
	C318.741211,409.127319 319.622620,405.335724 321.065674,401.185974 
	C326.556915,396.244385 331.574463,391.750061 336.363647,387.024323 
	C337.840271,385.567261 338.923340,383.582581 339.791748,381.661041 
	C342.838257,374.919952 344.408997,366.949310 349.066467,361.677521 
	C352.767914,357.487885 352.956482,354.957336 351.125549,350.758057 
	C350.787079,349.981812 351.254242,348.854309 351.349579,347.888916 
	C353.985748,347.159943 355.682068,345.643646 357.488464,344.272369 
	C361.005341,341.602600 364.514740,338.911987 368.168304,336.438293 
	C369.813995,335.324036 372.099365,335.059509 373.566589,333.796234 
	C376.713318,331.086945 379.731812,328.161713 382.419342,324.999023 
	C389.337952,316.857178 394.948853,308.007874 396.289001,296.814514 
	C398.710571,295.731750 400.833435,294.819763 403.334412,293.862854 
	C410.809204,291.206421 417.905884,288.594879 425.002563,285.983337 
	C425.739319,285.972565 426.476044,285.961761 427.885193,285.892059 
	C429.051727,285.240540 429.545868,284.647919 430.039978,284.055298 
	C431.089020,284.035034 432.138092,284.014771 433.579468,283.982178 
	C439.157043,286.934570 444.235504,286.975525 450.295959,285.996521 
	C454.298553,285.349976 459.250610,289.481415 463.531860,291.907257 
	C470.692383,295.964569 477.414673,300.870880 484.799774,304.426208 
	C488.823212,306.363220 489.733673,309.079742 488.847382,312.272247 
	C486.096069,322.182800 482.798767,331.941772 479.180664,341.606232 
	C471.494965,335.103882 463.935699,329.143066 457.350891,322.251221 
	C453.758667,318.491486 451.397583,313.333618 449.310608,308.451721 
	C448.278229,306.036774 447.402740,304.954102 444.896820,304.894623 
	C434.152802,304.639709 423.408264,304.370331 412.668915,303.972229 
	C408.369324,303.812805 404.998535,304.438629 404.729645,309.779541 
	C404.679596,310.773560 403.586365,311.702637 403.016022,312.685699 
	C401.994141,314.446960 400.960785,316.203796 400.010895,318.003998 
	C398.796295,320.305939 398.060547,322.998779 396.403046,324.899170 
	C393.267334,328.494476 389.624756,331.648193 386.186188,334.978577 
	C384.551422,336.561890 383.146637,338.972900 381.209564,339.535858 
	C376.589813,340.878448 375.177368,344.285370 374.026855,348.195404 
	C373.657104,349.451965 373.581726,351.402130 372.756287,351.820312 
	C366.846680,354.814331 367.472412,360.857117 365.779114,365.835419 
	C363.627747,372.160370 361.658386,378.585052 358.910553,384.649567 
	C357.897034,386.886353 354.871246,388.195862 352.804749,389.976501 
	C351.451080,391.142944 349.847290,392.190735 348.924255,393.651154 
	C346.728516,397.125244 345.741608,402.063019 342.709747,404.175323 
	C334.258545,410.063416 325.016022,414.815704 316.085938,420.016449 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M452.987091,271.660126 
	C445.796509,270.678345 438.922852,269.730499 432.140900,268.336365 
	C430.571747,268.013824 429.295166,266.190704 427.920471,265.015411 
	C425.040619,262.553284 426.243195,260.873810 429.205261,260.257538 
	C438.047516,258.417847 446.926971,256.743286 455.828613,255.218903 
	C457.006714,255.017151 458.398041,256.060547 459.690613,256.527252 
	C458.857239,257.794434 458.309753,259.598511 457.134796,260.224091 
	C454.096252,261.841827 450.780853,262.939636 446.067902,264.859344 
	C448.671417,266.696381 450.404388,267.766693 451.937958,269.071381 
	C452.613342,269.645935 452.883331,270.696991 452.987091,271.660126 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M424.985718,285.616699 
	C417.905884,288.594879 410.809204,291.206421 403.339111,293.477051 
	C402.309326,290.080139 401.652924,287.024109 400.996490,283.968079 
	C402.480621,282.729340 403.816406,281.215302 405.483856,280.316864 
	C407.945190,278.990631 410.642456,278.102356 413.615112,277.009888 
	C415.308258,277.731934 416.744904,278.319977 417.917938,279.243011 
	C420.342590,281.150879 422.626862,283.237183 424.985718,285.616699 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M429.737366,284.110779 
	C429.545868,284.647919 429.051727,285.240540 428.253601,285.887177 
	C428.444611,285.349518 428.939667,284.757874 429.737366,284.110779 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M315.991730,420.384827 
	C325.016022,414.815704 334.258545,410.063416 342.709747,404.175323 
	C345.741608,402.063019 346.728516,397.125244 348.924255,393.651154 
	C349.847290,392.190735 351.451080,391.142944 352.804749,389.976501 
	C354.871246,388.195862 357.897034,386.886353 358.910553,384.649567 
	C361.658386,378.585052 363.627747,372.160370 365.779114,365.835419 
	C367.472412,360.857117 366.846680,354.814331 372.756287,351.820312 
	C373.581726,351.402130 373.657104,349.451965 374.026855,348.195404 
	C375.177368,344.285370 376.589813,340.878448 381.209564,339.535858 
	C383.146637,338.972900 384.551422,336.561890 386.186188,334.978577 
	C389.624756,331.648193 393.267334,328.494476 396.403046,324.899170 
	C398.060547,322.998779 398.796295,320.305939 400.010895,318.003998 
	C400.960785,316.203796 401.994141,314.446960 403.016022,312.685699 
	C403.586365,311.702637 404.679596,310.773560 404.729645,309.779541 
	C404.998535,304.438629 408.369324,303.812805 412.668915,303.972229 
	C423.408264,304.370331 434.152802,304.639709 444.896820,304.894623 
	C447.402740,304.954102 448.278229,306.036774 449.310608,308.451721 
	C451.397583,313.333618 453.758667,318.491486 457.350891,322.251221 
	C463.935699,329.143066 471.494965,335.103882 478.840363,341.741211 
	C479.032745,342.464081 479.032684,342.898682 478.745239,343.846191 
	C478.203339,345.501556 477.828400,346.640900 477.798340,347.789215 
	C477.792511,348.012573 479.128815,348.271088 479.846069,348.513367 
	C480.232758,347.013672 480.619446,345.514008 481.006104,344.014282 
	C482.241974,342.537140 483.477844,341.059998 485.227631,339.324097 
	C486.363037,340.155884 487.572937,341.284973 487.504395,342.330292 
	C487.204803,346.899872 486.534363,351.445129 485.714722,356.157654 
	C484.289185,356.882965 483.147369,357.449860 482.005554,358.016754 
	C477.460480,351.838959 471.763153,347.026611 464.865295,342.329742 
	C464.572327,344.170929 464.243439,344.863129 464.411194,345.400116 
	C465.805145,349.862427 463.666229,351.049103 459.631714,351.028259 
	C444.979126,350.952545 430.325928,350.999603 415.672943,351.001465 
	C410.344604,351.002167 405.014099,350.912384 399.688477,351.033569 
	C393.951935,351.164093 391.188049,353.968445 391.027466,359.719147 
	C390.920593,363.545990 391.169525,367.388580 390.930450,371.203522 
	C390.807709,373.162598 389.964691,375.076538 389.432404,377.066101 
	C391.045502,377.198395 393.234589,377.377930 395.535400,378.073486 
	C395.323395,378.968140 395.072205,379.472504 394.665405,379.707062 
	C384.935120,385.317596 375.263367,391.036682 365.412903,396.428955 
	C360.390015,399.178528 359.859985,400.585968 362.984467,405.631287 
	C365.253693,409.295624 367.150635,413.194855 369.502533,416.800903 
	C370.206970,417.880920 371.884338,418.326416 373.113007,419.438232 
	C373.477600,423.751038 376.637482,422.678406 378.981995,423.470825 
	C378.834229,429.813812 383.592560,431.710815 387.528839,434.371124 
	C388.875854,435.281494 389.879822,436.699402 391.040527,437.885406 
	C386.668976,437.927094 382.297455,437.968781 377.460297,438.008545 
	C376.669952,432.433960 371.230072,430.519135 368.760742,426.484009 
	C367.124054,423.809448 365.493378,421.127014 363.984436,418.379761 
	C362.728546,416.093201 362.073364,413.330261 360.393188,411.460144 
	C358.279663,409.107635 355.265198,409.025116 354.238617,412.560547 
	C353.074036,416.571289 350.482056,417.153687 347.067078,417.009918 
	C345.411560,416.940247 343.747772,417.064240 342.092560,416.991119 
	C338.979156,416.853668 337.222809,417.805542 336.828979,421.365601 
	C336.649323,422.989624 334.909393,424.929565 333.357269,425.816620 
	C329.543854,427.995941 326.709198,432.151947 321.480621,431.682098 
	C318.936707,431.453461 316.332428,431.896942 313.402344,432.024689 
	C313.998657,428.259857 314.948090,424.506531 315.991730,420.384827 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M485.998474,355.999207 
	C486.534363,351.445129 487.204803,346.899872 487.504395,342.330292 
	C487.572937,341.284973 486.363037,340.155884 485.375488,339.035278 
	C486.775482,335.703491 488.337891,332.272369 490.340912,329.121307 
	C502.358276,310.216217 514.476868,291.375488 527.200684,272.282288 
	C532.103394,272.372253 536.442810,272.323456 540.597290,273.175934 
	C542.561279,273.578918 544.234314,275.633850 545.921997,277.077301 
	C547.352051,278.300446 548.556396,279.785614 549.969910,281.030823 
	C550.912598,281.861298 552.105042,282.402679 553.084290,283.198334 
	C555.603638,285.245239 558.198914,287.225250 560.521301,289.481689 
	C564.170044,293.026825 569.641113,295.409027 568.982910,301.909210 
	C568.989868,302.708954 568.996887,303.508698 568.976440,304.707825 
	C568.992737,306.961060 568.713318,308.891724 569.132385,310.656555 
	C572.118469,323.234558 575.247192,335.778748 578.311279,348.771881 
	C577.031006,348.808411 575.765503,348.420349 574.509705,348.002747 
	C567.624146,345.712738 566.467834,344.041718 566.710693,336.826569 
	C566.821777,333.522797 566.901062,330.217957 566.995605,326.083679 
	C566.787964,324.014008 566.579529,322.774261 566.371094,321.534515 
	C565.934265,321.494354 565.497375,321.454163 565.060547,321.414001 
	C564.379761,322.945312 563.698975,324.476624 563.018188,326.007935 
	C563.014038,326.451416 563.009888,326.894928 562.640259,327.653381 
	C560.636841,326.938507 559.072815,325.752899 557.337219,324.928925 
	C555.993164,324.290894 554.447449,323.838409 552.968811,323.766479 
	C547.661377,323.508240 541.971741,324.613281 537.111023,323.060455 
	C527.864868,320.106720 518.982727,322.178589 511.894928,326.458374 
	C504.462128,330.946442 497.874512,338.300629 499.688049,349.149628 
	C494.917694,351.534210 490.458069,353.766724 485.998474,355.999207 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M569.359375,301.931396 
	C569.641113,295.409027 564.170044,293.026825 560.521301,289.481689 
	C558.198914,287.225250 555.603638,285.245239 553.084290,283.198334 
	C552.105042,282.402679 550.912598,281.861298 549.969910,281.030823 
	C548.556396,279.785614 547.352051,278.300446 545.921997,277.077301 
	C544.234314,275.633850 542.561279,273.578918 540.597290,273.175934 
	C536.442810,272.323456 532.103394,272.372253 527.419922,272.025696 
	C527.105164,270.549194 527.210266,269.098358 527.325012,267.514130 
	C516.701050,267.514130 506.332672,267.517029 495.964325,267.512604 
	C492.197754,267.511017 488.431152,267.492065 484.329132,267.245056 
	C484.406464,265.786743 484.725006,264.522186 485.249939,263.350067 
	C486.755219,259.988495 488.290436,256.638031 489.914520,253.332672 
	C491.705994,249.686478 493.609100,246.095154 495.381439,242.640137 
	C495.941376,242.688431 496.238525,242.668427 496.252319,242.721710 
	C498.168365,250.129181 503.967834,249.118500 509.524933,249.041809 
	C518.903442,248.912338 528.469849,250.065918 537.025452,243.785294 
	C538.742188,244.063293 540.205566,244.651810 541.908875,245.481277 
	C540.468445,250.115524 538.400452,254.525345 543.328613,258.340057 
	C543.757996,258.672363 543.714783,259.576904 543.964172,260.185730 
	C546.437927,266.224701 548.636475,272.403259 551.555603,278.219727 
	C552.716309,280.532471 555.249207,282.418762 557.561707,283.842377 
	C564.686646,288.228638 570.344299,295.381317 580.085083,294.897766 
	C585.847229,294.611725 591.667725,295.673431 597.470093,295.974121 
	C602.280518,296.223419 606.726624,296.218048 610.768372,292.191254 
	C614.370361,288.602570 621.132812,292.198029 621.907166,297.628601 
	C622.163696,299.427582 621.984070,301.288727 622.004639,303.939026 
	C622.002808,305.503937 622.000183,306.251282 621.658325,307.121094 
	C620.880920,308.819733 620.442749,310.395905 620.004639,311.972046 
	C619.159119,311.816284 618.313721,311.660522 617.209229,310.943939 
	C616.425171,309.723419 615.900085,309.063660 615.375000,308.403931 
	C614.899475,308.971558 614.423889,309.539185 613.948364,310.106781 
	C612.194214,309.845093 610.440063,309.583405 608.363037,308.785736 
	C608.697327,307.159058 609.523804,306.133850 609.975647,304.963867 
	C610.861145,302.671143 611.537781,300.297760 612.300842,297.957764 
	C609.296631,297.790192 607.769958,298.821411 606.272095,298.781158 
	C598.007324,298.559113 589.704773,298.469177 581.505493,297.538788 
	C576.328003,296.951294 572.820374,297.941589 571.995972,301.962585 
	C571.242554,301.959595 570.489197,301.956604 569.359375,301.931396 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M480.677185,344.008667 
	C480.619446,345.514008 480.232758,347.013672 479.846069,348.513367 
	C479.128815,348.271088 477.792511,348.012573 477.798340,347.789215 
	C477.828400,346.640900 478.203339,345.501556 478.745911,344.172363 
	C479.472046,343.991455 479.910126,343.997253 480.677185,344.008667 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M330.739716,203.224945 
	C333.899811,205.707611 336.960876,207.979080 337.122833,212.668900 
	C337.309326,218.068909 335.579010,221.228928 331.186951,224.901901 
	C325.806763,229.401215 319.701385,230.611862 313.335480,232.900513 
	C314.251007,231.271698 315.187408,228.808777 316.898346,228.014709 
	C325.342194,224.095932 330.707886,218.635330 329.101715,208.465881 
	C328.854126,206.898361 329.939362,205.120331 330.739716,203.224945 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M682.755737,373.156616 
	C683.748962,374.029175 684.485779,375.037201 686.106140,377.254120 
	C683.796265,376.377838 682.630432,375.935547 681.238586,375.261292 
	C681.508118,374.450226 682.003662,373.871155 682.755737,373.156616 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M698.692871,356.062897 
	C698.518433,356.606689 698.043762,357.223846 697.265015,357.910645 
	C697.438171,357.365631 697.915405,356.750946 698.692871,356.062897 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M633.002441,277.996338 
	C633.112305,273.571808 634.264587,270.136658 639.665955,269.975830 
	C646.437622,269.774261 647.537354,270.712952 649.156799,277.296448 
	C649.511353,278.737762 651.407776,280.585083 652.830566,280.821289 
	C656.211243,281.382538 657.431030,282.938110 658.176453,286.225555 
	C658.867798,289.274780 660.410217,292.487396 662.504517,294.782410 
	C666.040039,298.656769 670.306702,301.864105 674.269104,305.348419 
	C674.516541,305.566010 675.012573,305.744965 675.020325,305.955597 
	C675.264893,312.607452 682.053467,313.609375 685.082336,317.785461 
	C686.314880,319.484772 686.395569,322.019531 687.000183,324.583405 
	C678.245422,323.353546 669.490784,321.714447 660.325562,319.626282 
	C655.767273,312.388397 651.852112,305.442627 647.404907,298.856079 
	C643.198303,292.625916 638.407471,286.791748 633.946472,280.729797 
	C633.400940,279.988525 633.306152,278.915558 633.002441,277.996338 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M632.648438,278.066437 
	C633.306152,278.915558 633.400940,279.988525 633.946472,280.729797 
	C638.407471,286.791748 643.198303,292.625916 647.404907,298.856079 
	C651.852112,305.442627 655.767273,312.388397 659.928711,319.522400 
	C647.538879,317.334686 635.135132,314.801849 622.370361,311.691406 
	C622.005310,309.742096 622.001465,308.370361 621.997559,306.998627 
	C622.000183,306.251282 622.002808,305.503937 622.006958,304.383179 
	C622.829956,303.278931 624.174072,302.675201 624.387756,301.796539 
	C625.732727,296.265442 627.138428,290.714478 627.883667,285.085327 
	C628.314758,281.829315 629.119873,279.383392 632.648438,278.066437 
z"/>
<path fill="#CED5DA" opacity="1.000000" stroke="none" 
	d="
M572.375610,302.049622 
	C572.820374,297.941589 576.328003,296.951294 581.505493,297.538788 
	C589.704773,298.469177 598.007324,298.559113 606.272095,298.781158 
	C607.769958,298.821411 609.296631,297.790192 612.300842,297.957764 
	C611.537781,300.297760 610.861145,302.671143 609.975647,304.963867 
	C609.523804,306.133850 608.697327,307.159058 608.020081,308.622437 
	C603.956665,308.343719 599.888489,307.816559 595.875305,307.014404 
	C588.152222,305.470703 580.460144,303.771423 572.375610,302.049622 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M621.658325,307.121094 
	C622.001465,308.370361 622.005310,309.742096 622.014404,311.522766 
	C621.571838,311.953339 621.124146,311.975006 620.340576,311.984375 
	C620.442749,310.395905 620.880920,308.819733 621.658325,307.121094 
z"/>
<path fill="#C8CFD5" opacity="1.000000" stroke="none" 
	d="
M614.201294,310.311035 
	C614.423889,309.539185 614.899475,308.971558 615.375000,308.403931 
	C615.900085,309.063660 616.425171,309.723419 616.964050,310.729248 
	C616.136658,310.888702 615.295471,310.701996 614.201294,310.311035 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M689.224304,325.262939 
	C689.932373,325.163940 690.885925,325.280212 691.920044,325.689880 
	C691.156921,325.814911 690.313416,325.646545 689.224304,325.262939 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M608.986389,269.289856 
	C602.887146,277.080658 595.078491,280.094086 585.240356,279.045319 
	C575.910583,278.050751 561.373047,262.218323 561.958130,252.310181 
	C564.482727,252.857956 566.954346,253.746445 569.735474,255.174011 
	C570.049744,265.332672 583.391357,274.657135 592.517700,272.985077 
	C597.278687,272.112854 601.296814,269.061035 601.000000,266.000000 
	C603.613708,266.998840 606.227478,267.997650 608.986389,269.289856 
z"/>
<path fill="#9AA7AF" opacity="1.000000" stroke="none" 
	d="
M600.686890,265.750885 
	C601.296814,269.061035 597.278687,272.112854 592.517700,272.985077 
	C583.391357,274.657135 570.049744,265.332672 570.064209,255.313675 
	C572.728577,256.412628 575.989807,257.365326 577.895935,259.517914 
	C582.500671,264.718018 587.388489,267.565186 594.515564,265.234833 
	C596.253418,264.666626 598.409851,265.378479 600.686890,265.750885 
z"/>
<path fill="#E8EBF0" opacity="1.000000" stroke="none" 
	d="
M357.953552,304.734680 
	C349.308929,299.995148 340.676025,295.234192 332.015778,290.523499 
	C325.953186,287.225769 319.853271,283.996704 313.395813,280.820618 
	C313.021606,280.904266 312.997314,280.967072 313.074890,280.634033 
	C312.432037,279.849670 311.711639,279.398346 310.991211,278.947021 
	C310.991211,278.947021 310.985931,279.014069 310.822632,278.749908 
	C310.091217,278.144196 309.523071,277.802612 308.954926,277.461060 
	C308.935730,277.280060 308.848694,277.141754 308.265167,276.781250 
	C306.887909,276.258850 305.939301,276.001343 304.990662,275.743835 
	C306.582214,274.257904 307.952728,272.363678 309.805664,271.360565 
	C316.040100,267.985413 322.446259,264.927399 328.910645,261.432861 
	C335.707947,257.952026 342.433380,254.890854 349.041656,251.594711 
	C357.577454,247.337143 366.019562,242.891769 374.764496,238.308563 
	C375.367798,239.479034 375.708557,240.868195 375.855286,242.939880 
	C375.236969,244.525070 374.682861,245.390060 374.408295,246.336182 
	C371.263702,257.171753 368.122589,268.008667 365.060669,278.867798 
	C362.634033,287.473877 360.318420,296.111206 357.953552,304.734680 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M358.076355,305.077393 
	C360.318420,296.111206 362.634033,287.473877 365.060669,278.867798 
	C368.122589,268.008667 371.263702,257.171753 374.408295,246.336182 
	C374.682861,245.390060 375.236969,244.525070 375.812073,243.279327 
	C376.099426,247.719498 376.298737,252.502151 376.345123,257.286285 
	C376.367157,259.565063 376.098114,261.846710 375.729553,264.905640 
	C373.396301,275.481689 375.673553,283.978516 382.798920,291.206604 
	C381.386597,292.257904 380.184143,293.075836 378.598969,293.922729 
	C373.079651,295.542603 371.925293,298.101501 374.112000,303.756653 
	C375.738739,303.181305 377.382233,302.600006 379.319824,301.883179 
	C380.442902,301.531036 381.271881,301.314423 382.007629,301.477905 
	C380.204407,306.272125 378.664124,310.762451 376.727570,315.074768 
	C375.328003,318.191284 373.564606,321.185272 371.606049,323.983765 
	C371.056793,324.768585 369.242310,324.667938 367.994324,324.663452 
	C367.969391,324.146912 367.958282,323.940369 367.947205,323.733795 
	C367.578339,321.988464 367.626312,319.995209 366.763092,318.543945 
	C364.095001,314.058350 361.079651,309.779327 358.076355,305.077393 
z"/>
<path fill="#BEC5CB" opacity="1.000000" stroke="none" 
	d="
M383.008789,290.973175 
	C375.673553,283.978516 373.396301,275.481689 375.726807,265.343262 
	C382.003082,271.327209 388.905640,277.051697 390.643799,286.789856 
	C388.594543,288.539856 386.773804,289.742645 384.629578,290.956543 
	C383.873688,290.969543 383.441254,290.971344 383.008789,290.973175 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M384.953033,290.945404 
	C386.773804,289.742645 388.594543,288.539856 390.713684,287.157104 
	C394.116211,285.942047 397.220428,284.906952 400.660553,283.919983 
	C401.652924,287.024109 402.309326,290.080139 402.961029,293.521973 
	C400.833435,294.819763 398.710571,295.731750 395.911377,296.829102 
	C392.826050,297.680054 390.416992,298.345734 388.006531,298.575195 
	C387.323181,295.598785 389.909058,291.672150 384.953033,290.945404 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M669.661987,438.001190 
	C669.152649,438.468506 668.314270,438.951660 667.246460,439.246765 
	C667.789001,438.711456 668.560974,438.364258 669.661987,438.001190 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M311.013062,279.278442 
	C311.711639,279.398346 312.432037,279.849670 313.107666,280.641724 
	C312.687897,280.948730 312.312988,280.915039 311.660339,280.886230 
	C311.266754,280.464050 311.150848,280.036957 311.013062,279.278442 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M305.240845,276.033325 
	C305.939301,276.001343 306.887909,276.258850 308.146942,276.737305 
	C307.468567,276.746460 306.479797,276.534637 305.240845,276.033325 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M309.146240,277.742981 
	C309.523071,277.802612 310.091217,278.144196 310.856262,278.751160 
	C310.481262,278.686035 309.909393,278.355438 309.146240,277.742981 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M600.057739,429.572723 
	C595.568909,430.768341 593.783142,427.057251 591.390503,424.248291 
	C591.044250,422.899963 590.520203,421.953491 589.996155,421.007019 
	C589.996155,421.007019 590.000000,421.000000 589.934570,420.640442 
	C588.053040,416.598572 586.083923,412.981415 584.478333,409.209564 
	C583.517822,406.953430 582.448486,404.403748 582.624634,402.076660 
	C582.958801,397.662567 584.351196,393.339417 584.927124,388.929291 
	C585.376038,385.492279 583.945557,383.075806 580.017090,382.995911 
	C581.793945,381.013397 583.570801,379.030884 585.721313,377.039276 
	C587.390198,382.084229 588.685486,387.138336 589.621948,392.624939 
	C588.189453,394.870850 586.005798,396.851471 586.249207,398.466248 
	C586.988708,403.372253 588.678284,408.133575 589.974060,412.957672 
	C590.835510,416.164490 592.797119,417.607971 596.143677,416.921417 
	C597.446167,421.003754 598.748657,425.086090 600.057739,429.572723 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M596.123535,416.514191 
	C592.797119,417.607971 590.835510,416.164490 589.974060,412.957672 
	C588.678284,408.133575 586.988708,403.372253 586.249207,398.466248 
	C586.005798,396.851471 588.189453,394.870850 589.643799,393.027039 
	C592.050720,400.700043 594.077026,408.403503 596.123535,416.514191 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M499.998779,348.997559 
	C497.874512,338.300629 504.462128,330.946442 511.894928,326.458374 
	C518.982727,322.178589 527.864868,320.106720 537.111023,323.060455 
	C541.971741,324.613281 547.661377,323.508240 552.968811,323.766479 
	C554.447449,323.838409 555.993164,324.290894 557.337219,324.928925 
	C559.072815,325.752899 560.636841,326.938507 562.638550,327.986023 
	C562.337280,330.739441 561.672180,333.475220 561.005859,336.859680 
	C561.004700,337.508362 560.997559,338.000000 560.609131,337.979645 
	C554.727661,337.028015 549.904419,334.843414 545.665100,331.089233 
	C544.257385,329.842651 541.885864,329.684540 539.613525,329.123199 
	C529.282898,334.100616 519.275269,338.946747 509.314972,343.888214 
	C506.144928,345.460968 503.100616,347.287262 499.998779,348.997559 
z"/>
<path fill="#B4BCC2" opacity="1.000000" stroke="none" 
	d="
M560.997559,338.000000 
	C560.997559,338.000000 561.004700,337.508362 561.007935,337.262756 
	C564.077332,336.717407 565.157471,338.245514 565.020508,341.148834 
	C564.895691,343.791809 564.987000,346.444946 564.969238,349.559814 
	C557.945129,345.356262 556.725708,352.366180 554.142029,355.481262 
	C548.904236,351.867188 543.939941,348.441742 538.564636,344.576294 
	C538.181885,342.490601 538.210083,340.844940 538.457764,339.143860 
	C538.677246,339.088440 539.125854,339.006531 539.508789,339.026794 
	C546.238647,340.083527 551.873535,342.503693 556.560547,348.704803 
	C558.294250,344.522034 559.645874,341.261017 560.997559,338.000000 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M579.903748,383.354340 
	C583.945557,383.075806 585.376038,385.492279 584.927124,388.929291 
	C584.351196,393.339417 582.958801,397.662567 582.624634,402.076660 
	C582.448486,404.403748 583.517822,406.953430 584.478333,409.209564 
	C586.083923,412.981415 588.053040,416.598572 589.930908,420.641968 
	C587.160400,418.913818 582.990967,417.424042 581.737061,414.627197 
	C577.305603,404.742432 575.098938,394.342804 579.903748,383.354340 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M563.415222,326.006409 
	C563.698975,324.476624 564.379761,322.945312 565.060547,321.414001 
	C565.497375,321.454163 565.934265,321.494354 566.371094,321.534515 
	C566.579529,322.774261 566.787964,324.014008 566.993286,325.629333 
	C565.930908,326.004883 564.871582,326.004883 563.415222,326.006409 
z"/>
<path fill="#B7BEC4" opacity="1.000000" stroke="none" 
	d="
M590.007446,421.335632 
	C590.520203,421.953491 591.044250,422.899963 591.290283,423.923279 
	C590.681091,423.221466 590.349976,422.442871 590.007446,421.335632 
z"/>
<path fill="#838C93" opacity="1.000000" stroke="none" 
	d="
M538.238281,339.199280 
	C538.210083,340.844940 538.181885,342.490601 538.198853,344.545227 
	C538.162781,347.336487 538.081482,349.718811 537.765747,352.879608 
	C537.035217,359.785248 536.539062,365.912384 536.042969,372.039520 
	C534.977722,375.526123 537.646240,376.781464 539.646362,378.785217 
	C533.760620,379.009766 533.952209,383.500610 533.148682,387.632446 
	C533.220947,390.720764 533.286926,393.370514 533.352966,396.020233 
	C533.075256,398.978271 532.797485,401.936279 532.497681,405.328949 
	C532.290771,408.734497 532.259583,411.725739 531.870422,414.669647 
	C531.580322,416.864288 530.827393,418.997772 530.124756,421.486084 
	C529.351746,419.292023 528.550476,416.799255 528.149719,414.243652 
	C526.532410,403.931335 525.038635,393.599670 523.571411,382.429535 
	C528.100586,366.729675 532.557678,351.875458 537.246094,337.236023 
	C537.731079,338.033630 537.984680,338.616455 538.238281,339.199280 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M533.693481,396.039886 
	C533.286926,393.370514 533.220947,390.720764 533.405823,387.477264 
	C534.197510,385.931000 534.738342,384.978516 535.122437,384.301971 
	C539.053101,386.617096 541.984009,388.343384 544.914978,390.069672 
	C547.914612,392.801056 550.914307,395.532471 553.990234,398.593689 
	C547.042542,400.392426 540.234436,400.351532 533.693481,396.039886 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M536.422852,372.024994 
	C536.539062,365.912384 537.035217,359.785248 537.776489,353.324829 
	C543.370361,352.896057 544.719971,356.472351 544.964111,360.804993 
	C545.377014,368.134949 543.566467,370.516785 536.422852,372.024994 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M545.291382,390.056091 
	C541.984009,388.343384 539.053101,386.617096 535.122437,384.301971 
	C534.738342,384.978516 534.197510,385.931000 533.399536,387.038635 
	C533.952209,383.500610 533.760620,379.009766 539.796509,379.105713 
	C547.748047,382.450317 549.280701,384.122345 550.402771,390.612762 
	C548.732178,390.411560 547.200012,390.227020 545.291382,390.056091 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M537.014771,337.021240 
	C532.557678,351.875458 528.100586,366.729675 523.476440,382.008240 
	C499.829926,380.299377 476.350555,378.166199 452.440674,376.047485 
	C452.957916,374.893250 453.670502,373.300232 454.891083,372.623749 
	C463.670044,367.758057 472.552002,363.078308 481.703430,358.178162 
	C483.147369,357.449860 484.289185,356.882965 485.714722,356.157654 
	C490.458069,353.766724 494.917694,351.534210 499.688049,349.149628 
	C503.100616,347.287262 506.144928,345.460968 509.314972,343.888214 
	C519.275269,338.946747 529.282898,334.100616 539.621582,329.514893 
	C539.929260,330.595642 539.887573,331.379120 539.508911,332.208313 
	C538.452942,333.843079 537.733826,335.432159 537.014771,337.021240 
z"/>
<path fill="#AFB9BF" opacity="1.000000" stroke="none" 
	d="
M378.974304,423.082397 
	C376.637482,422.678406 373.477600,423.751038 373.369965,419.227234 
	C374.770294,417.477692 376.063080,416.428314 377.026855,415.133911 
	C384.261475,405.417664 391.486633,395.693298 398.594879,385.884583 
	C401.595490,381.743988 404.306946,377.393860 407.149811,373.138947 
	C408.168915,372.373749 409.188019,371.608551 410.565643,370.574158 
	C412.810913,378.371185 414.938965,385.761200 417.083435,393.941956 
	C418.233246,399.395050 419.213898,404.101898 420.555817,408.703461 
	C421.395233,411.581940 422.778748,414.301758 423.918091,417.092773 
	C423.928711,417.519867 423.939362,417.946930 423.962738,418.694458 
	C424.168762,419.851990 424.362061,420.689087 424.775085,422.184631 
	C425.182404,424.936249 425.369995,427.029449 425.605438,429.656158 
	C423.616028,429.773499 421.827301,430.153992 420.122223,429.921478 
	C416.724792,429.458221 413.373291,428.658051 409.752319,427.991791 
	C409.334717,427.985535 409.167664,427.984558 408.679016,427.753845 
	C405.896942,427.364594 403.436523,427.205048 400.603790,427.040222 
	C399.486847,427.029541 398.742188,427.024139 397.739594,426.752625 
	C394.986816,425.992584 392.491974,425.498688 389.585266,424.997650 
	C385.773682,424.354462 382.373993,423.718414 378.974304,423.082397 
z"/>
<path fill="#E6E9EE" opacity="1.000000" stroke="none" 
	d="
M406.908997,372.936584 
	C404.306946,377.393860 401.595490,381.743988 398.594879,385.884583 
	C391.486633,395.693298 384.261475,405.417664 377.026855,415.133911 
	C376.063080,416.428314 374.770294,417.477692 373.372925,418.851593 
	C371.884338,418.326416 370.206970,417.880920 369.502533,416.800903 
	C367.150635,413.194855 365.253693,409.295624 362.984467,405.631287 
	C359.859985,400.585968 360.390015,399.178528 365.412903,396.428955 
	C375.263367,391.036682 384.935120,385.317596 394.665405,379.707062 
	C395.072205,379.472504 395.323395,378.968140 395.826294,378.297729 
	C399.559692,376.248718 403.113922,374.491486 406.908997,372.936584 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M423.944580,416.637085 
	C422.778748,414.301758 421.395233,411.581940 420.555817,408.703461 
	C419.213898,404.101898 418.233246,399.395050 417.123291,394.331085 
	C423.120667,395.343201 425.902740,400.453888 424.819824,407.793671 
	C424.410034,410.570862 424.246704,413.384399 423.944580,416.637085 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M410.002838,427.997070 
	C413.373291,428.658051 416.724792,429.458221 420.122223,429.921478 
	C421.827301,430.153992 423.616028,429.773499 425.605438,429.656158 
	C425.369995,427.029449 425.182404,424.936249 424.990540,422.430237 
	C426.321198,421.685028 427.656097,421.352600 429.108582,420.990875 
	C428.546112,424.211914 428.211029,426.826569 427.587067,429.370361 
	C427.328247,430.425415 426.618286,431.499237 425.827820,432.272491 
	C421.995270,436.021454 418.065735,439.671295 414.085266,443.678131 
	C414.000000,444.000000 414.003784,443.997284 413.710602,443.856476 
	C411.926178,443.569824 410.434937,443.423981 408.943695,443.278168 
	C405.739655,442.062927 402.535614,440.847687 399.305389,439.128693 
	C402.068359,436.675201 405.036743,434.928223 407.569550,432.688629 
	C408.800812,431.599945 409.217438,429.589966 410.002838,427.997070 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M414.982483,445.009247 
	C414.880157,445.185760 414.411407,445.132019 414.196899,444.834747 
	C413.989502,444.357391 413.996643,444.177338 414.003784,443.997284 
	C414.003784,443.997284 414.000000,444.000000 413.998016,444.001953 
	C414.358948,444.280182 414.721863,444.556458 414.982483,445.009247 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M409.752319,427.991791 
	C409.217438,429.589966 408.800812,431.599945 407.569550,432.688629 
	C405.036743,434.928223 402.068359,436.675201 399.035400,438.892761 
	C396.464600,438.791077 394.137604,438.421600 391.425568,437.968750 
	C389.879822,436.699402 388.875854,435.281494 387.528839,434.371124 
	C383.592560,431.710815 378.834229,429.813812 378.981995,423.470825 
	C382.373993,423.718414 385.773682,424.354462 389.650391,425.321899 
	C392.273376,428.859924 395.187012,427.642181 397.997528,427.018738 
	C398.742188,427.024139 399.486847,427.029541 400.746185,427.379761 
	C403.840790,427.810913 406.420685,427.897247 409.000610,427.983551 
	C409.167664,427.984558 409.334717,427.985535 409.752319,427.991791 
z"/>
<path fill="#CBD2D9" opacity="1.000000" stroke="none" 
	d="
M413.710602,443.856476 
	C413.996643,444.177338 413.989502,444.357391 413.962494,444.808044 
	C412.425537,444.648010 410.908478,444.217438 409.167542,443.532501 
	C410.434937,443.423981 411.926178,443.569824 413.710602,443.856476 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M311.369354,434.985474 
	C311.497101,434.438293 311.912323,433.790009 312.618286,433.047943 
	C312.491608,433.597565 312.074188,434.240967 311.369354,434.985474 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M352.546570,483.625916 
	C350.678314,483.267426 349.191925,482.899872 347.705536,482.532349 
	C347.839325,481.937195 347.973145,481.342041 348.106964,480.746887 
	C350.719147,480.614044 353.334290,480.356018 355.941925,480.417297 
	C356.836395,480.438324 357.712128,481.258179 358.596436,481.713074 
	C357.755005,482.040924 356.925049,482.403351 356.069214,482.687836 
	C355.033722,483.031952 353.976318,483.310120 352.546570,483.625916 
z"/>
<path fill="#D5D9DE" opacity="1.000000" stroke="none" 
	d="
M412.754150,471.654907 
	C413.829041,472.082428 414.670593,472.813751 415.321991,472.671906 
	C421.436951,471.340424 423.718018,474.579712 424.486237,480.957581 
	C417.432343,480.957581 410.539398,480.957581 403.363373,480.957581 
	C405.044128,476.256622 407.790497,473.000793 412.754150,471.654907 
z"/>
<path fill="#C4CCD1" opacity="1.000000" stroke="none" 
	d="
M388.007935,299.011414 
	C390.416992,298.345734 392.826050,297.680054 395.612732,296.999817 
	C394.948853,308.007874 389.337952,316.857178 382.419342,324.999023 
	C379.731812,328.161713 376.713318,331.086945 373.566589,333.796234 
	C372.099365,335.059509 369.813995,335.324036 368.168304,336.438293 
	C364.514740,338.911987 361.005341,341.602600 357.488464,344.272369 
	C355.682068,345.643646 353.985748,347.159943 351.349579,347.888916 
	C351.254242,348.854309 350.787079,349.981812 351.125549,350.758057 
	C352.956482,354.957336 352.767914,357.487885 349.066467,361.677521 
	C344.408997,366.949310 342.838257,374.919952 339.791748,381.661041 
	C338.923340,383.582581 337.840271,385.567261 336.363647,387.024323 
	C331.574463,391.750061 326.556915,396.244385 321.318329,400.915863 
	C321.351471,399.264557 321.693604,397.525208 322.034149,395.402985 
	C322.050812,394.598175 322.069061,394.176208 322.401672,393.308777 
	C323.077087,391.887024 323.438080,390.910675 323.799072,389.934357 
	C325.837921,382.219757 327.876740,374.505157 329.910645,366.393921 
	C332.283936,357.612549 334.662201,349.227783 337.386414,340.318848 
	C338.112061,338.807495 338.491730,337.820282 338.871399,336.833038 
	C338.895782,336.124084 338.920166,335.415100 339.336914,334.242920 
	C343.380493,333.186157 347.054138,332.702423 350.676056,331.964935 
	C353.653625,331.358612 356.688232,330.740417 359.480896,329.597870 
	C362.456024,328.380737 365.176514,326.541229 368.008179,324.973450 
	C369.242310,324.667938 371.056793,324.768585 371.606049,323.983765 
	C373.564606,321.185272 375.328003,318.191284 376.727570,315.074768 
	C378.664124,310.762451 380.204407,306.272125 382.345184,301.394012 
	C384.519958,300.290466 386.263947,299.650940 388.007935,299.011414 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M367.994324,324.663452 
	C365.176514,326.541229 362.456024,328.380737 359.480896,329.597870 
	C356.688232,330.740417 353.653625,331.358612 350.676056,331.964935 
	C347.054138,332.702423 343.380493,333.186157 339.364319,333.889221 
	C340.316162,329.580902 341.632874,325.163055 342.951691,320.353607 
	C342.953796,319.962036 342.999451,319.983398 343.381714,320.075867 
	C352.239502,323.720428 357.737701,324.047638 361.038910,318.826691 
	C363.182220,320.327606 365.325531,321.828552 367.708008,323.531616 
	C367.958282,323.940369 367.969391,324.146912 367.994324,324.663452 
z"/>
<path fill="#BBC2C8" opacity="1.000000" stroke="none" 
	d="
M338.570038,336.972931 
	C338.491730,337.820282 338.112061,338.807495 337.420837,339.909546 
	C337.495728,339.053894 337.882202,338.083344 338.570038,336.972931 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M323.500122,390.062378 
	C323.438080,390.910675 323.077087,391.887024 322.409668,392.992279 
	C322.469238,392.144287 322.835205,391.167328 323.500122,390.062378 
z"/>
<path fill="#A9B3BB" opacity="1.000000" stroke="none" 
	d="
M360.866089,318.544250 
	C357.737701,324.047638 352.239502,323.720428 343.398010,320.095093 
	C343.835083,313.485565 347.953186,310.886841 353.722717,313.594788 
	C356.225098,314.769287 358.380310,316.683563 360.866089,318.544250 
z"/>
<path fill="#A4AFB6" opacity="1.000000" stroke="none" 
	d="
M388.006531,298.575165 
	C386.263947,299.650940 384.519958,300.290466 382.438416,301.013885 
	C381.271881,301.314423 380.442902,301.531036 379.317993,301.490143 
	C379.008636,298.786346 378.995178,296.340027 378.981689,293.893738 
	C380.184143,293.075836 381.386597,292.257904 382.798920,291.206604 
	C383.441254,290.971344 383.873688,290.969543 384.629578,290.956543 
	C389.909058,291.672150 387.323181,295.598785 388.006531,298.575165 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M378.598969,293.922729 
	C378.995178,296.340027 379.008636,298.786346 379.023926,301.625671 
	C377.382233,302.600006 375.738739,303.181305 374.112000,303.756653 
	C371.925293,298.101501 373.079651,295.542603 378.598969,293.922729 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M539.845886,332.162598 
	C539.887573,331.379120 539.929260,330.595642 539.962952,329.420471 
	C541.885864,329.684540 544.257385,329.842651 545.665100,331.089233 
	C549.904419,334.843414 554.727661,337.028015 560.609131,337.979645 
	C559.645874,341.261017 558.294250,344.522034 556.560547,348.704803 
	C551.873535,342.503693 546.238647,340.083527 539.486816,338.635620 
	C539.336487,336.203705 539.591187,334.183136 539.845886,332.162598 
z"/>
<path fill="#94A1A8" opacity="1.000000" stroke="none" 
	d="
M539.508911,332.208313 
	C539.591187,334.183136 539.336487,336.203705 539.103882,338.615387 
	C539.125854,339.006531 538.677246,339.088440 538.457764,339.143860 
	C537.984680,338.616455 537.731079,338.033630 537.246094,337.236023 
	C537.733826,335.432159 538.452942,333.843079 539.508911,332.208313 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M397.739594,426.752625 
	C395.187012,427.642181 392.273376,428.859924 390.062256,425.329041 
	C392.491974,425.498688 394.986816,425.992584 397.739594,426.752625 
z"/>
<path fill="#C0C7CD" opacity="1.000000" stroke="none" 
	d="
M408.679016,427.753845 
	C406.420685,427.897247 403.840790,427.810913 401.118469,427.385071 
	C403.436523,427.205048 405.896942,427.364594 408.679016,427.753845 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
